import { Component } from 'react';
import { Input } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { injectIntl } from 'react-intl';
import { Icon, FormFieldWrapper, Toast } from '@plone/volto/components';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';

import copySVG from '@plone/volto/icons/copy.svg';

const messages = defineMessages({
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
  messageCopied: {
    id: 'Link URL copied.',
    defaultMessage: 'Link URL copied.',
  },
});

export interface AnchorWidgetProps {
  id: string;
  title: string;
  description?: string;
  required?: boolean;
  error?: string[];
  value?: string;
  focus?: boolean;
  onChange?: (id: string, value?: string) => void;
  onBlur?: (id: string, value?: string) => void;
  onClick?: () => void;
  onEdit?: (() => void) | null;
  onDelete?: (() => void) | null;
  icon?: {
    xmlns: string;
    viewBox: string;
    content: string;
  } | null;
  iconAction?: (() => void) | null;
  minLength?: number | null;
  maxLength?: number | null;
  wrapped?: boolean;
  placeholder?: string | null;
  isDisabled?: boolean;
  path?: string;
  toastify?: {
    toast: {
      success: (content: JSX.Element) => void;
    };
  };
  intl: IntlShape;
}

/**
 * The simple text widget.
 *
 * It is the default fallback widget, so if no other widget is found based on
 * passed field properties, it will be used.
 */
class AnchorWidget extends Component<AnchorWidgetProps> {
  static defaultProps: Partial<AnchorWidgetProps> = {
    description: undefined,
    required: false,
    error: [],
    value: '',
    focus: false,
    onChange: () => {},
    onBlur: () => {},
    onClick: () => {},
    onEdit: null,
    onDelete: null,
    icon: null,
    iconAction: null,
    minLength: null,
    maxLength: null,
  };

  node: HTMLInputElement | null = null;

  componentDidMount() {
    if (this.props.focus && this.node) {
      this.node.focus();
    }
  }

  onCopy(link: string) {
    navigator.clipboard.writeText(link);
    if (this.props.toastify) {
      this.props.toastify.toast.success(
        <Toast
          success
          title={this.props.intl.formatMessage(messages.success)}
          content={this.props.intl.formatMessage(messages.messageCopied)}
        />,
      );
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const {
      id,
      value,
      onChange,
      onBlur,
      onClick,
      minLength,
      maxLength,
      placeholder,
      isDisabled,
      path,
    } = this.props;

    const link = `${path}#${value}`;

    return (
      <FormFieldWrapper {...this.props} className="text">
        <Input
          id={`field-${id}`}
          name={id}
          value={value || ''}
          disabled={isDisabled}
          placeholder={placeholder}
          onChange={({ target }) => {
            let value = target.value;
            value = value.replace(/[^a-zA-Z0-9-_:.]/gi, '');
            return onChange
              ? onChange(id, value === '' ? undefined : value)
              : null;
          }}
          ref={(node: any) => {
            this.node = node;
          }}
          onBlur={({ target }: any) => {
            let value = target.value;
            value = value.replace(/[^a-zA-Z0-9-_:.]/gi, '');
            return onBlur ? onBlur(id, value === '' ? undefined : value) : null;
          }}
          onClick={() => onClick && onClick()}
          minLength={minLength || undefined}
          maxLength={maxLength || undefined}
        />
        <div
          className="copy-link-url"
          title={link}
          onClick={() => this.onCopy(link)}
          onKeyDown={() => this.onCopy(link)}
          role="button"
          tabIndex={0}
        >
          <Icon name={copySVG} size="24px" />
          &nbsp;
          <FormattedMessage id="Copy link URL" defaultMessage="Copy link URL" />
        </div>
      </FormFieldWrapper>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state: any, props: AnchorWidgetProps) => ({
      path: state.content.data?.['@id'],
    }),
    {},
  ),
  injectLazyLibs(['toastify']),
)(AnchorWidget);
