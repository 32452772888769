import React from 'react';
import { iconMappings as defaultIconMappings } from './icons/IconMappings';
import AACSB from './icons/AACSB';
import EmptyCircle from './icons/EmptyCircle';

export interface FollowUsFieldProps {
  title?: string;
  description?: string;
  links: Array<any>;
  iconMappings?: Record<
    string,
    React.FC<{ size: 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL'; color: string }>
  >;
  AACSBLogo?: React.FC<{
    size: 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL';
    color: string;
  }>;
}

const getLinkId = (link: string): string => {
  return link.split('/').pop() || '';
};

const FollowUs = ({
  links,
  title,
  description,
  iconMappings = defaultIconMappings,
  AACSBLogo = AACSB,
}: FollowUsFieldProps) => {
  const aacsb = links?.find((link) => {
    return getLinkId(link['@id']) === 'aacsb';
  });

  return (
    <div className="follow-us-wrapper">
      {title && <h2>{title}</h2>}
      {description && <p>{description}</p>}
      <div className="follow-us-container">
        <div className="follow-us-grid">
          {links?.length > 0 ? (
            links.map((link) => {
              const linkId = getLinkId(link['@id']);
              if (linkId === 'aacsb') {
                return null;
              }
              const IconComponent = iconMappings[linkId];
              return (
                <div className="follow-us-item" key={link['@id']}>
                  {IconComponent ? (
                    <IconComponent size="M" color="gray" />
                  ) : (
                    <EmptyCircle size="M" color="gray" />
                  )}
                  <a
                    className="follow-us-link"
                    href={link.remoteUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link.title}
                  </a>
                </div>
              );
            })
          ) : (
            <p>No social media links available.</p>
          )}
        </div>
        {aacsb && (
          <div className="follow-us-logo" aria-label="AACSB">
            <a href={aacsb.remoteUrl} target="_blank" rel="noopener noreferrer">
              <AACSBLogo size="L" color="gray" />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default FollowUs;
