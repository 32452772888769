import { cloneDeepSchema } from '@plone/volto/helpers/Utils/Utils';
import { defineMessages } from 'react-intl';
import baseMessages from '@fhnw/messages/base';
const messages = defineMessages({
  Default: {
    defaultMessage: 'Default',
    id: 'default',
  },
  Tabs: {
    defaultMessage: 'Tabs',
    id: 'tabs',
  },
  TabsBlock: {
    defaultMessage: 'Tabs',
    id: 'tabs-block',
  },
  Tab: {
    id: 'tab',
    defaultMessage: 'Tab',
  },
});

const tabSchema = (props) => {
  const intl = props.intl;
  return {
    title: intl.formatMessage(messages.Tab),

    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.Default),
        fields: ['title'],
      },
    ],

    properties: {
      title: {
        title: intl.formatMessage(baseMessages.TabTitle),
      },

      hideTitle: {
        title: intl.formatMessage(baseMessages.HideTitle),
        type: 'boolean',
      },
    },

    required: [],
  };
};

const toggleIconField = (schema, child) => {
  const cloned = cloneDeepSchema(schema);

  cloned.fieldsets[0].fields = [...cloned.fieldsets[0].fields];

  return cloned;
};

export const blockSchema = (props) => {
  const intl = props.intl;
  return {
    title: intl.formatMessage(messages.TabsBlock),
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.Default),
        fields: ['hideEmptyTabs', 'data'],
      },
    ],
    properties: {
      data: {
        title: intl.formatMessage(messages.Tabs),
        type: 'tabs',
        schema: tabSchema(props),
        schemaExtender: toggleIconField,
      },

      hideEmptyTabs: {
        title: intl.formatMessage(baseMessages.HideEmptyTabs),
        type: 'boolean',
      },
    },
    required: [],
  };
};
