import { useIntl } from 'react-intl';
import { BlockDataForm } from '@plone/volto/components';
import { itemSchema } from './schema';

const TestimonialsData = (props: any) => {
  const { block, data, onChangeBlock } = props;
  const intl = useIntl();
  const schema = itemSchema({ ...props, intl });

  return (
    <BlockDataForm
      schema={schema}
      title={schema.title}
      onChangeField={(id: string, value: object) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      formData={data}
      block={block}
    />
  );
};

export default TestimonialsData;
