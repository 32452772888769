import { defineMessages, useIntl } from 'react-intl';
import { getTitleByToken } from '@fhnw/components/Blocks/SolarSearch/lib';
import { Icon } from '@plone/volto/components';
import { vocabType } from '@fhnw/components/Blocks/SolarSearch/lib';
import {
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Table,
} from 'semantic-ui-react';
import arrowDown from '@fhnw/icons/arrow-l.svg';

const messages = defineMessages({
  faculty: {
    id: 'Faculty',
    defaultMessage: 'Faculty',
  },
  type: {
    id: 'Type',
    defaultMessage: 'Type',
  },
  ContinuingProgrammCourse: {
    id: 'Continuing Education Programmes and Courses',
    defaultMessage: 'Continuing Education Programmes and Courses',
  },
  location: {
    id: 'Location Placeholder',
    defaultMessage: 'Location',
  },
});

type Props = {
  sortOrder: string;
  result: any;
  continueEducationType: vocabType[];
  fieldsofstudy: vocabType[];
  location: vocabType[];
  onClickRow: (item: any) => void;
  onSortable: () => void;
};

const TableTemplateCourseView = ({
  sortOrder,
  result,
  continueEducationType,
  fieldsofstudy,
  location,
  onSortable,
  onClickRow,
}: Props) => {
  const intl = useIntl();
  return (
    <div className="search__table">
      <Table className="table-result-view-container">
        <TableHeader>
          <TableRow>
            <TableHeaderCell className="sortable-header" onClick={onSortable}>
              {intl.formatMessage(messages.ContinuingProgrammCourse)}
              <Icon
                className={sortOrder ? sortOrder : 'reverse faded'}
                name={arrowDown}
                size="20px"
              />
            </TableHeaderCell>
            <TableHeaderCell>
              {intl.formatMessage(messages.type)}
            </TableHeaderCell>
            <TableHeaderCell>
              {intl.formatMessage(messages.faculty)}
            </TableHeaderCell>
            <TableHeaderCell>
              {intl.formatMessage(messages.location)}
            </TableHeaderCell>
          </TableRow>
        </TableHeader>

        <TableBody>
          {result?.items.map((item: any, index: number) => (
            <TableRow
              key={index}
              className="clickable-table-row"
              onClick={() => {
                onClickRow(item);
              }}
            >
              <TableCell>{item.title}</TableCell>
              <TableCell>
                {item?.extras?.continuing_education_types?.map(
                  (item: string, index: number, array: any) => {
                    return (
                      <span key={index}>
                        {getTitleByToken(item, continueEducationType)}
                        {index < array.length - 1 && ', '}
                      </span>
                    );
                  },
                )}
              </TableCell>

              <TableCell>
                {item?.extras?.taxonomy_fieldsofstudy?.map(
                  (item: string, index: number, array: any) => {
                    return (
                      <span key={index}>
                        {getTitleByToken(item, fieldsofstudy)}{' '}
                        {index < array.length - 1 && ', '}
                      </span>
                    );
                  },
                )}
              </TableCell>

              <TableCell>
                {item?.extras?.location_category?.map(
                  (item: string, index: number, array: any) => {
                    return (
                      <span key={index}>
                        {getTitleByToken(item, location)}
                        {index < array.length - 1 && ', '}
                      </span>
                    );
                  },
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TableTemplateCourseView;
