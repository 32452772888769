import {
  Checkbox as RACCheckbox,
  CheckboxProps as RACCheckboxProps,
  Text,
} from 'react-aria-components';

type CheckboxProps = {
  /**
   * A label for the input field.
   */
  label?: string;
  /**
   * Description or additional information about the input field.
   */
  description?: string;
  /**
   * A label, indicating that the field is required.
   */
  labelRequired?: string;
  /**
   * The children of the checkbox.
   */
  children?: React.ReactNode;
  /**
   * Indicates whether the input field is required or not.
   */
  isRequired?: boolean;
} & Omit<RACCheckboxProps, 'children'>;

const Checkbox = ({
  label,
  labelRequired,
  isRequired,
  description,
  children,
  ...props
}: CheckboxProps) => {
  return (
    <RACCheckbox {...props}>
      <>
        <div className="checkbox">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width="10"
            height="10"
          >
            <path
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              d="m6 10 3 3 6-6"
            />
          </svg>
        </div>
        {label || children}
        {description && <Text slot="description">{description}</Text>}
      </>
    </RACCheckbox>
  );
};

export default Checkbox;
