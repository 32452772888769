/**
 * Language selector component.
 * @module components/LanguageSelector/LanguageSelector
 */

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { find, map } from 'lodash';

import {
  Helmet,
  langmap,
  flattenToAppURL,
  toReactIntlLang,
} from '@plone/volto/helpers';

import config from '@plone/volto/registry';

import { defineMessages, useIntl } from 'react-intl';

interface LanguageSelectorProps {
  onClickAction?: () => void;
}

interface AppState {
  content: {
    data?: {
      '@components'?: {
        translations?: {
          items?: { language: string; '@id': string }[];
        };
      };
    };
  };
  navroot: {
    data: {
      navroot: {
        id: string;
      };
    };
  };
  intl: {
    locale: string;
  };
}

interface LanguageInfo {
  nativeName: string;
  englishName: string;
}

const messages = defineMessages({
  switchLanguageTo: {
    id: 'Switch to',
    defaultMessage: 'Switch to',
  },
});

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  onClickAction,
}) => {
  const intl = useIntl();
  const currentLang = useSelector<AppState, string>((state) => {
    return state.intl.locale;
  });
  const location = useLocation();
  const pathname = location.pathname;

  const translations = useSelector<
    AppState,
    { language: string; '@id': string }[] | undefined
  >((state) => state.content.data?.['@components']?.translations?.items);

  const { settings } = config;

  return settings.isMultilingual ? (
    <div className="language-selector">
      {map(settings.supportedLanguages, (lang) => {
        const translation = find(translations, { language: lang });
        const langInfo = langmap[lang as keyof typeof langmap] as LanguageInfo;
        if (
          (pathname === '/en' ||
            pathname === '/de' ||
            pathname === '/de/edit' ||
            pathname === '/en/edit' ||
            pathname === '/de/add' ||
            pathname === '/en/add') &&
          lang === 'fr'
        ) {
          return null;
        }

        if (currentLang === toReactIntlLang(lang)) {
          return (
            <span
              className={cx('current-language', {
                selected: toReactIntlLang(lang) === currentLang,
              })}
              key={`language-selector-${lang}`}
            >
              {langInfo.nativeName.slice(0, 2)}
            </span>
          );
        }

        return translation?.language ? (
          <Link
            aria-label={`${intl.formatMessage(messages.switchLanguageTo)} ${langInfo.nativeName.toLowerCase()}`}
            className={cx({ selected: toReactIntlLang(lang) === currentLang })}
            to={translation ? flattenToAppURL(translation['@id']) : `/${lang}`}
            title={langInfo.nativeName}
            onClick={onClickAction}
            key={`language-selector-${lang}`}
          >
            {langInfo.nativeName.slice(0, 2)}
          </Link>
        ) : null;
      })}
    </div>
  ) : (
    <Helmet>
      <html lang={toReactIntlLang(settings.defaultLanguage)} />
    </Helmet>
  );
};

export default LanguageSelector;
