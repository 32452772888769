import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'PDF Block',
    defaultMessage: 'PDF Block',
  },
  hideToolbar: {
    id: 'Hide toolbar',
    defaultMessage: 'Hide toolbar',
  },
  hideNavbar: {
    id: 'Hide navbar',
    defaultMessage: 'Hide navbar',
  },
  disableScroll: {
    id: 'Disable page scroll',
    defaultMessage: 'Disable page scroll',
  },
  initialPage: {
    id: 'Initial page',
    defaultMessage: 'Initial page',
  },
  fitPageWidth: {
    id: 'Fit page width',
    defaultMessage: 'Fit page width',
  },
  clickToDownload: {
    id: 'Show download',
    defaultMessage: 'Show download',
  },
  showPagesPreview: {
    id: 'Show pages preview',
    defaultMessage: 'Show pages preview',
  },
});

const PDFBlockSchema = () => {
  const intl = useIntl();
  return {
    title: intl.formatMessage(messages.title),
    properties: {
      hideToolbar: {
        type: 'boolean',
        title: intl.formatMessage(messages.hideToolbar),
      },
      hideNavbar: {
        type: 'boolean',
        title: intl.formatMessage(messages.hideNavbar),
      },
      disableScroll: {
        type: 'boolean',
        title: intl.formatMessage(messages.disableScroll),
      },
      initialPage: {
        type: 'number',
        title: intl.formatMessage(messages.initialPage),
        default: 1,
      },
      fitPageWidth: {
        type: 'boolean',
        title: intl.formatMessage(messages.fitPageWidth),
      },
      clickToDownload: {
        type: 'boolean',
        title: intl.formatMessage(messages.clickToDownload),
      },
      showPagesPreview: {
        type: 'boolean',
        title: intl.formatMessage(messages.showPagesPreview),
      },
    },
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'hideToolbar',
          'hideNavbar',
          'disableScroll',
          'fitPageWidth',
          'clickToDownload',
          'showPagesPreview',
          'initialPage',
        ],
      },
    ],
    required: [],
  };
};

export default PDFBlockSchema;
