import { defineMessages } from 'react-intl';

export const EventDetailMessages = defineMessages({
  datetime: {
    id: 'block.eventdetail.datetime',
    defaultMessage: 'Date and Time',
  },
  location: {
    id: 'block.eventdetail.location',
    defaultMessage: 'Location',
  },
  organiser: {
    id: 'block.eventdetail.organiser',
    defaultMessage: 'Organiser',
  },
  costs: {
    id: 'block.eventdetail.costs',
    defaultMessage: 'Costs',
  },
  eventlanguage: {
    id: 'block.eventdetail.eventlanguage',
    defaultMessage: 'Event language',
  },
});
