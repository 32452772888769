import { Icon } from '@fhnw/components/Icon/Icon';

interface BlogsProps {
  size: 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL';
  color: string;
  onClick?: () => void;
}

const Blogs = (props: BlogsProps) => (
  <Icon color={props.color} onClick={props.onClick} size={props.size}>
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Desktop_2nd-Round"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="BlogsFollow"
          transform="translate(-544.000000, -3900.000000)"
          fill="#000000"
        >
          <path
            d="M559.909091,3931.81818 C551.1225,3931.81818 544,3924.69568 544,3915.90909 C544,3907.1233 551.1225,3900 559.909091,3900 C568.695682,3900 575.818182,3907.1233 575.818182,3915.90909 C575.818182,3924.69568 568.695682,3931.81818 559.909091,3931.81818 L559.909091,3931.81818 Z M565.979,3918.976 C565.979,3919.49734 565.871334,3919.95066 565.656,3920.336 C565.440666,3920.72134 565.146002,3921.0415 564.772,3921.2965 C564.397998,3921.5515 563.961669,3921.74417 563.463,3921.8745 C562.964331,3922.00483 562.437336,3922.07 561.882,3922.07 L556,3922.07 L556,3910 L562.715,3910 C563.134335,3910 563.513998,3910.09067 563.854,3910.272 C564.194002,3910.45333 564.482999,3910.6885 564.721,3910.9775 C564.959001,3911.2665 565.143166,3911.59516 565.2735,3911.9635 C565.403834,3912.33184 565.469,3912.70866 565.469,3913.094 C565.469,3913.672 565.324501,3914.216 565.0355,3914.726 C564.746499,3915.236 564.313003,3915.62133 563.735,3915.882 C564.426337,3916.086 564.973165,3916.44866 565.3755,3916.97 C565.777835,3917.49134 565.979,3918.16 565.979,3918.976 Z M563.14,3918.415 C563.14,3918.041 563.032334,3917.72367 562.817,3917.463 C562.601666,3917.20233 562.329668,3917.072 562.001,3917.072 L558.788,3917.072 L558.788,3919.707 L561.882,3919.707 C562.244668,3919.707 562.544999,3919.588 562.783,3919.35 C563.021001,3919.112 563.14,3918.80034 563.14,3918.415 Z M558.788,3912.38 L558.788,3914.879 L561.525,3914.879 C561.831002,3914.879 562.102999,3914.777 562.341,3914.573 C562.579001,3914.369 562.698,3914.05167 562.698,3913.621 C562.698,3913.22433 562.593168,3912.91833 562.3835,3912.703 C562.173832,3912.48767 561.921668,3912.38 561.627,3912.38 L558.788,3912.38 Z"
            id="blog"
            fill="currentColor"
          ></path>
        </g>
      </g>
    </svg>
  </Icon>
);

export default Blogs;
