import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { HeroExpander } from '@fhnw/types/hero';
import type { Content } from '@plone/types';
import { setSidebarTab, setMetadataFocus } from '@plone/volto/actions';
import { Image, UniversalLink } from '@plone/volto/components';
import { Button } from '@plone/components';
import cx from 'classnames';
import { FormState } from '@fhnw/types/formState';
import type { AnyAction } from 'redux';

const messages = defineMessages({
  heroInheritanceSource: {
    id: 'Hero inheritance source',
    defaultMessage: 'Hero inheritance source',
  },
  editHeroImage: {
    id: 'Edit Hero image',
    defaultMessage: 'Edit Hero image',
  },
});

const emptyHeroData = {};

export const Hero = ({ content }: { content: any }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const contactContentType = content['@type'] === 'Contact';
  const teamContactContentType = content['@type'] === 'TeamContact';

  const formData = useSelector<FormState, Content>(
    (state) => state.form?.global,
  );

  const inheritedHeroData = useSelector<FormState, HeroExpander>(
    (state) => state.content?.data?.['@components']?.hero || emptyHeroData,
  );

  const notDefault = (data: HeroExpander) => {
    return !isEmpty(data?.hero_image);
  };

  const showHeroOptions = () => {
    dispatch(setSidebarTab(0) as AnyAction);
    dispatch(setMetadataFocus('hero', 'hero_image') as AnyAction);
  };

  const data = !isEmpty(formData)
    ? notDefault(formData as HeroExpander)
      ? { ...formData, hero_inheritance_path: undefined }
      : inheritedHeroData
    : notDefault(content as HeroExpander)
      ? content
      : inheritedHeroData;

  return (
    <div className="hero-wrapper">
      {data.hero_image && (
        <Image
          item={data.hero_image}
          imageField="image"
          alt=""
          className={cx('hero', data?.hero_image_cropping?.token)}
        />
      )}
      {contactContentType && (
        <div className="contact-information-hero">
          <h1>
            {content.name_affix1 ?? ''} {content.first_name} {content.last_name}{' '}
          </h1>
        </div>
      )}

      {teamContactContentType && (
        <div className="contact-information-hero">
          <h1>{content.team_name}</h1>
        </div>
      )}

      {!isEmpty(formData) && (
        <div className="edit-options">
          <Button onPress={showHeroOptions} className="edit-hero">
            {intl.formatMessage(messages.editHeroImage)}
          </Button>
          {!isEmpty(data.hero_inheritance_path) && (
            <div className="hero-inheritance text-size-xxs p-xxs centered">
              {intl.formatMessage(messages.heroInheritanceSource)} <br />
              <UniversalLink
                href={data.hero_inheritance_path}
                openLinkInNewTab={true}
              >
                {data.hero_inheritance_path}
              </UniversalLink>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
