import { CSSProperties, useMemo, useState, useEffect } from 'react';
import { CRMFormBlockData } from './types';
import clsx from 'clsx';

interface CRMFormBlockViewProps {
  data: CRMFormBlockData;
  className?: string;
  style?: CSSProperties;
  isEditMode?: boolean;
}

const CRMFormBlockView = (props: CRMFormBlockViewProps) => {
  const { data, isEditMode = false, style, className } = props;
  const { formBlockId, websiteId, hostname, version } = data;

  const [iframeHeight, setIframeHeight] = useState<string | number>('200px');

  const iframeSrc = useMemo(() => {
    if (!formBlockId || !websiteId || !hostname || !version) {
      return null;
    }
    const params = new URLSearchParams({
      websiteId,
      hostname,
      formBlockId,
      version,
    });
    return `/crm-form-loader.html?${params.toString()}`;
  }, [formBlockId, websiteId, hostname, version]);

  // Effect to listen for messages from the iframe for resizing
  useEffect(() => {
    if (!iframeSrc) {
      return;
    }

    const handleIframeMessage = (event: MessageEvent) => {
      if (event.origin !== window.location.origin) {
        return;
      }

      const messageData = event.data;
      if (messageData && messageData.type === 'crmFormResize') {
        const height = messageData.height;
        if (typeof height === 'number' && height > 0) {
          setIframeHeight(height + 10);
        }
      }
    };
    window.addEventListener('message', handleIframeMessage);

    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, [iframeSrc]);

  if (!iframeSrc) {
    return;
  }

  return (
    <div
      className={clsx('crm-form-block', className)}
      style={{ ...style, position: 'relative' }}
      data-form-block-id={formBlockId}
    >
      <iframe
        src={iframeSrc}
        title={`CRM Form ${formBlockId}`}
        className="crm-form-iframe"
        style={{
          height:
            typeof iframeHeight === 'number'
              ? `${iframeHeight}px`
              : iframeHeight,
        }}
      />
      {isEditMode && <div className="crm-form-overlay"></div>}
    </div>
  );
};

export default CRMFormBlockView;
