import useDataFetchEffect from './useDataFetchEffect';
import { getQueryStringResults } from '@plone/volto/actions';
import type { FollowUsData } from '@fhnw/types/followUs';

// Represents a single item in the query configuration.
interface QueryItem {
  /**
   * The field name that the query applies to.
   * Example: 'path', 'portal_type', or any valid field in the system.
   */
  i: string;

  /**
   * The operation to perform on the field.
   * Example: 'plone.app.querystring.operation.string.absolutePath',
   * or 'plone.app.querystring.operation.selection.any'.
   */
  o: string;

  /**
   * The value(s) associated with the field.
   * - This can be a single string (e.g., '/example-path').
   * - Or it can be an array of strings (e.g., ['Document', 'Folder']).
   */
  v: string | string[];
}

// Represents the entire query configuration used by the hook.
interface QueryData {
  /**
   * The maximum number of items to fetch in a single batch.
   * Defaults to 0 if not provided.
   */
  b_size?: number;

  /**
   * The depth of the query, indicating how far into the hierarchy the query should search.
   * Defaults to '1' if not provided.
   */
  depth?: string;

  /**
   * The field by which the results should be sorted.
   * Example: 'sortable_title', 'created', or any sortable field.
   */
  sort_on?: string;

  /**
   * The sort order for the results.
   * Example: 'ascending' or 'descending'.
   * Defaults to 'ascending'.
   */
  sort_order?: string;

  /**
   * The starting index for the query results.
   * Typically used for pagination. Defaults to 0.
   */
  b_start?: number;

  /**
   * The metadata fields to include in the query results.
   * Example: '_all' to include all available fields.
   */
  metadata_fields?: string;

  /**
   * An array of query items that define the filters and conditions for the query.
   * Each item specifies a field, operation, and value(s).
   */
  query: QueryItem[];
}

const generateQuery = (data: QueryData): QueryData => {
  return {
    ...data,
    b_size: data.b_size || 0,
    depth: data.depth || '1',
    sort_on: data.sort_on,
    sort_order: data.sort_order || 'ascending',
    b_start: 0,
    metadata_fields: '_all',
    query: [
      ...(data.query.filter((q) => q.i !== 'portal_type') || []),
      {
        i: 'portal_type',
        o: 'plone.app.querystring.operation.selection.any',
        v: ['Link'],
      },
    ],
  };
};

const extractUniqueKey = (data: FollowUsData): string | null => {
  const pathQuery = data.query?.query?.find((q) => q.i === 'path');
  return pathQuery
    ? pathQuery.v.toString()
    : data.query?.query?.[0]?.v.toString();
};

const validateData = (data: FollowUsData): boolean =>
  !!data.query?.query && data.query.query.length > 0;

/**
 * A custom hook for handling query effects using `useDataFetchEffect`.
 *
 * @param data - The input data containing query configuration.
 * @param blockId - The block identifier for the query.
 */
const useQueryEffect = (data: FollowUsData, blockId: string): void => {
  useDataFetchEffect({
    data,
    block: blockId,
    generateQuery: () => generateQuery(data.query),
    extractUniqueKey: () => extractUniqueKey(data),
    createAction: (path, query) =>
      getQueryStringResults(path, query, blockId, 0),
    validateData: () => validateData(data),
    dependencies: [data.query],
  });
};

export default useQueryEffect;
