import React, { useState, useEffect, useMemo } from 'react';
import Select from '@fhnw/components/Form/Select/Select';
import { getVocabulary } from '@plone/volto/actions';
import ResultItemPreviewImage from '@kitconcept/volto-solr/components/theme/SolrSearch/resultItems/helpers/ResultItemPreviewImage';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { solrSearchContent } from '@kitconcept/volto-solr/actions';
import { encodeConditionTree } from '@kitconcept/volto-solr/components/theme/SolrSearch/SearchConditions';
import { Icon } from '@plone/volto/components';
import Teaser from '@fhnw/components/Teaser/Teaser';
import {
  createFacetToken,
  mapToEnabledSelectOptions,
  getEnabledTokens,
  EnabledSelectOptions,
  mapTokenToLabel,
} from '@fhnw/components/Blocks/SolarSearch/lib';
import { mapItemToTeaserProps } from '../Listing/variations/mapItemToTeaserProps';
import TableViewTemplate from './TableTemplate/TableTemplateProgramme';
import { encodeExtraConditions } from './ExtraConditions';
import SearchInput from './SearchInput';
import Button from '@fhnw/components/Button/Button';
import resetSVG from '@plone/volto/icons/reset.svg';

const messages = defineMessages({
  faculty: {
    id: 'Faculty',
    defaultMessage: 'Faculty',
  },
  type: {
    id: 'Type',
    defaultMessage: 'Type',
  },
  location: {
    id: 'Location Placeholder',
    defaultMessage: 'Location',
  },
  clearFilters: {
    id: 'Clear all filters',
    defaultMessage: 'Clear all filters',
  },
  moreResults: {
    id: 'More results',
    defaultMessage: 'More results',
  },
  studiesFound: {
    id: 'Studies found',
    defaultMessage: 'Studies found',
  },
  of: {
    id: 'of',
    defaultMessage: 'of',
  },
});

// NOTE: an immutable empty array MUST be used as the default value for the select components
const emptySelection: EnabledSelectOptions[] = [];

const DegreeProgramme = (props: any) => {
  const { data } = props;
  const { locale } = useIntl();
  const intl = useIntl();
  const batch_size = parseInt(data?.batch_size);
  const showImage = data?.['show-image'];
  const history = useHistory();
  const [isMounted, setIsMounted] = useState(false);
  const [fieldOfStudySelect, setFieldsofstudySelect] = useState<
    EnabledSelectOptions[] | []
  >([]);
  const [degreeProgramSelect, setDegreeProgramSelect] = useState<
    EnabledSelectOptions[] | []
  >([]);
  const [locationCategorySelect, setLocationCategorySelect] = useState<
    EnabledSelectOptions[] | []
  >([]);

  const [searchInput, setSearchInput] = useState('');
  const fieldsofstudy = useSelector(
    (state: any) =>
      state.vocabularies['collective.taxonomy.fieldsofstudy']?.items ||
      emptySelection,
  );
  const degreeProgramType = useSelector(
    (state: any) =>
      state.vocabularies['collective.taxonomy.degreeprogramtype']?.items ||
      emptySelection,
  );
  const location = useSelector(
    (state: any) =>
      state.vocabularies['collective.taxonomy.city']?.items || emptySelection,
  );

  // Sync FieldOfStudy
  const initialFieldsofstudySelected = useMemo(() => {
    return data.fieldsofstudy?.map((token: string) =>
      mapTokenToLabel(token, fieldsofstudy),
    );
  }, [data.fieldsofstudy, fieldsofstudy]);

  useEffect(() => {
    if (data.fieldsofstudy?.length > 0) {
      setFieldsofstudySelect(initialFieldsofstudySelected);
    } else {
      setFieldsofstudySelect([]);
    }
  }, [data.fieldsofstudy, initialFieldsofstudySelected]);

  // Sync degreeProgram
  const initialDegreeProgramTypeSelected = useMemo(() => {
    return data.degreeprogramtype?.map((token: string) =>
      mapTokenToLabel(token, degreeProgramType),
    );
  }, [data.degreeprogramtype, degreeProgramType]);

  useEffect(() => {
    if (data.degreeprogramtype?.length > 0) {
      setDegreeProgramSelect(initialDegreeProgramTypeSelected);
    } else {
      setDegreeProgramSelect([]);
    }
  }, [data.degreeprogramtype, initialDegreeProgramTypeSelected]);

  // Sync Location
  const initialLocationSelected = useMemo(() => {
    return data.citydegree?.map((token: string) =>
      mapTokenToLabel(token, location),
    );
  }, [data.citydegree, location]);

  useEffect(() => {
    if (data.citydegree?.length > 0) {
      setLocationCategorySelect(initialLocationSelected);
    } else {
      setLocationCategorySelect([]);
    }
  }, [data.citydegree, initialLocationSelected]);

  const dispatch = useDispatch();
  const blockuuid = props.block;
  const result = useSelector(
    (state: any) => state.solrsearch.subrequests?.[blockuuid],
  );

  const enabledTokens = getEnabledTokens(result);
  const fieldsofstudyWithEnable: EnabledSelectOptions[] =
    mapToEnabledSelectOptions(fieldsofstudy, 0, enabledTokens);
  const degreeProgramTypeWithEnable: EnabledSelectOptions[] =
    mapToEnabledSelectOptions(degreeProgramType, 1, enabledTokens);
  const locationCategoryWithEnable: EnabledSelectOptions[] =
    mapToEnabledSelectOptions(location, 2, enabledTokens);

  const onChangeFieldOfStudySelect = (value: any) => {
    setFieldsofstudySelect(value);
  };
  const onChangeDegreeProgramSelect = (value: any) => {
    setDegreeProgramSelect(value);
  };

  const onChangeLocationCategorySelect = (value: any) => {
    setLocationCategorySelect(value);
  };

  const onChangeSearchInput = (value: string) => {
    setSearchInput(value);
  };

  const onClickRow = (item: any) => {
    history.push(item['@id']);
  };

  const onResetClick = () => {
    setSearchInput('');
    setLocationCategorySelect([]);
    setDegreeProgramSelect([]);
    setFieldsofstudySelect([]);
  };
  const onSolrSearch = (b_size: number) => {
    const facetConditions = {
      taxonomy_fieldsofstudy: {
        c: createFacetToken(fieldOfStudySelect),
        m: true,
      },
      degree_program_types: {
        c: createFacetToken(degreeProgramSelect),
        m: true,
      },
      location_category: {
        c: createFacetToken(locationCategorySelect),
        m: true,
      },
    };

    const extra_conditions = [
      ['Subject', 'string', { in: data.keywords || emptySelection }],
    ];

    dispatch(
      solrSearchContent(
        '/',
        {
          SearchableText: searchInput.length < 3 ? '' : searchInput,
          group_select: 1,
          doEmptySearch: true,
          facet_conditions: encodeConditionTree(facetConditions),
          extra_conditions: encodeExtraConditions(extra_conditions as any),
          b_size,
          lang: locale,
        },
        blockuuid,
      ),
    );
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (!batch_size || batch_size > result?.total) {
      onSolrSearch(result?.total);
    } else onSolrSearch(batch_size);
  }, [
    dispatch,
    searchInput,
    blockuuid,
    fieldOfStudySelect,
    degreeProgramSelect,
    locationCategorySelect,
    data.keywords,
    batch_size,
    result?.total,
  ]);

  React.useEffect(() => {
    if (fieldsofstudy.length === 0) {
      dispatch(
        /* @ts-ignore */
        getVocabulary({
          vocabNameOrURL: 'collective.taxonomy.fieldsofstudy',
          size: -1,
        }),
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  React.useEffect(() => {
    if (degreeProgramType.length === 0) {
      dispatch(
        /* @ts-ignore */
        getVocabulary({
          vocabNameOrURL: 'collective.taxonomy.degreeprogramtype',
          size: -1,
        }),
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  React.useEffect(() => {
    if (location.length === 0) {
      dispatch(
        /* @ts-ignore */
        getVocabulary({
          vocabNameOrURL: 'collective.taxonomy.city',
          size: -1,
        }),
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  const onMoreResult = () => {
    onSolrSearch(result?.items?.length + batch_size);
  };

  return (
    <div>
      <SearchInput value={searchInput} onChange={onChangeSearchInput} />
      <div className="select-container">
        {data['faculty-filter'] && (
          <Select
            options={fieldsofstudyWithEnable}
            name="taxonomy_fieldsofstudy"
            onChange={onChangeFieldOfStudySelect}
            isMulti={true}
            value={fieldOfStudySelect}
            placeholder={intl.formatMessage(messages.faculty)}
          />
        )}
        {data['type-filter'] && (
          <Select
            options={degreeProgramTypeWithEnable}
            name="degree_program_types"
            onChange={onChangeDegreeProgramSelect}
            isMulti={true}
            value={degreeProgramSelect}
            placeholder={intl.formatMessage(messages.type)}
          />
        )}
        {data['location-filter'] && (
          <Select
            options={locationCategoryWithEnable}
            name="location_category"
            onChange={onChangeLocationCategorySelect}
            isMulti={true}
            value={locationCategorySelect}
            placeholder={intl.formatMessage(messages.location)}
          />
        )}
      </div>

      <div className="reset-toggle-container">
        <div>
          <Button type="button" styles="clear-all" onPress={onResetClick}>
            <Icon name={resetSVG} size="20px" />
            <span>{intl.formatMessage(messages.clearFilters)}</span>
          </Button>
        </div>
        <div>
          {result?.total}
          {`  ${intl.formatMessage(messages.studiesFound)}`}
        </div>
      </div>

      {data?.template === 'table' && (
        <TableViewTemplate
          result={result}
          degreeProgramType={degreeProgramType}
          fieldsofstudy={fieldsofstudy}
          location={location}
          onClickRow={onClickRow}
        />
      )}
      <div>
        {(data?.template === 'grid' || data?.template === 'list') &&
          isMounted && (
            <div className="resultview-container">
              <ul
                className={
                  data.template === 'grid' ? 'teaser-col-3' : 'teaser-listing'
                }
              >
                {result?.items.map((item: any, index: number) => {
                  return (
                    <Teaser
                      key={index}
                      {...mapItemToTeaserProps(
                        { ...item, effective: null },
                        {
                          locale,
                          ...data,
                          showTeaserImage: data?.['show-image'],
                          showTeaserText: data?.['show-text'],
                          showExtendedInfo: data?.['show-information'],
                        },
                        data.template === 'grid' ? 'default' : 'linklist',
                      )}
                      imageComponentProps={
                        showImage ? { item, Wrapper: null } : undefined
                      }
                      imageComponent={
                        showImage ? ResultItemPreviewImage : undefined
                      }
                    />
                  );
                })}
              </ul>
            </div>
          )}
      </div>
      <div className="more-result-container">
        <div className="more-container">
          <button onClick={onMoreResult}>
            {result?.items?.length >= result?.total
              ? ''
              : ` ${intl.formatMessage(messages.moreResults)}`}
          </button>
          <span>{`${result?.items?.length} ${intl.formatMessage(messages.of)} ${result?.total}`}</span>
        </div>
      </div>
    </div>
  );
};

export default DegreeProgramme;
