import { useIntl } from 'react-intl';
import cx from 'classnames';
import { ContactMessages as messages } from './index';
import { useState, KeyboardEvent, useRef } from 'react';
import ContactDetails from './ContactDetails';
import { ContactProps } from './index';

const getVocabSuffixLabel = (vocab: any, value: string) =>
  (vocab ? vocab.find((i: { value: string }) => i.value === value) : null)
    ?.label;

const ContactItem = (props: any) => {
  const intl = useIntl();
  const {
    content,
    phoneSuffixVocab,
    isContactTypes,
    functionRole,
    functionSuffixVocab,
    relatedContacts,
  } = props;

  const [openState, setOpenState] = useState(1);
  const wrapperRef = useRef<HTMLDivElement>(null);

  // Check if content is an array (multiple contacts with same totalFunctionRole)
  const isContentArray = Array.isArray(content);

  // If content is an array, use the first item for header information
  // Otherwise use the content object directly
  const primaryContact = isContentArray ? content[0] : content;

  const teamContactContentType = primaryContact['@type'] === 'TeamContact';
  const isPersonalPage: boolean = primaryContact.hasOwnProperty('parent');

  const toggleAccordion = () => {
    setOpenState(openState === 1 ? 0 : 1);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      toggleAccordion();
    }
  };

  return (
    <div
      ref={wrapperRef}
      className={cx({
        'contact-item': !relatedContacts,
        'related-contact-item': relatedContacts,
        open: openState > 0,
      })}
    >
      <button
        className="contact-header"
        onClick={toggleAccordion}
        onKeyDown={handleKeyDown}
        aria-expanded={openState > 0}
        aria-controls="contact-content"
      >
        <h4>
          {functionRole
            ? getVocabSuffixLabel(functionSuffixVocab, functionRole)
            : intl.formatMessage(messages.contact)}
        </h4>
      </button>
      <div
        className={`contact-content ${openState > 0 ? 'open' : ''}`}
        role="region"
        aria-labelledby="contact-header"
      >
        <div>
          {isContentArray ? (
            // Render all contacts in the array
            content.map((contactItem: ContactProps) => (
              <address
                key={contactItem['@id']}
                className="contact-content-sidebar"
              >
                <ContactDetails
                  isPersonalPage={isPersonalPage}
                  content={contactItem}
                  isContactTypes={isContactTypes}
                  phoneSuffixVocab={phoneSuffixVocab}
                  teamContactContentType={
                    contactItem['@type'] === 'TeamContact'
                  }
                  intl={intl}
                />
              </address>
            ))
          ) : (
            // Render single contact
            <address className="contact-content-sidebar">
              <ContactDetails
                isPersonalPage={isPersonalPage}
                content={content}
                isContactTypes={isContactTypes}
                phoneSuffixVocab={phoneSuffixVocab}
                teamContactContentType={teamContactContentType}
                intl={intl}
              />
            </address>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactItem;
