import { RefObject, useEffect, useRef } from 'react';

interface UseTabsIntersectionObserverProps {
  navRef: RefObject<HTMLUListElement>;
  jsonData: any[];
  setIsGradientNextEnabled: (value: boolean) => void;
}

export const useTabsIntersectionObserver = ({
  navRef,
  jsonData,
  setIsGradientNextEnabled,
}: UseTabsIntersectionObserverProps) => {
  const firstObserverRef = useRef<IntersectionObserver | null>(null);
  const lastObserverRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (!navRef.current || jsonData.length === 0) return;

    const buttons = navRef.current.querySelectorAll('li button');
    const firstButton = buttons[0];
    const lastButton = buttons[buttons.length - 1];

    const setupObservers = () => {
      // Cleanup existing observers
      if (firstObserverRef.current) {
        firstObserverRef.current.disconnect();
      }
      if (lastObserverRef.current) {
        lastObserverRef.current.disconnect();
      }

      // Observe first button for gradient_next
      if (firstButton) {
        firstObserverRef.current = new IntersectionObserver(
          ([entry]) => {
            setIsGradientNextEnabled(!entry.isIntersecting);
          },
          {
            root: navRef.current,
            threshold: 1.0,
            rootMargin: '0px 20px 0px 0px',
          },
        );
        firstObserverRef.current.observe(firstButton);
      }

      // Observe last button for gradient_prev
      if (lastButton) {
        lastObserverRef.current = new IntersectionObserver(
          ([entry]) => {
            navRef.current?.classList.toggle(
              'gradient_prev',
              !entry.isIntersecting,
            );
          },
          {
            root: navRef.current,
            threshold: 1.0,
            rootMargin: '0px 0px 0px 20px',
          },
        );
        lastObserverRef.current.observe(lastButton);
      }
    };

    setupObservers();

    return () => {
      if (firstObserverRef.current) {
        firstObserverRef.current.disconnect();
      }
      if (lastObserverRef.current) {
        lastObserverRef.current.disconnect();
      }
    };
  }, [navRef, jsonData, setIsGradientNextEnabled]);
};
