import type { IntlShape } from 'react-intl';
import { messages } from './messages';

export const schema = (props: { intl: IntlShape }) => {
  return {
    title: props.intl.formatMessage(messages.title),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['href'],
      },
    ],
    properties: {
      href: {
        title: props.intl.formatMessage(messages.target),
        description:
          'Select an HTML snippet object. The content will not be rendered until you save the content',
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'UID'],
        selectableTypes: ['Snippet'],
        allowExternals: false,
      },
    },
    required: [],
  };
};
