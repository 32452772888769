import React from 'react';
import {
  Switch as RACSwitch,
  SwitchProps as RACSwitchProps,
} from 'react-aria-components';

export type SwitchProps = {
  /**
   * A label for the switch.
   */
  children: React.ReactNode;
} & Omit<RACSwitchProps, 'children'>;

const Switch = ({ children, ...props }: SwitchProps) => {
  return (
    <RACSwitch {...props}>
      <div className="indicator" />
      {children}
    </RACSwitch>
  );
};

export default Switch;
