/**
 * Parses an ISO 8601 string and returns a Date object.
 *
 * The input is UTC time.
 *
 * @param {string} s - The ISO 8601 string to parse.
 * @returns {Date} The parsed Date object.
 */
const parseISOString = (s: string): Date | undefined => {
  if (s === '') {
    return undefined;
  }
  var b = s.split(/\D+/);
  return new Date(
    Date.UTC(
      parseInt(b[0]),
      parseInt(b[1]) - 1,
      parseInt(b[2]),
      parseInt(b[3]),
      parseInt(b[4]),
      parseInt(b[5]),
    ),
  );
};

export default parseISOString;
