/**
 * Fetches accordion data from multiple API endpoints
 *
 * @async
 * @function fetchData
 * @param {ApplicationAccordionFieldProps} content - Content object containing items with URLs to fetch
 * @param {Function} setLoading - State setter function for loading status
 * @param {Function} setError - State setter function for error messages
 * @param {Function} setData - State setter function for fetched data
 *
 * @returns {Promise<void>}
 *
 * @description
 * This function:
 * - Takes content with items containing URLs
 * - Fetches data from all URLs in parallel
 * - Handles authentication errors (401)
 * - Combines responses into a single dataset
 * - Updates loading, error, and data states accordingly
 *
 * @example
 * await fetchData(
 *   content,
 *   setLoading,
 *   setError,
 *   setData
 * );
 */

import config from '@plone/volto/registry';
import { ApplicationAccordionFieldProps } from '../index';

export const fetchData = async (
  content: ApplicationAccordionFieldProps,
  setLoading: (value: boolean) => void,
  setError: (value: string | null) => void,
  setData: (value: ApplicationAccordionFieldProps[]) => void,
): Promise<void> => {
  setLoading(true);
  setError(null);
  try {
    const urls = content?.items?.map((item) => item) ?? [];
    const responses: Response[] = await Promise.all(
      urls.map((url: { url: string }) =>
        fetch(`${config._data.settings.apiPath}/++api++/${url.url}`),
      ),
    );
    const dataPromises: Promise<ApplicationAccordionFieldProps>[] =
      responses.map((response: Response) => {
        if (response.status === 401) {
          return Promise.resolve(null); // Skip this response
        }
        if (!response.ok) {
          throw new Error(`Network response was not ok for ${response.url}`);
        }
        return response.json();
      });
    const results: any[] = await Promise.all(dataPromises);
    const combinedData: any[] = results.flat();
    setData(combinedData);
    setLoading(false);
  } catch (error: unknown) {
    const message = error instanceof Error ? error.message : 'Unknown error';
    setError(message);
    setLoading(false);
  }
};
