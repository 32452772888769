export const initialBlocks = {
  Document: [
    { '@type': 'title', fixed: true, required: true },
    { '@type': 'description', fixed: true, required: true },
    {
      '@type': 'slate',
      plaintext: '',
      value: [{ children: [{ text: '' }], type: 'p' }],
    },
  ],
  Project: [
    { '@type': 'title', fixed: true, required: true },
    { '@type': 'description', fixed: true, required: true },
    {
      '@type': 'slate',
      plaintext: '',
      value: [{ children: [{ text: '' }], type: 'p' }],
    },
  ],
  InfoEvent: [
    { '@type': 'title', fixed: true, required: true },
    { '@type': 'description', fixed: true, required: true },
    {
      '@type': 'slate',
      plaintext: '',
      value: [{ children: [{ text: '' }], type: 'p' }],
    },
    { '@type': 'eventdetail', fixed: true, required: true },
  ],
  FHNWEvent: [
    { '@type': 'title', fixed: true, required: true },
    { '@type': 'description', fixed: true, required: true },
    {
      '@type': 'slate',
      plaintext: '',
      value: [{ children: [{ text: '' }], type: 'p' }],
    },
    { '@type': 'eventdetail', fixed: true, required: true },
  ],
  ConEduEvent: [
    { '@type': 'title', fixed: true, required: true },
    { '@type': 'description', fixed: true, required: true },
    {
      '@type': 'slate',
      plaintext: '',
      value: [{ children: [{ text: '' }], type: 'p' }],
    },
    { '@type': 'eventdetail', fixed: true, required: true },
  ],
  DegreeProgram: [
    { '@type': 'title', fixed: true, required: true },
    { '@type': 'description', fixed: true, required: true },
    { '@type': 'edubox', fixed: true, required: true },
    {
      '@type': 'slate',
      plaintext: '',
      value: [{ children: [{ text: '' }], type: 'p' }],
    },
  ],
  ContinuingEducation: [
    { '@type': 'title', fixed: true, required: true },
    { '@type': 'description', fixed: true, required: true },
    { '@type': 'edubox', fixed: true, required: true },
    {
      '@type': 'slate',
      plaintext: '',
      value: [{ children: [{ text: '' }], type: 'p' }],
    },
  ],
  FHNWNews: [
    { '@type': 'newsdetail', fixed: true, required: true },
    { '@type': 'title', fixed: true, required: true },
    { '@type': 'description', fixed: true, required: true },
    {
      '@type': 'slate',
      plaintext: '',
      value: [{ children: [{ text: '' }], type: 'p' }],
    },
  ],
  Contact: [
    {
      '@type': 'slate',
      plaintext: '',
      value: [{ children: [{ text: '' }], type: 'p' }],
    },
  ],
  TeamContact: [
    {
      '@type': 'slate',
      plaintext: '',
      value: [{ children: [{ text: '' }], type: 'p' }],
    },
  ],
};
