import { useEffect } from 'react';
import { debounce } from 'lodash';

const useSyncBlockDataEffect = (
  data: any,
  title: string,
  setTitle: (title: string) => void,
  description: string,
  setDescription: (description: string) => void,
) => {
  useEffect(() => {
    const debouncedData = debounce(() => {
      if (data.title !== title) {
        setTitle(data.title);
      }

      if (data.description !== description) {
        setDescription(data.description);
      }
    }, 300);

    debouncedData();

    return () => debouncedData.cancel();
  }, [data, title, setTitle, description, setDescription]);
};

export default useSyncBlockDataEffect;
