import { useIntl } from 'react-intl';
import { Link } from '@fhnw/components/Link/Link';
import { UniversalLink } from '@plone/volto/components';
import { useState, KeyboardEvent } from 'react';
import {
  EventApplicationBoxProps,
  EventApplicationBoxMessages as messages,
} from './index';

const convertLineBreaks = (text: string | null | undefined): string => {
  if (!text) return '';
  return text.split('\\n').join('<br />').split('\n').join('<br />');
};

const EventApplicationBox = ({
  content,
}: {
  content: EventApplicationBoxProps;
}) => {
  const intl = useIntl();
  const [openState, setOpenState] = useState(1);

  const toggleAccordion = () => {
    setOpenState(openState === 1 ? 0 : 1);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      toggleAccordion();
    }
  };

  return (
    <div
      className={`sidebar-event-application-wrapper ${openState > 0 ? 'open' : ''}`}
    >
      <button
        className="sidebar-event-application-header"
        onClick={toggleAccordion}
        onKeyDown={handleKeyDown}
        aria-expanded={openState > 0}
        aria-controls="event-application-content"
      >
        <h4>{intl.formatMessage(messages.registration)}</h4>
      </button>
      <div
        className={`sidebar-event-application-content ${openState > 0 ? 'open' : ''}`}
        role="region"
        aria-labelledby="event-application-header"
      >
        <div>
          <p
            className="infotext"
            dangerouslySetInnerHTML={{
              __html: content.application_enabled
                ? convertLineBreaks(content.application_infotext_active)
                : content.application_infotext_inactive === null
                  ? intl.formatMessage(messages.infoText)
                  : convertLineBreaks(content.application_infotext_inactive),
            }}
          ></p>

          {content.application_note ? (
            <p className="infotext">{content.application_note}</p>
          ) : (
            ''
          )}

          {content.additional_information && (
            <p
              dangerouslySetInnerHTML={{
                __html: convertLineBreaks(content.additional_information),
              }}
            ></p>
          )}

          {content.application_enabled ? (
            <Link
              className="btn btn-primary btn--md text-center ml-lg mr-lg mb-lg"
              href={content.application_button_link}
              target="_blank"
              linkComponent={UniversalLink}
            >
              {content.application_button_text_enabled &&
              content.application_button_text
                ? content.application_button_text
                : intl.formatMessage(messages.buttonText)}
            </Link>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default EventApplicationBox;
