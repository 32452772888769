export const navItems = [
  {
    title: 'what_we_offer',
    items: [
      {
        title: 'degree_programmes',
        hrefDe: 'studium',
        hrefEn: 'degree-programmes',
        hrefFr: 'degree-programmes',
      },
      {
        title: 'continuing_education',
        hrefDe: 'weiterbildung',
        hrefEn: 'continuing-education',
        hrefFr: 'continuing-education',
      },
      {
        title: 'research_and_services',
        hrefDe: 'forschung-und-dienstleistungen',
        hrefEn: 'research-and-services',
        hrefFr: 'research-and-services',
      },
    ],
  },
  {
    title: 'about_fhnw',
    items: [
      {
        title: 'schools',
        hrefDe: 'die-fhnw/hochschulen',
        hrefEn: 'about-fhnw/schools',
        hrefFr: 'about-fhnw/schools',
      },
      {
        title: 'organisation',
        hrefDe: 'die-fhnw/organisation',
        hrefEn: 'about-fhnw/organisation',
        hrefFr: 'about-fhnw/organisation',
      },
      {
        title: 'management',
        hrefDe: 'die-fhnw/organisation/direktionspraesidium',
        hrefEn: 'about-fhnw/organisation/presidium-of-the-board-of-directors',
        hrefFr: 'about-fhnw/organisation/presidium-of-the-board-of-directors',
      },
      {
        title: 'facts_and_figures',
        hrefDe: 'die-fhnw/facts-und-figures',
        hrefEn: 'about-fhnw/facts-and-figures',
        hrefFr: 'about-fhnw/facts-and-figures',
      },
    ],
  },
  {
    title: 'information',
    items: [
      {
        title: 'data_protection',
        hrefDe: 'impressum/datenschutz',
        hrefEn: 'imprint/data-protection',
        hrefFr: 'imprint/data-protection',
      },
      {
        title: 'accessibility',
        hrefDe: 'impressum/accessibility',
        hrefEn: 'imprint/accessibility',
        hrefFr: 'imprint/accessibility',
      },
      {
        title: 'imprint',
        hrefDe: 'impressum',
        hrefEn: 'imprint',
        hrefFr: 'imprint',
      },
    ],
  },
  {
    title: 'support_intranet',
    items: [
      {
        title: 'it_support',
        hrefDe: 'die-fhnw/it-support',
        hrefEn: 'about-fhnw/it-support',
        hrefFr: 'about-fhnw/it-support',
      },
      {
        title: 'login_inside_fhnw',
        hrefDe: 'https://inside.fhnw.ch/',
        hrefEn: 'https://inside.fhnw.ch/',
        hrefFr: 'https://inside.fhnw.ch/',
        target: '_blank',
      },
    ],
  },
];

export const logos = [
  {
    href: 'https://www.swissuniversities.ch/',
    ariaLabel: 'Swiss Universities',
    className: 'swissuniversities',
  },
  {
    href: 'https://eua.eu',
    ariaLabel: 'European University Association',
    className: 'eua',
  },
];
