import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

interface ErrorProps {
  error?: {
    status?: number;
    message?: string;
  };
}

const Error: React.FC<ErrorProps> = ({ error }) => {
  const location = useLocation();
  const history = useHistory();

  const lang = location.pathname?.split('/')[1];
  const knownLangs = ['de', 'en', 'fr'];
  const langPrefix = knownLangs.includes(lang) ? lang : 'de';
  const redirectTarget = `/${langPrefix}/error-page`;
  const isOnErrorPage = location.pathname === redirectTarget;

  useEffect(() => {
    if (!isOnErrorPage && error?.status && [404].includes(error.status)) {
      // eslint-disable-next-line no-console
      console.warn('🔁 Redirecting via history.push to:', redirectTarget);
      history.replace(redirectTarget);
    }
  }, [isOnErrorPage, error?.status, redirectTarget, history]);

  return (
    <div className="error-404-view">
      <h2>Diese Seite existiert leider nicht.</h2>
      <p>
        Entschuldigung, aber die Webseite, die Sie versucht haben zu erreichen,
        ist unter dieser Adresse nicht verfügbar. Bitte benutzen Sie die
        aufgeführten Verweise unten, um zu finden, was Sie gesucht haben. Wenn
        Sie sicher sind, dass Sie die richtige Adresse eingegeben haben,
        kontaktieren Sie bitte die Website-Verantwortlichen. Vielen Dank!
      </p>
    </div>
  );
};

export default Error;
