import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbItem } from './utils';
import { BreadcrumbsConfig } from '@fhnw/types/breadcrumbs';

interface RenderBreadcrumbItemProps {
  item: BreadcrumbItem;
  index: number;
  allItems: BreadcrumbItem[];
  config: BreadcrumbsConfig;
  isExpanded: boolean;
  isMobile: boolean;
  toggleExpand: () => void;
}

/**
 * Renders a single breadcrumb item
 * @param props Component props
 * @returns JSX element for the breadcrumb item
 */
export const renderBreadcrumbItem = ({
  item,
  index,
  allItems,
  config,
  isExpanded,
  isMobile,
  toggleExpand,
}: RenderBreadcrumbItemProps) => {
  const isLastItem = index === allItems.length - 1;
  const isExpandable =
    item.isFirstLevel ||
    (config.navigation?.expandable.level !== undefined &&
      index === config.navigation.expandable.level) ||
    config.navigation?.expandable.items.includes(item.displayTitle || '') ||
    false;

  // Check if text is truncated
  const isTextTruncated = item.displayTitle !== item.originalTitle;

  // Only add tooltip props if text is truncated
  const tooltipProps =
    !isMobile && isTextTruncated
      ? {
          'data-tooltip': item.originalTitle,
          'data-position': 'bottom center',
        }
      : {};

  const truncatedClass = isTextTruncated ? 'truncated-breadcrumb' : '';

  return (
    <Fragment key={`breadcrumb-item-${item.url}`}>
      <div className="divider" key={`divider-${item.url}`} />
      {!isLastItem ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isExpandable ? (
            isExpanded ? (
              // When expanded, show the text
              <Link
                key={item.url}
                to={item.url}
                className={`section ${truncatedClass}`}
                title={isTextTruncated ? item.originalTitle : undefined}
              >
                {item.displayTitle}
              </Link>
            ) : (
              // When collapsed, show only the expand icon
              <button
                onClick={toggleExpand}
                title={isTextTruncated ? item.originalTitle : undefined}
                className="section plus-icon"
              />
            )
          ) : (
            // For all other items, show the link as usual
            <Link
              key={item.url}
              to={item.url}
              className={`section active ${truncatedClass} ${isMobile ? 'mobile' : ''}`}
              {...tooltipProps}
              style={{ position: 'relative' }}
            >
              {item.displayTitle}
            </Link>
          )}
        </div>
      ) : (
        // Last item is not a link
        <div
          className={`section active ${truncatedClass} ${isMobile ? 'mobile' : ''}`}
          key={item.url}
          {...tooltipProps}
        >
          {item.displayTitle}
        </div>
      )}
    </Fragment>
  );
};

export default renderBreadcrumbItem;
