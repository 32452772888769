import { useState } from 'react';
import { Icon } from '@plone/volto/components';
import { BodyClass } from '@plone/volto/helpers';
import GlobalSearchPanel from '@fhnw/components/GlobalSearch/GlobalSearchPanel';
import zoomSVG from '@plone/volto/icons/zoom.svg';
import cx from 'classnames';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  search: {
    id: 'Open Global Search',
    defaultMessage: 'Open Global Search',
  },
  overlay: {
    id: 'Overlay',
    defaultMessage: 'Overlay',
  },
});

const GlobalSearch = () => {
  const [openGlobalSearch, setOpenGlobalSearch] = useState(false);
  const intl = useIntl();

  const onOpenGlobalSearch = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    e.stopPropagation();
    setOpenGlobalSearch(true);
  };

  const closeGlobalSearch = (): void => {
    setOpenGlobalSearch(false);
  };

  return (
    <div className="global-search-container">
      <div className="global-search-icon-container">
        <button onClick={onOpenGlobalSearch}>
          <Icon className="global-search-icon" name={zoomSVG} size="22px" />{' '}
          <span className="visually-hidden">
            {intl.formatMessage(messages.search)}
          </span>
        </button>
      </div>
      {openGlobalSearch && (
        <>
          <BodyClass className="prevent-scroll" />
          <div className="overlay">
            <span className="visually-hidden">
              {intl.formatMessage(messages.overlay)}
            </span>
          </div>
        </>
      )}
      <div
        className={cx('global-search-content-container', {
          'is-open': openGlobalSearch,
        })}
        style={{ transform: 'translateX(100%)' }}
      >
        {openGlobalSearch && (
          <GlobalSearchPanel
            closeGlobalSearch={closeGlobalSearch}
            isMobile={false}
          />
        )}
      </div>
    </div>
  );
};

export default GlobalSearch;
