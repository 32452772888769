/**
 * AccordionHeader component displays the header section of an accordion item
 *
 * @component
 * @param {Object} props - Component props
 * @param {AccordionFieldProps} props.data - The data object containing accordion item information
 * @param {string} props.data.title - The title text to display in the accordion header
 *
 * @returns {JSX.Element} Rendered accordion header component
 */

import React from 'react';
import { useIntl } from 'react-intl';
import {
  ApplicationAccordionFieldProps,
  ApplicationAccordionMessages as messages,
  ApplicationButton,
  DateDisplay,
} from './index';

interface AccordionItemProps {
  data: ApplicationAccordionFieldProps;
}

const AccordionHeader = React.memo(({ data }: AccordionItemProps) => {
  const intl = useIntl();
  const getStatusClass = () => {
    if (!data?.application_status) return 'no-status';
    return data.application_status.token === 'open' ? 'open' : 'closed';
  };

  const getStatusText = () => {
    return getStatusClass() === 'open'
      ? intl.formatMessage(messages.status_open)
      : intl.formatMessage(messages.status_closed);
  };

  // Render date display (date only, no time) for the header
  const renderDateDisplay = () => {
    if (!data?.start && !data?.alternative_start) {
      return null;
    }

    return (
      <DateDisplay
        start={data.start}
        wholeDay={true} // Force dateOnly=true by setting wholeDay=true
        alternativeStart={data.alternative_start}
      />
    );
  };

  // Check if date display will render content
  const hasDateDisplay = data?.start || data?.alternative_start;

  return (
    <div
      className="accordion-header"
      tabIndex={0}
      role="button"
      aria-expanded="false"
      aria-controls="accordion-content"
    >
      <div className="accordion-header-status-location">
        <div
          className={`accordion-status ${getStatusClass()}`}
          title={getStatusText()}
        >
          {getStatusText()}
        </div>
        <div className="accordion-title">{data?.title}</div>

        {data?.place || hasDateDisplay ? (
          <div className="accordion-place">
            <div className={`accordion-status ${getStatusClass()}`} />
            {data?.place}
            {data?.place && hasDateDisplay && ', '}
            {renderDateDisplay()}
          </div>
        ) : null}
      </div>

      <ApplicationButton
        enabled={data?.application_button_enabled === true}
        link={data?.application_button_link}
        label={data?.application_button_label}
      />
    </div>
  );
});

export default AccordionHeader;
