import { IrfMessages as messages } from '../IrfMessages';
import { useIntl } from 'react-intl';

export const getTabTranslations = (intl: ReturnType<typeof useIntl>) => ({
  publicationTab: `${intl.formatMessage(messages.tabPublications)}`,
  projectTab: `${intl.formatMessage(messages.tabProjects)}`,
  presentationTab: `${intl.formatMessage(messages.tabPresentations)}`,
  supervisedThesesTab: `${intl.formatMessage(messages.tabSupervisedTheses)}`,
  othersTab: `${intl.formatMessage(messages.tabOthers)}`,
});
