/**
 * OVERRIDE EditBlockWrapper.jsx
 * REASON: https://gitlab.fhnw.ch/webteam/fhnw-webauftritt-2025/-/issues/98#note_532076 .
 *         As soon as insert text in the text block within the group,
 *         the "plus" to add more blocks within the group disappears.
 *         This also happens in the image block within the group.
 * FILE: https://github.com/eea/volto-group-block/blob/30a935a7155da37344418b24212c60586e0f9405/src/components/manage/Blocks/Group/EditBlockWrapper.jsx
 * FILE VERSION: eea/volto-group-block 7.1.0
 * PULL REQUEST: https://gitlab.fhnw.ch/webteam/fhnw-webauftritt-2025/-/merge_requests/307
 * TICKET: https://gitlab.fhnw.ch/webteam/fhnw-webauftritt-2025/-/issues/98
 * DATE: 2024-10-24
 * DEVELOPER: @tisha.soumya
 * CHANGELOG:
 *  - Remove block has value check which was disabling adding of new
 *    blocks when value was entered in a block. (#307) @tisha.soumya
 *
 * Depending on the nature of the change, if possible or suitable, mark the change with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */

import React from 'react';
import { Icon } from '@plone/volto/components';
import { buildStyleClassNamesFromData } from '@plone/volto/helpers';
import includes from 'lodash/includes';
import isBoolean from 'lodash/isBoolean';
import { defineMessages, injectIntl } from 'react-intl';
import cx from 'classnames';
import config from '@plone/volto/registry';
import { doesNodeContainClick } from 'semantic-ui-react/dist/commonjs/lib';
import NewBlockAddButton from './NewBlockAddButton';

import dragSVG from '@plone/volto/icons/drag.svg';
import trashSVG from '@plone/volto/icons/delete.svg';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
  delete: {
    id: 'delete',
    defaultMessage: 'delete',
  },
  dragAndDrop: {
    id: 'Drag and drop',
    defaultMessage: 'Drag and drop',
  },
});

class EditBlockWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addNewBlockOpened: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (
      this.blockNode.current &&
      doesNodeContainClick(this.blockNode.current, e)
    )
      return;

    if (this.state.addNewBlockOpened) {
      this.setState({
        addNewBlockOpened: false,
      });
      return true;
    }
  };

  blockNode = React.createRef();

  render() {
    const { intl, blockProps, draginfo, extraControls, disabled, children } =
      this.props;

    const {
      block,
      data,
      onSelectBlock,
      onDeleteBlock,
      onInsertBlock,
      selected,
      blocksConfig,
      index,
    } = blockProps;
    const type = data['@type'];
    const { disableNewBlocks } = data;
    const dragVisible = !data.fixed;
    const visible = selected;

    const required = isBoolean(data.required)
      ? data.required
      : includes(config.blocks.requiredBlocks, type);

    const styles = buildStyleClassNamesFromData(data.styles);

    // Get editing instructions from block settings or props
    let instructions = data?.instructions?.data || data?.instructions;
    if (!instructions || instructions === '<p><br/></p>') {
      instructions = '';
    }

    return (
      <div ref={this.blockNode}>
        <div
          ref={draginfo?.innerRef}
          {...(selected ? draginfo?.draggableProps : null)}
          className={cx(`block-editor-${data['@type']}`, styles, {
            [data.align]: data.align,
          })}
        >
          {(!selected || !visible || disabled) && (
            <div
              style={{
                display: 'none',
                // keep react-beautiful-dnd happy
              }}
              {...draginfo.dragHandleProps}
            ></div>
          )}
          {visible && (
            <div className="block-toolbar">
              {instructions ? extraControls : ''}

              {!disabled && (
                <>
                  <button
                    title={intl.formatMessage(messages.dragAndDrop)}
                    style={{
                      display: dragVisible ? 'inline-block' : 'none',
                    }}
                    {...draginfo.dragHandleProps}
                    className="drag handle wrapper-group-block"
                  >
                    <Icon name={dragSVG} size="19px" />
                  </button>
                  {/* START CUSTOMIZATION */}
                  {!disableNewBlocks && (
                    // END CUSTOMIZATION
                    <NewBlockAddButton
                      block={block}
                      index={index}
                      blocksConfig={blocksConfig}
                      onInsertBlock={(id, value) => {
                        onSelectBlock(onInsertBlock(id, value));
                      }}
                    />
                  )}
                  {!required && (
                    <button
                      title={intl.formatMessage(messages.delete)}
                      onClick={() => onDeleteBlock(block)}
                      className="delete-button-group-block"
                      aria-label={intl.formatMessage(messages.delete)}
                    >
                      <Icon name={trashSVG} size="19px" color="#e40166" />
                    </button>
                  )}
                </>
              )}
            </div>
          )}

          <div
            className={cx('ui drag block wrapper inner', type, {
              multiSelected: this.props.multiSelected,
            })}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(EditBlockWrapper);
