import { useState, useCallback } from 'react';

type PeerReviewCache = Record<string, boolean>;

const DEFAULT_TABS = [
  'publicationTab',
  'presentationTab',
  'projectTab',
  'supervisedThesesTab',
  'othersTab',
] as const;

export const usePeerReviewState = (initialPeerReviewed: boolean) => {
  const [peerReviewedCache, setPeerReviewedCache] = useState<PeerReviewCache>(
    () => {
      const initialCache: PeerReviewCache = {};
      DEFAULT_TABS.forEach((tab) => {
        initialCache[tab] =
          tab.includes('publication') || tab.includes('presentation')
            ? initialPeerReviewed
            : false;
      });
      return initialCache;
    },
  );

  const togglePeerReviewed = useCallback((activeTab: string) => {
    if (['publicationTab', 'presentationTab'].includes(activeTab)) {
      setPeerReviewedCache((prev) => ({
        ...prev,
        [activeTab]: !prev[activeTab as keyof PeerReviewCache],
      }));
    }
  }, []);

  return {
    peerReviewedCache,
    togglePeerReviewed,
  };
};
