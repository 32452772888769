/**
 * Scrolls the navigation and sets the next active tab.
 *
 * @param {('left' | 'right')} direction - The direction to scroll, either 'left' or 'right'.
 * @param {React.RefObject<HTMLUListElement>} navRef - A reference to the navigation element.
 * @param {[string, any][]} activeTabs - An array of active tabs with their keys and associated data.
 * @param {keyof typeof tabs} activeTab - The currently active tab key.
 * @param {React.Dispatch<React.SetStateAction<keyof typeof tabs>>} setActiveTab - Function to set the active tab.
 * @param {Record<string, any[]>} tabs - An object containing all tabs and their associated data.
 */
export const scrollNavigation = (
  direction: 'left' | 'right',
  navRef: React.RefObject<HTMLUListElement>,
  activeTabs: [string, any][],
  activeTab: keyof typeof tabs,
  setActiveTab: React.Dispatch<
    React.SetStateAction<
      | 'publicationTab'
      | 'projectTab'
      | 'presentationTab'
      | 'supervisedThesesTab'
      | 'othersTab'
    >
  >,
  tabs: Record<string, any[]>,
) => {
  if (navRef.current) {
    const scrollAmount = direction === 'left' ? -100 : 100;
    navRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });

    const currentIndex = activeTabs.findIndex(
      ([tabKey]) => tabKey === activeTab,
    );
    let newIndex = currentIndex;

    if (direction === 'right') {
      newIndex = (currentIndex + 1) % activeTabs.length;
    } else if (direction === 'left') {
      newIndex = (currentIndex - 1 + activeTabs.length) % activeTabs.length;
    }

    setActiveTab(
      activeTabs[newIndex][0] as keyof typeof tabs as
        | 'publicationTab'
        | 'projectTab'
        | 'presentationTab'
        | 'supervisedThesesTab'
        | 'othersTab',
    );
  }
};
