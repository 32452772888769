/**
 * DateDisplay component for formatting and displaying date ranges
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} [props.start] - Start date string in ISO format
 * @param {string} [props.end] - End date string in ISO format
 * @param {boolean} [props.wholeDay=false] - Flag indicating if the event is a whole day event
 * @param {boolean} [props.openEnd=false] - Flag indicating if the event has an open end date
 * @param {string} [props.alternativeStart] - Alternative text to display instead of formatted date
 * @param {boolean} [props.dateOnly] - Flag to force display of date only (without time)
 *
 * @returns {JSX.Element|null} Rendered date display component or null if no start date provided
 *
 * @example
 * <DateDisplay
 *   start="2023-01-01T09:00:00"
 *   end="2023-01-01T17:00:00"
 *   wholeDay={false}
 *   openEnd={false}
 * />
 */

import React from 'react';
import FHNWDateFormat from '../DateFormat/FHNWDateFormat';
import parseISOString from '../DateFormat/parseISOString';

interface DateDisplayProps {
  start?: string;
  end?: string;
  wholeDay?: boolean;
  openEnd?: boolean;
  alternativeStart?: string;
  dateOnly?: boolean;
}

/**
 * Checks if a date has time set to 00:00
 * @param date The date to check
 * @returns true if the time is 00:00, false otherwise
 */
const hasZeroTime = (date: Date | null | undefined): boolean => {
  if (!date) return false;
  return date.getHours() === 0 && date.getMinutes() === 0;
};

const DateDisplay = React.memo(
  ({
    start,
    end,
    wholeDay = false,
    openEnd = false,
    alternativeStart,
    dateOnly: forceDateOnly,
  }: DateDisplayProps) => {
    if (alternativeStart) {
      return <>{alternativeStart}</>;
    }

    if (!start) {
      return null;
    }

    const directDate = new Date(start);
    // If direct parsing works, use that instead of parseISOString
    const startDate = !isNaN(directDate.getTime())
      ? directDate
      : parseISOString(start);

    const endDate = end
      ? !isNaN(new Date(end).getTime())
        ? new Date(end)
        : parseISOString(end)
      : null;

    // Check if times are 00:00
    const startHasZeroTime = hasZeroTime(startDate);
    const endHasZeroTime = hasZeroTime(endDate);

    // Show time if:
    // 1. It's not a whole day event
    // 2. AND either start or end time is not 00:00
    const shouldShowTime =
      !wholeDay && (!startHasZeroTime || (endDate && !endHasZeroTime));

    // Use dateOnly=true to hide time, dateOnly=false to show time
    // If forceDateOnly is provided, use that value instead
    const dateOnly =
      forceDateOnly !== undefined ? forceDateOnly : !shouldShowTime;

    if (openEnd) {
      return (
        <FHNWDateFormat
          start={startDate}
          showWeekday={false}
          openEnd={openEnd}
        />
      );
    }

    if (endDate) {
      return (
        <>
          <FHNWDateFormat
            start={startDate}
            end={endDate}
            showWeekday={false}
            wholeDay={wholeDay}
            dateOnly={dateOnly}
          />
        </>
      );
    }

    return (
      <>
        <FHNWDateFormat
          start={startDate}
          showWeekday={false}
          dateOnly={dateOnly}
        />
      </>
    );
  },
);

export default DateDisplay;
