import React from 'react';
import { ReactEditor } from 'slate-react';
import { useSelector, useDispatch } from 'react-redux';
import AddLinkForm from '@plone/volto/components/manage/AnchorPlugin/components/LinkButton/AddLinkForm';
import {
  _insertElement,
  _unwrapElement,
  _isActiveElement,
  _getActiveElement,
} from '@plone/volto-slate/elementEditor/utils';
import { SIMPLELINK } from '@plone/volto-slate/constants';
import { setPluginOptions } from '@plone/volto-slate/actions';
import { PositionedToolbar } from '@plone/volto-slate/editor/ui';
import { useSelectionPosition } from '@plone/volto-slate/hooks';

function getPositionStyle(rect) {
  return {
    style: {
      opacity: 1,
      top: rect.top + window.pageYOffset - 6,
      left: rect.left + window.pageXOffset + rect.width / 2,
    },
  };
}

const LinkEditor = (props) => {
  const { editor, pluginId, getActiveElement, unwrapElement, insertElement } =
    props;
  const pid = `${editor.uid}-${pluginId}`;
  const showEditor = useSelector((state) => {
    return state['slate_plugins']?.[pid]?.show_sidebar_editor;
  });
  const savedPosition = React.useRef();
  const rect = useSelectionPosition();

  const dispatch = useDispatch();

  const active = getActiveElement(editor);
  const [node] = active || [];

  if (showEditor && !savedPosition.current) {
    savedPosition.current = getPositionStyle(rect);
  }

  return showEditor ? (
    <PositionedToolbar
      className="add-link-customised"
      position={savedPosition.current}
    >
      <AddLinkForm
        block="draft-js"
        placeholder={'Add link'}
        customized={true} // customized or pass new to detect the customized part.
        data={{
          url: node?.data?.url || '',
          openLinkInNewTab: node?.data?.openLinkInNewTab || false,
        }}
        theme={{}}
        onChangeValue={(changeValue) => {
          if (!active) {
            if (!editor.selection) editor.selection = editor.savedSelection;
            insertElement(editor, changeValue);
          } else {
            const selection = unwrapElement(editor);
            editor.selection = selection;
            insertElement(editor, changeValue);
          }
          ReactEditor.focus(editor);
          dispatch(setPluginOptions(pid, { show_sidebar_editor: false }));
          savedPosition.current = null;
        }}
        onClear={() => {
          // clear button was pressed in the link edit popup
          const newSelection = JSON.parse(
            JSON.stringify(unwrapElement(editor)),
          );
          editor.selection = newSelection;
          editor.savedSelection = newSelection;
        }}
        onOverrideContent={(c) => {
          dispatch(setPluginOptions(pid, { show_sidebar_editor: false }));
          savedPosition.current = null;
        }}
      />
    </PositionedToolbar>
  ) : null;
};

const applyConfig = (config) => {
  const { slate } = config.settings;

  const PLUGINID = SIMPLELINK;
  const insertElement = _insertElement(PLUGINID);
  const getActiveElement = _getActiveElement(PLUGINID);
  const isActiveElement = _isActiveElement(PLUGINID);
  const unwrapElement = _unwrapElement(PLUGINID);

  const pluginOptions = {
    insertElement,
    getActiveElement,
    isActiveElement,
    unwrapElement,
  };

  slate.persistentHelpers = [
    ...slate.persistentHelpers,
    (props) => <LinkEditor {...props} pluginId={PLUGINID} {...pluginOptions} />,
  ];

  return config;
};

export default applyConfig;
