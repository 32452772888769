/**
 * View image block.
 * @module components/manage/Blocks/Image/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { UniversalLink } from '@plone/volto/components';
import cx from 'classnames';
import { Link } from '@fhnw/components/Link/Link';
import {
  flattenToAppURL,
  isInternalURL,
  withBlockExtensions,
} from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import Caption from '../../Caption/Caption';
/**
 * View image block class.
 * @class View
 * @extends Component
 */
export const ImageView = ({ className, data, detached, style }) => {
  let href;
  if (data.href?.length > 0) {
    if (typeof data.href === 'object') {
      href = data.href[0]['@id'];
    } else if (typeof data.href === 'string') {
      // just to catch cases where a string might be supplied
      href = data.href;
    }
  }
  const Image = config.getComponent({ name: 'Image' }).component;
  const shouldRenderCaption =
    data.caption ||
    data.description ||
    (data?.copyright_and_sources ?? data.credit?.data);
  return (
    <div
      className={cx(
        'block image align',
        {
          center: !Boolean(data.align),
          detached,
        },
        data.align,
        className,
      )}
      style={style}
    >
      <div>
        <figure
          className={cx(
            'figure',
            {
              center: !Boolean(data.align),
              detached,
            },
            data.align,
            {
              half_format: data.format === 'half',
              third_format: data.format === 'third',
              portrait_format: data.format === 'portrait',
              large_format: data.format === 'large',
            },
          )}
        >
          {data.url && (
            <>
              {(() => {
                const image = (
                  <Image
                    item={
                      data.image_scales
                        ? {
                            '@id': data.url,
                            image_field: data.image_field,
                            image_scales: data.image_scales,
                          }
                        : undefined
                    }
                    src={
                      data.image_scales
                        ? undefined
                        : isInternalURL(data.url)
                          ? // Backwards compat in the case that the block is storing the full server URL
                            (() => {
                              if (data.large_format === 'large')
                                return `${flattenToAppURL(data.url)}/@@images/image`;
                              if (data.half_format === 'half')
                                return `${flattenToAppURL(
                                  data.url,
                                )}/@@images/image/preview`;
                              if (
                                data.third_format === 'third' ||
                                data.portrait_format === 'portrait'
                              )
                                return `${flattenToAppURL(
                                  data.url,
                                )}/@@images/image/mini`;
                              return `${flattenToAppURL(data.url)}/@@images/image`;
                            })()
                          : data.url
                    }
                    sizes={config.blocks.blocksConfig.image.getSizes(data)}
                    alt={data.alt || ''}
                    loading="lazy"
                    responsive={true}
                  />
                );
                if (href) {
                  return (
                    <Link
                      href={href}
                      openLinkInNewTab={data.openLinkInNewTab}
                      linkComponent={UniversalLink}
                    >
                      {image}
                    </Link>
                  );
                } else {
                  return image;
                }
              })()}
            </>
          )}
          {shouldRenderCaption && <Caption title={data.description} />}
        </figure>
        {data.block_description && (
          <div className="description">
            <p>{data.block_description}</p>
          </div>
        )}
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ImageView.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withBlockExtensions(ImageView);
