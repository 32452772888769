import { BlockDataForm } from '@plone/volto/components';
import { InfoEventSchema } from './index';
const EduBoxData = (props: any) => {
  const { data, block, onChangeBlock } = props;
  const schema = InfoEventSchema(props.intl);
  return (
    <BlockDataForm
      schema={InfoEventSchema(props.intl)}
      title={schema.fieldsets[0].title}
      onChangeField={(id: number, value: string) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      formData={data}
      block={block}
    />
  );
};

export default EduBoxData;
