import { Icon } from '@fhnw/components/Icon/Icon';

interface XIconProps {
  size: 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL';
  color: string;
  onClick?: () => void;
}

const X = (props: XIconProps) => (
  <Icon color={props.color} onClick={props.onClick} size={props.size}>
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>X</title>
      <mask id="cutout-mask">
        <circle cx="16" cy="16" r="15.91" fill="white" />
        <path
          transform="translate(6 6) scale(0.8)"
          d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
          fill="#000000"
        />
      </mask>
      <circle cx="16" cy="16" r="15.91" mask="url(#cutout-mask)" />
    </svg>
  </Icon>
);

export default X;
