// OneThirdTeaserTemplate.tsx
import Teaser from '@fhnw/components/Teaser/Teaser';
import {
  mapItemToTeaserProps,
  mapItemAttrsByContenttype,
} from './mapItemToTeaserProps';
import type { TemplateProps } from './mapItemToTeaserProps';
import { ListingVariationButton } from './ListingVariationButton';
import { useVocab } from './useVocab';
import { useSelector } from 'react-redux';
import { FormState } from '@fhnw/types/formState';

const OneThirdTeaser = (props: TemplateProps) => {
  const token = useSelector((state: FormState) => state?.userSession?.token);
  const items = props?.items ?? [];
  const localeFromState = useSelector((state: any) => state.intl.locale);

  const dateline_tax = useVocab('collective.taxonomy.dateline');
  const organisation_entities = useVocab(
    'fhnw.vocabularies.organisation_entities',
  );
  const phonesuffix_tax = useVocab('collective.taxonomy.phonesuffix');

  const showPrivatePages = props?.showPrivatePages === true;

  return (
    <div>
      <ul className="teaser-col-3">
        {props &&
          items.map((item) => {
            const ct_mapped_item = mapItemAttrsByContenttype(
              item,
              organisation_entities,
              phonesuffix_tax,
              dateline_tax,
              props,
            );
            const teaserProps = mapItemToTeaserProps(
              ct_mapped_item,
              { ...props, locale: localeFromState },
              'default',
              true,
              token,
            );

            if (!showPrivatePages && teaserProps.isPrivate) return null;

            return <Teaser key={item['@id']} {...teaserProps} />;
          })}
      </ul>
      {props?.buttonText && props?.buttonLink && (
        <ListingVariationButton
          isEditMode={props.isEditMode ?? false}
          buttonLink={props.buttonLink}
          buttonText={props.buttonText}
        />
      )}
    </div>
  );
};

export default OneThirdTeaser;
