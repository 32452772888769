import { UniversalLink } from '@plone/volto/components';
import React, { useState } from 'react';
import { Link } from '../Link/Link';
import { FooterMessages as messages } from './FooterMessages';
import { useIntl } from 'react-intl';

interface FooterItem {
  title: string;
  href?: string;
  hrefDe?: string;
  hrefEn?: string;
  hrefFr?: string;
  target?: string;
  rel?: string;
}

interface FooterSection {
  title: string;
  items: FooterItem[];
}

interface FooterProps {
  navItems: FooterSection[];
  logos: {
    href: string;
    ariaLabel: string;
    className: string;
  }[];
}

const Footer: React.FC<FooterProps> = ({ navItems, logos }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const intl = useIntl();

  const toggleMenu = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleKeyDown = (event: React.KeyboardEvent, index: number) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      toggleMenu(index);
    }
  };

  const getItemCountClass = (count: number) => {
    return `items-${count}`;
  };

  return (
    <footer className="footer">
      <nav className="footernavigation" aria-label="Footer Navigation">
        {navItems.map((section, sectionIndex) => (
          <section
            key={sectionIndex}
            className={`footernavigation__column ${
              sectionIndex === activeIndex ? 'is-open' : ''
            }`}
            aria-labelledby={`${section.title}_title`}
          >
            <h2 id={`${section.title}_title`} className="column-title">
              <span
                onClick={() => toggleMenu(sectionIndex)}
                onKeyDown={(event) => handleKeyDown(event, sectionIndex)}
                role="button"
                tabIndex={0}
                aria-expanded={sectionIndex === activeIndex}
                aria-controls={`${section.title}_list`}
              >
                {intl.formatMessage(
                  messages[section.title as keyof typeof messages],
                )}
              </span>
            </h2>
            <ul
              id={`${section.title}_list`}
              className={`${sectionIndex === activeIndex ? 'show' : ''} ${getItemCountClass(
                section.items.length,
              )}`}
            >
              {section.items.map((item, itemIndex) => {
                const localeKey =
                  `href${intl.locale.charAt(0).toUpperCase() + intl.locale.slice(1)}` as keyof FooterItem;
                const rawHref = item[localeKey] || item.href || '';
                const isExternal = rawHref.startsWith('https');
                const href = isExternal
                  ? rawHref
                  : `/${intl.locale}/${rawHref}`;
                const openInNewTab = isExternal || item.target === '_blank';

                return (
                  <li key={itemIndex}>
                    <Link
                      href={href}
                      newTab={openInNewTab}
                      aria-label={intl.formatMessage(
                        messages[item.title as keyof typeof messages],
                      )}
                      linkComponent={UniversalLink}
                    >
                      {intl.formatMessage(
                        messages[item.title as keyof typeof messages],
                      )}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </section>
        ))}

        <section
          className="footernavigation__column footer__logos"
          aria-label="Membership Logos"
        >
          <h2 className="column-title">
            {intl.formatMessage(messages.member_of)}:
          </h2>
          {logos.map((logo, index) => (
            <Link
              key={index}
              href={logo.href}
              newTab={true}
              aria-label={logo.ariaLabel}
              className={logo.className}
              linkComponent={UniversalLink}
            />
          ))}
        </section>
      </nav>
    </footer>
  );
};

export default Footer;
