/**
 * ApplicationButton component that renders either a link or a disabled button based on props
 *
 * @component
 * @param {Object} props - Component props
 * @param {boolean} props.enabled - Determines if the button should be enabled and rendered as a link
 * @param {string} [props.link] - URL for the link when button is enabled
 * @param {string} [props.label] - Custom label text for the button. Falls back to translated default if not provided
 *
 * @returns {JSX.Element} Either a Link component or a disabled Button component based on enabled state
 *
 * @example
 * <ApplicationButton
 *   enabled={true}
 *   link="https://example.com"
 *   label="Apply Now"
 * />
 */

import { useIntl } from 'react-intl';
import Button from '../Button/Button';
import { Link } from '../Link/Link';
import { ApplicationAccordionMessages as messages } from './index';
import { UniversalLink } from '@plone/volto/components';
import { getReference } from '@fhnw/helpers/Url/UrlHelper';

interface ApplicationButtonProps {
  enabled: boolean;
  link?: string;
  label?: string;
}

const ApplicationButton = ({
  enabled,
  link,
  label,
}: ApplicationButtonProps) => {
  const intl = useIntl();
  const defaultLabel = intl.formatMessage(messages.application_button_label);
  const buttonLabel = label || defaultLabel;

  if (enabled && link) {
    return (
      <Link
        className={`btn--md btn--link btn-primary`}
        href={getReference(link)}
        title={buttonLabel}
        linkComponent={UniversalLink}
      >
        {buttonLabel}
      </Link>
    );
  }

  return (
    <Button
      label={buttonLabel}
      onPress={() => {}}
      styles={`btn--md btn-primary`}
      type="button"
      isDisabled={true}
    />
  );
};

export default ApplicationButton;
