import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { IrfDatas } from './utils/actions';
import Switch from '../Form/Switch/Switch';
import { IrfMessages as messages } from './IrfMessages';
import { getTabTranslations } from './utils/tabTranslations';
import { InternalIrfPublicationProps } from './utils/fetchData';
import { useTabsIntersectionObserver } from './hooks/useTabsIntersectionObserver';
import { usePeerReviewState } from './hooks/usePeerReviewState';
import { IrfErrorBoundary } from './IrfErrorBoundary';
import {
  IrfPublicationProps,
  RenderEntries,
  IrfAlertMessages,
  fetchData,
  scrollNavigation,
  useHandleResize,
  useCheckLastElementVisibility,
  useTabs,
  useActiveTabs,
} from './index';

const IrfPublication: React.FC<IrfPublicationProps> = ({
  data,
  block,
  content,
  isEditMode,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [jsonData, setJsonData] = useState<InternalIrfPublicationProps[]>([]);
  const navRef = useRef<HTMLUListElement>(null);
  const [isScrollVisible, setIsScrollVisible] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [dataState, setDataState] = useState<string>('');
  const [isGradientNextEnabled, setIsGradientNextEnabled] =
    useState<boolean>(false);
  const [personOrCollectionChanged, setPersonOrCollectionChanged] =
    useState<boolean>(false);

  const pageUID = content?.UID || null;
  const showOnlyPeerReviewed = !!data?.only_peer_reviewed;
  const person = data?.person || null;
  const collection = data?.collection || '';
  const selectedTypes = data?.selected_types || null;
  const excluded_publications = data?.excluded_publications || null;
  const newer_than = data?.newer_than || '1970';
  const citation_style = data?.citation_style || 'apa7th';

  const prevPersonRef = useRef(person);
  const prevCollectionRef = useRef(collection);

  useEffect(() => {
    if (
      prevPersonRef.current !== person ||
      prevCollectionRef.current !== collection
    ) {
      setPersonOrCollectionChanged(true);
      prevPersonRef.current = person;
      prevCollectionRef.current = collection;
    }
  }, [person, collection]);

  const handleResize = useHandleResize({ navRef });
  const checkLastElementVisibility = useCheckLastElementVisibility({
    setIsScrollVisible,
    navRef,
  });

  /**
   * Retrieves excluded publications.
   */
  const getExcludedPublications = (excluded_publications || '')
    .split(/[,\n]/)
    .map((url) => url.trim())
    .filter(Boolean);

  /**
   * Boolean indicating whether to display content grouped or not
   */
  const displayContentGrouped =
    data?.display_content_grouped === 'group_by_type'
      ? 'group_by_type'
      : 'no_grouping';

  /**
   * Filters publications by type code.
   */
  const { tabs, activeTab, setActiveTab } = useTabs(
    jsonData,
    selectedTypes,
    person,
  );

  /**
   * Mapping tab keys to their German translations
   */
  const tabTranslations = getTabTranslations(intl);

  /**
   * Array of active tabs with entries.
   */
  const activeTabs = useActiveTabs(
    tabs,
    showOnlyPeerReviewed,
    getExcludedPublications,
  );

  /**
   * State to cache peer-reviewed status for different tabs
   */
  const { peerReviewedCache, togglePeerReviewed } = usePeerReviewState(
    !!data?.only_peer_reviewed,
  );

  /**
   * Handles tab change and peer review toggle.
   */
  const handleTabChange = useCallback(
    (tabKey: string) => {
      setActiveTab(tabKey as keyof typeof tabs);
    },
    [setActiveTab],
  );

  const handlePeerReviewedToggle = useCallback(() => {
    togglePeerReviewed(activeTab);
  }, [togglePeerReviewed, activeTab]);

  /**
   * Fetch data from API.
   */
  useEffect(() => {
    fetchData(
      person,
      collection,
      citation_style,
      block,
      pageUID,
      newer_than,
      showOnlyPeerReviewed,
      setIsLoading,
      setIsProcessing,
      setError,
      setDataState,
      setJsonData,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    block,
    person,
    collection,
    citation_style,
    showOnlyPeerReviewed,
    newer_than,
  ]);

  // Use custom hook for intersection observer
  useTabsIntersectionObserver({
    navRef,
    jsonData,
    setIsGradientNextEnabled,
  });

  // Effect to handle resize and visibility
  useEffect(() => {
    if (jsonData.length > 0) {
      checkLastElementVisibility();
      handleResize();
    }

    window.addEventListener('resize', handleResize);
    window.addEventListener('resize', checkLastElementVisibility);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('resize', checkLastElementVisibility);
    };
  }, [handleResize, checkLastElementVisibility, jsonData]);

  useEffect(() => {
    if (jsonData.length > 0) {
      dispatch(IrfDatas(jsonData));
    }
  }, [dispatch, jsonData]);

  /**
   * Handle active tab.
   */
  useEffect(() => {
    if (
      activeTabs.length > 0 &&
      !activeTabs.find(([key]) => key === activeTab)
    ) {
      setActiveTab(activeTabs[0][0] as keyof typeof tabs);
    }
  }, [activeTabs, activeTab, setActiveTab]);

  return (
    <>
      {isEditMode &&
      personOrCollectionChanged === true &&
      (person || collection) ? (
        <IrfAlertMessages messageType="saveDatas" />
      ) : (
        <>
          <IrfErrorBoundary>
            {jsonData.length ? (
              <div className="web25_irf-publication fade-in">
                <div className="blocks-group-wrapper transparent">
                  <nav className="irf-navigation" aria-label="Publication Tabs">
                    <ul
                      className={`irf-tabs ${
                        isGradientNextEnabled ? 'gradient_next' : ''
                      }`}
                      role="tablist"
                      ref={navRef}
                    >
                      {activeTabs.map(([tabKey]) => (
                        <li key={tabKey} role="presentation">
                          <button
                            className={`tab-button ${activeTab === tabKey ? 'active' : ''}`}
                            onClick={() =>
                              handleTabChange(tabKey as keyof typeof tabs)
                            }
                            role="tab"
                            aria-selected={activeTab === tabKey}
                            aria-controls={`${tabKey}-panel`}
                            id={`${tabKey}-tab`}
                          >
                            {tabTranslations[tabKey as keyof typeof tabs]}
                          </button>
                        </li>
                      ))}
                    </ul>
                    {isScrollVisible && (
                      <ul
                        data-testid="scroll-navigation"
                        className="scroll-navigation"
                      >
                        <li className="scroll-left">
                          <button
                            className="btn btn-white--colored btn--sm scroll-button left"
                            onClick={() => {
                              scrollNavigation(
                                'left',
                                navRef,
                                activeTabs,
                                activeTab,
                                setActiveTab,
                                tabs,
                              );
                            }}
                            aria-label="Scroll Left"
                            disabled={
                              activeTabs.findIndex(
                                ([tabKey]) => tabKey === activeTab,
                              ) === 0
                            }
                          >
                            {'←'}
                          </button>
                        </li>
                        <li className="scroll-right">
                          <button
                            className="btn btn-white--colored btn--sm scroll-button right"
                            onClick={() => {
                              scrollNavigation(
                                'right',
                                navRef,
                                activeTabs,
                                activeTab,
                                setActiveTab,
                                tabs,
                              );
                            }}
                            aria-label="Scroll Right"
                            disabled={
                              activeTabs.findIndex(
                                ([tabKey]) => tabKey === activeTab,
                              ) ===
                              activeTabs.length - 1
                            }
                          >
                            {'→'}
                          </button>
                        </li>
                      </ul>
                    )}
                  </nav>

                  {(activeTab === 'publicationTab' ||
                    activeTab === 'presentationTab') &&
                    showOnlyPeerReviewed === false && (
                      <div className="irf-switch">
                        <Switch
                          isSelected={peerReviewedCache[activeTab]}
                          onChange={handlePeerReviewedToggle}
                          name="peerReviewed"
                          aria-label={intl.formatMessage(
                            messages.only_peer_reviewed_switch,
                          )}
                        >
                          {intl.formatMessage(
                            messages.only_peer_reviewed_switch,
                          )}
                        </Switch>
                      </div>
                    )}
                  {isLoading || isProcessing ? (
                    <div className="loading-indicator">
                      <div className="dot-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      {!isLoading || isProcessing ? (
                        <p>{intl.formatMessage(messages.processing_message)}</p>
                      ) : null}
                    </div>
                  ) : (
                    <ul
                      className="teaser-listing linklist has-anim"
                      id={`${activeTab}-panel`}
                      aria-labelledby={`${activeTab}-tab`}
                    >
                      <RenderEntries
                        entries={tabs[activeTab]}
                        peerReviewedCache={peerReviewedCache}
                        activeTab={activeTab}
                        showOnlyPeerReviewed={showOnlyPeerReviewed}
                        getExcludedPublications={getExcludedPublications}
                        isEditMode={!!isEditMode}
                        dataState={dataState || ''}
                        jsonData={jsonData}
                        displayContentGrouped={displayContentGrouped}
                      />
                    </ul>
                  )}
                </div>
              </div>
            ) : (
              <IrfAlertMessages messageType="selectCollection" />
            )}

            {error === true && (person || collection) && (
              <IrfAlertMessages messageType="serverError500" />
            )}
          </IrfErrorBoundary>
        </>
      )}
    </>
  );
};

export default IrfPublication;
