import React from 'react';
import { UniversalLink } from '@plone/volto/components';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { getItemsForBreadcrumbs } from './getItemsForBreadcrumbs';
import type { itemForBreadcrumbs } from './getItemsForBreadcrumbs';
import { getBreadcrumbs } from '@fhnw/actions/extendedBreadcrumbs/extendedBreadcrumbs';

const messages = defineMessages({
  breadcrumbs: {
    id: 'Breadcrumbs',
    defaultMessage: 'Breadcrumbs',
  },
});

type GlobalBreadcrumbsProps = {
  closeGlobalSearch: () => void;
  item: any;
};
const GlobalSearchBreadcrumbs = ({
  closeGlobalSearch,
  item,
}: GlobalBreadcrumbsProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const breadcrumbs = item ? item['@id'].split('/') : [];

  const dropDownBreadcrumbs = breadcrumbs.slice(5);
  const basePathForDropDown = `${breadcrumbs.slice(0, 5).join('/')}`;

  const { navroot, navigation_with_excluded } = useSelector((state: any) => {
    const { navroot, navigation_with_excluded } =
      state.content?.data?.['@components'] ?? {};
    return { navroot, navigation_with_excluded };
  });

  const breadcrumbItems = getItemsForBreadcrumbs(
    navroot,
    navigation_with_excluded,
    breadcrumbs,
  );
  const subrequestId = `globalsearch-${item['@id']}`;

  const requestedBreadcrumbs = useSelector(
    (state: any) => state.extendedBreadcrumbs?.subrequests?.[subrequestId],
  );

  const requestBreadcrumbs = () => {
    if (!requestedBreadcrumbs?.loading && !requestedBreadcrumbs?.loaded) {
      dispatch(getBreadcrumbs(item['@id'], subrequestId));
    }
  };

  return (
    <div className="global-search-breadcrumbs">
      <nav
        role="navigation"
        aria-label={intl.formatMessage(messages.breadcrumbs)}
        className="breadcrumbs"
      >
        <div className="breadcrumb">
          {breadcrumbItems.map(
            (item: itemForBreadcrumbs, index: number, items) => {
              return (
                <React.Fragment key={`divider-${index}`}>
                  {index > 0 && <div className="divider" />}
                  <div className="section">
                    <UniversalLink
                      href={item['@id']}
                      onClick={closeGlobalSearch}
                      linkComponent={UniversalLink}
                      openLinkInNewTab={false}
                    >
                      {item['title']}
                    </UniversalLink>
                  </div>
                </React.Fragment>
              );
            },
          )}
          {dropDownBreadcrumbs.length > 0 && (
            <Dropdown
              className="dropdown-menu"
              trigger={
                <React.Fragment>
                  <div className="divider" />
                  ...
                </React.Fragment>
              }
              onOpen={() => requestBreadcrumbs()}
            >
              <Dropdown.Menu>
                {dropDownBreadcrumbs.map(
                  (item: any, index: number, dropDownBreadcrumbs: string[]) => {
                    const customLink = `${basePathForDropDown}/${dropDownBreadcrumbs.slice(0, index + 1).join('/')}`;
                    return (
                      <Dropdown.Item key={index}>
                        <div className="section">
                          <UniversalLink
                            href={customLink}
                            onClick={closeGlobalSearch}
                            linkComponent={UniversalLink}
                            openLinkInNewTab={false}
                          >
                            {requestedBreadcrumbs?.items?.[index + 3]?.title ??
                              item}
                          </UniversalLink>
                        </div>
                      </Dropdown.Item>
                    );
                  },
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </nav>
    </div>
  );
};

export default GlobalSearchBreadcrumbs;
