/**
 * Configuration file for EduBox field order based on content type
 * This defines the order in which fields should be displayed for different content types
 */

import { EduBoxFieldProps } from '../../types/eduBox';

/**
 * Field names as constants to avoid typos and enable better IDE support
 * These match the property names in the EduBoxFieldProps interface
 */
export const FIELD_NAMES = {
  DEGREE: 'degree',
  STUDYING_TYPE: 'studying_type',
  ECTS: 'ects',
  STUDY_START: 'study_start',
  NEXT_START: 'next_start',
  DURATION: 'duration',
  FINAL_APPLICATION_DATE: 'final_application_date',
  STUDYING_MODEL: 'studying_model',
  SCHOOL_DAYS: 'school_days',
  TEACHING_LANGUAGE: 'teaching_language',
  PLACE: 'place',
  FEE: 'fee',
  STAY_ABROAD: 'stay_abroad',
  APPLICATION_FEE: 'application_fee',
  SEMESTER_FEE: 'semester_fee',
  SEMESTER_START: 'semester_start',
  TARGET_GROUP: 'target_group',
  ADMISSION_REQUIREMENTS: 'admission_requirements',
  FINANCING_TERMS: 'financing_terms',
  DOCUMENTS: 'documents',
  TERMS_AND_CONDITIONS: 'terms_and_conditions',
} as const;

/**
 * Content type identifiers
 * These match the @type values from the backend
 */
export const CONTENT_TYPES = {
  DEGREE_PROGRAM: 'DegreeProgram',
  CONTINUING_EDUCATION: 'ContinuingEducation',
} as const;

/**
 * Field order configuration for each content type
 *
 * DegreeProgram:
 * - studying_type appears between degree and ects
 * - duration appears between school_days and teaching_language
 *
 * ContinuingEducation:
 * - studying_type appears after semester_fee
 * - duration appears after next_start
 */
export const fieldOrderConfig: Record<string, Array<keyof EduBoxFieldProps>> = {
  [CONTENT_TYPES.DEGREE_PROGRAM]: [
    FIELD_NAMES.DEGREE,
    FIELD_NAMES.STUDYING_TYPE, // Positioned after degree
    FIELD_NAMES.ECTS,
    FIELD_NAMES.STUDY_START,
    FIELD_NAMES.NEXT_START,
    FIELD_NAMES.FINAL_APPLICATION_DATE,
    FIELD_NAMES.STUDYING_MODEL,
    FIELD_NAMES.SCHOOL_DAYS,
    FIELD_NAMES.DURATION, // Positioned after school_days
    FIELD_NAMES.TEACHING_LANGUAGE,
    FIELD_NAMES.PLACE,
    FIELD_NAMES.FEE,
    FIELD_NAMES.STAY_ABROAD,
    FIELD_NAMES.APPLICATION_FEE,
    FIELD_NAMES.SEMESTER_FEE,
    FIELD_NAMES.SEMESTER_START,
    FIELD_NAMES.TARGET_GROUP,
    FIELD_NAMES.ADMISSION_REQUIREMENTS,
    FIELD_NAMES.FINANCING_TERMS,
    FIELD_NAMES.DOCUMENTS,
    FIELD_NAMES.TERMS_AND_CONDITIONS,
  ],
  [CONTENT_TYPES.CONTINUING_EDUCATION]: [
    FIELD_NAMES.DEGREE,
    FIELD_NAMES.ECTS,
    FIELD_NAMES.STUDY_START,
    FIELD_NAMES.NEXT_START,
    FIELD_NAMES.DURATION, // Positioned after next_start
    FIELD_NAMES.FINAL_APPLICATION_DATE,
    FIELD_NAMES.STUDYING_MODEL,
    FIELD_NAMES.SCHOOL_DAYS,
    FIELD_NAMES.TEACHING_LANGUAGE,
    FIELD_NAMES.PLACE,
    FIELD_NAMES.FEE,
    FIELD_NAMES.STAY_ABROAD,
    FIELD_NAMES.APPLICATION_FEE,
    FIELD_NAMES.SEMESTER_FEE,
    FIELD_NAMES.STUDYING_TYPE, // Positioned after semester_fee
    FIELD_NAMES.SEMESTER_START,
    FIELD_NAMES.TARGET_GROUP,
    FIELD_NAMES.ADMISSION_REQUIREMENTS,
    FIELD_NAMES.FINANCING_TERMS,
    FIELD_NAMES.DOCUMENTS,
    FIELD_NAMES.TERMS_AND_CONDITIONS,
  ],
};

/**
 * Helper function to get the field order for a specific content type
 * @param contentType The content type to get the field order for
 * @returns Array of field names in the correct order
 */
export const getFieldOrder = (
  contentType: string,
): Array<keyof EduBoxFieldProps> => {
  return (
    fieldOrderConfig[contentType] ||
    fieldOrderConfig[CONTENT_TYPES.CONTINUING_EDUCATION]
  );
};

export default fieldOrderConfig;
