import React, { useEffect } from 'react';
import cx from 'classnames';
import { defineMessages } from 'react-intl';
import { isValidUrl } from './isValidUrl.tsx';
import { getAllowlist } from '../../../actions/allowlist';
import { useDispatch, useSelector } from 'react-redux';

const messages = defineMessages({
  Error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  InvalidURL: {
    id: 'URL Not Permitted',
    defaultMessage: 'URL Not Permitted',
  },
  ErrorMessage: {
    id: 'The URL in your block configuration is no longer on the approved allowlist but is still being referenced. Please remove or update the URL to comply with the current allowlist.',
    defaultMessage:
      'The URL in your block configuration is no longer on the approved allowlist but is still being referenced. Please remove or update the URL to comply with the current allowlist.',
  },
  Support: {
    id: 'If you think this is a mistake, please contact the site administrators.',
    defaultMessage:
      'If you think this is a mistake, please contact the site administrators.',
  },
});

const EMPTY_ARRAY = [];

const IframeView = (props) => {
  const { className, data, isEditMode, intl } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllowlist());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allowlist = useSelector(
    (state) => state.allowlist?.items || EMPTY_ARRAY,
  );

  return (
    <>
      {data.src && isValidUrl(allowlist, data.src) && (
        <div
          className={cx('block iframe', { default: !data.height }, className)}
        >
          <iframe
            className="frame"
            src={data.src}
            title={data.title}
            width="100%"
            height={data.height}
          />
        </div>
      )}
      {isEditMode && !isValidUrl(allowlist, data.src) && (
        <div className="error">
          <p>{intl.formatMessage(messages.InvalidURL)}</p>
          <p>{intl.formatMessage(messages.ErrorMessage)}</p>
          <p>{intl.formatMessage(messages.Support)}</p>
        </div>
      )}
    </>
  );
};

export default IframeView;
