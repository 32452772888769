import allowlist from './allowlist/allowlist';
import siteInit from './siteInit/siteInit';
import extendedBreadcrumbs from './extendedBreadcrumbs/extendedBreadcrumbs';

const reducers = {
  allowlist,
  siteInit,
  extendedBreadcrumbs,
};

export default reducers;
