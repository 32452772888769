/**
 * Props for the dateFormat component.
 */
export interface dateFormatProps {
  /**
   * The start date and time of the event.
   */
  start?: Date;
  /**
   * The end date and time of the event.
   * @default undefined
   */
  end?: Date;
  /**
   * Indicates whether the event has an open end time.
   * @default false
   */
  openEnd?: boolean;
  /**
   * Indicates whether to display the time.
   * @default false
   */
  wholeDay?: boolean;
  /**
   * Indicates whether to display the weekday.
   * @default false
   */
  showWeekday?: boolean;
  /**
   * Locale string to format the date and time.
   * If not provided, it defaults to the locale from the Redux store.
   * Supported locales are 'de', 'fr', and 'en'.
   * @default value from Redux store
   */
  locale?: string;

  timeOnly?: boolean;
  /**
   * Indicates whether to display only the date without time.
   * @default false
   */
  dateOnly?: boolean;
}

/**
 * Formats a date range into a string based on the provided options.
 *
 * @param {Object} params - The parameters for formatting the date.
 * @param {Date} [params.start] - The start date (optional).
 * @param {Date} [params.end] - The end date (optional).
 * @param {boolean} [params.openEnd=false] - Whether the end time is open-ended.
 * @param {boolean} [params.wholeDay=false] - Whether the event lasts the whole day.
 * @param {boolean} [params.showWeekday=false] - Whether to show the weekday.
 * @param {string} params.locale - The locale for formatting (supports 'de', 'fr', and defaults to 'en').
 * @param {boolean} [params.timeOnly=false] - Whether to show only the time.
 * @returns {string} The formatted date string.
 */
const dateFormat = ({
  start,
  end,
  openEnd = false,
  wholeDay = false,
  showWeekday = false,
  locale,
  timeOnly = false,
  dateOnly = false,
}: dateFormatProps): string => {
  const formatDate = (date: Date | undefined): string => {
    return date != null
      ? `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
      : '';
  };

  const formatTime = (date: Date | undefined): string => {
    return date != null
      ? `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`
      : '';
  };

  const language = locale === 'de' || locale === 'fr' ? locale : 'en'; // reduced to supported languages

  const startDate = start ? formatDate(start) : '';
  const endDate = end ? formatDate(end) : '';
  const startTime = !wholeDay ? formatTime(start) : '';
  const endTime = !wholeDay && end ? formatTime(end) : '';

  let displayValue = startDate;

  if (!displayValue) {
    return '';
  }
  if (endDate && startDate !== endDate) {
    displayValue = `${displayValue}–${endDate}`;
  }

  if (dateOnly) {
    if (endDate && startDate !== endDate) {
      return displayValue;
    }
    return startDate;
  }

  if (timeOnly) {
    displayValue = startTime;

    if (openEnd) {
      displayValue += '–Open End';
    } else if (endTime && startTime !== endTime) {
      displayValue += `–${endTime}`;
    }

    if (language === 'de') {
      displayValue = `${displayValue} Uhr`;
    }

    return displayValue;
  }

  if (showWeekday) {
    const shortWeekday = start
      ? start.toLocaleDateString(language, {
          weekday: 'short',
        })
      : '';
    // removes the "." behind the short weekday
    displayValue = `${shortWeekday.substring(
      0,
      shortWeekday.length,
    )}, ${displayValue}`;
  }

  if (!wholeDay && !openEnd) {
    if (startTime === endTime || !endTime) {
      displayValue = `${displayValue}, ${startTime}`;
    } else {
      displayValue = `${displayValue}, ${startTime}–${endTime}`;
    }

    if (language === 'de') {
      displayValue = `${displayValue} Uhr`;
    }
  } else if (!wholeDay && openEnd) {
    displayValue = `${displayValue}, ${startTime}–Open End`;
  }

  return displayValue;
};

/**
 * Returns short-form date labels for start and end, depending on whether it’s a single- or multi-day event.
 *
 * @param {dateFormatProps} params - The parameters for formatting the date.
 * @returns {{ start: string; end?: string }} An object containing `start` and optional `end`.
 */
export const getShortDates = ({
  start,
  end,
  locale,
}: dateFormatProps): { start?: string; end?: string } => {
  // Decide on supported languages ("de", "fr", or fallback to "en")
  const language = locale === 'de' || locale === 'fr' ? locale : 'en';

  // Helper to get a short, day-month style date (e.g. "1. Okt", "27. Mär", "1 Oct").
  const formatShortDate = (date: Date): string => {
    // "day: 'numeric', month: 'short'" often produces results like "1. Okt."
    // Removing trailing "." in some languages if needed:
    return date
      .toLocaleDateString(language, { day: 'numeric', month: 'short' })
      .replace(/\.$/, ''); // remove trailing period if present
  };

  // If no start date is given, return empty placeholders
  if (!start) {
    return { start: undefined, end: undefined };
  }

  // Prepare the start date string
  const startDateString = formatShortDate(start);

  // If no end date is provided, it’s effectively a “single-day” scenario
  if (!end) {
    return { start: startDateString };
  }

  // Check if start and end are on the same (calendar) day
  const isSameCalendarDay =
    start.getFullYear() === end.getFullYear() &&
    start.getMonth() === end.getMonth() &&
    start.getDate() === end.getDate();

  if (isSameCalendarDay) {
    // Single-day event
    // To prevent a trailing slash we return the start date as end.
    return { start: startDateString };
  }

  // Multi-day event
  return {
    start: startDateString,
    end: formatShortDate(end),
  };
};

export default dateFormat;
