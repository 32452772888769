import type { Location } from '@plone/types';

export const transformContentLocations = (
  contentLocations: any[],
): Location[] => {
  return contentLocations
    .map((loc) => ({
      '@id': loc?.['@id'],
      title: loc?.title,
      navTitle: loc?.title,
      address: {
        name: loc?.title,
        department: loc?.department,
        street: loc?.street,
        zipCity: loc?.zip_city,
      },
      contactData: {
        telephone: loc?.phone,
        email: loc?.email,
      },
      coordinates: {
        x: loc?.longitude,
        y: loc?.latitude,
      },
      zoomLevel: 16,
      locationPageURL: loc?.['@id'],
      routeURL: loc?.remoteUrl,
      buttonLink: loc?.button_link,
      tabName: loc?.tab_name,
      showInSlider: loc?.show_in_slider ?? false,
    }))
    .filter((location) => location.showInSlider === true);
};
