import React from 'react';

/**
 * Replaces newline characters (\n) with React <br /> elements.
 * Useful for displaying multi-line text. Returns JSX elements or null.
 * @param text - The input string to process
 * @returns JSX elements with line breaks or null if no text is provided
 * @example
 * const text = "Hello\nWorld";
 * const result = addLineBreaks(text);
 * // result: <React.Fragment>Hello<br />World</React.Fragment>
 */

const addLineBreaks = (text?: string) =>
  text
    ? text.split('\n').map((line, i, arr) => (
        <React.Fragment key={i}>
          {line}
          {i < arr.length - 1 && <br />}
        </React.Fragment>
      ))
    : null;

export default addLineBreaks;

/**
 * Replaces newline characters (\n) with HTML <br /> tags.
 * Useful for displaying multi-line text in HTML format.
 * @param text - The input string to process
 * @returns A string with <br /> tags replacing newline characters or an empty string if no text is provided
 * @example
 * const text = "Hello\nWorld";
 * const result = addLineBreaksAsHTML(text);
 * // result: "Hello<br />World"
 */
export const addLineBreaksAsHTML = (text?: string): string => {
  return text ? text.replace(/\n/g, '<br />') : '';
};
