import { useIntl } from 'react-intl';
import { EduBoxFieldProps, EduBoxMessages as messages } from './index';
import { Link } from '../Link/Link';
import { UniversalLink } from '@plone/volto/components';
import { getFieldOrder, CONTENT_TYPES } from './eduBoxFieldsConfig';
import { getItemReference } from '@fhnw/helpers/Url/UrlHelper';

const EduBoxKeyDatas = ({ content }: { content: EduBoxFieldProps }) => {
  const intl = useIntl();

  // Check if any key data exists
  const hasKeyData = !!(
    content.degree ||
    content.ects ||
    content.study_start ||
    content.next_start ||
    content.duration ||
    content.final_application_date ||
    content.studying_model ||
    content.school_days ||
    content.teaching_language ||
    content.place ||
    content.fee ||
    content.stay_abroad ||
    content.application_fee ||
    content.semester_fee ||
    content.studying_type ||
    content.semester_start ||
    content.target_group ||
    content.admission_requirements ||
    content.financing_terms ||
    (content.documents &&
      content.documents.filter((doc) => doc !== null).length > 0) ||
    content.terms_and_conditions
  );

  if (!hasKeyData) {
    return null;
  }

  // Get the content type, defaulting to ContinuingEducation if not specified
  const contentType = content['@type'] || CONTENT_TYPES.CONTINUING_EDUCATION;

  // Get the field order for the current content type
  const fieldOrder = getFieldOrder(contentType);

  // Helper function to render a field
  const renderField = (fieldName: keyof EduBoxFieldProps) => {
    // Special handling for documents field
    if (fieldName === 'documents') {
      if (
        content.documents &&
        content.documents.filter((doc) => doc !== null).length > 0
      ) {
        return (
          <div key={fieldName}>
            <dt>{intl.formatMessage(messages[fieldName])}</dt>
            <dd className="documents-container">
              <div className="documents-column">
                {content.documents.map((doc, index) => (
                  <div key={doc?.UID || index}>
                    <span>
                      <Link
                        href={getItemReference(doc)}
                        linkComponent={UniversalLink}
                      >
                        {doc?.title}
                      </Link>
                      {doc?.description && <span> - {doc?.description}</span>}
                    </span>
                  </div>
                ))}
              </div>
            </dd>
          </div>
        );
      }
      return null;
    }

    // Special handling for terms_and_conditions field
    if (fieldName === 'terms_and_conditions' && content.terms_and_conditions) {
      return (
        <div key={fieldName}>
          <dt>{intl.formatMessage(messages[fieldName])}</dt>
          <dd>
            <Link
              href={content.terms_and_conditions}
              aria-label={intl.formatMessage(
                messages.to_the_general_terms_and_conditions,
              )}
              linkComponent={UniversalLink}
            >
              {intl.formatMessage(messages.to_the_general_terms_and_conditions)}
            </Link>
          </dd>
        </div>
      );
    }

    // Standard field rendering
    const fieldValue = content[fieldName];
    if (fieldValue) {
      return (
        <div key={fieldName}>
          <dt>
            {intl.formatMessage(messages[fieldName as keyof typeof messages])}
          </dt>
          <dd
            dangerouslySetInnerHTML={{
              __html: fieldValue as string,
            }}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="web25__edubox-keydatas">
      <h2 className="text-weight-semibold no-border">
        {intl.formatMessage(messages.keydata)}
      </h2>

      <dl>{fieldOrder.map(renderField)}</dl>
    </div>
  );
};

export default EduBoxKeyDatas;
