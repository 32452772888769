import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import type {
  BreadcrumbsComponentProps,
  BreadcrumbItem,
} from '@fhnw/types/breadcrumbs';
import { Link, useLocation } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getBreadcrumbs } from '@plone/volto/actions';
import { getBaseUrl, hasApiExpander } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';
import homeSVG from '@plone/volto/icons/home.svg';
import SlotRenderer from '@plone/volto/components/theme/SlotRenderer/SlotRenderer';
import useBreadcrumbsConfig from './config';
import renderBreadcrumbItem from './BreadcrumbItem';
import {
  useBreadcrumbScroll,
  useIsMobile,
  useContentWidth,
  useTruncatedItems,
} from './utils/utils';

// Messages
const messages = defineMessages({
  home: {
    id: 'Home',
    defaultMessage: 'Home',
  },
  breadcrumbs: {
    id: 'Breadcrumbs',
    defaultMessage: 'Breadcrumbs',
  },
  controlpanel: {
    id: 'Site Setup',
    defaultMessage: 'Site Setup',
  },
});

const BreadcrumbsComponent = ({ pathname }: BreadcrumbsComponentProps) => {
  const intl = useIntl();
  const breadcrumbRef = useRef<HTMLDivElement>(null);
  const breadcrumbListRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const content = useSelector((state: any) => state.content.data);

  // Custom hooks for responsive behavior
  const isMobile = useIsMobile();
  const contentWidth = useContentWidth(breadcrumbRef);
  const { isGradientNextEnabled, isGradientPrevEnabled } =
    useBreadcrumbScroll(breadcrumbListRef);

  // Redux state and dispatch
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname: realPath } = location;

  const controlpanelItems = useMemo(
    () => [
      {
        url: '/controlpanel',
        title: intl.formatMessage(messages.controlpanel),
      },
    ],
    [intl],
  );

  const items = useSelector(
    (state: any) =>
      realPath.startsWith('/controlpanel')
        ? controlpanelItems
        : state.breadcrumbs.items,
    shallowEqual,
  );
  const root = useSelector((state: any) => state.breadcrumbs.root);

  // Toggle expand/collapse
  const toggleExpand = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  // Get breadcrumbs configuration
  const config = useBreadcrumbsConfig();

  // Use imported hook for truncation logic
  const truncatedItems = useTruncatedItems({
    items,
    contentWidth,
    isExpanded,
    isMobile,
    config,
    forceUpdateTrigger: Date.now(), // Use a timestamp that changes on each render when the location changes
  });

  // Create a memoized render function that uses the imported renderBreadcrumbItem
  const renderItem = useCallback(
    (item: BreadcrumbItem, index: number, allItems: BreadcrumbItem[]) => {
      return renderBreadcrumbItem({
        item,
        index,
        allItems,
        config,
        isExpanded,
        isMobile,
        toggleExpand,
      });
    },
    [config, isExpanded, isMobile, toggleExpand],
  );

  // Memoize style objects to prevent unnecessary re-renders
  const containerStyle = useMemo(
    () => ({
      width: contentWidth ? `${contentWidth}px` : '100%',
    }),
    [contentWidth],
  );

  // Memoize breadcrumb list class name
  const breadcrumbListClassName = useMemo(() => {
    const classes = ['breadcrumb'];

    if (isMobile) {
      classes.push('mobile');
      if (isGradientNextEnabled) classes.push('gradient_next');
      if (isGradientPrevEnabled) classes.push('gradient_prev');
    }

    return classes.join(' ');
  }, [isMobile, isGradientNextEnabled, isGradientPrevEnabled]);

  // Fetch breadcrumbs data if needed
  useEffect(() => {
    if (!hasApiExpander('breadcrumbs', getBaseUrl(pathname))) {
      dispatch(getBreadcrumbs(getBaseUrl(pathname)) as any);
    }
  }, [dispatch, pathname]);

  return (
    <>
      <SlotRenderer name="aboveBreadcrumbs" content={content} />
      <nav
        role="navigation"
        aria-label={intl.formatMessage(messages.breadcrumbs)}
        className={`breadcrumbs ${isMobile ? 'mobile' : ''}`}
        ref={breadcrumbRef}
        style={containerStyle}
      >
        <div
          ref={breadcrumbListRef}
          className={breadcrumbListClassName}
          style={containerStyle}
        >
          <Link
            to={root || '/'}
            className="home"
            title={intl.formatMessage(messages.home)}
          >
            <Icon name={homeSVG} size="25px" />
          </Link>
          {truncatedItems.map(renderItem)}
        </div>
      </nav>
    </>
  );
};

// For better debugging in React DevTools
BreadcrumbsComponent.displayName = 'Breadcrumbs';

BreadcrumbsComponent.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default BreadcrumbsComponent;
