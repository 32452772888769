import { SidebarPortal, BlockDataForm } from '@plone/volto/components';
import { withBlockExtensions } from '@plone/volto/helpers';
import NewsletterBlockSchema from './NewsletterBlockSchema';
import NewsletterView from './NewsletterView';
import { IntlShape } from 'react-intl';

interface NewsletterEditProps {
  data: any;
  block: string;
  onChangeBlock: (blockId: string, newData: any) => void;
  selected: boolean;
  intl: IntlShape;
  blocksConfig: any;
}

const NewsletterEdit = (props: NewsletterEditProps) => {
  const { data, onChangeBlock, block, selected, blocksConfig } = props;
  const schema = NewsletterBlockSchema(props.intl);

  return (
    <>
      <NewsletterView {...props} />
      <SidebarPortal selected={selected}>
        <BlockDataForm
          schema={schema}
          title={schema.title}
          onChangeField={(id: any, value: any) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          onChangeBlock={onChangeBlock}
          formData={data}
          block={block}
          blocksConfig={blocksConfig}
        />
      </SidebarPortal>
    </>
  );
};

export default withBlockExtensions(NewsletterEdit);
