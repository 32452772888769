import React from 'react';
import { Link } from '@fhnw/components/Link/Link';
import { UniversalLink } from '@plone/volto/components';
import { removePortalUrl } from '@fhnw/helpers/Url/UrlHelper';

interface ListingVariationButtonProps {
  buttonLink: any[] | string;
  buttonText: string;
  isEditMode: boolean;
}

export const ListingVariationButton: React.FC<ListingVariationButtonProps> = ({
  buttonLink,
  buttonText,
}) => {
  const isArray = Array.isArray(buttonLink);
  const item = isArray && buttonLink.length > 0 ? buttonLink[0] : undefined;
  const href = !isArray ? buttonLink : undefined;
  const forwardingLink = removePortalUrl(item?.link ?? item?.remoteUrl);

  const linkProps = {
    ...(item && !forwardingLink ? { item: item } : {}),
    ...(href || forwardingLink ? { href: forwardingLink ?? href } : {}),
  };

  return (
    <Link
      className="btn--md btn-white btn--link"
      title={buttonText ?? ''}
      {...linkProps}
      linkComponent={UniversalLink}
    >
      {buttonText}
    </Link>
  );
};
