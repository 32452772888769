import { Item } from '@fhnw/components/Blocks/Listing/variations/mapItemToTeaserProps';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';

export const removePortalUrl = (url?: string) => {
  if (!url) return url;

  // eslint-disable-next-line no-template-curly-in-string
  return url.replace('${portal_url}', '');
};

// Helper to check if an item is a PDF File based on type and content_type
const isPdfFileItem = (item: Item): boolean => {
  // Check both @type and file info if available
  return item['@type'] === 'File' && item?.mime_type === 'application/pdf';
};

// Helper to check if an item is a non-PDF File based on type
const isNonPdfFileItem = (item: Item): boolean => {
  return item['@type'] === 'File' && !isPdfFileItem(item);
};

// Helper to check if a URL string likely points to a PDF
// Use cautiously, only when full item info is missing.
const looksLikePdfUrl = (url: string): boolean => {
  if (!url) return false;

  // Check if URL is already an PDF download link
  if (url.includes('.pdf/@@download/file')) {
    return true;
  }

  // Basic check for .pdf extension, ignoring query params/hash
  try {
    // Handle relative/absolute URLs, ensure path check isn't fooled by domain
    const pathname = new URL(url, 'http://dummybase').pathname;
    return pathname.toLowerCase().endsWith('.pdf');
  } catch (e) {
    // Basic fallback for simple relative paths or potential invalid URLs
    return url.split('?')[0].split('#')[0].toLowerCase().endsWith('.pdf');
  }
};

// Handles only URL strings by creating a minimal item for getItemReference
export const getReference = (url?: string, token?: string): string => {
  if (!url) return '';
  // Pass only @id. getItemReference must handle missing type/file info.
  // We explicitly set @type to null/undefined here so getItemReference knows it's minimal
  return getItemReference({ '@id': url, '@type': undefined }, token);
};

// Handles full item objects or minimal objects from getReference
export const getItemReference = (item: Item, token?: string): string => {
  // 1. Determine the initial URL: Link target or item's own URL
  let effectiveUrl = flattenToAppURL(item['@id']);
  const targetUrl = flattenToAppURL(
    removePortalUrl(item.remoteUrl ?? item.link) ?? '',
  );
  const isLinkObject =
    item['@type'] === 'Link' ||
    (!!targetUrl && targetUrl !== '' && targetUrl !== effectiveUrl);

  // If it's a Link object with a target (and user is logged out), use the target URL
  if (!token && isLinkObject && targetUrl) {
    effectiveUrl = targetUrl; // Use the target URL (flattened in case it's relative)
  }

  // 2. Apply special behavior ONLY for logged-out users and INTERNAL URLs/Items
  if (!token && isInternalURL(effectiveUrl)) {
    let finalUrl = effectiveUrl; // Work with a final URL variable

    // --- Strategy: Prioritize full item info if available ---
    if (item['@type'] === 'File') {
      // If we have type info (and it's not the Link obj itself)
      if (isPdfFileItem(item)) {
        // It's a real PDF File object -> use its own base URL + display view
        finalUrl = `${finalUrl}/@@display-file/file`;
      } else if (isNonPdfFileItem(item)) {
        if (!item.mime_type && finalUrl.endsWith('.pdf')) {
          // if we don't know the mimetype but the url ends with .pdf
          finalUrl = `${finalUrl}/@@display-file/file`;
        } else {
          // It's a real non-PDF File object -> use its own base URL + download view
          finalUrl = `${finalUrl}/@@download/file`;
        }
      }
      // If it's a Document, etc. and it is not
      // a linkObject, finalUrl remains effectiveUrl (item's URL)
    } else if (item['@type'] !== 'File' && !isLinkObject) {
      return finalUrl;
    } else if (isLinkObject) {
      if (looksLikePdfUrl(finalUrl)) {
        // Looks like PDF -> add display view
        if (!finalUrl.includes('/@@')) {
          // Avoid adding if view already exists
          finalUrl = `${finalUrl}/@@display-file/file`;
        } else if (finalUrl.includes('/@@download/file')) {
          // Correct download to display for PDFs
          finalUrl = finalUrl.replace(
            '/@@download/file',
            '/@@display-file/file',
          );
        } else {
          // If it looks like PDF but doesn't end in /@@, add display view
          // WARNING: This can lead to errors in seldom cases.
          finalUrl = `${finalUrl}/@@display-file/file`;
        }
      }
    }

    return finalUrl;
  }

  return effectiveUrl;
};
