// @ts-nocheck
import {
  bToA,
  aToB,
} from '@kitconcept/volto-solr/components/theme/SolrSearch/base64Helpers';

export const encodeExtraConditions = (extraConditions) =>
  extraConditions.length === 0 ? '' : bToA(JSON.stringify(extraConditions));

export const decodeConditionTree = (encoded, { catchError } = {}) => {
  if (encoded) {
    try {
      return JSON.parse(aToB(encoded));
    } catch (exc) {
      if (catchError) {
        // eslint-disable-next-line no-console
        console.warn(
          `Ignored broken extra_conditions value [${encoded}] [${exc.message}]`,
        );
      } else {
        throw exc;
      }
    }
  }
  return {};
};
