import type {
  BreadcrumbItem,
  ScrollState,
  TruncatedItemsProps,
  EnhancedBreadcrumbItem,
} from '@fhnw/types/breadcrumbs';
import { useState, useEffect, useCallback, RefObject, useMemo } from 'react';

// Debug utility that only logs in development
export const debug =
  process.env.NODE_ENV !== 'production'
    ? (message: string, data?: any) =>
        // eslint-disable-next-line no-console
        console.debug(`[Breadcrumbs] ${message}`, data)
    : () => {};

/**
 * Simple hook to detect mobile viewport
 */
export const useIsMobile = (mobileBreakpoint = 768): boolean => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const checkMobile = () => setIsMobile(window.innerWidth < mobileBreakpoint);

    // Initial check
    checkMobile();

    // Throttled resize handler
    let timeoutId: ReturnType<typeof setTimeout>;
    const handleResize = () => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(checkMobile, 100);
    };

    window.addEventListener('resize', handleResize, { passive: true });

    return () => {
      window.removeEventListener('resize', handleResize);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [mobileBreakpoint]);

  return isMobile;
};

// Define isBrowser outside components to avoid window access during SSR
const isBrowser =
  typeof window !== 'undefined' && typeof document !== 'undefined';

/**
 * Custom hook to measure content width and trigger truncation recalculation on resize
 */
export const useContentWidth = (
  breadcrumbRef: React.RefObject<HTMLDivElement>,
  onWidthChange?: () => void,
): number => {
  const [contentWidth, setContentWidth] = useState(0);

  // Use useMemo to create DOM functions and state values
  // This allows them to be recreated when dependencies change,
  // which works both for route changes and for window safety
  const domUtils = useMemo(() => {
    // If not in browser, return dummy functions
    if (!isBrowser) {
      return {
        getContentElement: () => null,
        measureWidth: () => 0,
        setupObserver: () => () => {}, // Return empty cleanup function
      };
    }

    // These functions are created fresh on each render with useMemo dependencies
    const getContentElement = () => document.querySelector('.content');

    const measureWidth = () => {
      const contentElement = getContentElement();
      if (contentElement) {
        const newWidth = contentElement.clientWidth;
        if (newWidth !== contentWidth) {
          setContentWidth(newWidth);
          if (onWidthChange) onWidthChange();
        }
        return newWidth;
      }
      return contentWidth;
    };

    const setupObserver = (ref: React.RefObject<HTMLDivElement>) => {
      if (!ref.current) return () => {};

      // Setup ResizeObserver for more efficient width tracking
      if (typeof ResizeObserver !== 'undefined') {
        const observer = new ResizeObserver(measureWidth);
        observer.observe(ref.current);

        // Also observe the content element if it exists
        const contentElement = getContentElement();
        if (contentElement) {
          observer.observe(contentElement);
        }

        return () => observer.disconnect();
      } else {
        // Fallback to window resize for older browsers
        window.addEventListener('resize', measureWidth, { passive: true });
        return () => window.removeEventListener('resize', measureWidth);
      }
    };

    return { getContentElement, measureWidth, setupObserver };
  }, [contentWidth, onWidthChange]); // This dependency array is key for route change detection

  // Use a standard useEffect only for setup and cleanup
  useEffect(() => {
    // Skip on server
    if (!isBrowser) return;

    // Perform initial measurement
    domUtils.measureWidth();

    // Setup and return cleanup
    return domUtils.setupObserver(breadcrumbRef);
  }, [breadcrumbRef, domUtils]);

  return contentWidth;
};

/**
 * Custom hook to manage breadcrumb scroll state and gradients
 */
export const useBreadcrumbScroll = (
  breadcrumbListRef: React.RefObject<HTMLDivElement>,
): ScrollState => {
  const [scrollState, setScrollState] = useState<ScrollState>({
    isGradientNextEnabled: false,
    isGradientPrevEnabled: false,
  });

  // Setup scroll listener and initial check
  useEffect(() => {
    const listElement = breadcrumbListRef.current;
    if (!listElement) return;

    // Function to update scroll state
    const updateScrollState = () => {
      if (breadcrumbListRef.current) {
        const container = breadcrumbListRef.current;
        const scrollLeft = container.scrollLeft;
        const maxScrollLeft = container.scrollWidth - container.clientWidth;

        setScrollState({
          // Show left gradient only when scrolled to the right
          isGradientNextEnabled: scrollLeft > 0,
          // Show right gradient only when not scrolled fully to the right end
          isGradientPrevEnabled: scrollLeft < maxScrollLeft - 2,
        });
      }
    };

    // Initial check
    updateScrollState();

    // Add scroll event listener with passive option for performance
    listElement.addEventListener('scroll', updateScrollState, {
      passive: true,
    });

    return () => {
      listElement.removeEventListener('scroll', updateScrollState);
    };
  }, [breadcrumbListRef]);

  return scrollState;
};

/**
 * Combined hook for breadcrumbs state management
 */
export const useBreadcrumbsState = (
  breadcrumbRef: RefObject<HTMLDivElement>,
) => {
  const [state, setState] = useState({
    isExpanded: false,
    hasOverflow: false,
    scrollLeft: 0,
    contentWidth: 0,
    showGradientLeft: false,
    showGradientRight: false,
  });

  const toggleExpand = useCallback(() => {
    setState((prev) => ({ ...prev, isExpanded: !prev.isExpanded }));
  }, []);

  // Function to update scroll state
  const updateScrollState = () => {
    if (breadcrumbRef.current) {
      const container = breadcrumbRef.current;
      const scrollLeft = container.scrollLeft;
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      const hasOverflow = container.scrollWidth > container.clientWidth;

      setState((prev) => ({
        ...prev,
        scrollLeft,
        hasOverflow,
        showGradientLeft: scrollLeft > 0,
        showGradientRight: scrollLeft < maxScrollLeft - 2,
      }));
    }
  };

  // Measure content width and handle resize
  useEffect(() => {
    if (!breadcrumbRef.current) return;

    // Update measurements
    const updateMeasurements = () => {
      if (breadcrumbRef.current) {
        const contentElement = document.querySelector('.content');
        if (contentElement) {
          setState((prev) => ({
            ...prev,
            contentWidth: contentElement.clientWidth,
            hasOverflow:
              breadcrumbRef.current!.scrollWidth >
              breadcrumbRef.current!.clientWidth,
          }));
        }
      }

      updateScrollState();
    };

    // Initial measurement
    updateMeasurements();

    // Setup observer
    if (typeof ResizeObserver !== 'undefined') {
      const observer = new ResizeObserver(updateMeasurements);

      if (breadcrumbRef.current) {
        observer.observe(breadcrumbRef.current);
      }

      const contentElement = document.querySelector('.content');
      if (contentElement) {
        observer.observe(contentElement);
      }

      return () => observer.disconnect();
    } else {
      // Fallback
      window.addEventListener('resize', updateMeasurements, { passive: true });
      return () => window.removeEventListener('resize', updateMeasurements);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbRef]);

  // Add scroll listener
  useEffect(() => {
    const element = breadcrumbRef.current;
    if (!element) return;

    element.addEventListener('scroll', updateScrollState, { passive: true });
    return () => element.removeEventListener('scroll', updateScrollState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbRef]);

  return {
    ...state,
    toggleExpand,
  };
};

// Simple text truncation
export const truncateText = (
  text: string,
  maxLength: number,
  ellipsis: string = '...',
): string => {
  if (!text) return '';
  if (text.length <= maxLength) return text;
  const adjustedMaxLength = Math.max(1, maxLength - ellipsis.length);
  return `${text.substring(0, adjustedMaxLength).trim()}${ellipsis}`;
};

/**
 * Hook for truncating breadcrumb text based on available space
 */
export const useTruncatedItems = ({
  items,
  contentWidth,
  isExpanded,
  isMobile,
  config,
  forceUpdateTrigger = 0,
}: TruncatedItemsProps): EnhancedBreadcrumbItem[] => {
  return useMemo(() => {
    // Debug logging
    debug('Recalculating truncated items:', {
      itemsCount: items.length,
      contentWidth,
      isExpanded,
      isMobile,
      forceUpdateTrigger,
    });

    if (items.length === 0) return items;

    // On mobile, disable truncation completely
    if (isMobile) {
      return items.map(
        (item: BreadcrumbItem, index: number): EnhancedBreadcrumbItem => ({
          ...item,
          displayTitle: item.title,
          originalTitle: item.title,
          isFirstLevel: index === 0,
        }),
      );
    }

    // Use different base widths for truncation calculation based on expanded state
    const baseWidth = isExpanded
      ? config.layout.expandedWidth
      : config.layout.baseWidth;
    const availableWidth = contentWidth || baseWidth;
    const charWidth = 8; // 8px per character
    const homeIconWidth = 25; // Home icon width
    const dividerWidth = 20; // Width per divider
    const fixedWidth = homeIconWidth + items.length * dividerWidth;

    // Calculate total width needed for all items
    const totalTextWidth = items.reduce(
      (sum: number, item: { title: string }) =>
        sum + item.title.length * charWidth,
      0,
    );

    // Use padding from config or default to 5px
    const padding = config.layout.padding || 5;
    const totalWidth = fixedWidth + totalTextWidth + padding;

    // If total width exceeds available width, apply truncation
    if (totalWidth > availableWidth) {
      // Calculate total characters across all items
      const totalChars = items.reduce(
        (sum: number, item: { title: string }) => sum + item.title.length,
        0,
      );

      // Create a copy of items to modify
      const result = [...items] as EnhancedBreadcrumbItem[];
      const availableTextWidth = availableWidth - fixedWidth;

      // Process each item for truncation
      for (let i = 0; i < result.length; i++) {
        const isFirstLevel = i === 0;
        const isLastItem = i === result.length - 1;
        const originalTitle = result[i].title;

        // Calculate proportional max length for this item
        const proportion = originalTitle.length / (totalChars || 1); // Avoid division by zero

        // Apply factors based on item position and expanded state
        const itemFactor = isLastItem
          ? config.layout.lastItemFactor || 1.2
          : 1.0;
        let widthFactor = Math.max(1, availableWidth / 1000); // Scale based on width

        if (isExpanded) {
          widthFactor *= 1.5; // Show 50% more characters when expanded
          if (isFirstLevel) {
            widthFactor *= 2.0; // Double the characters for first level when expanded
          }
        }

        // Calculate character limits
        const adjustedMinChars = Math.floor(
          config.text.truncation.minChars * widthFactor,
        );
        const proportionalChars = Math.floor(
          (proportion * availableTextWidth * itemFactor) / charWidth,
        );

        // Determine max characters for this item
        const maxChars = Math.max(adjustedMinChars, proportionalChars);

        // Apply truncation if needed and enabled
        const displayTitle =
          config.text.truncation.enabled && originalTitle.length > maxChars
            ? truncateText(
                originalTitle,
                maxChars,
                config.text.truncation.ellipsis,
              )
            : originalTitle;

        // Update the result item
        result[i] = {
          ...result[i],
          isFirstLevel,
          originalTitle,
          displayTitle,
        };
      }

      return result;
    }

    // If no truncation needed, add displayTitle and originalTitle properties
    return items.map(
      (item: BreadcrumbItem, index: number): EnhancedBreadcrumbItem => ({
        ...item,
        displayTitle: item.title,
        originalTitle: item.title,
        isFirstLevel: index === 0,
      }),
    );
  }, [items, contentWidth, isExpanded, isMobile, config, forceUpdateTrigger]);
};
