import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { mapContentTypesToLocaleId } from '@fhnw/components/GlobalSearch/lib';
import GlobalSearchBreadcrumbs from '@fhnw/components/GlobalSearch/GlobalSearchBreadcrumbs';

type DefaultResultProps = {
  closeGlobalSearch: () => void;
  item: any;
};
const DefaultGlobalSearchResultItem = ({
  item,
  closeGlobalSearch,
}: DefaultResultProps) => {
  return (
    <article className="result-item default-result-item">
      <div className="result-top">
        <GlobalSearchBreadcrumbs
          item={item}
          closeGlobalSearch={closeGlobalSearch}
        />
        <div className="result-type">
          <span>
            <FormattedMessage id={mapContentTypesToLocaleId(item['@type'])} />
          </span>
        </div>
      </div>
      <Link
        to={item['@id']}
        className="url"
        onClick={closeGlobalSearch}
        title={item['@type']}
      >
        <div className="tileHeadline">
          {item?.extras?.highlighting_title &&
          item.extras?.highlighting_title.length > 0 ? (
            <span
              className="title"
              dangerouslySetInnerHTML={{
                __html: item.extras.highlighting_title,
              }}
            />
          ) : (
            <span className="title">{item.title}</span>
          )}
        </div>
        <div className="tileBody">
          {item?.extras?.highlighting &&
          item.extras?.highlighting.length > 0 ? (
            <span
              className="itemDescription"
              dangerouslySetInnerHTML={{
                __html: item.extras.highlighting,
              }}
            />
          ) : (
            <span className="itemDescription">{item.description}</span>
          )}
        </div>
      </Link>
    </article>
  );
};

export default DefaultGlobalSearchResultItem;
