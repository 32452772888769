import React from 'react';
import { useSelector } from 'react-redux';
import type { dateFormatProps } from './dateFormat';
import dateFormat from './dateFormat';

/**
 * FHNWDateFormat is a React component that formats and displays date and time information
 * according to FHNW specifications, supporting multiple locales and various display options.
 *
 * @param props - Props for configuring date and time display.
 * @returns A formatted date and time string.
 */
const FHNWDateFormat: React.FC<dateFormatProps> = ({
  start,
  end,
  openEnd = false,
  wholeDay = false,
  showWeekday = false,
  locale,
  timeOnly = false,
  dateOnly = false,
}) => {
  const localeFromState = useSelector((state: any) => state.intl.locale);
  return dateFormat({
    start,
    end,
    openEnd,
    wholeDay,
    showWeekday,
    locale: locale || localeFromState,
    timeOnly,
    dateOnly,
  });
};

export default FHNWDateFormat;
