import { IntlShape } from 'react-intl';
import { InfoEventMessages } from './InfoEventMessages';

const InfoEventSchema = (intl: IntlShape) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Standard',
        fields: [
          'info_event_subjects',
          'info_event_roominfo',
          'info_event_emptytext',
        ],
      },
    ],
    properties: {
      info_event_subjects: {
        title: intl.formatMessage(InfoEventMessages.info_event_subjects),
        widget: 'token',
        items: {
          vocabulary: { '@id': 'plone.app.vocabularies.Keywords' },
        },
      },
      info_event_roominfo: {
        title: intl.formatMessage(InfoEventMessages.info_event_roominfo),
        widget: 'text',
      },
      info_event_emptytext: {
        title: intl.formatMessage(InfoEventMessages.info_event_emptytext),
        widget: 'text',
      },
    },
  };
};

export default InfoEventSchema;
