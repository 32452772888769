import { ReactNode } from 'react';
import type { EduBoxFieldProps } from './index';

const EduBoxInfoTextValue = ({ children }: { children: ReactNode }) => {
  return (
    <div className="web25__edubox-infotext">
      <p>{children}</p>
    </div>
  );
};

const EduBoxInfoText = ({ content }: { content: EduBoxFieldProps }) => {
  // Determine what text to display based on application_enabled state
  const activeText =
    content?.application_enabled === true &&
    content?.application_infotext_active
      ? content.application_infotext_active
      : '';

  const inactiveText =
    content?.application_enabled === false &&
    content?.application_infotext_inactive
      ? content.application_infotext_inactive
      : '';

  const noteText = content?.application_note || '';

  // Determine if we should display anything
  const shouldDisplay = activeText || inactiveText || noteText;

  return (
    <>
      {shouldDisplay && (
        <EduBoxInfoTextValue>
          {activeText}
          {inactiveText}
          {noteText && ` ${noteText}`}
        </EduBoxInfoTextValue>
      )}
    </>
  );
};

export default EduBoxInfoText;
