import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ApplicationAccordionFieldProps, AccordionItem } from './index';
import { ApplicationAccordionBlockData } from '../../types/applicationAccordion';
import { AccordionDatas } from './utils/actions';
import { fetchData } from './utils/fetchData';

interface ApplicationAccordionProps {
  content: ApplicationAccordionFieldProps;
  isEditMode?: boolean;
  data?: ApplicationAccordionBlockData;
}

const ApplicationAccordion: React.FC<ApplicationAccordionProps> = (props) => {
  const { content, isEditMode, data } = props;
  const [accordionData, setAccordionData] = useState<
    ApplicationAccordionFieldProps[]
  >([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());

  useEffect(() => {
    fetchData(content, setLoading, setError, setAccordionData);
  }, [content, content.items]);

  useEffect(() => {
    if (accordionData.length > 0) {
      dispatch(AccordionDatas(accordionData));
    }
  }, [dispatch, accordionData]);

  // Filter the data first
  const filteredData = accordionData.filter(
    (item: ApplicationAccordionFieldProps) =>
      item &&
      item['@type'] &&
      item['@type'] === 'Occasion' &&
      (isEditMode
        ? item.review_state === 'published' || item.review_state === 'private'
        : item.review_state === 'published') &&
      (!item.expires || new Date(item.expires) > new Date()),
  );

  // Process data based on listing_order
  let processedData;

  if (data?.listing_order === true) {
    // Use data as-is when listing_order is true
    processedData = [...filteredData];
  } else {
    // Sort by date when listing_order is false or undefined
    // First, separate items with and without start dates
    const itemsWithStart: ApplicationAccordionFieldProps[] = [];
    const itemsWithoutStart: ApplicationAccordionFieldProps[] = [];

    filteredData.forEach((item) => {
      if (item.start) {
        itemsWithStart.push(item);
      } else {
        itemsWithoutStart.push(item);
      }
    });

    // Sort items with start dates by date (ascending order - earlier dates first)
    itemsWithStart.sort((a, b) => {
      if (a.start && b.start) {
        return new Date(a.start).getTime() - new Date(b.start).getTime();
      }
      return 0;
    });

    // Combine the sorted items with start dates first, followed by items without start dates
    // in their original order from filteredData
    processedData = [...itemsWithStart, ...itemsWithoutStart];
  }

  const handleToggle = (id: string) => {
    setExpandedItems((prevItems) => {
      const newItems = new Set(prevItems);
      if (newItems.has(id)) {
        newItems.delete(id);
      } else {
        newItems.add(id);
      }
      return newItems;
    });
  };

  return (
    <>
      {error && <div className="error">{error}</div>}
      {loading ? (
        <div className="loading">
          <div className="loading-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <>
          <div
            className="blocks-group-wrapper transparent"
            id="event-application-accordion"
          >
            {processedData.map((item, index) => {
              const itemId = item.id || index.toString();
              return (
                <AccordionItem
                  key={itemId}
                  data={item}
                  reviewState={item.review_state}
                  isExpanded={expandedItems.has(itemId)}
                  onToggle={() => handleToggle(itemId)}
                />
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default ApplicationAccordion;
