import { SidebarPortal } from '@plone/volto/components';
import Sidebar from './Sidebar';
import { FormattedMessage } from 'react-intl';
import { getParentUrl, addAppURL } from '@plone/volto/helpers';
import { toast } from 'react-toastify';
import { defineMessages, useIntl } from 'react-intl';
import { Icon, Toast } from '@plone/volto/components';
import AnchorSVG from '@fhnw/icons/anchor-icon.svg';

const messages = defineMessages({
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
  messageCopied: {
    id: 'Link URL copied.',
    defaultMessage: 'Link URL copied.',
  },
});

const AnchorBlockEdit = (props: any) => {
  const { data, onChangeBlock, block, selected, pathname } = props;
  let path = getParentUrl(pathname);
  path = addAppURL(path);
  const link = `${path}#${data.name}`;
  const intl = useIntl();

  const onCopy = () => {
    navigator.clipboard.writeText(link);
    toast.success(
      <Toast
        success
        title={intl.formatMessage(messages.success)}
        content={intl.formatMessage(messages.messageCopied)}
      />,
    );
  };

  return (
    <>
      <div>
        <div className="anchor-container">
          <div className="id-container">
            <Icon name={AnchorSVG} size="15px" /> <span>{data.name}</span>
          </div>
          <div>
            <button onClick={onCopy}>
              <FormattedMessage id="URL Copy" defaultMessage="URL Copy" />
            </button>
          </div>
        </div>
      </div>
      <SidebarPortal selected={selected}>
        <Sidebar
          {...props}
          data={data}
          block={block}
          onChangeBlock={onChangeBlock}
        />
      </SidebarPortal>
    </>
  );
};

export default AnchorBlockEdit;
