import { IntlShape } from 'react-intl';
import { IrfMessages } from './IrfMessages';

const IRFBlockSchema = (intl: IntlShape) => ({
  title: 'IRF Block',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [
        'person',
        'collection',
        'selected_types',
        'citation_style',
        'excluded_publications',
        'newer_than',
        'only_peer_reviewed',
        'display_content_grouped',
      ],
    },
  ],
  properties: {
    person: {
      title: intl.formatMessage(IrfMessages.person),
      description: intl.formatMessage(IrfMessages.person_description),
      widget: 'select',
      vocabulary: {
        '@id': '/@vocabularies/fhnw.vocabularies.irf_people',
      },
    },
    collection: {
      title: intl.formatMessage(IrfMessages.collection),
      description: intl.formatMessage(IrfMessages.collection_description),
      widget: 'select',
      vocabulary: {
        '@id': '/@vocabularies/fhnw.vocabularies.irf_collections',
      },
    },
    selected_types: {
      title: intl.formatMessage(IrfMessages.selected_types),
      description: intl.formatMessage(IrfMessages.selected_types_description),
      widget: 'select',
      isMulti: true,
      widgetOptions: {
        vocabulary: {
          '@id': '/@vocabularies/fhnw.vocabularies.irf_publication_types',
        },
      },
    },
    citation_style: {
      title: intl.formatMessage(IrfMessages.citation_style),
      description: intl.formatMessage(IrfMessages.citation_style_description),
      widget: 'select',
      vocabulary: {
        '@id': '/@vocabularies/fhnw.vocabularies.irf_citation_styles',
        defaultSelectedValue: 'APA 7th edition',
      },
    },
    excluded_publications: {
      title: intl.formatMessage(IrfMessages.excluded_publications),
      description: intl.formatMessage(
        IrfMessages.excluded_publications_description,
      ),
      type: 'string',
      widget: 'textarea',
    },
    newer_than: {
      title: intl.formatMessage(IrfMessages.newer_than),
      description: intl.formatMessage(IrfMessages.newer_than_description),
      type: 'integer',
      widget: 'year',
      default: 1970,
    },
    only_peer_reviewed: {
      title: intl.formatMessage(IrfMessages.only_peer_reviewed),
      description: intl.formatMessage(
        IrfMessages.only_peer_reviewed_description,
      ),
      type: 'boolean',
      default: false,
    },
    display_content_grouped: {
      title: intl.formatMessage(IrfMessages.display_content_grouped),
      description: intl.formatMessage(
        IrfMessages.display_content_grouped_description,
      ),
      type: 'select',
      choices: [
        ['no_grouping', intl.formatMessage(IrfMessages.content_no_grouping)],
        [
          'group_by_type',
          intl.formatMessage(IrfMessages.content_group_by_type),
        ],
      ],
      default: 'no_grouping',
    },
  },
  required: ['person', 'collection'],
});

export default IRFBlockSchema;
