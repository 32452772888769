import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { getVocabulary } from '@plone/volto/actions';
import { mapContentTypesToLocaleId } from '@fhnw/components/GlobalSearch/lib';
import GlobalSearchBreadcrumbs from '@fhnw/components/GlobalSearch/GlobalSearchBreadcrumbs';
import {
  EnabledSelectOptions,
  mapTokenToLabel,
} from '@fhnw/components/Blocks/SolarSearch/lib';
import { mapItemToTeaserProps } from '@fhnw/components/Blocks/Listing/variations/mapItemToTeaserProps';
import { processDateAndLocation } from '@fhnw/components/Teaser/Teaser';

const emptySelection: EnabledSelectOptions[] = [];

type NewsProps = {
  closeGlobalSearch: () => void;
  item: any;
};

const NewsGlobalSearchResultItem = ({ item, closeGlobalSearch }: NewsProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const locale = intl.locale;
  const universityToken = item?.extras?.taxonomy_university;
  const teaserProps = mapItemToTeaserProps(
    { ...item, whole_day: true },
    { locale },
    'news',
  );
  const university = useSelector(
    (state: any) =>
      state.vocabularies['collective.taxonomy.university']?.items ||
      emptySelection,
  );

  const UniversityName = mapTokenToLabel(universityToken, university);

  useEffect(() => {
    if (university.length === 0) {
      dispatch(
        /* @ts-ignore */
        getVocabulary({
          vocabNameOrURL: 'collective.taxonomy.university',
          size: -1,
        }),
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <article className="result-item news-result-item">
      <div className="result-top">
        <GlobalSearchBreadcrumbs
          item={item}
          closeGlobalSearch={closeGlobalSearch}
        />
        <div className="result-type">
          <span>
            <FormattedMessage id={mapContentTypesToLocaleId(item['@type'])} />
          </span>
        </div>
      </div>
      <Link
        to={item['@id']}
        className="summary url"
        onClick={closeGlobalSearch}
        title={item['@type']}
      >
        <div className="tileHeadline">
          {item?.extras?.highlighting_title &&
          item.extras?.highlighting_title.length > 0 ? (
            <span
              className="title"
              dangerouslySetInnerHTML={{
                __html: item.extras.highlighting_title,
              }}
            />
          ) : (
            <span className="title">{item.title}</span>
          )}
        </div>
        <div className="tileBody">
          {item?.extras?.highlighting &&
          item.extras?.highlighting.length > 0 ? (
            <span
              className="itemDescription"
              dangerouslySetInnerHTML={{
                __html: item.extras.highlighting,
              }}
            />
          ) : (
            <span className="itemDescription">{item.description}</span>
          )}
          <span className="additional-desc">
            {UniversityName.label && `${UniversityName.label} | `}
            {processDateAndLocation(teaserProps.dateAndLocation)}
          </span>
        </div>
      </Link>
    </article>
  );
};

export default NewsGlobalSearchResultItem;
