import React, { useState, useEffect } from 'react';
import { Icon, Image, Button } from 'semantic-ui-react';
import { isInternalURL, flattenToAppURL } from '@plone/volto/helpers';
import cx from 'classnames';
import noop from 'lodash/noop';

export const AssetTab = ({ props, tabIndex, tabTitle }) => {
  const {
    icon,
    image,
    assetType,
    assetPosition,
    iconSize,
    imageSize,
    hideTitle,
  } = props;
  const imageObject = image?.[0];
  return (
    <div
      className={cx('asset-position', {
        'asset-top': assetPosition === 'top',
        'asset-left': assetPosition === 'left',
        'asset-right': assetPosition === 'right',
      })}
    >
      {assetType === 'icon' && icon && (
        <Icon
          className={cx('tab-icon', icon, iconSize, 'aligned')}
          {...{
            ...(hideTitle && {
              role: 'img',
              'aria-hidden': 'false',
              'aria-label': tabTitle,
            }),
          }}
        />
      )}

      {assetType === 'image' && imageObject && (
        <Image
          src={
            isInternalURL(imageObject['@id'])
              ? `${flattenToAppURL(imageObject['@id'])}/${
                  imageObject?.image_scales?.image?.[0]?.scales?.[imageSize]
                    ?.download ||
                  imageObject?.image_scales?.image?.[0]?.download
                }`
              : imageObject['@id']
          }
          className={`ui ${imageSize} aligned`}
          alt={hideTitle ? tabTitle : ''}
        />
      )}

      {!hideTitle && (
        <div>
          <span className="menu-item-count">{tabIndex}</span>
          <span className="menu-item-text">{tabTitle}</span>
        </div>
      )}
    </div>
  );
};

export const MenuItem = (props) => {
  const { activeTab = null, tabs = {}, setActiveTab = noop, blockId } = props;

  const { tab, index } = props;
  const tabIndex = index + 1;
  const [tabChanged, setTabChanged] = useState(false);
  const defaultTitle = `Tab ${tabIndex}`;
  const tabSettings = tabs[tab];
  const { title, assetType } = tabSettings;
  const tabTitle = title || defaultTitle;

  useEffect(() => {
    if (
      tabChanged === true &&
      document?.getElementById(blockId)?.querySelector('#tab-pane-' + tab)
    ) {
      document
        .getElementById(blockId)
        .querySelector('#tab-pane-' + tab)
        .focus();
      setTabChanged(false);
    }
  }, [tabChanged, tab, blockId]);

  return (
    <>
      <div className="tab_menu ui menu" role="presentation">
        <Button
          className="tab_button ui item"
          name={defaultTitle}
          active={tab === activeTab}
          aria-selected={tab === activeTab}
          tabIndex={0}
          role={'tab'}
          aria-controls={'tab-' + tabIndex + '-panel'}
          onClick={() => {
            if (activeTab !== tab) {
              setActiveTab(tab);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              if (activeTab !== tab) {
                setActiveTab(tab);
              }
              setTabChanged(true);
            }
          }}
        >
          <>
            {assetType ? (
              <AssetTab
                props={tabSettings}
                tabTitle={tabTitle}
                tabIndex={tabIndex}
              />
            ) : (
              <>
                <span className="menu-item-count">{tabIndex}</span>
                <span className="menu-item-text">{tabTitle}</span>
              </>
            )}
          </>
        </Button>
      </div>
    </>
  );
};
