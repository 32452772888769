/**
 * Extract menu configuration properties from data
 * @param {Object} data - The data object containing menu configuration
 * @returns {Object} - Object containing all menu configuration properties
 */
export const getMenuConfig = (data) => {
  const {
    align,
    menuBorderless,
    menuColor,
    menuCompact,
    menuFluid,
    menuInverted,
    menuPointing,
    menuSecondary,
    menuSize,
    menuStackable,
    menuTabular,
    menuText,
    menuAlign,
  } = data;

  return {
    align,
    menuBorderless,
    menuColor,
    menuCompact,
    menuFluid,
    menuInverted,
    menuPointing,
    menuSecondary,
    menuSize,
    menuStackable,
    menuTabular,
    menuText,
    menuAlign,
    isContainer: align === 'full',
  };
};
