// searchQueries.ts

import { Config } from './index';

export const getNonSubjectSearchQuery = (
  formattedDate?: string,
  path?: string,
) => [
  {
    i: 'path',
    o: 'plone.app.querystring.operation.string.relativePath',
    v: `${path}`,
  },
  {
    i: 'portal_type',
    o: 'plone.app.querystring.operation.selection.any',
    v: [Config.portalType],
  },
  {
    i: 'review_state',
    o: 'plone.app.querystring.operation.selection.any',
    v: [Config.reviewState],
  },
];

export const getSubjectSearchQuery = (
  formattedDate: string,
  subjects: string[],
  path?: string,
) => [
  {
    i: 'path',
    o: 'plone.app.querystring.operation.string.relativePath',
    v: `${path}`,
  },
  {
    i: 'portal_type',
    o: 'plone.app.querystring.operation.selection.any',
    v: [Config.portalType],
  },
  {
    i: 'review_state',
    o: 'plone.app.querystring.operation.selection.any',
    v: [Config.reviewState],
  },
  {
    i: 'Subject',
    o: 'plone.app.querystring.operation.selection.any',
    v: subjects,
  },
];
