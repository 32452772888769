import { useEffect, useRef, useState } from 'react';

export const useInfiniteScroll = <T>(
  items: T[],
  initialItemsCount: number = 10,
) => {
  const [displayedItems, setDisplayedItems] = useState<T[]>([]);
  const [currentCount, setCurrentCount] = useState(initialItemsCount);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setDisplayedItems(items.slice(0, initialItemsCount));
  }, [items, initialItemsCount]);

  useEffect(() => {
    const loadMore = () => {
      if (currentCount >= items.length) return;

      const nextCount = Math.min(
        currentCount + initialItemsCount,
        items.length,
      );
      setCurrentCount(nextCount);
      setDisplayedItems(items.slice(0, nextCount));
    };

    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
          loadMore();
        }
      },
      {
        root: null,
        rootMargin: '100px',
        threshold: 0.1,
      },
    );

    observerRef.current = observer;

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [currentCount, items, initialItemsCount]);

  return {
    displayedItems,
    loadMoreRef,
    hasMore: currentCount < items.length,
  };
};
