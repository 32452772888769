import React, { useState, useEffect, useMemo } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import Select from '@fhnw/components/Form/Select/Select';
import TeaserContactSearch from '@fhnw/components/Teaser/TeaserContactSearch';
import { Icon } from '@plone/volto/components';
import { useDispatch, useSelector } from 'react-redux';
import { solrSearchContent } from '@kitconcept/volto-solr/actions';
import { encodeConditionTree } from '@kitconcept/volto-solr/components/theme/SolrSearch/SearchConditions';
import ResultItemPreviewImage from '@kitconcept/volto-solr/components/theme/SolrSearch/resultItems/helpers/ResultItemPreviewImage';
import SearchInput from './SearchInput';
import { encodeExtraConditions } from './ExtraConditions';
import Button from '@fhnw/components/Button/Button';
import resetSVG from '@plone/volto/icons/reset.svg';
import { getVocabulary } from '@plone/volto/actions';
import {
  mapToEnabledSelectOptions,
  getEnabledTokens,
  FacetToken,
  EnabledSelectOptions,
  mapTokenToLabel,
} from '@fhnw/components/Blocks/SolarSearch/lib';

const messages = defineMessages({
  faculty: {
    id: 'Faculty',
    defaultMessage: 'Faculty',
  },
  clearFilters: {
    id: 'Clear all filters',
    defaultMessage: 'Clear all filters',
  },
  moreResults: {
    id: 'More results',
    defaultMessage: 'More results',
  },
  personFound: {
    id: 'Persons found',
    defaultMessage: 'Persons found',
  },
  of: {
    id: 'of',
    defaultMessage: 'of',
  },
});

// NOTE: an immutable empty array MUST be used as the default value for the select components
const emptySelection: EnabledSelectOptions[] = [];

const PersonView = (props: any) => {
  const { data } = props;
  const { locale } = useIntl();
  const intl = useIntl();
  const showImage = data?.['show-image'];
  const batch_size = parseInt(data?.batch_size);
  const [selectValue, setSelectValue] = useState<EnabledSelectOptions[] | []>(
    [],
  );
  const [searchInput, setSearchInput] = useState('');

  const fieldsofstudy = useSelector(
    (state: any) =>
      state.vocabularies['collective.taxonomy.fieldsofstudy']?.items ||
      emptySelection,
  );

  // Sync FieldOfStudy
  const initialFieldsofstudySelected = useMemo(() => {
    return data.fieldsofstudyperson?.map((token: string) =>
      mapTokenToLabel(token, fieldsofstudy),
    );
  }, [data.fieldsofstudyperson, fieldsofstudy]);

  useEffect(() => {
    if (data.fieldsofstudyperson?.length > 0) {
      setSelectValue(initialFieldsofstudySelected);
    } else {
      setSelectValue([]);
    }
  }, [data.fieldsofstudyperson, initialFieldsofstudySelected]);

  const dispatch = useDispatch();
  const blockuuid = props.block;
  const result = useSelector(
    (state: any) => state.solrsearch.subrequests?.[blockuuid],
  );

  const enabledTokens = getEnabledTokens(result);
  const fieldsofstudyWithEnable: EnabledSelectOptions[] =
    mapToEnabledSelectOptions(fieldsofstudy, 0, enabledTokens);

  const onSolrSearch = (b_size: number) => {
    let facetToken: FacetToken = {};
    if (selectValue.length > 0) {
      for (let item of selectValue) {
        facetToken[item.value] = true;
      }
    }

    const extra_conditions = [
      ['Subject', 'string', { in: data.keywords || emptySelection }],
    ];

    dispatch(
      solrSearchContent(
        '/',
        {
          SearchableText: searchInput.length < 3 ? '' : searchInput,
          group_select: 6,
          doEmptySearch: true,
          facet_conditions: encodeConditionTree({
            taxonomy_fieldsofstudy: {
              c: facetToken,
              m: true,
            },
          }),
          extra_conditions: encodeExtraConditions(extra_conditions as any),
          b_size,
          sort_on: searchInput.length < 3 ? 'last_name_first_name' : null,
          lang: locale,
        },
        blockuuid,
      ),
    );
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (!batch_size || batch_size > result?.total) {
      onSolrSearch(result?.total);
    } else onSolrSearch(batch_size);
  }, [
    dispatch,
    searchInput,
    blockuuid,
    selectValue,
    batch_size,
    result?.total,
    data.keywords,
  ]);

  React.useEffect(() => {
    if (fieldsofstudy.length === 0) {
      dispatch(
        /* @ts-ignore */
        getVocabulary({
          vocabNameOrURL: 'collective.taxonomy.fieldsofstudy',
          size: -1,
        }),
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const onChangeSelect = (value: any) => {
    setSelectValue(value);
  };

  const onChangeSearchInput = (value: string) => {
    setSearchInput(value);
  };

  const onResetClick = () => {
    setSearchInput('');
    setSelectValue([]);
  };

  const onMoreResult = () => {
    onSolrSearch(result?.items?.length + batch_size);
  };

  return (
    <div>
      <div className="top-section">
        <div>
          <SearchInput value={searchInput} onChange={onChangeSearchInput} />
        </div>
        <div className="select-container">
          {data['faculty-filter'] && (
            <Select
              name="field-of-study-select"
              options={fieldsofstudyWithEnable}
              isMulti={true}
              onChange={onChangeSelect}
              value={selectValue}
              placeholder={intl.formatMessage(messages.faculty)}
            />
          )}
        </div>
      </div>

      <div className="reset-toggle-container">
        <div>
          <Button type="button" styles="clear-all" onPress={onResetClick}>
            <Icon name={resetSVG} size="20px" />
            <span>{intl.formatMessage(messages.clearFilters)}</span>
          </Button>
        </div>
        <div>
          {result?.total}
          {` ${intl.formatMessage(messages.personFound)}`}
        </div>
      </div>
      <div className="resultview-container">
        <ul className="teaser-listing">
          {result?.items.map((item: any, index: number) => (
            <TeaserContactSearch
              key={index}
              imageComponentProps={showImage ? { item } : undefined}
              imageComponent={showImage ? ResultItemPreviewImage : undefined}
              title={item.title}
              text={item.extras.role}
              reference={item['@id']}
              name_affix1={item.extras.name_affix1}
              address={{
                email: item.extras.email,
                tel1: item.extras.phone1,
                location: item.extras.address,
              }}
            />
          ))}
        </ul>
      </div>
      <div className="more-result-container">
        <div className="more-container">
          <button onClick={onMoreResult}>
            {result?.items?.length >= result?.total
              ? ''
              : ` ${intl.formatMessage(messages.moreResults)}`}
          </button>
          <span>{`${result?.items?.length} ${intl.formatMessage(messages.of)} ${result?.total}`}</span>
        </div>
      </div>
    </div>
  );
};

export default PersonView;
