/**
 * OVERRIDE ImageGallery.jsx
 * REASON: Design for rendering is different in fhnw project.
 * FILE: https://github.com/kitconcept/volto-light-theme/blob/d39ce99024d3411acbfc74e83d0ad172981abe8e/packages/volto-light-theme/src/components/Blocks/Listing/ImageGallery.jsx
 * FILE VERSION: VLT 5.0.1
 * PULL REQUEST: https://gitlab.fhnw.ch/webteam/fhnw-webauftritt-2025/-/merge_requests/226
 * TICKET: https://gitlab.fhnw.ch/webteam/fhnw-webauftritt-2025/-/issues/120
 * DATE: 2024-09-17
 * DEVELOPER: @iFlameing
 *
 * Depending on the nature of the change, if possible or suitable, mark the change with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */

import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import 'react-image-gallery/styles/css/image-gallery.css';
import config from '@plone/volto/registry';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Image as Img } from '@plone/volto/components';
import Video from '@plone/volto/components/manage/Blocks/Video/Body';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import Caption from './Caption.jsx';
import galleryRightSVG from '@fhnw/icons/arrow-white.svg';

/** START CUSTOMIZATION
* We have to copy all the file of library because we have to animate height during
* changing the slide. Which is not possilbe without copying the library

*/
const ReactImageGallery = loadable(
  () => import('./ImageGallery/src/ImageGallery'),
);
/* END CUSTOMIZATION */

/* Remove semantic ui button because it is messing css */
const renderLeftNav = (onClick) => {
  return (
    <button
      className="image-gallery-icon image-gallery-left-nav"
      onClick={onClick}
      aria-label="Go to previous slide"
    >
      <Icon name={galleryRightSVG} size="10px" />
    </button>
  );
};

/* Remove semantic ui button because it is messing css */
const renderRightNav = (onClick) => {
  return (
    <button
      className="image-gallery-icon image-gallery-right-nav"
      onClick={onClick}
      aria-label="Go to next slide"
    >
      <Icon name={galleryRightSVG} size="10px" />
    </button>
  );
};

/* Removed the full screen button and renderPlayPauseButton because it is not present in design */

const ImageGallery = React.forwardRef(
  ({ items, showCaption, showThumbnails, ...props }, ref) => {
    const { settings } = config;
    const defaultImg = config.getComponent('DefaultImage').component;
    const slides = items
      .map((item, index, array) => {
        if (
          !settings.imageObjects.includes(item['@type']) &&
          item['@type'] !== 'Video'
        ) {
          return null;
        }
        const image = item.image_field
          ? item.image_scales?.[item.image_field]?.[0]
          : null;
        const imageNumber = (
          <>
            <span className="image-number">
              {`${index + 1}`}/{array.length}
            </span>
          </>
        );
        const renderImage = () => {
          const currentSlide = ref?.current && ref.current.getCurrentIndex();
          return (
            <div className="image-gallery-image">
              <figure className="image-wrapper">
                {item['@type'] === 'Video' ? (
                  <Video
                    data={{
                      target_url: item.getRemoteUrl,
                      image_scales: item.image_scales,
                    }}
                    inGallery
                    currentSlide={index === currentSlide}
                  />
                ) : image ? (
                  <Img
                    item={item}
                    blurhash={image.blurhash}
                    scales={image.scales}
                    defaultScale="great"
                    alt=""
                  />
                ) : (
                  <img src={defaultImg} alt="" />
                )}
                <Caption
                  title={item.title}
                  currentSlide={index === currentSlide}
                  description={item.description}
                  imageNumber={imageNumber}
                  showCaption={showCaption}
                />
              </figure>
            </div>
          );
        };

        return {
          thumbnail: image
            ? `${flattenToAppURL(image.base_path || item['@id'])}/${
                image.scales.preview?.download || image.download
              }`
            : defaultImg,
          renderItem: renderImage,
          thumbnailAlt: '',
        };
      })
      .filter(Boolean);

    return (
      slides.length > 0 && (
        <div>
          <ReactImageGallery
            items={slides}
            showPlayButton={false}
            showFullscreenButton={false}
            showThumbnails={showThumbnails}
            renderLeftNav={renderLeftNav}
            renderRightNav={renderRightNav}
            lazyLoad
            infinite
            ref={ref}
            {...props}
          />
        </div>
      )
    );
  },
);

ImageGallery.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ImageGallery;
