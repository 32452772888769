import { useIntl } from 'react-intl';
import { useEffect, useRef } from 'react';

type TabsProps = {
  groupSelect: number;
  setGroupSelect: (index: number) => void;
  facetGroups: [string, number][];
};

export const SearchTabs = ({
  groupSelect,
  setGroupSelect,
  facetGroups,
}: TabsProps) => {
  facetGroups = facetGroups || [];
  const intl = useIntl();
  const searchTabCategoriesRef = useRef<HTMLDivElement | null>(null);

  // Add scroll event listener
  useEffect(() => {
    const container = searchTabCategoriesRef.current;

    if (!container) return;

    handleScroll();

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    const container = searchTabCategoriesRef.current;

    if (!container) return;

    const { scrollLeft, scrollWidth, clientWidth } = container;

    if (scrollLeft > 0) {
      container.classList.add('show-before');
    }

    if (scrollLeft <= 0) {
      container.classList.remove('show-before');
    }

    if (scrollLeft + clientWidth < scrollWidth) {
      container.classList.add('show-after');
    }

    if (scrollLeft + clientWidth + 20 > scrollWidth) {
      container.classList.remove('show-after');
    }
  };
  return (
    <div
      className="searchTabs ui top attached tabular menu"
      ref={searchTabCategoriesRef}
    >
      {facetGroups.map(([label, counter], index) => {
        const isActive = index === groupSelect;
        const hasResults = counter;
        return (
          <span
            onClick={() => hasResults && setGroupSelect(index)}
            onKeyDown={() => hasResults && setGroupSelect(index)}
            role="button"
            tabIndex={0}
            key={index}
            className={
              'searchTab item' +
              (isActive ? ' active' : ' inactive') +
              (hasResults ? ' results' : ' noresults')
            }
          >
            <span>
              {intl.formatMessage({ id: label, defaultMessage: label })}
              <span className="searchCounter">
                {hasResults ? counter : '0'}
              </span>
            </span>
          </span>
        );
      })}
    </div>
  );
};
