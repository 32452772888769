import { defineMessages } from 'react-intl';

export const ApplicationAccordionMessages = defineMessages({
  alternative_start: {
    id: 'block.occasion.alternative_start',
    defaultMessage: 'Date',
  },
  application_button_label: {
    id: 'block.occasion.application_button_label',
    defaultMessage: 'Registration',
  },
  start_end_date: {
    id: 'block.occasion.start_end_date',
    defaultMessage: 'Date',
  },
  open_end: {
    id: 'block.occasion.open_end',
    defaultMessage: 'open end',
  },
  place: {
    id: 'block.occasion.place',
    defaultMessage: 'Place',
  },
  duration: {
    id: 'block.occasion.duration',
    defaultMessage: 'Duration',
  },
  free_places: {
    id: 'block.occasion.free_places',
    defaultMessage: 'Available seats',
  },
  total_places: {
    id: 'block.occasion.total_places',
    defaultMessage: 'Number of seats',
  },
  occ_number: {
    id: 'block.occasion.occ_number',
    defaultMessage: 'Number',
  },
  final_application_date: {
    id: 'block.occasion.final_application_date',
    defaultMessage: 'Final application date',
  },
  course_management: {
    id: 'block.occasion.course_management',
    defaultMessage: 'Course Management',
  },
  additional_information: {
    id: 'block.occasion.additional_information',
    defaultMessage: 'Additional information',
  },
  no_additional_information: {
    id: 'block.occasion.no_additional_information',
    defaultMessage: 'No further information available for this application.',
  },
  status_open: {
    id: 'block.occasion.status_open',
    defaultMessage: 'Seats available',
  },
  status_closed: {
    id: 'block.occasion.status_closed',
    defaultMessage: 'Registration no longer possible',
  },
  listing_order: {
    id: 'block.occasion.listing_order',
    defaultMessage: 'Sort listing by content table',
  },
  listing_order_description: {
    id: 'block.occasion.listing_order_description',
    defaultMessage: 'Sort listing by content listing.',
  },
});
