import { useEffect, useRef, useState } from 'react';
import Logo from '@fhnw/components/Logo/Logo';
import { Container } from '@plone/components';
import { Link as ReactAriaLink } from 'react-aria-components';
import {
  LanguageSelector,
  Navigation,
  UniversalLink,
  Icon,
} from '@plone/volto/components';
import facebokSvg from '../../../../../icons/Facebookfhnw.svg';
import instagramSvg from '../../../../../icons/instagramfhnw.svg';
import youtubeSvg from '../../../../../icons/youtubefhnw.svg';
import linkedinSvg from '../../../../../icons/linkdein.svg';
import xSvg from '../../../../../icons/x.svg';
import { Link } from '@fhnw/components/Link/Link';
import cx from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import MobileNavigation from './MobileNavigation';
import { useSelector, shallowEqual } from 'react-redux';
import { NavItem } from '@fhnw/types/navItem';

interface NavigationFooterProps {
  token: string;
  metaNav: NavItem[];
}

export const NavigationFooter: React.FC<NavigationFooterProps> = ({
  metaNav,
}) => (
  <div className="bottom-section">
    <LanguageSelector />
    <div className="bottom-links-container">
      {metaNav?.map((item: NavItem) => (
        <UniversalLink
          key={item['@id']}
          href={item.remoteUrl}
          openLinkInNewTab={false}
        >
          {item.title}
        </UniversalLink>
      ))}
    </div>
    <div className="social-media-container">
      {[
        {
          href: 'https://www.facebook.com/fhnw.ch',
          label: 'FHNW on Facebook',
          icon: facebokSvg,
        },
        {
          href: 'https://www.instagram.com/fhnw.ch/',
          label: 'FHNW on Instagram',
          icon: instagramSvg,
        },
        {
          href: 'https://twitter.com/FHNW',
          label: 'FHNW on X',
          icon: xSvg,
        },
        {
          href: 'https://www.linkedin.com/school/fachhochschule-nordwestschweiz-fhnw/',
          label: 'FHNW on LinkedIn',
          icon: linkedinSvg,
        },
        {
          href: 'https://www.youtube.com/@FHNW',
          label: 'FHNW on YouTube',
          icon: youtubeSvg,
        },
      ].map(({ href, label, icon }, index) => (
        <ReactAriaLink
          key={index}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={label}
          className="logo-link"
        >
          <Icon name={icon} size="30px" />
        </ReactAriaLink>
      ))}
    </div>
  </div>
);

interface HeaderProps {
  pathname: string;
}

const Header: React.FC<HeaderProps> = ({ pathname }) => {
  const firstLevelDivRef = useRef<HTMLDivElement>(null);
  const secondLevelDivRef = useRef<HTMLDivElement>(null);
  const [openMenuFirst, setOpenMenuFirst] = useState<boolean>(false);
  const [openMenuSecond, setOpenMenuSecond] = useState<boolean>(false);
  const [firstLevel, setFirstLevel] = useState<number>(-1);
  const [secondLevel, setSecondLevel] = useState<number>(-1);

  const firstLevelItemsRef = useRef<(HTMLAnchorElement | null)[]>([]);
  const secondLevelItemsRef = useRef<(HTMLAnchorElement | null)[]>([]);

  const token = useSelector(
    (state: any) => state.userSession.token,
    shallowEqual,
  );

  const metaNav = useSelector(
    (state: any) => state.content?.data?.['@components']?.metanav || [],
  );

  const navItems = useSelector(
    (state: any) => state.content?.data?.['@components']?.navtree?.items,
  );

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLAnchorElement>,
    index: number,
    level: 'first' | 'second',
  ) => {
    if (event.key === 'Tab' && event.shiftKey) {
      const itemsRef =
        level === 'first' ? firstLevelItemsRef : secondLevelItemsRef;
      if (level === 'first' ? openMenuFirst : openMenuSecond) {
        event.preventDefault();
        itemsRef.current[index]?.focus();
      }
    }
  };

  useEffect(() => {
    if (openMenuFirst) {
      firstLevelDivRef.current?.focus();
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [openMenuFirst]);

  useEffect(() => {
    if (openMenuSecond) secondLevelDivRef.current?.focus();
  }, [openMenuSecond]);

  const handleNavigation = (
    level: 'first' | 'second',
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    index: number,
    remoteUrl: string,
  ) => {
    event.stopPropagation();
    event.preventDefault();

    if (level === 'first') {
      if (!remoteUrl && (!openMenuFirst || firstLevel !== index)) {
        setFirstLevel(index);
        setOpenMenuFirst(true);
        setOpenMenuSecond(false);
        setSecondLevel(-1);
      } else {
        closeNavigation();
      }
    } else {
      if (!remoteUrl && !openMenuSecond) {
        setSecondLevel(index);
        setOpenMenuSecond(true);
      } else {
        setOpenMenuSecond(false);
        setSecondLevel(-1);
      }
    }
  };

  const closeNavigation = () => {
    setOpenMenuFirst(false);
    setOpenMenuSecond(false);
    setFirstLevel(-1);
    setSecondLevel(-1);
  };

  return (
    <>
      <header className="header-slot">
        <OutsideClickHandler onOutsideClick={closeNavigation} display="flex">
          <Container layout>
            <div className="upper-section">
              <Logo />
              <Navigation
                firstLevel={firstLevel}
                handleNavigation={handleNavigation}
                navItems={navItems}
                firstLevelItemsRef={firstLevelItemsRef}
              />
            </div>
            <NavigationFooter token={token} metaNav={metaNav} />
          </Container>

          <div
            ref={firstLevelDivRef}
            tabIndex={-1}
            className={cx('submenu-wrapper', {
              'slide-in-left': openMenuFirst,
              'slide-out-left': !openMenuFirst,
            })}
          >
            <h2>{navItems?.[firstLevel]?.title}</h2>
            <ul>
              {navItems?.[firstLevel]?.items.map(
                (subitem: NavItem, index2: number) => (
                  <li key={subitem['@id']}>
                    <Link
                      href={subitem.remoteUrl ?? '#'}
                      className="btn btn-nav--sublevel"
                      data-is-expandable={!subitem.remoteUrl}
                      data-is-active={index2 === secondLevel}
                      ref={(el) => {
                        secondLevelItemsRef.current[index2] =
                          el as HTMLAnchorElement | null;
                      }}
                      onKeyDown={(
                        event: React.KeyboardEvent<HTMLAnchorElement>,
                      ) => handleKeyDown(event, index2, 'first')}
                      onClick={(
                        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                      ) => {
                        !subitem.remoteUrl
                          ? handleNavigation(
                              'second',
                              event,
                              index2,
                              subitem.remoteUrl,
                            )
                          : closeNavigation();
                      }}
                      linkComponent={UniversalLink}
                    >
                      {subitem.title}
                    </Link>
                  </li>
                ),
              )}
            </ul>
          </div>

          <div
            ref={secondLevelDivRef}
            tabIndex={-1}
            className={cx('subsubmenu-wrapper', {
              'slide-in-left': openMenuSecond,
              'slide-out-left': !openMenuSecond,
            })}
          >
            <h2>{navItems?.[firstLevel]?.items?.[secondLevel]?.title}</h2>
            <ul>
              {navItems?.[firstLevel]?.items?.[secondLevel]?.items.map(
                (subsubitem: NavItem, index3: number) => (
                  <li key={subsubitem['@id']}>
                    <Link
                      href={subsubitem.remoteUrl ?? '#'}
                      className="btn btn-nav--sublevel"
                      data-is-expandable={false}
                      onKeyDown={(
                        event: React.KeyboardEvent<HTMLAnchorElement>,
                      ) => handleKeyDown(event, index3, 'second')}
                      onClick={closeNavigation}
                      linkComponent={UniversalLink}
                    >
                      {subsubitem.title}
                    </Link>
                  </li>
                ),
              )}
            </ul>
          </div>
          <MobileNavigation
            navItems={navItems}
            token={token}
            metaNav={metaNav}
          />
        </OutsideClickHandler>
      </header>
    </>
  );
};

export default Header;
