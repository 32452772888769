import { Icon } from '@fhnw/components/Icon/Icon';

interface InstagramProps {
  size: 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL';
  color: string;
  onClick?: () => void;
}

const Instagram = (props: InstagramProps) => (
  <Icon color={props.color} onClick={props.onClick} size={props.size}>
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Desktop_2nd-Round"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="InstagramFollow"
          transform="translate(-330.000000, -3962.000000)"
          fill="#000000"
        >
          <path
            d="M345.909091,3993.81818 C337.1225,3993.81818 330,3986.69568 330,3977.90909 C330,3969.1233 337.1225,3962 345.909091,3962 C354.695682,3962 361.818182,3969.1233 361.818182,3977.90909 C361.818182,3986.69568 354.695682,3993.81818 345.909091,3993.81818 Z M353,3982.0824 L353,3973.91807 C353,3972.30457 351.693917,3971.00023 350.082167,3971.00023 L341.917833,3971.00023 C340.304333,3971.00023 339,3972.30632 339,3973.91807 L339,3982.0824 C339,3983.6959 340.306083,3985.00023 341.917833,3985.00023 L350.082167,3985.00023 C351.695667,3985.00023 353,3983.69415 353,3982.0824 Z M350.082167,3983.83357 L341.917833,3983.83357 C340.952417,3983.83357 340.166667,3983.04782 340.166667,3982.0824 L340.166667,3973.91807 C340.166667,3972.95265 340.952417,3972.1669 341.917833,3972.1669 L350.082167,3972.1669 C351.047583,3972.1669 351.833333,3972.95265 351.833333,3973.91807 L351.833333,3982.0824 C351.833333,3983.04782 351.047583,3983.83357 350.082167,3983.83357 Z M346,3974.50023 C344.067417,3974.50023 342.5,3976.06707 342.5,3978.00023 C342.5,3979.93282 344.067417,3981.50023 346,3981.50023 C347.932583,3981.50023 349.5,3979.93282 349.5,3978.00023 C349.5,3976.06707 347.932583,3974.50023 346,3974.50023 Z M346,3980.33357 C344.71375,3980.33357 343.666667,3979.28648 343.666667,3978.00023 C343.666667,3976.7134 344.71375,3975.6669 346,3975.6669 C347.286833,3975.6669 348.333333,3976.7134 348.333333,3978.00023 C348.333333,3979.28648 347.286833,3980.33357 346,3980.33357 Z M349.791667,3975.08357 C350.274667,3975.08357 350.666667,3974.69157 350.666667,3974.20857 C350.666667,3973.72498 350.274667,3973.33357 349.791667,3973.33357 C349.308667,3973.33357 348.916667,3973.72498 348.916667,3974.20857 C348.916667,3974.69157 349.308667,3975.08357 349.791667,3975.08357 Z"
            id="instagram"
            fill="currentColor"
          ></path>
        </g>
      </g>
    </svg>
  </Icon>
);

export default Instagram;
