import React, { useCallback, useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import type { Location } from '@plone/types';
import { MapState } from '../LocationMapContent';
import { Location as HistoryLocation } from 'history';
import { isCurrentPage } from '../utils/pageUtils';

interface AppLocation extends Omit<HistoryLocation, 'key'> {
  key?: string;
}

type UseMapManagerProps = {
  locationsData: Location[];
  state: MapState;
  dispatch: React.Dispatch<any>;
  location: AppLocation;
  oneOfThesePages: string[];
  offset: [number, number];
  mapInstance: mapboxgl.Map;
};

function useMapManager({
  locationsData,
  state,
  dispatch,
  location,
  oneOfThesePages,
  offset,
  mapInstance,
}: UseMapManagerProps) {
  const markersRef = useRef<mapboxgl.Marker[]>([]);
  const currentMarkerRef = useRef<mapboxgl.Marker | null>(null);
  const boundsRef = useRef(new mapboxgl.LngLatBounds());

  const handleFlyToLocation = useCallback(
    (
      coordinates: { x: number; y: number },
      zoomLevel: number,
      triggeredByClick: boolean = false,
    ) => {
      if (state.map) {
        state.map.flyTo({
          center: [coordinates.x, coordinates.y],
          zoom: zoomLevel,
        });

        if (triggeredByClick) {
          dispatch({ type: 'SET_CLICK_TRIGGERED', payload: false });
        }
      }
    },
    [dispatch, state.map],
  );

  const fitBoundsToMarkers = useCallback(() => {
    if (state.map && !boundsRef.current.isEmpty()) {
      state.map.fitBounds(boundsRef.current, { padding: 100 });
      dispatch({ type: 'SET_FIT_BOUNDS', payload: true });
    }
  }, [dispatch, state.map]);

  const findMatchingMarker = (location: Location) =>
    markersRef.current.find(
      (marker) =>
        marker.getLngLat().lng === location.coordinates.x &&
        marker.getLngLat().lat === location.coordinates.y,
    );

  const createMarker = (location: Location) => {
    const markerElement = document.createElement('div');
    const marker = new mapboxgl.Marker({ element: markerElement, offset })
      .setLngLat([location.coordinates.x, location.coordinates.y])
      .addTo(mapInstance);

    markerElement.addEventListener('click', () => {
      handleLocationClick(location);
    });

    return marker;
  };

  const handleLocationClick = useCallback(
    (location: Location) => {
      const matchingMarker = findMatchingMarker(location);

      setMarkerClass(currentMarkerRef.current, false);

      if (currentMarkerRef.current === matchingMarker) {
        // Zoom out (second click)
        fitBoundsToMarkers();
        currentMarkerRef.current = null;
      } else {
        // Zoom in (first click)
        handleFlyToLocation(location.coordinates, location.zoomLevel, true);
        dispatch({
          type: 'SET_LOCATION_AND_BOUNDS',
          payload: { location, fitBounds: false },
        });
        currentMarkerRef.current = matchingMarker || null;
        matchingMarker && setMarkerClass(matchingMarker, true);
      }
    },
    [dispatch, fitBoundsToMarkers, handleFlyToLocation],
  );

  const setMarkerClass = (
    marker: mapboxgl.Marker | null,
    isActive: boolean,
  ) => {
    if (!marker) return;

    const element = marker.getElement();
    element.className = isActive
      ? 'mapboxgl-marker mapboxgl-marker-anchor-center active-marker'
      : 'mapboxgl-marker mapboxgl-marker-anchor-center';
  };

  useEffect(() => {
    markersRef.current.forEach((marker) => marker.remove());
    markersRef.current = [];
    boundsRef.current = new mapboxgl.LngLatBounds();

    const isMatchingPage = isCurrentPage(location.pathname, oneOfThesePages);

    if (
      state.map &&
      locationsData.length > 0 &&
      locationsData[0].coordinates.x &&
      locationsData[0].coordinates.y
    ) {
      locationsData.forEach((loc) => {
        const marker = createMarker(loc);
        markersRef.current.push(marker);
        boundsRef.current.extend([loc.coordinates.x, loc.coordinates.y]);

        // Set the initial active marker if it matches the activeLocation, but not on startpages
        if (
          !isMatchingPage &&
          loc.coordinates.x === state.activeLocation.coordinates.x &&
          loc.coordinates.y === state.activeLocation.coordinates.y
        ) {
          currentMarkerRef.current = marker;
          setMarkerClass(marker, true);
        }
      });

      if (isMatchingPage) {
        fitBoundsToMarkers();
      } else {
        handleFlyToLocation(
          locationsData[0].coordinates,
          state.activeLocation.zoomLevel,
        );
        dispatch({
          type: 'SET_LOCATION_AND_BOUNDS',
          payload: { location: locationsData[0], fitBounds: false },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, state.map, locationsData, handleLocationClick]);

  return { handleLocationClick };
}

export default useMapManager;
