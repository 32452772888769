import React from 'react';
import { withBlockExtensions } from '@plone/volto/helpers';
import IrfPublication from './IrfPublication';

interface IrfViewProps {
  data: { folder: string };
  block: string;
  onChangeBlock: (blockId: string, value: any) => void;
}

const IrfView: React.FC<IrfViewProps> = (props: any) => {
  return <IrfPublication {...props} />;
};

export default withBlockExtensions(IrfView);
