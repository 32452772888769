import { useContext } from 'react';
import { DatePickerStateContext, Button } from 'react-aria-components';

const DatePickerClearButton = () => {
  let state = useContext(DatePickerStateContext)!;

  return (
    <Button slot={null} aria-label="Clear" onPress={() => state.setValue(null)}>
      &times;
    </Button>
  );
};

export default DatePickerClearButton;
