import { defineMessages } from 'react-intl';

const messages = defineMessages({
  fieldset_forms_api: {
    id: 'fieldset_forms_api',
    defaultMessage: 'Store data',
  },
  help_enableFormsAPI: {
    id: 'help_enableFormsAPI',
    defaultMessage:
      'When activated, the data will be stored for later use when the form is submitted',
  },
  label_dataCollectionId: {
    id: 'label_dataCollectionId',
    defaultMessage: 'Data Collection ID',
  },
});

export const formBlockSchemaEnhancer = ({ formData, schema, intl }) => {
  schema.fieldsets = schema.fieldsets.filter((f) => f.id !== 'savedata');
  schema.fieldsets.push({
    id: 'forms_api',
    title: intl.formatMessage(messages.fieldset_forms_api),
    fields: [
      'enableFormsAPI',
      ...(formData?.enableFormsAPI ? ['dataCollectionId'] : []),
    ],
  });
  schema.properties.enableFormsAPI = {
    type: 'boolean',
    title: intl.formatMessage(messages.fieldset_forms_api),
    description: intl.formatMessage(messages.help_enableFormsAPI),
  };
  schema.properties.dataCollectionId = {
    title: intl.formatMessage(messages.label_dataCollectionId),
    type: 'string',
    widget: 'text',
    maxLength: 100,
    // TODO: use a select widget with choices populated from the API
  };
  let conditional_required = schema.required.filter(
    (field) => field !== 'store' && field !== 'send',
  );
  if (formData?.enableFormsAPI !== true && formData?.send !== true) {
    conditional_required.push('enableFormsAPI');
    conditional_required.push('send');
  }
  if (formData.enableFormsAPI) {
    conditional_required.push('dataCollectionId');
  }

  schema.required = conditional_required;

  return schema;
};
