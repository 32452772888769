import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from '../Link/Link';
import { getName } from './TeaserContactProfile';
import { ContactProfileMessages as messages } from './TeaserContactMessages';

export type TeaserContactSearchProps<T> = {
  imageComponent?: React.ComponentType<any>;
  imageComponentProps?: T;
  reference?: string;
  title: string;
  name_affix1?: string;
  name_affix2?: string;
  text: string;
  address?: {
    location?: string;
    street?: string;
    tel1?: string;
    tel2?: string;
    email?: string | null;
  };
};

const TeaserContactSearch: React.FC<TeaserContactSearchProps<any>> = ({
  imageComponent,
  imageComponentProps,
  reference,
  title,
  name_affix1,
  name_affix2,
  text,
  address,
}) => {
  const intl = useIntl();
  const Image = imageComponent || 'img';

  return (
    <li className="teaser">
      <Link className="teaser-link" href={reference}>
        <div className="teaser-wrapper">
          <div className="teaser-image">
            <Link
              className="teaser-link"
              href={reference}
              title={title}
              newTab={false}
            >
              <Image {...imageComponentProps} />
            </Link>
          </div>
          <div className="teaser-content">
            <Link className="profile-link" href={reference} title={title}>
              <h3>{getName(title, name_affix1, name_affix2)}</h3>
            </Link>
            <p>{text}</p>
          </div>
          <address>
            {address?.location && (
              <>
                {address.location}
                <br />
              </>
            )}
            {address?.street && (
              <>
                {address.street}
                <br />
              </>
            )}
            {(address?.tel1 || address?.tel2) && (
              <>
                <br />
                {address?.tel1 && (
                  <>
                    <span>
                      {intl.formatMessage(messages.telepone_direct)}
                      <Link href={`tel:${address.tel1}`} title={title}>
                        {address.tel1}
                      </Link>
                    </span>
                  </>
                )}
                {address?.tel2 && (
                  <>
                    <span>
                      {intl.formatMessage(messages.telepone_reception)}
                      <Link href={`tel:${address.tel2}`} title={title}>
                        {address.tel2}
                      </Link>
                    </span>
                  </>
                )}
                {address?.email && (
                  <>
                    <span>
                      {intl.formatMessage(messages.email)}
                      <Link href={`mailto:${address.email}`}>
                        {address.email}
                      </Link>
                    </span>
                  </>
                )}
              </>
            )}
          </address>
        </div>
      </Link>
    </li>
  );
};

export default TeaserContactSearch;
