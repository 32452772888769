import { useMemo } from 'react';
import type { BreadcrumbsConfig } from '@fhnw/types/breadcrumbs';

/**
 * Default breadcrumbs configuration
 */
export const defaultBreadcrumbsConfig: BreadcrumbsConfig = {
  text: {
    truncation: {
      enabled: true, // We'll still use CSS truncation (text-overflow: ellipsis)
      minChars: 5, // Minimum characters to keep when truncating
      ellipsis: '...', // Used for manual truncation (CSS handles automatic truncation)
    },
    fullTextTitle: true, // Always use full text in title attribute
  },
  navigation: {
    homeItem: {
      include: true,
      title: 'Home',
    },
    expandable: {
      items: [], // Empty array - will be determined dynamically
      level: 0, // The first level is expandable (0-based index)
    },
  },
  layout: {
    baseWidth: 768, // Reasonable default for standard viewports
    expandedWidth: 1600, // Larger width when expanded
    padding: 20, // Add some padding
    lastItemFactor: 1.2,
  },
};

/**
 * Hook to get breadcrumbs configuration with optional overrides
 * @param overrides Optional configuration overrides
 * @returns Merged breadcrumbs configuration
 */
export const useBreadcrumbsConfig = (
  overrides?: Partial<BreadcrumbsConfig>,
): BreadcrumbsConfig => {
  return useMemo(() => {
    if (!overrides) {
      return defaultBreadcrumbsConfig;
    }

    // Deep merge the default config with overrides
    return {
      ...defaultBreadcrumbsConfig,
      text: {
        ...defaultBreadcrumbsConfig.text,
        ...(overrides.text || {}),
        truncation: {
          ...defaultBreadcrumbsConfig.text.truncation,
          ...(overrides.text?.truncation || {}),
        },
      },
      navigation: {
        ...defaultBreadcrumbsConfig.navigation,
        ...(overrides.navigation || {}),
        homeItem: {
          ...defaultBreadcrumbsConfig.navigation.homeItem,
          ...(overrides.navigation?.homeItem || {}),
        },
        expandable: {
          ...defaultBreadcrumbsConfig.navigation.expandable,
          ...(overrides.navigation?.expandable || {}),
        },
      },
      layout: {
        ...defaultBreadcrumbsConfig.layout,
        ...(overrides.layout || {}),
      },
    };
  }, [overrides]);
};

// Default export for backward compatibility
export default useBreadcrumbsConfig;
