import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { mapContentTypesToLocaleId } from '@fhnw/components/GlobalSearch/lib';
import GlobalSearchBreadcrumbs from '@fhnw/components/GlobalSearch/GlobalSearchBreadcrumbs';
import { processDateAndLocation } from '@fhnw/components/Teaser/Teaser';
import { mapItemToTeaserProps } from '@fhnw/components/Blocks/Listing/variations/mapItemToTeaserProps';

type EventProps = {
  closeGlobalSearch: () => void;
  item: any;
};

const EventGlobalSearchResultItem = ({
  item,
  closeGlobalSearch,
}: EventProps) => {
  const intl = useIntl();
  const locale = intl.locale;
  const teaserProps = mapItemToTeaserProps(item, { locale }, 'event');

  return (
    <article className="result-item event-result-item">
      <div className="result-top">
        <GlobalSearchBreadcrumbs
          item={item}
          closeGlobalSearch={closeGlobalSearch}
        />
        <div className="result-type">
          <span>
            <FormattedMessage id={mapContentTypesToLocaleId(item['@type'])} />
          </span>
        </div>
      </div>
      <Link
        to={item['@id']}
        className="summary url"
        onClick={closeGlobalSearch}
        title={item['@type']}
      >
        <div className="tileHeadline">
          {item?.extras?.highlighting_title &&
          item.extras?.highlighting_title.length > 0 ? (
            <span
              className="title"
              dangerouslySetInnerHTML={{
                __html: item.extras.highlighting_title,
              }}
            />
          ) : (
            <span className="title">{item.title}</span>
          )}
        </div>
        <div className="tileBody">
          {item?.extras?.highlighting &&
          item.extras?.highlighting.length > 0 ? (
            <div
              className="itemDescription"
              dangerouslySetInnerHTML={{
                __html: item.extras.highlighting,
              }}
            />
          ) : (
            <span className="itemDescription">{item.description}</span>
          )}
          <span className="additional-desc">
            {processDateAndLocation(teaserProps.dateAndLocation)}
          </span>
        </div>
      </Link>
    </article>
  );
};

export default EventGlobalSearchResultItem;
