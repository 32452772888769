import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getVocabulary } from '@plone/volto/actions';
import { mapContentTypesToLocaleId } from '@fhnw/components/GlobalSearch/lib';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  EnabledSelectOptions,
  mapTokenToLabel,
} from '@fhnw/components/Blocks/SolarSearch/lib';

const messages = defineMessages({
  people: {
    id: 'People',
    defaultMessage: 'People',
  },
});
const emptySelection: EnabledSelectOptions[] = [];

type PersonProps = {
  closeGlobalSearch: () => void;
  item: any;
};

const PersonGlobalSearchResultItem = ({
  item,
  closeGlobalSearch,
}: PersonProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const organisation = useSelector(
    (state: any) =>
      state.vocabularies['fhnw.vocabularies.organisation_entities']?.items ||
      emptySelection,
  );
  const organisationToken = item?.extras?.organisation_entities?.[0];
  const organisationLabel = mapTokenToLabel(
    organisationToken,
    organisation,
  ).label;

  useEffect(() => {
    if (organisation.length === 0) {
      dispatch(
        /* @ts-ignore */
        getVocabulary({
          vocabNameOrURL: 'fhnw.vocabularies.organisation_entities',
          size: -1,
        }),
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <article className="result-item person-result-item">
      <div className="result-top">
        <div className="breadcrumb">
          <span>{intl.formatMessage(messages.people)}</span>
        </div>
        <div className="result-type">
          <span>
            <FormattedMessage id={mapContentTypesToLocaleId(item['@type'])} />
          </span>
        </div>
      </div>
      <Link
        to={item['@id']}
        onClick={closeGlobalSearch}
        className="summary url"
        title={item['@type']}
      >
        <div className="tileHeadline">
          {item?.extras?.highlighting_title &&
          item.extras?.highlighting_title.length > 0 ? (
            <span
              className="title"
              dangerouslySetInnerHTML={{
                __html: item.extras.highlighting_title,
              }}
            />
          ) : (
            <span className="title">{item.title}</span>
          )}
        </div>
        <div className="tileBody">
          {item?.extras?.highlighting_role &&
          item.extras?.highlighting_role.length > 0 ? (
            <p
              className="itemDescription"
              dangerouslySetInnerHTML={{
                __html: item.extras.highlighting_role,
              }}
            />
          ) : (
            <span className="itemDescription">{item.extras?.role}</span>
          )}
          <span className="additional-desc">{organisationLabel}</span>
        </div>
      </Link>
    </article>
  );
};

export default PersonGlobalSearchResultItem;
