import { getVocabulary } from '@plone/volto/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { VocabularyItem } from './mapItemToTeaserProps';

export const useVocab = (vocab_name: string) => {
  const dispatch = useDispatch();

  const vocab: VocabularyItem[] = useSelector(
    (state: any) => state.vocabularies[vocab_name]?.items || [],
  );

  useEffect(() => {
    if (vocab.length === 0) {
      dispatch(
        /* @ts-ignore */
        getVocabulary({
          vocabNameOrURL: vocab_name,
          size: -1,
        }),
      );
    }
  }, [dispatch, vocab?.length, vocab_name]);

  return vocab;
};
