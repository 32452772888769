import { defineMessages } from 'react-intl';

export const ContactProfileMessages = defineMessages({
  telephone: {
    id: 'block.contactprofile.telephone',
    defaultMessage: 'Telephone',
  },
  telepone_reception: {
    id: 'block.contactprofile.telephone_reception',
    defaultMessage: 'Telephone (reception)',
  },
  telepone_direct: {
    id: 'block.contactprofile.telephone_direct',
    defaultMessage: 'Telephone (Direct)',
  },
  email: {
    id: 'block.contactprofile.email',
    defaultMessage: 'Email',
  },
});
