import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import Button from '../Button/Button';
import { useHandleSlick } from './hooks/useHandleSlick';
import { useHandleWidth } from './hooks/useHandleWidth';
import config from '@plone/volto/registry';
import { useHistory } from 'react-router';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import cx from 'classnames';

interface StartSliderProps {
  content: any;
}

interface StoryImageScale {
  download: string;
  height: number;
  width: number;
}

interface StoryImageScales {
  [key: string]: StoryImageScale;
}

interface StoryImage {
  'content-type': string;
  download: string;
  filename: string;
  height: number;
  scales: StoryImageScales;
  size: number;
  width: number;
}

export interface Story {
  '@id': string;
  '@type': string;
  back_link: string | null;
  button_link: string | null;
  department: string | null;
  description: string;
  email: string | null;
  exclude_from_nav: boolean;
  image_field: string;
  image_scales: {
    image: StoryImage[];
  };
  latitude: number | null;
  link_text: string;
  longitude: number | null;
  phone: string | null;
  remoteUrl: string | null;
  review_state: string;
  short_description: string;
  street: string | null;
  tab_name: string | null;
  title: string;
  type_title: string;
  zip_city: string | null;
  link: string;
  image_cropping: string;
}

const StartSlider: React.FC<StartSliderProps> = ({ content }) => {
  const Image = config.getComponent('Image').component;
  const storys: Story[] = content?.['@components']?.storys?.items;

  const sliderRef = useRef<any>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const playSpeed = 7000;

  useHandleWidth();

  const { resetProgressBar, settings } = useHandleSlick(
    storys,
    sliderRef,
    currentSlide,
    playSpeed,
  );

  const history = useHistory();

  const handleBeforeChange = (oldIndex: number, newIndex: number) => {
    resetProgressBar();
    setCurrentSlide(newIndex);
  };

  return (
    <div className="start-slider-slot">
      <div className="story-slider">
        <Slider ref={sliderRef} {...settings} beforeChange={handleBeforeChange}>
          {storys?.map((story: Story, index: number) => {
            const normalizedLink = flattenToAppURL(story.link);

            return (
              <div
                key={index}
                className="slick_slide story-slide"
                {...{
                  [`data-carousel-label-${index + 1}`]: story.short_description,
                }}
              >
                <Image
                  item={story}
                  imageField="image"
                  alt={story.title || 'Story Image'}
                  sizes="(max-width: 1024px) 100vw, (max-width: 1279px) 70vw, 80vw"
                  className={cx('story-image', story.image_cropping)}
                />

                <div className="story-info">
                  <h3 className="story_title">{story.title}</h3>
                  <p className="story_category">{story.description}</p>
                  <div className="story_link">
                    <Button
                      className="btn btn-secondary"
                      type="submit"
                      label={story.link_text}
                      styles="btn btn-primary"
                      onPress={() =>
                        normalizedLink && history.push(normalizedLink)
                      }
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default StartSlider;
