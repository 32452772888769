import addLineBreaks from '@fhnw/helpers/text/addLineBreaks';
import React from 'react';

type TeaserContentProps = {
  template?:
    | 'linklist'
    | 'event'
    | 'news'
    | 'fullBleedTeaser'
    | 'infobox'
    | 'default';
  category?: string;
  title?: string;
  processedDateAndLocation?: string;
  text?: string;
  truncate?: boolean;
  name_affix1?: string;
};

const truncateText = (text: string, maxLength: number) =>
  text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

const TeaserContent: React.FC<TeaserContentProps> = ({
  template,
  category,
  title,
  processedDateAndLocation,
  text,
  truncate = false,
  name_affix1,
}) => {
  const renderPointedMark = () => {
    const content = template === 'news' ? processedDateAndLocation : category;
    return content ? (
      <span className="teaser-content--pointed-mark">{content}</span>
    ) : null;
  };

  const textWithBreaks =
    text && truncate
      ? addLineBreaks(truncateText(text, 170))
      : addLineBreaks(text);

  return (
    <div className="teaser-content">
      {renderPointedMark()}
      <h3>{name_affix1 ? `${name_affix1} ${title}` : title}</h3>
      {template !== 'news' && processedDateAndLocation && (
        <span className="teaser-content--addition">
          {processedDateAndLocation}
        </span>
      )}
      {text && <p>{textWithBreaks}</p>}
    </div>
  );
};

export default TeaserContent;
