/**
 * Image/video caption component.
 * @module components/Image/Caption
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Image/video caption component class.
 * @function Caption
 * @params {string} as HTML tag used to wrap the caption.
 * @params {string} title Image title.
 * @params {string} description Image description.
 * @params {boolean} showCaption
 * @params {object} imageNumber Image number.

 * @returns {string} Markup of the component.
 */
const Caption = ({
  as = 'figcaption',
  title,
  description,
  showCaption,
  imageNumber,
}) => {
  const As = as;

  return (
    <As>
      <div className="image-number-container">{imageNumber}</div>
      {showCaption && (
        <>
          {title && <div className="title">{title}</div>}
          {description && (
            <div className="description">
              {description?.split('\n').map((line, index) => (
                <div key={index}>{line || '\u00A0'}</div>
              ))}
            </div>
          )}
        </>
      )}
    </As>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Caption.propTypes = {
  showCaption: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Caption;
