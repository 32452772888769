import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { useLocation } from 'react-router';
import type { Dependency, FollowUsData } from '@fhnw/types/followUs';

/**
 * Parameters for the useDataFetchEffect hook.
 * This interface defines the inputs required for data fetching and state updates.
 */
interface UseDataFetchEffectParams {
  /**
   * The input data used by the hook.
   * This data is passed to the `generateQuery`, `extractUniqueKey`, and `validateData` functions.
   */
  data: FollowUsData;

  /**
   * A string identifier for the block associated with this hook.
   * Typically used to differentiate or categorize queries.
   */
  block: string;

  /**
   * Function to generate a query object based on the input data.
   * The returned object is used in the fetch request and must conform to the required query structure.
   *
   * @param data - The input data used to generate the query.
   * @returns A query object used for fetching data.
   */
  generateQuery: (data: FollowUsData) => Record<string, any>;

  /**
   * Function to extract a unique key from the input data.
   * This key is used to identify requests and prevent redundant fetches.
   *
   * @param data - The input data used to extract the unique key.
   * @returns A unique key as a string, or `null` if no key can be generated.
   */
  extractUniqueKey: (data: FollowUsData) => string | null;

  /**
   * Redux action creator to perform the fetch request.
   * This function dispatches the action to update the Redux store with the fetched data.
   *
   * @param path - The path for the request.
   * @param query - The query object generated by `generateQuery`.
   * @param block - The block identifier for the request.
   * @returns A Redux action that triggers the data fetch.
   */
  createAction: (
    path: string,
    query: Record<string, any>,
    block: string,
  ) => any;

  /**
   * Optional function to validate the input data.
   * If this function returns `false`, the fetch will be skipped.
   *
   * @param data - The input data to validate.
   * @returns `true` if the data is valid; otherwise `false`.
   * @default Always returns `true`.
   */
  validateData?: (data: FollowUsData) => boolean;

  /**
   * Array of dependencies for the effect.
   * Changes in these dependencies will re-trigger the useEffect.
   *
   * @default An empty array.
   */
  dependencies: Dependency[];

  /**
   * Optional debounce time in milliseconds to throttle fetch requests.
   * This prevents excessive fetches from rapid data or dependency changes.
   *
   * @default `300` milliseconds.
   */
  debounceTime?: number;
}

const useDataFetchEffect = ({
  data,
  block,
  generateQuery,
  extractUniqueKey,
  createAction,
  validateData = () => true,
  dependencies = [],
  debounceTime = 300,
}: UseDataFetchEffectParams) => {
  const dispatch = useDispatch();
  const isMounted = useRef(false);
  const location = useLocation();
  const path = getPathFromUrl(location.pathname);

  const prevDataRef = useRef<any>(extractUniqueKey(data));
  const hasDataChanged =
    prevDataRef.current !== extractUniqueKey(data) ||
    dependencies.some(
      (dep) =>
        dep && JSON.stringify(dep) !== JSON.stringify(prevDataRef.current),
    );

  const fetchData = async () => {
    if (!validateData(data)) {
      // eslint-disable-next-line no-console
      console.warn('fetchData: Validation failed, skipping fetch');
      return;
    }

    const uniqueKey = extractUniqueKey(data);
    if (!uniqueKey) return;

    const queryObject = generateQuery(data);

    if (hasDataChanged || !isMounted.current) {
      await dispatch(createAction(path, queryObject, block));
      prevDataRef.current = extractUniqueKey(data);
    }
  };

  const debouncedFetchData = debounce(fetchData, debounceTime);

  useEffect(() => {
    if (isMounted.current) {
      debouncedFetchData();
    } else {
      fetchData();
      isMounted.current = true;
    }

    return () => {
      debouncedFetchData.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);
};

export default useDataFetchEffect;

/**
 * Utility function to clean and format the URL path.
 *
 * @param {string} path - The URL path to process.
 * @returns {string} - The formatted path string.
 */
function getPathFromUrl(path: string): string {
  try {
    // Remove trailing slashes and segments e.g. edit|delete|view|other_segment
    path = path.replace(/\/(edit)$/, '');

    return path;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Invalid URL:', error);
    return '/';
  }
}
