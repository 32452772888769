import { defineMessages } from 'react-intl';
import type { BlockConfigBase, JSONSchema } from '@plone/types';
import { addStyling } from '@plone/volto/helpers/Extensions/withBlockSchemaEnhancer';
import baseMessages from '@fhnw/messages/base';

const messages = defineMessages({
  Variation: {
    id: 'Variation',
    defaultMessage: 'Variation',
  },
});

interface FlexibleSchema extends JSONSchema {
  properties: any;
}

export const ButtonSchemaEnhancer: BlockConfigBase['schemaEnhancer'] = ({
  schema,
  formData,
  intl,
}) => {
  const flexibleSchema = schema as FlexibleSchema;

  flexibleSchema.title = intl.formatMessage(baseMessages.ButtonBlock);

  if (flexibleSchema.fieldsets?.[0]?.fields) {
    const index = flexibleSchema.fieldsets[0].fields.indexOf('inneralign');
    if (index !== -1) {
      flexibleSchema.fieldsets[0].fields.splice(index, 1);
    }
  }

  flexibleSchema.properties.href = {
    ...(flexibleSchema.properties.href ?? {}),
    title: intl.formatMessage(baseMessages.ButtonLink),
    format: 'url',
  };

  flexibleSchema.properties.title = {
    ...(flexibleSchema.properties.title ?? {}),
    title: intl.formatMessage(baseMessages.ButtonText),
  };

  flexibleSchema.properties.openLinkInNewTab = {
    ...(flexibleSchema.properties.openLinkInNewTab ?? {}),
    title: intl.formatMessage(baseMessages.openLinkInNewTab),
  };

  addStyling({ schema: flexibleSchema, formData, intl });

  flexibleSchema.properties.styles.schema.fieldsets[0].fields = [
    ...flexibleSchema.properties.styles.schema.fieldsets[0].fields,
    'variation',
  ];

  flexibleSchema.properties.styles.schema.properties = {
    ...flexibleSchema.properties.styles.schema.properties,
    variation: {
      title: intl.formatMessage(messages.Variation),
      widget: 'color_picker',
      colors: [
        { name: 'black', label: 'black' },
        { name: 'white', label: 'white' },
      ],
      default: 'black',
    },
  };

  return flexibleSchema;
};
