import React, { useState, useEffect } from 'react';
import { Tab } from 'semantic-ui-react';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import cx from 'classnames';
import noop from 'lodash/noop';
import { withScrollToTarget } from '@eeacms/volto-tabs-block/hocs';
import { getMenuPosition } from '@eeacms/volto-tabs-block/utils';
import {
  scrollTabIntoView,
  useHandleResize,
  useTabsOverflow,
} from './utils/utils.ts';
import { getMenuConfig } from './menuConfig';
import { getPanes } from './panesConfig';
export { AssetTab } from './TabComponents';

const View = (props) => {
  const {
    metadata = {},
    data = {},
    tabsList = [],
    tabs = {},
    activeTabIndex = 0,
    setActiveTab = noop,
  } = props;
  const [menuPosition, setMenuPosition] = useState({});
  const blockId = props.id || '';
  const handleResize = useHandleResize(blockId);
  const [currentTabIndex, setCurrentTabIndex] = useState(activeTabIndex);
  const isOverflowing = useTabsOverflow(blockId);
  const [hasScrolled, setHasScrolled] = useState(false);

  // Get menu configuration
  const menuConfig = getMenuConfig(data);
  const { isContainer } = menuConfig;

  // Function to check if the last tab is fully visible
  const checkLastElementVisibility = () => {
    if (!blockId) return;
    const blockElement = document.getElementById(blockId);
    if (!blockElement) return;

    const menuElement = blockElement.querySelector(
      '.tabs-secondary-variant.menu',
    );
    if (!menuElement) return;

    const allTabs = Array.from(menuElement.querySelectorAll('.tab_button'));
    if (allTabs.length === 0) return;
  };

  // Function to check if tabs have scrolled from default position
  const checkScrollPosition = () => {
    // Skip this function on mobile devices
    // if (window.innerWidth <= 768) {
    //   return;
    // }

    if (!blockId) return;
    const blockElement = document.getElementById(blockId);
    if (!blockElement) return;

    const menuElement = blockElement.querySelector(
      '.tabs-secondary-variant.menu',
    );
    if (!menuElement) return;

    // If scrollLeft is greater than 0, tabs have scrolled
    setHasScrolled(menuElement.scrollLeft > 0);
  };

  useEffect(() => {
    if (Object.keys(menuPosition).length === 0) {
      setMenuPosition(getMenuPosition(data));
    }
  }, [data, menuPosition]);

  // Initial scroll to first tab on mount
  useEffect(() => {
    setTimeout(() => scrollActiveTabIntoView(), 500);
  });

  useEffect(() => {
    setCurrentTabIndex(activeTabIndex);
    scrollActiveTabIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabIndex]);

  // Effect to handle resize and visibility
  useEffect(() => {
    handleResize();
    checkLastElementVisibility();
    checkScrollPosition();

    // Add event listeners for resize
    window.addEventListener('resize', handleResize);
    window.addEventListener('resize', checkLastElementVisibility);
    window.addEventListener('resize', checkScrollPosition);

    // Add scroll event listener to the menu element
    const blockElement = document.getElementById(blockId);
    if (blockElement) {
      const menuElement = blockElement.querySelector(
        '.tabs-secondary-variant.menu',
      );
      if (menuElement) {
        menuElement.addEventListener('scroll', checkScrollPosition);
      }
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('resize', checkLastElementVisibility);
      window.removeEventListener('resize', checkScrollPosition);

      // Clean up scroll event listener
      const blockElement = document.getElementById(blockId);
      if (blockElement) {
        const menuElement = blockElement.querySelector(
          '.tabs-secondary-variant.menu',
        );
        if (menuElement) {
          menuElement.removeEventListener('scroll', checkScrollPosition);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleResize, tabsList, blockId]);

  // Scroll active tab into view when it's not visible
  const scrollActiveTabIntoView = () => {
    if (window.innerWidth <= 768) {
      return;
    }

    if (!blockId) return;

    // Immediate attempt to scroll
    scrollTabIntoView(currentTabIndex, blockId);

    // One additional attempt after a short delay to ensure DOM is fully updated
    setTimeout(() => {
      scrollTabIntoView(currentTabIndex, blockId);
      checkScrollPosition(); // Check scroll position after scrolling
    }, 100);
  };

  // Get panes configuration
  const panes = getPanes(props, tabsList, tabs, blockId, isContainer, metadata);

  const handlePrevTab = () => {
    // Calculate the new index
    const newIndex =
      currentTabIndex > 0 ? currentTabIndex - 1 : tabsList.length - 1;

    // Update the active tab
    setCurrentTabIndex(newIndex);
    setActiveTab(tabsList[newIndex]);

    // After a short delay to allow state updates, ensure the tab is fully visible
    setTimeout(() => {
      scrollTabIntoView(newIndex, blockId);
      checkScrollPosition(); // Check scroll position after scrolling
    }, 50);
  };

  const handleNextTab = () => {
    const newIndex =
      currentTabIndex < tabsList.length - 1 ? currentTabIndex + 1 : 0;
    setCurrentTabIndex(newIndex);
    setActiveTab(tabsList[newIndex]);

    // After a short delay to allow state updates, ensure the tab is fully visible
    setTimeout(() => {
      scrollTabIntoView(newIndex, blockId);
      checkScrollPosition(); // Check scroll position after scrolling
    }, 50);
  };

  const {
    menuBorderless,
    menuColor,
    menuCompact,
    menuFluid,
    menuInverted,
    menuPointing,
    menuSecondary,
    menuSize,
    menuStackable,
    menuTabular,
    menuText,
    menuAlign,
  } = menuConfig;

  return (
    <>
      <div className="tabs-container" id={blockId}>
        <div className="tabs-navigation-wrapper">
          <Tab
            activeIndex={currentTabIndex}
            className={cx('default tabs tabs-accessibility', {
              gradient_next: hasScrolled,
            })}
            renderActiveOnly={false}
            menu={{
              role: 'tablist',
              attached: menuPosition.attached,
              borderless: menuBorderless,
              color: menuColor,
              compact: menuCompact,
              fluid: menuFluid,
              inverted: menuInverted,
              pointing: menuPointing,
              secondary: menuSecondary,
              size: menuSize,
              stackable: menuStackable,
              tabular: menuTabular,
              text: menuText,
              vertical: menuPosition.vertical,
              className: cx(
                'tabs-secondary-variant',
                menuAlign,
                menuPosition.direction === 'left' ? 'border-right' : '',
                menuPosition.direction === 'right' ? 'border-left' : '',
                menuPosition.direction === 'top' ? 'border-bottom' : '',
                menuPosition.direction === 'bottom' ? 'border-top' : '',
                { container: isContainer },
              ),
            }}
            menuPosition={menuPosition.direction}
            panes={panes}
            grid={{ paneWidth: 9, tabWidth: 3 }}
          />
          {isOverflowing && (
            <div className="tabs-navigation-buttons gradient_prev">
              <button
                className="btn btn-white--colored btn--sm scroll-button right"
                onClick={handlePrevTab}
                aria-label="Previous tab"
                title="Previous tab"
              >
                {'←'}
              </button>
              <button
                className="btn btn-white--colored btn--sm scroll-button right"
                onClick={handleNextTab}
                aria-label="Next tab"
                title="Next tab"
              >
                {'→'}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default compose(withScrollToTarget)(withRouter(View));
