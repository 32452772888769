import { Icon } from '@fhnw/components/Icon/Icon';

interface EmptyCircleProps {
  size: 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL';
  color: string;
  onClick?: () => void;
}

const EmptyCircle = (props: EmptyCircleProps) => (
  <Icon color={props.color} onClick={props.onClick} size={props.size}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="32"
      height="32"
    >
      <title>Empty SVG</title>

      <path d="M16,0a16,16,0,1,1-16,16A16,16,0,0,1,16,0Z" fill="currentColor" />
    </svg>
  </Icon>
);

export default EmptyCircle;
