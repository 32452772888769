import React from 'react';
import { Transforms, Editor, Element } from 'slate';
import { useSlate } from 'slate-react';
import { useIntl, defineMessages } from 'react-intl';
import { ToolbarButton } from '@plone/volto-slate/editor/ui';
import indentSVG from '@plone/volto/icons/indent.svg';
import outdentSVG from '@plone/volto/icons/outdent.svg';

const messages = defineMessages({
  indent: {
    id: 'Indent',
    defaultMessage: 'Indent',
  },
  outdent: {
    id: 'Outdent',
    defaultMessage: 'Outdent',
  },
});

const IndentButton = (props) => {
  const editor = useSlate();
  const intl = useIntl();

  const applyIndent = () => {
    const [match] = Editor.nodes(editor, {
      match: (n) => Element.isElement(n),
      mode: 'highest',
    });

    if (match) {
      const [node, p] = match;
      let { styleName } = node;

      if (!styleName) {
        styleName = 'indent-1';
      } else {
        const indentMatch = styleName.match(/indent-(\d+)/);
        const currentIndent = indentMatch ? parseInt(indentMatch[1], 10) : 0;
        const newIndent = currentIndent + 1;
        styleName =
          styleName.replace(/indent-\d+/, '').trim() + ` indent-${newIndent}`;
      }
      Transforms.setNodes(editor, { styleName: styleName.trim() }, { at: p });
    }
  };

  return (
    <ToolbarButton
      title={intl.formatMessage(messages.indent)}
      {...props}
      icon={indentSVG}
      onMouseDown={applyIndent}
    />
  );
};

const OutdentButton = (props) => {
  const editor = useSlate();
  const intl = useIntl();
  const applyOutdent = () => {
    const [match] = Editor.nodes(editor, {
      match: (n) => Element.isElement(n),
      mode: 'highest',
    });

    if (match) {
      const [node, p] = match;
      let { styleName } = node;

      if (!styleName || !styleName.includes('indent-')) {
        return;
      }

      const indentMatch = styleName.match(/indent-(\d+)/);
      const currentIndent = indentMatch ? parseInt(indentMatch[1], 10) : 0;

      if (currentIndent > 1) {
        const newIndent = currentIndent - 1;
        styleName =
          styleName.replace(/indent-\d+/, '').trim() + ` indent-${newIndent}`;
      } else if (currentIndent === 1) {
        styleName = styleName.replace(/indent-\d+/, '').trim();
      }

      Transforms.setNodes(editor, { styleName: styleName || null }, { at: p });
    }
  };

  return (
    <ToolbarButton
      title={intl.formatMessage(messages.outdent)}
      {...props}
      icon={outdentSVG}
      onMouseDown={applyOutdent}
    />
  );
};

export default function install(config) {
  const { slate } = config.settings;

  slate.buttons.indent = IndentButton;
  slate.buttons.outdent = OutdentButton;
  slate.toolbarButtons = [
    ...slate.toolbarButtons,
    'separator',
    'indent',
    'outdent',
  ];

  return config;
}
