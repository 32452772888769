import TextField from '@fhnw/components/Form/TextField/TextField';
import { useIntl, defineMessages } from 'react-intl';
import Button from '@fhnw/components/Button/Button';
import { Icon } from '@plone/volto/components';
import clearSVG from '@plone/volto/icons/clear.svg';

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
}

const messages = defineMessages({
  search: {
    id: 'Search Term',
    defaultMessage: 'Search',
  },
});

const SearchInput = ({ value, onChange }: SearchInputProps) => {
  const intl = useIntl();
  return (
    <div className="search-container">
      <TextField
        placeholder={intl.formatMessage(messages.search)}
        value={value}
        onChange={onChange}
      />
      {value && (
        <Button type="button" styles="clear-icon" onPress={() => onChange('')}>
          <Icon name={clearSVG} />
        </Button>
      )}
    </div>
  );
};

export default SearchInput;
