import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useHandleWidth = () => {
  const location = useLocation();

  useEffect(() => {
    const updateWidth = (contentSlot: HTMLElement) => {
      const storyNavigation = document.querySelector(
        '.story_navigation',
      ) as HTMLElement;
      const startSliderSlot = document.querySelector(
        '.start-slider-slot',
      ) as HTMLElement;
      const imageElements = document.querySelectorAll(
        '.slick_slide.story-slide',
      ) as NodeListOf<HTMLElement>;

      const adjustedWidth = contentSlot.offsetWidth + 2;

      if (storyNavigation) storyNavigation.style.width = `${adjustedWidth}px`;
      if (startSliderSlot) startSliderSlot.style.width = `${adjustedWidth}px`;
      if (imageElements.length) {
        imageElements.forEach((image) => {
          image.style.width = `${adjustedWidth}px`;
        });
      }
    };

    let resizeObserver: ResizeObserver | null = null;

    // wait for the element to appear using a MutationObserver
    const mutationObserver = new MutationObserver(() => {
      const contentSlot = document.querySelector(
        '.content-slot',
      ) as HTMLElement;
      if (contentSlot) {
        mutationObserver.disconnect();
        updateWidth(contentSlot);
        resizeObserver = new ResizeObserver(() => updateWidth(contentSlot));
        resizeObserver.observe(contentSlot);
      }
    });

    mutationObserver.observe(document.body, { childList: true, subtree: true });
    return () => {
      mutationObserver.disconnect();
      if (resizeObserver) resizeObserver.disconnect();
    };
  }, [location]);
};
