/* eslint-disable prettier/prettier */
import React from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import Image from '@plone/volto/components/theme/Image/Image';
import { Teaser, TeaserBlockDefaultProps } from '../../../Teaser/index';
import isEmpty from 'lodash/isEmpty';
import { teaserBlockDefaultMessages } from './TeaserBlockDefaultMessages';
import { getItemReference } from '@fhnw/helpers/Url/UrlHelper';
import { FormState } from '@fhnw/types/formState';
import { useSelector } from 'react-redux';

const TeaserBlockDefault: React.FC<TeaserBlockDefaultProps> = (props) => {
  const { className, data, isEditMode } = props;
  const token = useSelector((state: FormState) => state?.userSession?.token);
  const intl = useIntl();
  const showTeaserImage = data.showTeaserImage ?? false;
  const showTeaserText = data.showTeaserText ?? false;
  const showTeaserHeading = data.showTeaserHeading ?? false;

  const overwrite = data?.overwrite === true;
  const href = data?.href?.[0];

  // TODO: use descriptive getters for these values
  const title = overwrite
    ? data?.title
    : isEmpty(href?.teaser_title)
      ? href?.title ?? data?.title
      : href?.teaser_title;
  const text = overwrite
    ? isEmpty(data?.description)
      ? undefined
      : data?.description
    : showTeaserText
      ? isEmpty(href?.teaser_text)
        ? data?.description
        : href?.teaser_text
      : undefined;
  const image = showTeaserImage
    ? overwrite
      ? data?.preview_image?.[0]
      : href
    : undefined;
  const heading = showTeaserHeading
    ? overwrite
      ? data?.head_title
      : '' // TODO: Add dateline taxonomy
    : undefined;
  const name_affix1 = data?.href?.[0]?.name_affix1 ?? '';

  return (
    <>
      <ul className={cx('teaser-block', className, 'teaser-listing has-anim')}>
        {!href && isEditMode && (
          <div className="teaser-item placeholder">
            <img src={imageBlockSVG} alt="" />
            <p>
              {intl.formatMessage(
                teaserBlockDefaultMessages.PleaseChooseContent,
              )}
            </p>
          </div>
        )}
        {href && (
          <Teaser
            template="linklist"
            category={heading}
            title={title ?? ''}
            {...(isEditMode
              ? {}
              : {
                  reference: getItemReference(href, token),
                })}
            text={text}
            imageComponent={showTeaserImage ? Image : undefined}
            imageComponentProps={
              showTeaserImage
                ? {
                    item: image ?? href,
                    src: null,
                    imageField: image ? image.image_field : href.image_field,
                    alt: '',
                    loading: 'lazy',
                    responsive: true,
                  }
                : undefined
            }
            name_affix1={name_affix1}
          />
        )}
      </ul>
    </>
  );
};

export default TeaserBlockDefault;
