import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { getVocabulary } from '@plone/volto/actions';
import { solrSearchContent } from '@kitconcept/volto-solr/actions';
import { encodeConditionTree } from '@kitconcept/volto-solr/components/theme/SolrSearch/SearchConditions';
import ResultItemPreviewImage from '@kitconcept/volto-solr/components/theme/SolrSearch/resultItems/helpers/ResultItemPreviewImage';
import Select from '@fhnw/components/Form/Select/Select';
import { Icon } from '@plone/volto/components';
import Teaser from '@fhnw/components/Teaser/Teaser';
import {
  createFacetToken,
  mapToEnabledSelectOptions,
  getEnabledTokens,
  EnabledSelectOptions,
  mapTokenToLabel,
} from '@fhnw/components/Blocks/SolarSearch/lib';
import SearchInput from './SearchInput';
import Button from '@fhnw/components/Button/Button';
import resetSVG from '@plone/volto/icons/reset.svg';
import contentlistingActive from '@fhnw/icons/list_active.svg';
import gridListing from '@fhnw/icons/grid.svg';
import { mapItemToTeaserProps } from '../Listing/variations/mapItemToTeaserProps';
import { parseDate } from '@internationalized/date';
import { DateRangeCondition, formatDateInUTC } from './NewsView';
import { encodeExtraConditions } from './ExtraConditions';

type ComponentViews = 'list' | 'grid';

const messages = defineMessages({
  school: {
    id: 'School',
    defaultMessage: 'School',
  },
  type: {
    id: 'Type',
    defaultMessage: 'Type',
  },
  location: {
    id: 'Location Placeholder',
    defaultMessage: 'Location',
  },
  clearFilters: {
    id: 'Clear all filters',
    defaultMessage: 'Clear all filters',
  },
  moreResults: {
    id: 'More results',
    defaultMessage: 'More results',
  },
  of: {
    id: 'of',
    defaultMessage: 'of',
  },
});

// NOTE: an immutable empty array MUST be used as the default value for the select components
const emptySelection: EnabledSelectOptions[] = [];

const EventsView = (props: any) => {
  const { locale } = useIntl();
  const intl = useIntl();
  const { data } = props;
  const showImage = data?.['show-image'];
  const batch_size = parseInt(data?.batch_size);
  const [searchInput, setSearchInput] = useState('');
  const [universitySelect, setUniversitySelect] = useState<
    EnabledSelectOptions[] | []
  >([]);
  const [eventTypeSelect, setEventTypeSelect] = useState<
    EnabledSelectOptions[] | []
  >([]);
  const [locationCategorySelect, setLocationCategorySelect] = useState<
    EnabledSelectOptions[] | []
  >([]);

  const university = useSelector(
    (state: any) =>
      state.vocabularies['collective.taxonomy.university']?.items ||
      emptySelection,
  );
  const eventType = useSelector(
    (state: any) =>
      state.vocabularies['collective.taxonomy.eventtype']?.items ||
      emptySelection,
  );
  const location = useSelector(
    (state: any) =>
      state.vocabularies['collective.taxonomy.city']?.items || emptySelection,
  );

  // Sync University
  const initialUniversitySelected = useMemo(() => {
    return data.universityevents?.map((token: string) =>
      mapTokenToLabel(token, university),
    );
  }, [data.universityevents, university]);

  useEffect(() => {
    if (data.universityevents?.length > 0) {
      setUniversitySelect(initialUniversitySelected);
    } else {
      setUniversitySelect([]);
    }
  }, [data.universityevents, initialUniversitySelected]);

  // Sync eventtype
  const initialEventTypeSelected = useMemo(() => {
    return data.eventtype?.map((token: string) =>
      mapTokenToLabel(token, eventType),
    );
  }, [data.eventtype, eventType]);

  useEffect(() => {
    if (data.eventtype?.length > 0) {
      setEventTypeSelect(initialEventTypeSelected);
    } else {
      setEventTypeSelect([]);
    }
  }, [data.eventtype, initialEventTypeSelected]);

  // Sync Location
  const initialLocationSelected = useMemo(() => {
    return data.city?.map((token: string) => mapTokenToLabel(token, location));
  }, [data.city, location]);

  useEffect(() => {
    if (data.city?.length > 0) {
      setLocationCategorySelect(initialLocationSelected);
    } else {
      setLocationCategorySelect([]);
    }
  }, [data.city, initialLocationSelected]);

  const defaultTemplate = data['default-template'];
  const [currentView, setCurrentView] = useState(defaultTemplate);

  React.useEffect(() => {
    setCurrentView(defaultTemplate);
  }, [defaultTemplate]);

  // const [, setCurrentView] = useState<ComponentViews>('Default');
  const dispatch = useDispatch();
  const blockuuid = props.block;
  const result = useSelector(
    (state: any) => state.solrsearch.subrequests?.[blockuuid],
  );
  // const RenderView = config.getComponent({
  //   name: 'searchBlockResultItem',
  //   dependencies: ['Event', currentView],
  // }).component;

  const enabledTokens = getEnabledTokens(result);
  const universityWithEnable: EnabledSelectOptions[] =
    mapToEnabledSelectOptions(university, 0, enabledTokens);
  const eventTypeWithEnable: EnabledSelectOptions[] = mapToEnabledSelectOptions(
    eventType,
    1,
    enabledTokens,
  );
  const locationCategoryWithEnable: EnabledSelectOptions[] =
    mapToEnabledSelectOptions(location, 2, enabledTokens);

  const onChangeSearchInput = (value: string) => {
    setSearchInput(value);
  };

  const onChangeUniversitySelect = (value: any) => {
    setUniversitySelect(value);
  };
  const onChangeEventTypeSelect = (value: any) => {
    setEventTypeSelect(value);
  };

  const onChangeLocationCategorySelect = (value: any) => {
    setLocationCategorySelect(value);
  };

  const onResetClick = () => {
    setSearchInput('');
    setLocationCategorySelect([]);
    setEventTypeSelect([]);
    setUniversitySelect([]);
  };

  const onChangeView = (view: ComponentViews) => {
    setCurrentView(view);
  };

  const onSolrSearch = (b_size: number) => {
    const facetConditions = {
      taxonomy_university: {
        c: createFacetToken(universitySelect),
        m: true,
      },
      event_type: {
        c: createFacetToken(eventTypeSelect),
        m: true,
      },
      location_category: {
        c: createFacetToken(locationCategorySelect),
        m: true,
      },
    };

    const dateRangeCondition: DateRangeCondition = {};
    dateRangeCondition.ge = formatDateInUTC(
      parseDate(new Date().toISOString().split('T')[0]),
    );
    const extra_conditions = [
      ['end', 'date-range', dateRangeCondition],
      ['Subject', 'string', { in: data.keywords || emptySelection }],
    ];

    dispatch(
      /* @ts-ignore */
      solrSearchContent(
        '/',
        {
          SearchableText: searchInput.length < 3 ? '' : searchInput,
          group_select: 3,
          doEmptySearch: true,
          sort_on: searchInput.length < 3 ? 'end' : null,
          facet_conditions: encodeConditionTree(facetConditions),
          extra_conditions: encodeExtraConditions(extra_conditions as any),
          b_size,
          lang: locale,
        },
        blockuuid,
      ),
    );
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (!batch_size || batch_size > result?.total) {
      onSolrSearch(result?.total);
    } else onSolrSearch(batch_size);
  }, [
    dispatch,
    searchInput,
    blockuuid,
    universitySelect,
    eventTypeSelect,
    locationCategorySelect,
    batch_size,
    result?.total,
    data.keywords,
  ]);

  // Fetching all the vocabulary
  React.useEffect(() => {
    if (university.length === 0) {
      dispatch(
        /* @ts-ignore */
        getVocabulary({
          vocabNameOrURL: 'collective.taxonomy.university',
          size: -1,
        }),
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  React.useEffect(() => {
    if (eventType.length === 0) {
      dispatch(
        /* @ts-ignore */
        getVocabulary({
          vocabNameOrURL: 'collective.taxonomy.eventtype',
          size: -1,
        }),
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  React.useEffect(() => {
    if (location.length === 0) {
      dispatch(
        /* @ts-ignore */
        getVocabulary({
          vocabNameOrURL: 'collective.taxonomy.city',
          size: -1,
        }),
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const onMoreResult = () => {
    onSolrSearch(result?.items?.length + batch_size);
  };
  return (
    <div>
      <SearchInput value={searchInput} onChange={onChangeSearchInput} />
      <div className="select-container">
        {data['school-fliter'] && (
          <Select
            options={universityWithEnable}
            name="taxonomy_fieldsofstudy"
            onChange={onChangeUniversitySelect}
            isMulti={true}
            value={universitySelect}
            placeholder={intl.formatMessage(messages.school)}
          />
        )}
        {data['type-filter'] && (
          <Select
            options={eventTypeWithEnable}
            name="degree_program_types"
            onChange={onChangeEventTypeSelect}
            isMulti={true}
            value={eventTypeSelect}
            placeholder={intl.formatMessage(messages.type)}
          />
        )}
        {data['location-filter'] && (
          <Select
            options={locationCategoryWithEnable}
            name="location_category"
            onChange={onChangeLocationCategorySelect}
            isMulti={true}
            value={locationCategorySelect}
            placeholder={intl.formatMessage(messages.location)}
          />
        )}
      </div>

      <div className="reset-toggle-container">
        <div>
          <Button type="button" styles="clear-all" onPress={onResetClick}>
            <Icon name={resetSVG} size="20px" />
            <span>{intl.formatMessage(messages.clearFilters)}</span>
          </Button>
        </div>
        <div className="toggle-button">
          <Button
            type="button"
            styles="view-icon"
            onPress={() => onChangeView('list')}
          >
            <Icon
              name={contentlistingActive}
              size="30px"
              color={currentView === 'list' ? '#000' : '#8D8D8D'}
            />
          </Button>
          <Button
            type="button"
            styles="view-icon grid"
            onPress={() => onChangeView('grid')}
          >
            <Icon
              name={gridListing}
              size="30px"
              color={currentView === 'grid' ? '#000' : '#8D8D8D'}
            />
          </Button>
        </div>
      </div>
      <div className="resultview-container">
        <ul
          className={currentView === 'grid' ? 'teaser-col-3' : 'teaser-listing'}
        >
          {result?.items.map((item: any, index: number) => (
            <Teaser
              key={index}
              {...mapItemToTeaserProps(
                item,
                {
                  locale,
                  ...data,
                  showTeaserImage: data?.['show-image'],
                  showTeaserText: data?.['show-text'],
                  showExtendedInfo: data?.['show-information'],
                },
                'event',
              )}
              imageComponentProps={
                showImage ? { item, Wrapper: null } : undefined
              }
              imageComponent={showImage ? ResultItemPreviewImage : undefined}
            />
          ))}
        </ul>
      </div>
      <div className="more-result-container">
        <div className="more-container">
          <button onClick={onMoreResult}>
            {result?.items?.length >= result?.total
              ? ''
              : ` ${intl.formatMessage(messages.moreResults)}`}
          </button>
          <span>{`${result?.items?.length} ${intl.formatMessage(messages.of)} ${result?.total}`}</span>
        </div>
      </div>
    </div>
  );
};

export default EventsView;
