import React from 'react';
import {
  FieldError,
  Label,
  CheckboxGroup as RACCheckboxGroup,
  CheckboxGroupProps as RACCheckboxGroupProps,
  Text,
  ValidationResult,
} from 'react-aria-components';

export type CheckboxGroupProps = {
  /**
   * A label for the radio group.
   */
  label?: string;
  /**
   * A label, indicating that the field is required.
   */
  labelRequired?: string;
  /**
   * Description or additional information about the radio group.
   */
  description?: string;
  /**
   * An error message to display when validation fails.
   */
  errorMessage?: string | ((validation: ValidationResult) => string);
  /**
   * Children elements to render as radio buttons.
   */
  children?: React.ReactNode;
  /**
   * Indicates whether the input field is required or not.
   */
  isRequired?: boolean;
} & RACCheckboxGroupProps;

const CheckboxGroup = ({
  label,
  description,
  errorMessage,
  children,
  isRequired,
  labelRequired,
  ...props
}: CheckboxGroupProps) => {
  return (
    <RACCheckboxGroup {...props}>
      <Label>
        {label}
        {isRequired && <Text slot="description">{labelRequired}</Text>}
      </Label>
      <FieldError>{errorMessage}</FieldError>
      {children}
      {description && <Text slot="description">{description}</Text>}
    </RACCheckboxGroup>
  );
};

export default CheckboxGroup;
