import { schema } from './schema';
import keyboardSVG from '@plone/volto/icons/keyboard.svg';
import ViewHTMLBlock from './View';
import EditHTMLBlock from './Edit';
import type { BlockConfigBase } from '@plone/types';
import { IntlShape } from 'react-intl';

export const config: BlockConfigBase = {
  id: 'html',
  title: 'HTML',
  icon: keyboardSVG,
  group: 'content',
  view: ViewHTMLBlock,
  edit: EditHTMLBlock,
  blockSchema: (args) =>
    schema(args as unknown as { props: unknown; intl: IntlShape }),
  restricted: false,
  mostUsed: false,
  sidebarTab: 1,
  category: '',
};
