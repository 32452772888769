import { Icon } from '@fhnw/components/Icon/Icon';

interface FlickrProps {
  size: 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL';
  color: string;
  onClick?: () => void;
}

const Flickr = (props: FlickrProps) => (
  <Icon color={props.color} onClick={props.onClick} size={props.size}>
    <svg
      width="32px"
      height="32px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <path
        d="M32,16c0,8.8-7.2,16-16,16S0,24.8,0,16S7.2,0,16,0S32,7.2,32,16z M9.8,11.4c-2.6,0-4.6,2.1-4.6,4.6
	s2.1,4.6,4.6,4.6s4.6-2.1,4.6-4.6S12.4,11.4,9.8,11.4z M22.2,11.4c-2.6,0-4.6,2.1-4.6,4.6s2.1,4.6,4.6,4.6s4.6-2.1,4.6-4.6
	S24.7,11.4,22.2,11.4z"
      />
    </svg>
  </Icon>
);

export default Flickr;
