import { SET_SITE_INIT } from '../../constants/ActionTypes';

const initialState = {
  siteInit: true,
};

interface State {
  siteInit: boolean;
}

interface Action {
  type: string;
  key: boolean;
}

export default function siteInit(state: State = initialState, action: Action) {
  switch (action.type) {
    case SET_SITE_INIT:
      return {
        ...state,
        siteInit: action.key,
      };
    default:
      return state;
  }
}
