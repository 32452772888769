import { LinkElement } from './renderer.jsx';
import applyConfig from './LinkEditor.jsx';

export default function install(config) {
  const { slate } = config.settings;

  applyConfig(config);

  slate.elements.link = LinkElement;
  return config;
}
