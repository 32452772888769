export const listingSchemaEnhancer = ({ schema }: { schema: any }) => {
  schema.fieldsets[0].fields.push('showTeaserHeading');
  schema.properties.showTeaserHeading = {
    title: 'Überschrift anzeigen',
    type: 'boolean',
    default: true,
  };
  schema.fieldsets[0].fields.push('showTeaserImage');
  schema.properties.showTeaserImage = {
    title: 'Teaserbild anzeigen',
    type: 'boolean',
    default: true,
  };
  schema.fieldsets[0].fields.push('showTeaserText');
  schema.properties.showTeaserText = {
    title: 'Teasertext anzeigen',
    type: 'boolean',
    default: true,
  };
  schema.fieldsets[0].fields.push('showExtendedInfo');
  schema.properties.showExtendedInfo = {
    title: 'Erweiterte Informationen',
    description:
      'E-Mail/Telefon bei Personen werden angezeigt, OE bei News wird angezeigt',
    type: 'boolean',
    default: true,
  };

  schema.fieldsets[0].fields.push('buttonText');
  schema.properties.buttonText = {
    title: 'Button Beschriftung',
    type: 'string',
    default: '',
  };

  schema.fieldsets[0].fields.push('buttonLink');
  schema.properties.buttonLink = {
    title: 'Button Link',
    type: 'string',
    widget: 'object_browser',
    mode: 'link',
    allowExternals: true,
    default: '',
  };

  schema.fieldsets[0].fields.push('showPrivatePages');
  schema.properties.showPrivatePages = {
    title: 'Private Seiten im Editiermodus anzeigen',
    type: 'boolean',
    default: true,
  };

  return schema;
};
