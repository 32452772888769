import BlockDataForm from '@plone/volto/components/manage/Form/BlockDataForm';
import SidebarPortal from '@plone/volto/components/manage/Sidebar/SidebarPortal';
import HTMLBlockView from './View';
import { messages } from './messages';
import { useIntl } from 'react-intl';

const HTMLBlockEdit = (props) => {
  const intl = useIntl();
  const {
    block,
    blocksConfig,
    data,
    navRoot,
    contentType,
    blocksErrors,
    selected,
    onChangeBlock,
  } = props;

  const schema = blocksConfig[data['@type']].blockSchema({
    data,
    intl,
  });

  return (
    <>
      <HTMLBlockView {...props} />
      <SidebarPortal selected={selected}>
        <BlockDataForm
          schema={schema}
          title={intl.formatMessage(messages.title)}
          onChangeField={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          onChangeBlock={onChangeBlock}
          formData={data}
          block={block}
          navRoot={navRoot}
          contentType={contentType}
          errors={blocksErrors}
        />
      </SidebarPortal>
    </>
  );
};

export default HTMLBlockEdit;
