/**
 * Image view component.
 * @module components/theme/View/ImageView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container as SemanticContainer } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Redirect, useHistory } from 'react-router-dom';
import prettybytes from 'pretty-bytes';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers/Url/Url';
import config from '@plone/volto/registry';

/**
 * Image view component class.
 * @function ImageView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const ImageView = ({ content, token }) => {
  const Image = config.getComponent({ name: 'Image' }).component;
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;
  const history = useHistory();

  // If content has a link and user is logged out, redirect
  React.useEffect(() => {
    if (!token && content?.link) {
      const url = content.link;
      if (isInternalURL(url) && history) {
        history.replace(flattenToAppURL(url));
      } else if (!__SERVER__) {
        window.open(flattenToAppURL(url));
      }
    }
  }, [content, history, token]);
  if (__SERVER__ && !token && content?.link) {
    const url = content.link;
    if (isInternalURL(url)) {
      return <Redirect to={content.link} />;
    }
    // For server-side rendering, if it is not an internal URL, we just skip it
    // The effect hook above should do the opening in a new tab on client-side.
  }

  return (
    <Container className="view-wrapper">
      <h1 className="documentFirstHeading">
        {content.title}
        {content.subtitle && ` - ${content.subtitle}`}
      </h1>
      {content.description && (
        <p className="documentDescription">{content.description}</p>
      )}
      {content?.image?.download && (
        <a href={flattenToAppURL(content.image.download)}>
          <Image
            item={content}
            imageField="image"
            alt={content.title}
            responsive={true}
          />
          <figcaption>
            <FormattedMessage
              id="Size: {size}"
              defaultMessage="Size: {size}"
              values={{ size: prettybytes(content.image.size) }}
            />
            &nbsp; &mdash; &nbsp;
            <FormattedMessage
              id="Click to download full sized image"
              defaultMessage="Click to download full sized image"
            />
          </figcaption>
        </a>
      )}
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ImageView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      scales: PropTypes.shape({
        preview: PropTypes.shape({
          download: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
};

export default ImageView;
