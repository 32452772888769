import { defineMessages } from 'react-intl';

export default defineMessages({
  fhnwTitle: {
    id: 'University of Applied Sciences and Arts Northwestern Switzerland',
    defaultMessage:
      'University of Applied Sciences and Arts Northwestern Switzerland',
  },
  AlternativeText: {
    id: 'Alternative Text',
    defaultMessage: 'Alternative Text',
  },
  Description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  Caption: {
    id: 'Image Caption',
    defaultMessage: 'Image Caption',
  },
  Format: {
    id: 'Format',
    defaultMessage: 'Format',
  },
  TabTitle: {
    defaultMessage: 'Tab title',
    id: 'tabTitle',
  },
  Title: {
    defaultMessage: 'Title',
    id: 'title',
  },
  HideTitle: {
    id: 'hideTitle',
    defaultMessage: 'Hide tab title?',
  },
  HideEmptyTabs: {
    id: 'hideEmptyTabs',
    defaultMessage: 'Hide empty tabs in view?',
  },
  toc: {
    id: 'toc',
    defaultMessage: 'Table of Contents',
  },
  Entries: {
    id: 'Entries',
    defaultMessage: 'Headings to be listed',
  },
  Enumeration: {
    id: 'Enumeration',
    defaultMessage: 'Enumeration',
  },
  Ordered: {
    id: 'NumberedList',
    defaultMessage: 'Numbered List',
  },
  Variant: {
    id: 'variant',
    defaultMessage: 'Variant',
  },
  hideHeaders: {
    id: 'Hide headers',
    defaultMessage: 'Hide header',
  },
  sortable: {
    id: 'Make the table sortable',
    defaultMessage: 'Sortable Table',
  },
  striped: {
    id: 'Stripe alternate rows with color',
    defaultMessage: 'Alternate row coloring',
  },
  table: {
    id: 'table',
    defaultMessage: 'Table',
  },
  visibleUntil: {
    id: 'visibleUntil',
    defaultMessage: 'Visible Until',
  },
  visibleFrom: {
    id: 'visibleFrom',
    defaultMessage: 'Visible From',
  },
  showOnMobile: {
    id: 'showOnMobile',
    defaultMessage: 'Show on mobile',
  },
  showOnDesktop: {
    id: 'showOnDesktop',
    defaultMessage: 'Show on Desktop',
  },
  ButtonBlock: {
    id: 'Button Block',
    defaultMessage: 'Button',
  },
  ButtonText: {
    id: 'ButtonText',
    defaultMessage: 'Button Text',
  },
  ButtonLink: {
    id: 'ButtonLink',
    defaultMessage: 'Button Link',
  },
  openLinkInNewTab: {
    id: 'Open link in new browser tab',
    defaultMessage: 'Open link in new browser tab',
  },
  column: {
    id: 'column',
    defaultMessage: 'column',
  },
  columns: {
    id: 'columns',
    defaultMessage: 'columns',
  },
  AddBlock: {
    id: 'addBlock',
    defaultMessage: 'Add a new block',
  },
  AddTab: {
    defaultMessage: 'Add Tab',
    id: 'addTab',
  },
});
