/**
 * OVERRIDE EditBlockWrapper.jsx
 * REASON: Remove blockHasValue(), as it was allowing only one time adding of any block inside the tabs.
 * FILE: https://github.com/eea/volto-tabs-block/blob/9da0b6998d89ecc10ff5e1bf4dd6b261a9a11ff0/src/components/EditBlockWrapper.jsx
 * FILE VERSION: eea/volto-tabs-block 8.1.0
 * PULL REQUEST: https://gitlab.fhnw.ch/webteam/fhnw-webauftritt-2025/-/merge_requests/405
 * TICKET: https://gitlab.fhnw.ch/webteam/fhnw-webauftritt-2025/-/issues/390
 * DATE: 2024-12-09
 * DEVELOPER: @tishasoumya
 * CHANGELOG:
 *  - Remove blockHasValue(), as it was allowing only one time adding of any block inside the tabs @tishasoumya
 *
 * Depending on the nature of the change, if possible or suitable, mark the change with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */

import React from 'react';
import { Icon } from '@plone/volto/components';
import { buildStyleClassNamesFromData } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import includes from 'lodash/includes';
import isBoolean from 'lodash/isBoolean';
import { defineMessages, injectIntl } from 'react-intl';
import { doesNodeContainClick } from 'semantic-ui-react/dist/commonjs/lib';
import cx from 'classnames';
import NewBlockAddButton from './NewBlockAddButton';

import dragSVG from '@plone/volto/icons/drag.svg';
import trashSVG from '@plone/volto/icons/delete.svg';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
  delete: {
    id: 'delete',
    defaultMessage: 'delete',
  },
  dragAndDrop: {
    id: 'Drag and drop',
    defaultMessage: 'Drag and drop',
  },
});

class EditBlockWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addNewBlockOpened: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (
      this.blockNode.current &&
      doesNodeContainClick(this.blockNode.current, e)
    ) {
      return;
    }

    if (this.state.addNewBlockOpened) {
      this.setState({
        addNewBlockOpened: false,
      });
      return true;
    }
  };

  blockNode = React.createRef();

  render() {
    const { intl, blockProps, draginfo, extraControls, children } = this.props;

    const {
      // allowedBlocks,
      block,
      data,
      onDeleteBlock,
      // onMutateBlock,
      onSelectBlock,
      onInsertBlock,
      selected,
      blocksConfig,
      index,
    } = blockProps;
    const type = data['@type'];
    const { disableNewBlocks } = data;
    const visible = !data.fixed;
    const required = isBoolean(data.required)
      ? data.required
      : includes(config.blocks.requiredBlocks, type);
    const styles = buildStyleClassNamesFromData(data.styles);

    return (
      <div ref={this.blockNode} className="block-wrapper">
        <div
          ref={draginfo?.innerRef}
          {...(selected ? draginfo?.draggableProps : null)}
          className={cx(`block-editor-${data['@type']}`, styles, {
            [data.align]: data.align,
          })}
        >
          {!selected && (
            <div
              style={{
                display: 'none',
                // keep react-beautiful-dnd happy
              }}
              {...draginfo.dragHandleProps}
            ></div>
          )}
          {selected && (
            <div className="block-toolbar">
              <button
                title={intl.formatMessage(messages.dragAndDrop)}
                style={{
                  display: visible ? 'inline-block' : 'none',
                }}
                {...draginfo.dragHandleProps}
                className="drag handle wrapper-column-block"
              >
                <Icon name={dragSVG} size="19px" />
              </button>

              {extraControls}

              {!disableNewBlocks && (
                <NewBlockAddButton
                  block={block}
                  index={index}
                  blocksConfig={blocksConfig}
                  onInsertBlock={(id, value) => {
                    onSelectBlock(onInsertBlock(id, value));
                  }}
                />
              )}
              {!required && (
                <button
                  title={intl.formatMessage(messages.delete)}
                  onClick={() => onDeleteBlock(block)}
                  className="delete-button-column-block"
                  aria-label={intl.formatMessage(messages.delete)}
                >
                  <Icon name={trashSVG} size="19px" color="#e40166" />
                </button>
              )}
            </div>
          )}

          <div
            className={cx('ui drag block wrapper inner', type, {
              multiSelected: this.props.multiSelected,
            })}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(EditBlockWrapper);
