import FHNWDateFormat from '@fhnw/components/DateFormat/FHNWDateFormat';
import parseISOString from '@fhnw/components/DateFormat/parseISOString';

export interface NewsDetailProps {
  effective?: string;
  organisation_entities: { title: string; token: string }[];
}

const NewsDetail = ({ content }: { content: NewsDetailProps }) => {
  const start_date = content.effective
    ? parseISOString(content.effective)
    : null;
  return (
    <>
      <time
        dateTime={start_date?.toISOString()}
        itemProp="datePublished"
        className="news-detail-block"
      >
        {start_date && <FHNWDateFormat start={start_date} wholeDay={true} />}
        {content?.organisation_entities.length > 0 && (
          <>
            {start_date && ' | '}
            {content.organisation_entities
              .map((entity) => entity.title)
              .join(', ')}
          </>
        )}
      </time>
    </>
  );
};

export default NewsDetail;
