import { ApplicationAccordionFieldProps } from '../index';

export const ACCORDION_DATA = 'ACCORDION_DATA';

export function AccordionDatas(data: ApplicationAccordionFieldProps[]) {
  return {
    type: ACCORDION_DATA,
    data,
  };
}
