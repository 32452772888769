import { Image, UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { ContactMessages as messages } from './index';

const getVocabSuffixLabel = (vocab: any, value: string) =>
  (vocab ? vocab.find((i: { value: string }) => i.value === value) : null)
    ?.label;

const getName = (
  first_name: string,
  last_name: string,
  name_affix1?: string,
  name_affix2?: string,
) => {
  const prefix = name_affix1 ? `${name_affix1} ` : '';
  const suffix = name_affix2 ? `, ${name_affix2}` : '';
  return `${prefix}${first_name} ${last_name}${suffix}`;
};

interface ContactDetailsProps {
  content: any;
  isContactTypes: boolean;
  phoneSuffixVocab: any[];
  teamContactContentType: boolean;
  isPersonalPage: boolean;
  intl: {
    formatMessage: (message: { id: string; defaultMessage: string }) => string;
  };
}
const ContactDetails = ({
  content,
  isContactTypes,
  phoneSuffixVocab,
  teamContactContentType,
  isPersonalPage,
  intl,
}: ContactDetailsProps) => (
  <>
    {isPersonalPage === false && (
      <div className="coordinates_image">
        <UniversalLink
          openLinkInNewTab={false}
          href={flattenToAppURL(content['@id'])}
        >
          <Image
            item={content.preview_image_link}
            width="340"
            height="226"
            alt={`${content.first_name} ${content.last_name}`}
            src="/profile-default.png"
          />
        </UniversalLink>
      </div>
    )}

    <div className="coordinates">
      <UniversalLink
        openLinkInNewTab={false}
        href={flattenToAppURL(content['@id'])}
      >
        {teamContactContentType ? (
          <h5 className="description name">{content.team_name}</h5>
        ) : (
          <p className="description name">
            {getName(
              content.first_name,
              content.last_name,
              content.name_affix1,
              content.name_affix2,
            )}
          </p>
        )}
      </UniversalLink>

      {content.role && <p className="role">{content.role}</p>}
      {(content.phone1 || content.phone2) && (
        <>
          <h5 className="heading">{intl.formatMessage(messages.telephone)}</h5>
          <p className="description">
            <a href={`tel:${content.phone1}`}>{content.phone1}</a>{' '}
            {content.phone1_suffix ? (
              <span>
                {isContactTypes
                  ? `(${getVocabSuffixLabel(phoneSuffixVocab, content.phone1_suffix?.token)})`
                  : `(${getVocabSuffixLabel(phoneSuffixVocab, content.phone1_suffix)})`}
              </span>
            ) : null}
            <br />
            <a href={`tel:${content.phone2}`}>{content.phone2}</a>{' '}
            {content.phone2_suffix ? (
              <span>
                {isContactTypes
                  ? `(${getVocabSuffixLabel(phoneSuffixVocab, content.phone2_suffix?.token)})`
                  : `(${getVocabSuffixLabel(phoneSuffixVocab, content.phone2_suffix)})`}
              </span>
            ) : null}
          </p>
        </>
      )}
      {content.email && (
        <>
          <h5 className="heading">{intl.formatMessage(messages.email)}</h5>
          <p className="description">
            <a href={`mailto:${content.email}`}>{content.email}</a>
          </p>
        </>
      )}
      {content.address && (
        <>
          <h5 className="heading">{intl.formatMessage(messages.address)}</h5>
          <p className="description">{content.address}</p>
        </>
      )}
      {content.room && (
        <>
          <h5 className="heading">{intl.formatMessage(messages.room)}</h5>
          <p className="description">{content.room}</p>
        </>
      )}
      {content.availability && (
        <>
          <p className="heading">{intl.formatMessage(messages.availability)}</p>
          <p className="description">{content.availability}</p>
        </>
      )}
    </div>
  </>
);

export default ContactDetails;
