import { defineMessages } from 'react-intl';

export const EventApplicationBoxMessages = defineMessages({
  infoText: {
    id: 'block.eventapplicationbox.infoText',
    defaultMessage: 'Registration is not required.',
  },
  buttonText: {
    id: 'block.eventapplicationbox.buttonText',
    defaultMessage: 'Apply now',
  },
  registration: {
    id: 'block.eventapplicationbox.registration',
    defaultMessage: 'Registration',
  },
});
