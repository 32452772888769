import { Icon } from '@fhnw/components/Icon/Icon';

interface LinkedinProps {
  size: 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL';
  color: string;
  onClick?: () => void;
}

const Linkedin = (props: LinkedinProps) => (
  <Icon color={props.color} onClick={props.onClick} size={props.size}>
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Desktop_2nd-Round"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Linked2InFollow"
          transform="translate(-972.000000, -3900.000000)"
          fill="#000000"
        >
          <path
            d="M987.909091,3931.81818 C979.1225,3931.81818 972,3924.69568 972,3915.90909 C972,3907.1233 979.1225,3900 987.909091,3900 C996.695682,3900 1003.81818,3907.1233 1003.81818,3915.90909 C1003.81818,3924.69568 996.695682,3931.81818 987.909091,3931.81818 Z M981.17675,3922.38129 L984.178,3922.38129 L984.178,3913.35391 L981.17675,3913.35391 L981.17675,3922.38129 Z M982.677375,3912.12051 C983.723875,3912.12051 984.374875,3911.42664 984.374875,3910.56039 C984.3565,3909.67489 983.723875,3909.00026 982.696625,3909.00026 C981.672,3909.00026 981,3909.67489 981,3910.56039 C981,3911.42664 981.651,3912.12051 982.658125,3912.12051 L982.677375,3912.12051 Z M995,3922.38129 L995,3917.20479 C995,3914.43104 993.518625,3913.14216 991.544625,3913.14216 C989.95125,3913.14216 989.239,3914.01541 988.839125,3914.63316 L988.839125,3913.35479 L985.83875,3913.35479 C985.87725,3914.20004 985.83875,3922.38129 985.83875,3922.38129 L988.839125,3922.38129 L988.839125,3917.33954 C988.839125,3917.06829 988.858375,3916.80141 988.938,3916.60804 C989.155,3916.06991 989.65025,3915.50904 990.478,3915.50904 C991.56475,3915.50904 991.99875,3916.33854 991.99875,3917.55129 L991.99875,3922.38129 L995,3922.38129 Z"
            id="linkedin"
            fill="currentColor"
          ></path>
        </g>
      </g>
    </svg>
  </Icon>
);

export default Linkedin;
