import { defineMessages } from 'react-intl';

export const ContactMessages = defineMessages({
  contact: {
    id: 'block.contact.sidebar.contact',
    defaultMessage: 'Contact',
  },
  telephone: {
    id: 'block.contact.sidebar.telephone',
    defaultMessage: 'Telephone',
  },
  email: {
    id: 'block.contact.sidebar.email',
    defaultMessage: 'E-mail',
  },
  room: {
    id: 'block.contact.sidebar.room',
    defaultMessage: 'Room',
  },
  availability: {
    id: 'block.contact.sidebar.availability',
    defaultMessage: 'Availability',
  },
  address: {
    id: 'block.contact.sidebar.address',
    defaultMessage: 'Address',
  },
});
