import React from 'react';
import { SolarSearchSchema } from './schema';
import { BlockDataForm } from '@plone/volto/components';
import { useIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  Block: {
    id: 'Search',
    defaultMessage: 'Search',
  },
});

const SolarSearchData = (props) => {
  const {
    block,
    blocksConfig,
    data,
    schemaEnhancer,
    onChangeBlock,
    navRoot,
    contentType,
  } = props;
  const intl = useIntl();
  const schema = schemaEnhancer
    ? schemaEnhancer(SolarSearchSchema({ ...props, intl }), props)
    : SolarSearchSchema({ ...props, intl });
  return (
    <BlockDataForm
      schema={schema}
      title={intl.formatMessage(messages.Block)}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      onChangeBlock={onChangeBlock}
      formData={data}
      block={block}
      blocksConfig={blocksConfig}
      navRoot={navRoot}
      contentType={contentType}
    />
  );
};

export default SolarSearchData;
