export type itemForBreadcrumbs = {
  '@id': string;
  title: string;
};

export const getItemsForBreadcrumbs = (
  navroot: any,
  navigation: any,
  breadcrumbs: Array<string>,
): Array<itemForBreadcrumbs> => {
  let path = navroot.navroot['@id'];
  const [, rootBreadCrumb, ...moreBreadCrumbs] = breadcrumbs;
  const result: itemForBreadcrumbs[] = [];
  if (rootBreadCrumb) {
    let remainingTree = navigation.items;
    for (const breadcrumb of moreBreadCrumbs) {
      path = `${path}/${breadcrumb}`;
      const itemPath = path;
      const item = remainingTree.find((item: any) => item['@id'] === itemPath);
      if (!item) {
        break;
      }
      result.push({
        '@id': path,
        title: item.title,
      });
      remainingTree = item.items;
    }
  }
  return result;
};
