import config from '@plone/volto/registry';
import Helmet from '@plone/volto/helpers/Helmet/Helmet';

const CookieConsent: React.FC = () => {
  const { settings } = config;
  const gaId = settings.gaMeasurementId as string | undefined;
  const cbId = settings.cookiebotDomainGroupId as string | undefined;

  return (
    <Helmet>
      <script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid={cbId}
        data-blockingmode="auto"
        type="text/javascript"
        async
      />
      {/* GTM container snippet, blocked until consent is given */}
      <script type="text/plain" data-cookieconsent="statistics" defer>
        {`
    (function(w,d,s,l,i){
      w[l]=w[l]||[];
      w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),
          dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','` +
          gaId +
          `');
  `}
      </script>
    </Helmet>
  );
};

export default CookieConsent;
