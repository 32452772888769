/**
 * Fetches IRF publication data from the API endpoint and processes the response.
 *
 * @param {string | null} person - Person identifier to filter publications
 * @param {string | null} collection - Collection identifier to filter publications
 * @param {string | null} citation_style - Citation style to format the response
 * @param {string | undefined} block - Block identifier for the request
 * @param {URL | null} pageUID - Page URL object for constructing the request path
 * @param {string} newer_than - Filter publications newer than this date (YYYY format)
 * @param {boolean} showOnlyPeerReviewed - Flag to filter only peer-reviewed publications
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsLoading - Loading state setter
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsProcessing - Processing state setter
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setError - Error state setter
 * @param {React.Dispatch<React.SetStateAction<string>>} setDataState - Data state setter
 * @param {React.Dispatch<React.SetStateAction<InternalIrfPublicationProps[]>>} setJsonData - Data setter
 * @returns {Promise<void>} - Returns nothing, updates state through callbacks
 * @throws {Error} - Throws error if API request fails or server connection error occurs
 */
import { LinkHTMLAttributes } from 'react';
import config from '@plone/volto/registry';
import mockData from '../irf_results.json';

export interface InternalIrfPublicationProps {
  type?: string;
  peer_reviewed?: boolean;
  authors?: string[];
  url_uuid?: LinkHTMLAttributes<HTMLLinkElement>;
  url_handle?: LinkHTMLAttributes<HTMLLinkElement>;
  url_api_uuid?: LinkHTMLAttributes<HTMLLinkElement>;
  invented_here?: boolean;
  citation_entry?: string;
  showOnlyPeerReviewed: boolean;
}

interface ApiResponse {
  result: InternalIrfPublicationProps[];
  state: string;
}

const fetchData = async (
  person: string | null,
  collection: string | null,
  citation_style: string,
  block: string | undefined,
  pageUID: URL | null,
  newer_than: string,
  showOnlyPeerReviewed: boolean,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<boolean>>,
  setDataState: React.Dispatch<React.SetStateAction<string>>,
  setJsonData: React.Dispatch<
    React.SetStateAction<InternalIrfPublicationProps[]>
  >,
) => {
  if (!person && !collection && !citation_style) {
    return;
  }

  try {
    setIsLoading(true);
    setIsProcessing(true);
    setError(false);

    // Check if request is from same origin
    const isSameOrigin =
      window.location.origin === config._data.settings.apiPath;

    let resultIrfDatas: ApiResponse;

    if (isSameOrigin) {
      const baseUrl = `${config._data.settings.apiPath}/++api++/@irf_publications`;
      const params = new URLSearchParams({
        person: person || '',
        collection: collection || '',
        block_id: `/Plone${pageUID?.pathname}/${block}`,
        citation_style: citation_style,
        newer_than: (newer_than ?? '1970').toString(),
      });

      const response = await fetch(`${baseUrl}?${params.toString()}`);

      if (!response.ok) {
        if (
          !response.status ||
          response.status === 503 ||
          response.status === 504
        ) {
          setError(true);
          throw new Error('Server connection error');
        }
        setError(true);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      resultIrfDatas = await response.json();
    } else {
      // Use mock data when not from same origin
      resultIrfDatas = {
        result: mockData.map((item) => ({
          ...item,
          url_uuid: { href: item.url_uuid },
          url_handle: { href: item.url_handle },
          url_api_uuid: { href: item.url_api_uuid },
          showOnlyPeerReviewed: false,
        })),
        state: 'utd',
      };
    }

    const processedData = resultIrfDatas.result.map(
      (item: InternalIrfPublicationProps) => ({
        ...item,
        showOnlyPeerReviewed: !!showOnlyPeerReviewed,
      }),
    );

    setDataState(resultIrfDatas.state);
    setJsonData(processedData);
    setError(false);
  } catch (error) {
    if (error instanceof TypeError && error.message === 'Failed to fetch') {
      setError(true);
    }
    // eslint-disable-next-line no-console
    console.error('An error occurred:', error);
  } finally {
    setTimeout(() => {
      setIsProcessing(false);
      setIsLoading(false);
    }, 500);
  }
};

export default fetchData;
