import { useEffect, useState } from 'react';
import type { Content, Location } from '@plone/types';
import { transformContentLocations } from '../data/transformContentLocations';
import { isCurrentPage } from '../utils/pageUtils';

type UseDynamicLocationProps = {
  content: Content;
  location: { pathname: string };
  oneOfThesePages: string[];
  locationsData: Location[];
};

const useDynamicLocations = ({
  content,
  location,
  oneOfThesePages,
  locationsData,
}: UseDynamicLocationProps) => {
  const [locations, setLocations] = useState<Location[]>(
    content?.related_locations && content.related_locations.length > 0
      ? transformContentLocations(content?.related_locations)
      : locationsData,
  );

  useEffect(() => {
    const isMatchingPage = isCurrentPage(location.pathname, oneOfThesePages);

    if (isMatchingPage) {
      setLocations(locationsData);
    } else if (
      content?.related_locations &&
      content.related_locations.length > 0
    ) {
      setLocations(transformContentLocations(content.related_locations));
    }
  }, [
    content?.related_locations,
    location.pathname,
    oneOfThesePages,
    locationsData,
  ]);

  return {
    locations,
  };
};

export default useDynamicLocations;
