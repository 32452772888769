import React, { useState } from 'react';
import { Button as RACButton } from 'react-aria-components';
import type { ButtonProps as RACButtonProps } from 'react-aria-components';

type ButtonProps = {
  label?: string;
  isDisabled?: boolean;
  isActive?: boolean;
  styles: string;
  gaTrigger?: string;
  type?: 'button' | 'submit' | 'reset';
  onPress: () => void;
  children?: React.ReactNode;
} & RACButtonProps;

const Button = ({
  label,
  isDisabled = false,
  isActive,
  styles,
  gaTrigger,
  type = 'button',
  onPress,
  children,
  ...props
}: ButtonProps) => {
  const [isPressed, setIsPressed] = useState(isActive);

  const toggleActive = () => {
    setIsPressed((isPressed) => !isPressed);
  };

  return (
    <RACButton
      className={`${styles} ${gaTrigger ? gaTrigger : ''}`}
      type={type}
      {...props}
      aria-label={label}
      data-is-active={isPressed}
      onPress={() => {
        toggleActive();
        onPress();
      }}
      isDisabled={isDisabled}
    >
      {label} {children}
    </RACButton>
  );
};

export default Button;
