/**
 * Slate Table block's View component.
 * @module volto-slate/blocks/Table/View
 */

import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { map } from 'lodash';
import {
  serializeNodes,
  serializeNodesToText,
} from '@plone/volto-slate/editor/render';
import { Node } from 'slate';
import swipe_hand_icon from '../Table/swipe_hand_icon.gif';
// TODO: loading LESS files with `volto-slate/...` paths does not work currently
import '../../editor/plugins/Table/less/public.less';

/**
 * Slate Table block's View class.
 * @class View
 * @extends Component
 * @param {object} data The table data to render as a table.
 */
const View = ({ data }) => {
  const [state, setState] = useState({
    column: null,
    direction: null,
  });

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  const headers = useMemo(() => {
    return data.table.rows?.[0]?.cells;
  }, [data.table.rows]);

  const rows = useMemo(() => {
    const items = [];
    if (!data.table.rows) return [];
    data.table.rows.forEach((row, index) => {
      if (index > 0) {
        items[index] = [];
        row.cells.forEach((cell, cellIndex) => {
          items[index][cellIndex] = {
            ...cell,
            value:
              cell.value && Node.string({ children: cell.value }).length > 0
                ? serializeNodes(cell.value)
                : '\u00A0',
            valueText:
              cell.value && Node.string({ children: cell.value }).length > 0
                ? serializeNodesToText(cell.value)
                : '\u00A0',
          };
        });
      }
    });
    return items;
  }, [data.table.rows]);

  const sortedRows = useMemo(() => {
    if (state.column === null) return Object.keys(rows);
    return Object.keys(rows).sort((a, b) => {
      const a_text = rows[a][state.column].valueText;
      const b_text = rows[b][state.column].valueText;
      if (state.direction === 'ascending' ? a_text < b_text : a_text > b_text) {
        return -1;
      }
      if (state.direction === 'ascending' ? a_text > b_text : a_text < b_text) {
        return 1;
      }
      return 0;
    });
  }, [state, rows]);

  const handleRemoveImg = () => {
    setShow(false);
  };
  return (
    <>
      <div className=" scroll_gif_container" onTouchStart={handleRemoveImg}>
        {data && data.table && show && (
          <div>
            <img
              alt="table_scrollable_animation_gif"
              className="scroll_gif"
              src={swipe_hand_icon}
            />
          </div>
        )}
        {data && data.table && (
          <div className="container-table-block">
            <Table
              selectable
              fixed={data.table.fixed}
              basic={data.table.basic ? 'very' : false}
              striped={data.table_field?.striped}
              sortable={data.table_field?.sortable}
              unstackable
              className="slate-table-block"
            >
              {!data.table_field?.hideHeaders ? (
                <Table.Header>
                  <Table.Row>
                    {headers.map((cell, index) => (
                      <Table.HeaderCell
                        key={cell.key}
                        textAlign="left"
                        verticalAlign="top"
                        sorted={state.column === index ? state.direction : null}
                        onClick={() => {
                          if (!data.table_field?.sortable) return;
                          setState({
                            column: index,
                            direction:
                              state.column !== index
                                ? 'ascending'
                                : state.direction === 'ascending'
                                  ? 'descending'
                                  : 'ascending',
                          });
                        }}
                      >
                        {cell.value &&
                        Node.string({ children: cell.value }).length > 0
                          ? serializeNodes(cell.value)
                          : '\u00A0'}
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>
              ) : (
                ''
              )}

              <Table.Body>
                {map(sortedRows, (row) => (
                  <Table.Row key={row}>
                    {map(rows[row], (cell) => (
                      <Table.Cell
                        key={cell.key}
                        textAlign="left"
                        verticalAlign="top"
                      >
                        {cell.value}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        )}
      </div>
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
