import React from 'react';
import { SidebarPortal, BlockDataForm } from '@plone/volto/components';
import IrfBlockSchema from './IrfBlockSchema';
import { withBlockExtensions } from '@plone/volto/helpers';
import IrfPublication from './IrfPublication';

interface IrfEditViewProps {
  data: { folder: string };
  block: string;
  onChangeBlock: (blockId: string, value: any) => void;
  isEditMode?: boolean;
}

const IrfEditView: React.FC<IrfEditViewProps> = (props: any) => {
  const { data, block, selected, onChangeBlock } = props;

  return (
    <>
      <IrfPublication {...props} isEditMode />
      <SidebarPortal selected={selected}>
        <BlockDataForm
          schema={IrfBlockSchema(props.intl)}
          title="Irf Block"
          onChangeField={(id: any, value: any) =>
            onChangeBlock(block, { ...data, [id]: value })
          }
          onChangeBlock={onChangeBlock}
          formData={data}
        />
      </SidebarPortal>
    </>
  );
};

export default withBlockExtensions(IrfEditView);
