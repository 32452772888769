import { AnyAction } from 'redux';

/**
 * Action types and creators for IRF (Institute of Research and Filtering) data management
 */
export const SET_IRF_DATAS = 'SET_IRF_DATAS';

/**
 * Creates an action to set IRF data in the store
 * @param {any} data - The IRF data to be stored
 * @returns {AnyAction} Redux action with the IRF data as payload
 */
export const IrfDatas = (data: any): AnyAction => ({
  type: SET_IRF_DATAS,
  payload: data,
});
