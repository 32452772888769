import React from 'react';
import FollowUs from './FollowUs';
import { useFolderEffect, useQueryEffect } from './hooks';
import { useSelector } from 'react-redux';

interface FollowUsViewProps {
  data: any;
  block: string;
}

const FollowUsView: React.FC<FollowUsViewProps> = (props) => {
  const { data, block } = props;

  const title = useSelector(
    (state: any) => state.content.data.blocks[block].title || '',
  );

  const description = useSelector(
    (state: any) => state.content.data.blocks[block].description || '',
  );

  const links = useSelector((state: any) => {
    return state.querystringsearch.subrequests[block]?.items;
  });

  useFolderEffect(data, block);
  useQueryEffect(data, block);

  return <FollowUs links={links} title={title} description={description} />;
};

export default FollowUsView;
