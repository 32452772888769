import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { map } from 'lodash';
import { Title } from '@plone/volto/components';
import SlotRenderer from '@plone/volto/components/theme/SlotRenderer/SlotRenderer';
import type { Content as PloneContent } from '@plone/types';

// --- Replicate Backend Field Mapping Logic ---

// Maps generic field names to actual Plone field names for the main language (usually German 'de')
const FIELD_MAP_MAIN: { [key: string]: string } = {
  Bezeichnung: 'BezeichnungD',
  Nummer: 'Nummer', // Same field used regardless of language
  LeitungText: 'LeitungText', // Same field used regardless of language
  ECTS: 'ECTS', // Same field used regardless of language
  Label1: 'LabelD1',
  Memo1: 'MemoD1',
  Label2: 'LabelD2',
  Memo2: 'MemoD2',
  Label3: 'LabelD3',
  Memo3: 'MemoD3',
  Label4: 'LabelD4',
  Memo4: 'MemoD4',
  Label5: 'LabelD5',
  Memo5: 'MemoD5',
  Label6: 'LabelD6',
  Memo6: 'MemoD6',
  Label7: 'LabelD7',
  Memo7: 'MemoD7',
  Label8: 'LabelD8',
  Memo8: 'MemoD8',
  Label9: 'LabelD9',
  Memo9: 'MemoD9',
  Label10: 'LabelD10',
  Memo10: 'MemoD10',
  Label11: 'LabelD11',
  Memo11: 'MemoD11',
  Label12: 'LabelD12',
  Memo12: 'MemoD12',
  Label13: 'LabelD13',
  Memo13: 'MemoD13',
  LabelBemerk: 'LabelDBemerk',
  MemoBemerk: 'MemoDBemerk',
};

// Maps generic field names to actual Plone field names for the translation language (usually English 'en')
// Note: The original Python map had inconsistencies (e.g., Label11/Memo11 mapped to E12).
// I've corrected these based on the likely intent (LabelE11/MemoE11). Please verify.
const FIELD_MAP_TRANSLATION: { [key: string]: string } = {
  Bezeichnung: 'BezeichnungE',
  Nummer: 'Nummer', // Same field used regardless of language
  LeitungText: 'LeitungText', // Same field used regardless of language
  ECTS: 'ECTS', // Same field used regardless of language
  Label1: 'LabelE1',
  Memo1: 'MemoE1',
  Label2: 'LabelE2',
  Memo2: 'MemoE2',
  Label3: 'LabelE3',
  Memo3: 'MemoE3',
  Label4: 'LabelE4',
  Memo4: 'MemoE4',
  Label5: 'LabelE5',
  Memo5: 'MemoE5',
  Label6: 'LabelE6',
  Memo6: 'MemoE6',
  Label7: 'LabelE7',
  Memo7: 'MemoE7',
  Label8: 'LabelE8',
  Memo8: 'MemoE8',
  Label9: 'LabelE9',
  Memo9: 'MemoE9',
  Label10: 'LabelE10',
  Memo10: 'MemoE10',
  Label11: 'LabelE11', // Corrected from LabelE12
  Memo11: 'MemoE11', // Corrected from MemoE12
  Label12: 'LabelE12',
  Memo12: 'MemoE12',
  Label13: 'LabelE13',
  Memo13: 'MemoE13',
  LabelBemerk: 'LabelEBemerk',
  MemoBemerk: 'MemoEBemerk',
};

// --- Define Prop Types ---

interface ModuleContent {
  '@id': string;
  '@type': string;
  title: string; // Standard Plone title (often not used if BezeichnungD/E exists)
  description: string; // Standard Plone description

  // Add all fields from your IModule schema
  // Use 'string | null' or 'number | null' for optional fields
  AnlassID?: number | null;
  Nummer?: string | null;
  StatusID?: number | null;
  StatusText?: string | null;
  TypID?: number | null;
  TypText?: string | null;
  KategorieID?: number | null;
  KategorieText?: string | null;
  VeranstalterID?: string | null;
  VeranstalterText?: string | null;
  BezeichnungD?: string | null;
  BezeichnungE?: string | null;
  DatumVon?: string | null;
  DatumBis?: string | null;
  DateFrom?: string | null; // Consider using DateTimeWidget in schema if these are dates
  DateTo?: string | null; // Consider using DateTimeWidget in schema if these are dates
  Wochentag?: string | null;
  Dauer?: string | null;
  ZeitVon?: string | null;
  ZeitBis?: string | null;
  Ort?: string | null;
  Preis?: string | null;
  LeitungPersonID?: string | null;
  LeitungPersonAaiID?: string | null;
  LeitungPersonIDListe?: string | null;
  LeitungText?: string | null;
  MaxTeilnehmer?: string | null; // Consider Integer field
  MinTeilnehmer?: string | null; // Consider Integer field
  InstanzVon?: string | null;
  ZeitKurs?: string | null;
  ZeitSelbststudium?: string | null;
  ZeitPruefung?: string | null;
  ZeitTotal?: string | null;
  ECTS?: string | null; // Consider Float or Integer field
  Unterrichtssprache?: string | null;
  Studienstufe?: string | null;
  InternetSichtbar?: string | null; // Consider Boolean field

  // LabelD/MemoD fields
  LabelD1?: string | null;
  MemoD1?: string | null;
  LabelD2?: string | null;
  MemoD2?: string | null;
  LabelD3?: string | null;
  MemoD3?: string | null;
  LabelD4?: string | null;
  MemoD4?: string | null;
  LabelD5?: string | null;
  MemoD5?: string | null;
  LabelD6?: string | null;
  MemoD6?: string | null;
  LabelD7?: string | null;
  MemoD7?: string | null;
  LabelD8?: string | null;
  MemoD8?: string | null;
  LabelD9?: string | null;
  MemoD9?: string | null;
  LabelD10?: string | null;
  MemoD10?: string | null;
  LabelD11?: string | null;
  MemoD11?: string | null;
  LabelD12?: string | null;
  MemoD12?: string | null;
  LabelD13?: string | null;
  MemoD13?: string | null;
  LabelDBemerk?: string | null;
  MemoDBemerk?: string | null;

  // LabelE/MemoE fields
  LabelE1?: string | null;
  MemoE1?: string | null;
  LabelE2?: string | null;
  MemoE2?: string | null;
  LabelE3?: string | null;
  MemoE3?: string | null;
  LabelE4?: string | null;
  MemoE4?: string | null;
  LabelE5?: string | null;
  MemoE5?: string | null;
  LabelE6?: string | null;
  MemoE6?: string | null;
  LabelE7?: string | null;
  MemoE7?: string | null;
  LabelE8?: string | null;
  MemoE8?: string | null;
  LabelE9?: string | null;
  MemoE9?: string | null;
  LabelE10?: string | null;
  MemoE10?: string | null;
  LabelE11?: string | null;
  MemoE11?: string | null;
  LabelE12?: string | null;
  MemoE12?: string | null;
  LabelE13?: string | null;
  MemoE13?: string | null;
  LabelE14?: string | null;
  MemoE14?: string | null; // Note: LabelE14/MemoE14 exist but aren't in the Python maps
  LabelEBemerk?: string | null;
  MemoEBemerk?: string | null;

  CodesKategorie?: string | null;
  Gruppenzuordnungen?: string | null;
  Codezuweisungen?: string | null;
  Lektionen?: string | null;
  InternetAnmeldeArt?: string | null;
  StatusErlaubtAnmeldung?: string | null; // Consider Boolean field
  AnmeldungVon?: string | null; // Consider DateTimeWidget
  AnmeldungBis?: string | null; // Consider DateTimeWidget
  SignupFrom?: string | null; // Consider DateTimeWidget
  SignupTo?: string | null; // Consider DateTimeWidget
  AnmeldeStatusInfo?: string | null;
  hauptsprache?: string | null; // Key field for translation logic
  uebersetzung?: string | null; // Key field for translation logic

  // Allow any other properties that might come from Plone REST API
  [key: string]: any;
}

interface ModuleViewProps {
  content: ModuleContent;
}

// --- i18n Message Definitions ---
// Use keys matching your .po files where possible/sensible
// For fields specific to this view, use the view.module.<fieldname> pattern
const messages = defineMessages({
  // --- Generic Field Labels (from fhnw.education domain) ---
  textLeading: { id: 'text_Leading', defaultMessage: 'Module head' },
  textNumber: { id: 'text_Number', defaultMessage: 'Number' },
  textECTS: { id: 'text_ECTS', defaultMessage: 'ECTS' }, // Although ECTS is also a direct field name
  textUnterrichtssprache: {
    id: 'text_Unterrichtssprache',
    defaultMessage: 'Teaching language',
  },

  // --- Fields NOT handled by D/E mapping (using view.module pattern) ---
  AnlassID: { id: 'view.module.AnlassID', defaultMessage: 'Event ID' },
  StatusID: { id: 'view.module.StatusID', defaultMessage: 'Status ID' },
  StatusText: { id: 'view.module.StatusText', defaultMessage: 'Status Text' },
  TypID: { id: 'view.module.TypID', defaultMessage: 'Type ID' },
  TypText: { id: 'view.module.TypText', defaultMessage: 'Type Text' },
  KategorieID: { id: 'view.module.KategorieID', defaultMessage: 'Category ID' },
  KategorieText: {
    id: 'view.module.KategorieText',
    defaultMessage: 'Category Text',
  },
  VeranstalterID: {
    id: 'view.module.VeranstalterID',
    defaultMessage: 'Organizer ID',
  },
  VeranstalterText: {
    id: 'view.module.VeranstalterText',
    defaultMessage: 'Organizer Text',
  },
  DatumVon: { id: 'view.module.DatumVon', defaultMessage: 'Date From (Text)' },
  DatumBis: { id: 'view.module.DatumBis', defaultMessage: 'Date To (Text)' },
  DateFrom: {
    id: 'view.module.DateFrom',
    defaultMessage: 'Date From (Parsed)',
  },
  DateTo: { id: 'view.module.DateTo', defaultMessage: 'Date To (Parsed)' },
  Wochentag: { id: 'view.module.Wochentag', defaultMessage: 'Weekday' },
  Dauer: { id: 'view.module.Dauer', defaultMessage: 'Duration' },
  ZeitVon: { id: 'view.module.ZeitVon', defaultMessage: 'Time From' },
  ZeitBis: { id: 'view.module.ZeitBis', defaultMessage: 'Time To' },
  Ort: { id: 'view.module.Ort', defaultMessage: 'Location' },
  Preis: { id: 'view.module.Preis', defaultMessage: 'Price' },
  LeitungPersonID: {
    id: 'view.module.LeitungPersonID',
    defaultMessage: 'Lead Person ID',
  },
  LeitungPersonAaiID: {
    id: 'view.module.LeitungPersonAaiID',
    defaultMessage: 'Lead Person AAI ID',
  },
  LeitungPersonIDListe: {
    id: 'view.module.LeitungPersonIDListe',
    defaultMessage: 'Lead Person ID List',
  },
  MaxTeilnehmer: {
    id: 'view.module.MaxTeilnehmer',
    defaultMessage: 'Max Participants',
  },
  MinTeilnehmer: {
    id: 'view.module.MinTeilnehmer',
    defaultMessage: 'Min Participants',
  },
  InstanzVon: { id: 'view.module.InstanzVon', defaultMessage: 'Instance Of' },
  ZeitKurs: { id: 'view.module.ZeitKurs', defaultMessage: 'Time Course' },
  ZeitSelbststudium: {
    id: 'view.module.ZeitSelbststudium',
    defaultMessage: 'Time Self-Study',
  },
  ZeitPruefung: {
    id: 'view.module.ZeitPruefung',
    defaultMessage: 'Time Examination',
  },
  ZeitTotal: { id: 'view.module.ZeitTotal', defaultMessage: 'Time Total' },
  // ECTS handled via textECTS
  // Unterrichtssprache handled via textUnterrichtssprache
  Studienstufe: {
    id: 'view.module.Studienstufe',
    defaultMessage: 'Level of Study',
  },
  InternetSichtbar: {
    id: 'view.module.InternetSichtbar',
    defaultMessage: 'Visible Online',
  },
  CodesKategorie: {
    id: 'view.module.CodesKategorie',
    defaultMessage: 'Category Codes',
  },
  Gruppenzuordnungen: {
    id: 'view.module.Gruppenzuordnungen',
    defaultMessage: 'Group Assignments',
  },
  Codezuweisungen: {
    id: 'view.module.Codezuweisungen',
    defaultMessage: 'Code Assignments',
  },
  Lektionen: { id: 'view.module.Lektionen', defaultMessage: 'Lessons' },
  InternetAnmeldeArt: {
    id: 'view.module.InternetAnmeldeArt',
    defaultMessage: 'Online Signup Type',
  },
  StatusErlaubtAnmeldung: {
    id: 'view.module.StatusErlaubtAnmeldung',
    defaultMessage: 'Status Allows Signup',
  },
  AnmeldungVon: {
    id: 'view.module.AnmeldungVon',
    defaultMessage: 'Signup From (Text)',
  },
  AnmeldungBis: {
    id: 'view.module.AnmeldungBis',
    defaultMessage: 'Signup To (Text)',
  },
  SignupFrom: {
    id: 'view.module.SignupFrom',
    defaultMessage: 'Signup From (Parsed)',
  },
  SignupTo: {
    id: 'view.module.SignupTo',
    defaultMessage: 'Signup To (Parsed)',
  },
  AnmeldeStatusInfo: {
    id: 'view.module.AnmeldeStatusInfo',
    defaultMessage: 'Signup Status Info',
  },
  hauptsprache: {
    id: 'view.module.hauptsprache',
    defaultMessage: 'Main Language',
  },
  uebersetzung: {
    id: 'view.module.uebersetzung',
    defaultMessage: 'Translation Language',
  },

  // No explicit translations needed for LabelD1, MemoE5 etc. as their *content* is shown,
  // and the *label* for the pair comes from the LabelDX/LabelEX field itself.
});

const ModuleView: React.FC<ModuleViewProps> = ({ content }) => {
  const intl = useIntl();
  const currentLang = intl.locale; // e.g., 'en', 'de', 'fr'

  // Determine module's languages
  const mainLang = content.hauptsprache?.toLowerCase() || 'de';
  const translationLang = content.uebersetzung?.toLowerCase() || null; // Can be null

  // Helper to get the translated value based on the Python logic
  const getTranslatedFieldValue = (
    genericFieldName: string,
  ): string | null | undefined => {
    // Determine which map to use based on current language and module's main language
    // Fallback to main language map if the current language is not the translation language
    const useTranslationMap =
      currentLang !== mainLang && currentLang === translationLang;
    const map = useTranslationMap ? FIELD_MAP_TRANSLATION : FIELD_MAP_MAIN;

    const actualFieldName = map[genericFieldName];

    if (actualFieldName && content.hasOwnProperty(actualFieldName)) {
      return content[actualFieldName];
    } else if (
      FIELD_MAP_MAIN[genericFieldName] &&
      content.hasOwnProperty(FIELD_MAP_MAIN[genericFieldName])
    ) {
      return content[FIELD_MAP_MAIN[genericFieldName]];
    }
    return undefined;
  };

  interface RenderFieldProps {
    label: string;
    value: string | boolean | number | null | undefined;
    renderHTML?: boolean; // New prop to control HTML rendering
  }

  const RenderField: React.FC<RenderFieldProps> = ({
    label,
    value,
    renderHTML = false,
  }) => {
    if (
      value === null ||
      value === undefined ||
      value === '' ||
      value === '-' ||
      value === '0.0' ||
      value === 0
    ) {
      return null;
    }

    const displayValue =
      typeof value === 'boolean' ? value.toString() : String(value); // Ensure value is string for dangerouslySetInnerHTML

    return (
      // Add a wrapper div for easier row styling
      <div className="module-field-row">
        <dt className="module-field-label">{label}</dt>
        {renderHTML ? (
          // Use dangerouslySetInnerHTML for HTML content
          <dd
            className="module-field-value html-content"
            dangerouslySetInnerHTML={{ __html: displayValue }}
          />
        ) : (
          // Render plain text (or numbers/booleans converted to string)
          <dd className="module-field-value">{displayValue}</dd>
        )}
      </div>
    );
  };

  const fieldsToDisplay = [
    // --- Fields using the D/E mapping (genericName) ---
    // {
    //   genericName: 'Bezeichnung',
    //   labelMsg: messages.textLeading,
    //   renderHTML: false,
    // }, // Usually handled by the main H1 title, but included for completeness
    { genericName: 'Nummer', labelMsg: messages.textNumber, renderHTML: false },
    // {
    //   genericName: 'LeitungText',
    //   labelMsg: messages.textLeading,
    //   renderHTML: false,
    // },
    { genericName: 'ECTS', labelMsg: messages.textECTS, renderHTML: false },
    // Note: Label/Memo pairs are handled separately below, not listed here

    // --- Direct fields (name) ---
    // { name: 'AnlassID', labelMsg: messages.AnlassID, renderHTML: false },
    // { name: 'StatusID', labelMsg: messages.StatusID, renderHTML: false },
    // { name: 'StatusText', labelMsg: messages.StatusText, renderHTML: false },
    // { name: 'TypID', labelMsg: messages.TypID, renderHTML: false },
    // { name: 'TypText', labelMsg: messages.TypText, renderHTML: false },
    // { name: 'KategorieID', labelMsg: messages.KategorieID, renderHTML: false },
    // {
    //   name: 'KategorieText',
    //   labelMsg: messages.KategorieText,
    //   renderHTML: false,
    // },
    // {
    //   name: 'VeranstalterID',
    //   labelMsg: messages.VeranstalterID,
    //   renderHTML: false,
    // },
    // {
    //   name: 'VeranstalterText',
    //   labelMsg: messages.VeranstalterText,
    //   renderHTML: false,
    // },
    { name: 'DatumVon', labelMsg: messages.DatumVon, renderHTML: false },
    { name: 'DatumBis', labelMsg: messages.DatumBis, renderHTML: false },
    { name: 'DateFrom', labelMsg: messages.DateFrom, renderHTML: false },
    { name: 'DateTo', labelMsg: messages.DateTo, renderHTML: false },
    { name: 'Wochentag', labelMsg: messages.Wochentag, renderHTML: false },
    { name: 'Dauer', labelMsg: messages.Dauer, renderHTML: false },
    { name: 'ZeitVon', labelMsg: messages.ZeitVon, renderHTML: false },
    { name: 'ZeitBis', labelMsg: messages.ZeitBis, renderHTML: false },
    { name: 'Ort', labelMsg: messages.Ort, renderHTML: false },
    { name: 'Preis', labelMsg: messages.Preis, renderHTML: false },
    // {
    //   name: 'LeitungPersonID',
    //   labelMsg: messages.LeitungPersonID,
    //   renderHTML: false,
    // },
    // {
    //   name: 'LeitungPersonAaiID',
    //   labelMsg: messages.LeitungPersonAaiID,
    //   renderHTML: false,
    // },
    // {
    //   name: 'LeitungPersonIDListe',
    //   labelMsg: messages.LeitungPersonIDListe,
    //   renderHTML: false,
    // },
    {
      name: 'MaxTeilnehmer',
      labelMsg: messages.MaxTeilnehmer,
      renderHTML: false,
    },
    {
      name: 'MinTeilnehmer',
      labelMsg: messages.MinTeilnehmer,
      renderHTML: false,
    },
    { name: 'InstanzVon', labelMsg: messages.InstanzVon, renderHTML: false },
    { name: 'ZeitKurs', labelMsg: messages.ZeitKurs, renderHTML: false },
    {
      name: 'ZeitSelbststudium',
      labelMsg: messages.ZeitSelbststudium,
      renderHTML: false,
    },
    {
      name: 'ZeitPruefung',
      labelMsg: messages.ZeitPruefung,
      renderHTML: false,
    },
    { name: 'ZeitTotal', labelMsg: messages.ZeitTotal, renderHTML: false },
    // ECTS handled above via genericName
    {
      name: 'Unterrichtssprache',
      labelMsg: messages.textUnterrichtssprache,
      renderHTML: false,
    },
    {
      name: 'Studienstufe',
      labelMsg: messages.Studienstufe,
      renderHTML: false,
    },
    {
      name: 'InternetSichtbar',
      labelMsg: messages.InternetSichtbar,
      renderHTML: false,
    },
    {
      name: 'CodesKategorie',
      labelMsg: messages.CodesKategorie,
      renderHTML: false,
    },
    {
      name: 'Gruppenzuordnungen',
      labelMsg: messages.Gruppenzuordnungen,
      renderHTML: false,
    },
    {
      name: 'Codezuweisungen',
      labelMsg: messages.Codezuweisungen,
      renderHTML: false,
    },
    { name: 'Lektionen', labelMsg: messages.Lektionen, renderHTML: false },
    {
      name: 'InternetAnmeldeArt',
      labelMsg: messages.InternetAnmeldeArt,
      renderHTML: false,
    },
    {
      name: 'StatusErlaubtAnmeldung',
      labelMsg: messages.StatusErlaubtAnmeldung,
      renderHTML: false,
    },
    {
      name: 'AnmeldungVon',
      labelMsg: messages.AnmeldungVon,
      renderHTML: false,
    },
    {
      name: 'AnmeldungBis',
      labelMsg: messages.AnmeldungBis,
      renderHTML: false,
    },
    { name: 'SignupFrom', labelMsg: messages.SignupFrom, renderHTML: false },
    { name: 'SignupTo', labelMsg: messages.SignupTo, renderHTML: false },
    {
      name: 'AnmeldeStatusInfo',
      labelMsg: messages.AnmeldeStatusInfo,
      renderHTML: false,
    },
    // {
    //   name: 'hauptsprache',
    //   labelMsg: messages.hauptsprache,
    //   renderHTML: false,
    // }, // Useful for debugging
    // {
    //   name: 'uebersetzung',
    //   labelMsg: messages.uebersetzung,
    //   renderHTML: false,
    // }, // Useful for debugging
  ];

  // Extract the specific title/designation for the H1
  const pageTitle = getTranslatedFieldValue('Bezeichnung') || content.title;

  return (
    <div id="module-view">
      <article id="page-document" className="module-view">
        <section className="content">
          <div className="blocks-group-wrapper transparent">
            {/* Use the specific Bezeichnung as the main title */}
            <Title title={pageTitle} />
            {/* Optional: Render standard description if available */}
            {content.description && (
              <p className="documentDescription">{content.description}</p>
            )}

            {/* Use a div instead of dl for more flexible CSS (like grid or flex) */}
            <div className="module-details">
              {/* Render the selected standard fields */}
              {map(fieldsToDisplay, (field) => {
                // Determine value: use mapping or direct access
                const value = field.genericName
                  ? getTranslatedFieldValue(field.genericName)
                  : content?.[field.name || '']; // Handle potential undefined name defensively

                return (
                  <RenderField
                    key={field.genericName || field.name}
                    label={intl.formatMessage(field.labelMsg)}
                    value={value}
                    renderHTML={field.renderHTML}
                  />
                );
              })}

              {/* Render Label/Memo pairs */}
              {map(
                Array.from({ length: 13 }, (_, i) => i + 1),
                (i) => {
                  const labelValue = getTranslatedFieldValue(`Label${i}`);
                  const memoValue = getTranslatedFieldValue(`Memo${i}`);
                  // Only render if the labelValue exists (which acts as the label)
                  // and the memoValue has content.
                  if (
                    labelValue &&
                    memoValue !== null &&
                    memoValue !== undefined &&
                    memoValue !== ''
                  ) {
                    return (
                      <RenderField
                        key={`memo-pair-${i}`}
                        label={String(labelValue)} // Label comes *from the content*
                        value={memoValue}
                        renderHTML={true} // Always render Memos as HTML
                      />
                    );
                  }
                  return null;
                },
              )}

              {/* Render Bemerk Label/Memo pair */}
              {(() => {
                const labelValue = getTranslatedFieldValue('LabelBemerk');
                const memoValue = getTranslatedFieldValue('MemoBemerk');
                if (
                  labelValue &&
                  memoValue !== null &&
                  memoValue !== undefined &&
                  memoValue !== ''
                ) {
                  return (
                    <RenderField
                      key="memo-pair-bemerk"
                      label={String(labelValue)}
                      value={memoValue}
                      renderHTML={true}
                    />
                  );
                }
                return null;
              })()}

              {/* Handle LabelE14/MemoE14 if needed (only shown if they have content and maybe only for 'en') */}
              {/* Adjust logic based on requirements */}
              {currentLang === 'en' && content.LabelE14 && content.MemoE14 && (
                <RenderField
                  key="memo-pair-14"
                  label={content.LabelE14}
                  value={content.MemoE14}
                  renderHTML={true}
                />
              )}
            </div>
          </div>
        </section>
        <aside className="sidebar-nav-right-wrapper">
          <SlotRenderer
            name="sidebar-nav-right"
            content={content as PloneContent}
          />
        </aside>
      </article>
    </div>
  );
};

export default ModuleView;
