import { Icon } from '@fhnw/components/Icon/Icon';

export type DatePickerIconProps = {
  size: 'S' | 'M' | 'L' | 'XS' | 'XL' | 'XXL';
};

const DatePickerIcon: React.FC<DatePickerIconProps> = ({ size }) => (
  <Icon size={size}>
    <svg
      width="20px"
      height="18px"
      viewBox="0 0 20 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="1680_ST_Produkt_CAS@2x"
          transform="translate(-1089.000000, -3823.000000)"
          fill="#767573"
        >
          <g id="Page-1" transform="translate(1089.000000, 3823.000000)">
            <path
              d="M1,6 L19,6 L19,16.5 C19,16.776 18.776,17 18.5,17 L1.5,17 C1.225,17 1,16.776 1,16.5 L1,6 Z M1,2.5 C1,2.224 1.225,2 1.5,2 L4,2 L4,3.5 C4,3.776 4.225,4 4.5,4 C4.776,4 5,3.776 5,3.5 L5,2 L15,2 L15,3.5 C15,3.776 15.225,4 15.5,4 C15.776,4 16,3.776 16,3.5 L16,2 L18.5,2 C18.776,2 19,2.224 19,2.5 L19,5 L1,5 L1,2.5 Z M1.5,18 L18.5,18 C19.327,18 20,17.327 20,16.5 L20,2.5 C20,1.673 19.327,1 18.5,1 L16,1 L16,0.5 C16,0.224 15.776,0 15.5,0 C15.225,0 15,0.224 15,0.5 L15,1 L5,1 L5,0.5 C5,0.224 4.776,0 4.5,0 C4.225,0 4,0.224 4,0.5 L4,1 L1.5,1 C0.674,1 0,1.673 0,2.5 L0,16.5 C0,17.327 0.674,18 1.5,18 L1.5,18 Z"
              id="Fill-1"
            ></path>
            <path
              d="M6.5,9 L7.5,9 C7.776,9 8,8.776 8,8.5 C8,8.224 7.776,8 7.5,8 L6.5,8 C6.225,8 6,8.224 6,8.5 C6,8.776 6.225,9 6.5,9"
              id="Fill-3"
            ></path>
            <path
              d="M9.5,9 L10.5,9 C10.776,9 11,8.776 11,8.5 C11,8.224 10.776,8 10.5,8 L9.5,8 C9.225,8 9,8.224 9,8.5 C9,8.776 9.225,9 9.5,9"
              id="Fill-5"
            ></path>
            <path
              d="M12.5,9 L13.5,9 C13.776,9 14,8.776 14,8.5 C14,8.224 13.776,8 13.5,8 L12.5,8 C12.225,8 12,8.224 12,8.5 C12,8.776 12.225,9 12.5,9"
              id="Fill-7"
            ></path>
            <path
              d="M15.5,9 L16.5,9 C16.776,9 17,8.776 17,8.5 C17,8.224 16.776,8 16.5,8 L15.5,8 C15.225,8 15,8.224 15,8.5 C15,8.776 15.225,9 15.5,9"
              id="Fill-9"
            ></path>
            <path
              d="M3.5,11 L4.5,11 C4.776,11 5,10.776 5,10.5 C5,10.224 4.776,10 4.5,10 L3.5,10 C3.225,10 3,10.224 3,10.5 C3,10.776 3.225,11 3.5,11"
              id="Fill-11"
            ></path>
            <path
              d="M6.5,11 L7.5,11 C7.776,11 8,10.776 8,10.5 C8,10.224 7.776,10 7.5,10 L6.5,10 C6.225,10 6,10.224 6,10.5 C6,10.776 6.225,11 6.5,11"
              id="Fill-13"
            ></path>
            <path
              d="M9.5,11 L10.5,11 C10.776,11 11,10.776 11,10.5 C11,10.224 10.776,10 10.5,10 L9.5,10 C9.225,10 9,10.224 9,10.5 C9,10.776 9.225,11 9.5,11"
              id="Fill-15"
            ></path>
            <path
              d="M12.5,11 L13.5,11 C13.776,11 14,10.776 14,10.5 C14,10.224 13.776,10 13.5,10 L12.5,10 C12.225,10 12,10.224 12,10.5 C12,10.776 12.225,11 12.5,11"
              id="Fill-17"
            ></path>
            <path
              d="M15.5,11 L16.5,11 C16.776,11 17,10.776 17,10.5 C17,10.224 16.776,10 16.5,10 L15.5,10 C15.225,10 15,10.224 15,10.5 C15,10.776 15.225,11 15.5,11"
              id="Fill-19"
            ></path>
            <path
              d="M3.5,13 L4.5,13 C4.776,13 5,12.776 5,12.5 C5,12.224 4.776,12 4.5,12 L3.5,12 C3.225,12 3,12.224 3,12.5 C3,12.776 3.225,13 3.5,13"
              id="Fill-21"
            ></path>
            <path
              d="M6.5,13 L7.5,13 C7.776,13 8,12.776 8,12.5 C8,12.224 7.776,12 7.5,12 L6.5,12 C6.225,12 6,12.224 6,12.5 C6,12.776 6.225,13 6.5,13"
              id="Fill-23"
            ></path>
            <path
              d="M9.5,13 L10.5,13 C10.776,13 11,12.776 11,12.5 C11,12.224 10.776,12 10.5,12 L9.5,12 C9.225,12 9,12.224 9,12.5 C9,12.776 9.225,13 9.5,13"
              id="Fill-25"
            ></path>
            <path
              d="M12.5,13 L13.5,13 C13.776,13 14,12.776 14,12.5 C14,12.224 13.776,12 13.5,12 L12.5,12 C12.225,12 12,12.224 12,12.5 C12,12.776 12.225,13 12.5,13"
              id="Fill-27"
            ></path>
            <path
              d="M15.5,13 L16.5,13 C16.776,13 17,12.776 17,12.5 C17,12.224 16.776,12 16.5,12 L15.5,12 C15.225,12 15,12.224 15,12.5 C15,12.776 15.225,13 15.5,13"
              id="Fill-29"
            ></path>
            <path
              d="M3.5,15 L4.5,15 C4.776,15 5,14.776 5,14.5 C5,14.224 4.776,14 4.5,14 L3.5,14 C3.225,14 3,14.224 3,14.5 C3,14.776 3.225,15 3.5,15"
              id="Fill-31"
            ></path>
            <path
              d="M6.5,15 L7.5,15 C7.776,15 8,14.776 8,14.5 C8,14.224 7.776,14 7.5,14 L6.5,14 C6.225,14 6,14.224 6,14.5 C6,14.776 6.225,15 6.5,15"
              id="Fill-33"
            ></path>
            <path
              d="M9.5,15 L10.5,15 C10.776,15 11,14.776 11,14.5 C11,14.224 10.776,14 10.5,14 L9.5,14 C9.225,14 9,14.224 9,14.5 C9,14.776 9.225,15 9.5,15"
              id="Fill-35"
            ></path>
            <path
              d="M12.5,15 L13.5,15 C13.776,15 14,14.776 14,14.5 C14,14.224 13.776,14 13.5,14 L12.5,14 C12.225,14 12,14.224 12,14.5 C12,14.776 12.225,15 12.5,15"
              id="Fill-37"
            ></path>
            <path
              d="M15.5,15 L16.5,15 C16.776,15 17,14.776 17,14.5 C17,14.224 16.776,14 16.5,14 L15.5,14 C15.225,14 15,14.224 15,14.5 C15,14.776 15.225,15 15.5,15"
              id="Fill-39"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </Icon>
);

export default DatePickerIcon;
