import React from 'react';
import LocationMapContent from './LocationMapContent';
import { useLocation } from 'react-router';
import { useIntl } from 'react-intl';
import useFetchLocations from './hooks/useFetchLocations';
import { locationQueryObject } from './data/locationQueries';
import type { Location, Content } from '@plone/types';
import { isCurrentPage } from './utils/pageUtils';

type LocationMapProps = {
  content: Content;
};

const oneOfThesePages = ['/de', '/en'];

const LocationMap: React.FC<LocationMapProps> = ({ content }) => {
  const location = useLocation();
  const intl = useIntl();

  const documentUrl = `/${intl.locale}/start-maps`;
  const queryObject = locationQueryObject(documentUrl);

  const locationsData: Location[] = useFetchLocations(documentUrl, queryObject);

  const isMatchingPage = isCurrentPage(location.pathname, oneOfThesePages);
  const shouldShowMap =
    (content?.related_locations?.length > 0 &&
      content.related_locations[0]?.['@id']) ||
    isMatchingPage;

  if (!shouldShowMap) {
    return null;
  }

  return (
    <LocationMapContent
      content={content}
      oneOfThesePages={oneOfThesePages}
      locationsData={locationsData}
    />
  );
};

export default LocationMap;
