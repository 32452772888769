import calendarSVG from '@plone/volto/icons/calendar.svg';
import { expandToBackendURL } from '@plone/volto/helpers';
import Icon from '@plone/volto/components/theme/Icon/Icon';

type ICalendarProp = {
  link: any;
};

const ICalendar = ({ link }: { link: ICalendarProp }) => {
  return (
    <>
      <span>
        <a
          className="ics-download"
          target="_blank"
          rel="noreferrer"
          href={`${expandToBackendURL(link ? link : '')}/ics_view`}
        >
          <Icon className="calendarSvg" name={calendarSVG} size="24px" /> iCal
        </a>
      </span>
    </>
  );
};

export default ICalendar;
