import { useState, useEffect } from 'react';

const GoToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > 150);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setIsVisible(false);
  };

  return (
    <div
      aria-hidden="true"
      className={`web25__gototop ${isVisible ? 'visible' : ''}`}
      onClick={scrollToTop}
      role="button"
      tabIndex={0}
    />
  );
};

export default GoToTopButton;
