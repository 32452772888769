/**
 * OVERRIDE templates.js
 * REASON: Add 'de' translations for column and columns.
 * FILE: https://github.com/plone/volto/blob/596ae97cc3657e88210aa77cebe6e49ec3a1a66d/packages/volto/src/components/manage/Blocks/Grid/templates.js
 * FILE VERSION: Volto 18.1.1
 * PULL REQUEST: https://gitlab.fhnw.ch/webteam/fhnw-webauftritt-2025/-/merge_requests/387
 * TICKET: https://gitlab.fhnw.ch/webteam/fhnw-webauftritt-2025/-/issues/388
 * DATE: 2024-12-02
 * DEVELOPER: @tishasoumya
 * CHANGELOG:
 *  - 'de' translation message - @tishasoumya
 *
 * Depending on the nature of the change, if possible or suitable, mark the change with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */
import { blocksFormGenerator } from '@plone/volto/helpers';
import baseMessages from '@fhnw/messages/base';

// import gridTemplate1 from '@plone/volto/components/manage/Blocks/Grid/grid-1.svg';
// import gridTemplate2 from '@plone/volto/components/manage/Blocks/Grid/grid-2.svg';
import gridTemplate3 from '@plone/volto/components/manage/Blocks/Grid/grid-3.svg';
import gridTemplate4 from '@plone/volto/components/manage/Blocks/Grid/grid-4.svg';

// START CUSTOMIZATION
const templates = (type) => (intl) => [
  //Remove 1-splaten and 2-splaten as per the requirements
  // {
  //   image: gridTemplate1,
  //   id: 'gridtemplateone',
  //   title: `1 ${intl.formatMessage(baseMessages.column)}`,
  //   blocksData: blocksFormGenerator(1, type),
  // },
  // {
  //   image: gridTemplate2,
  //   id: 'gridtemplatetwo',
  //   title: `2 ${intl.formatMessage(baseMessages.columns)}`,
  //   blocksData: blocksFormGenerator(2, type),
  // },
  {
    image: gridTemplate3,
    id: 'gridtemplatethree',
    title: `3 ${intl.formatMessage(baseMessages.columns)}`,
    blocksData: blocksFormGenerator(3, type),
  },
  {
    image: gridTemplate4,
    id: 'gridtemplatefour',
    title: `4 ${intl.formatMessage(baseMessages.columns)}`,
    blocksData: blocksFormGenerator(4, type),
  },
];
// END CUSTOMIZATION

export default templates;
