import { useIntl } from 'react-intl';
import { Link } from '../Link/Link';
import { ContactProfileMessages as messages } from './TeaserContactMessages';

export type TeaserContactProfileProps<T> = {
  imageComponent?: React.ComponentType<any>;
  imageComponentProps?: T;
  reference?: string;
  title: string;
  name_affix1?: string;
  name_affix2?: string;
  text: string;
  address?: {
    tel1?: string;
    tel2?: string;
    tel1_suffix?: string;
    tel2_suffix?: string;
    email?: string | null;
  };
};

export const getName = (
  title: string,
  name_affix1?: string,
  name_affix2?: string,
) => {
  const prefix = name_affix1 ? `${name_affix1} ` : '';
  const suffix = name_affix2 ? `, ${name_affix2}` : '';
  return `${prefix}${title}${suffix}`;
};

const TeaserContactProfile: React.FC<TeaserContactProfileProps<any>> = ({
  imageComponent,
  imageComponentProps,
  reference,
  title,
  name_affix1,
  name_affix2,
  text,
  address,
}) => {
  const intl = useIntl();
  const Image = imageComponent || 'img';

  return (
    <li className="teaser">
      <Link className="teaser-link" href={reference}>
        <div className="teaser-wrapper">
          {(imageComponentProps?.imageField || imageComponentProps?.src) && (
            <div className="teaser-image">
              <Link
                className="teaser-link"
                href={reference}
                title={title}
                newTab={false}
              >
                <Image {...imageComponentProps} />
              </Link>
            </div>
          )}
          <div className="teaser-content">
            <Link className="profile-link" href={reference} title={title}>
              <h3>{getName(title, name_affix1, name_affix2)}</h3>
            </Link>
            <p>{text}</p>
            <div className="teaser-content--profile">
              {(address?.tel1 || address?.tel2) && (
                <div className="teaser-content--profile--tel">
                  <span>{intl.formatMessage(messages.telephone)}</span>
                  {address?.tel1 && (
                    <>
                      <span>
                        {address?.tel1_suffix && `(${address.tel1_suffix}) `}
                        <Link href={`tel:${address.tel1}`} title={title}>
                          {address.tel1}
                        </Link>
                      </span>
                    </>
                  )}
                  {address?.tel2 && (
                    <>
                      <span>
                        {address?.tel2_suffix && `(${address.tel2_suffix}) `}
                        <Link href={`tel:${address.tel2}`} title={title}>
                          {address.tel2}
                        </Link>
                      </span>
                    </>
                  )}
                </div>
              )}
              {address?.email && (
                <div className="teaser-content--profile--email">
                  <span>
                    {intl.formatMessage(messages.email)}
                    <Link href={`mailto:${address.email}`}>
                      {address.email}
                    </Link>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
};

export default TeaserContactProfile;
