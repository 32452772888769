import { Link } from '@fhnw/components/Link/Link';
import { getItemReference } from '@fhnw/helpers/Url/UrlHelper';
import { FormState } from '@fhnw/types/formState';
import { UniversalLink } from '@plone/volto/components';
import { useSelector } from 'react-redux';

interface SidebarStopSignContent {
  '@id': string;
  title: string;
}

interface Item {
  '@id': string;
  '@type': string;
  back_link: boolean;
  description: string;
  exclude_from_nav: boolean;
  image_field?: string;
  image_scales: any;
  remoteUrl: string;
  review_state: string;
  title: string;
  type_title: string;
}

const SidebarNavigation = ({ content }: { content: any }) => {
  const token = useSelector((state: FormState) => state?.userSession?.token);
  const sidebarStopSignContent: SidebarStopSignContent[] =
    content['@components']?.['subnav_stopsigns']
      ?.filter((item: SidebarStopSignContent) => item !== null)
      .reverse() || [];

  const subNavigationItems: Item[] =
    content.items?.filter(
      (item: Item) =>
        item !== null && !item.exclude_from_nav && !item.back_link,
    ) || [];

  const backLinkObject: Item = content.items?.find(
    (item: Item) => item['@type'] === 'Link' && item.back_link,
  );

  return (
    <div className="sidebar-navigation-container">
      {sidebarStopSignContent.length > 0 && (
        <div className="stop-sign-container">
          {sidebarStopSignContent.map((item, index: number) => (
            <Link
              key={item?.['@id']}
              href={getItemReference(item, token)}
              linkComponent={UniversalLink}
              className="stop-sign-anchor"
            >
              {index === 0 ? (
                <h2>{item?.title}</h2>
              ) : (
                <span>{item?.title}</span>
              )}
            </Link>
          ))}
        </div>
      )}

      <div className="sub-navigation-container">
        {subNavigationItems.length > 0 &&
          subNavigationItems.map((item) => (
            <Link
              key={item['@id']}
              className="btn btn-gray--subnav"
              href={getItemReference(item, token)}
              linkComponent={UniversalLink}
            >
              <span>{item.title}</span>
            </Link>
          ))}

        {backLinkObject && (
          <Link
            href={getItemReference(backLinkObject, token)}
            linkComponent={UniversalLink}
            className="btn btn-lemon--subnav"
          >
            <span>{backLinkObject.title}</span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default SidebarNavigation;
