import TeaserBlockDefault from '@fhnw/components/Blocks/Teaser/variations/TeaserBlockDefault';
import { FullBleedTeaser } from '@fhnw/components/FullbleedTeaser';
import { Infobox } from '@fhnw/components/Infobox';

export const teaserBlockVariations = [
  {
    id: 'default',
    title: 'Standard',
    isDefault: true,
    template: TeaserBlockDefault,
    schemaEnhancer: ({ schema }: { schema: any }) => {
      schema.properties.styles.schema.properties = {};
      schema.fieldsets[0].fields.push('showTeaserHeading');
      schema.properties.showTeaserHeading = {
        title: 'Teaserüberschrift anzeigen',
        type: 'boolean',
        default: true,
      };
      schema.fieldsets[0].fields.push('showTeaserImage');
      schema.properties.showTeaserImage = {
        title: 'Teaserbild anzeigen',
        type: 'boolean',
        default: true,
      };
      schema.fieldsets[0].fields.push('showTeaserText');
      schema.properties.showTeaserText = {
        title: 'Teasertext anzeigen',
        type: 'boolean',
        default: true,
      };
      schema.properties.href.selectedItemAttrs.push('ShowTeaserImage');
      schema.properties.href.selectedItemAttrs.push('ShowTeaserText');
      schema.properties.href.selectedItemAttrs.push('ShowTeaserHeading');

      return schema;
    },
  },
  {
    id: 'infobox',
    title: 'Infobox',
    isDefault: false,
    template: Infobox,
    schemaEnhancer: ({ schema }: { schema: any }) => {
      schema.properties.styles.schema.properties = {};
      schema.fieldsets[0].fields.push('buttonText');
      schema.properties.buttonText = {
        title: 'Button Beschriftung',
        type: 'string',
        default: '',
      };
      schema.fieldsets[0].fields.push('showTeaserImage');
      schema.properties.showTeaserImage = {
        title: 'Teaserbild anzeigen',
        type: 'boolean',
        default: true,
      };
      schema.properties.href.selectedItemAttrs.push('ButtonText');
      schema.properties.href.selectedItemAttrs.push('ShowTeaserImage');

      return schema;
    },
  },
  {
    id: 'fullBleedTeaser',
    title: 'Full Bleed Teaser',
    isDefault: false,
    template: FullBleedTeaser,
  },
];
