import { useEffect } from 'react';
import { ContactProps, ContactItem } from './index';
import { getVocabulary } from '@plone/volto/actions';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

type GroupedContacts = { [key: string]: ContactProps[] };

export function groupByFunctionRole(contact: ContactProps[]): GroupedContacts {
  return contact?.reduce<GroupedContacts>((acc, item) => {
    const role = item.functionRole;
    if (!acc[role]) {
      acc[role] = [];
    }
    acc[role].push(item);
    return acc;
  }, {});
}

const ContactSidebar = ({ content }: { content: any }) => {
  const isContactTypes = ['TeamContact', 'Contact'].includes(content['@type']);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getVocabulary({
        vocabNameOrURL: 'collective.taxonomy.phonesuffix',
        size: -1,
      } as any) as AnyAction,
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getVocabulary({
        vocabNameOrURL: 'collective.taxonomy.function',
        size: -1,
      } as any) as AnyAction,
    );
  }, [dispatch]);

  const phoneSuffixVocab = useSelector(
    (state: any) =>
      state.vocabularies?.['collective.taxonomy.phonesuffix']?.items,
  );

  const functionSuffixVocab = useSelector(
    (state: any) => state.vocabularies?.['collective.taxonomy.function']?.items,
  );

  let relatedContacts = content['@components']?.related_contacts;
  relatedContacts = relatedContacts?.map((contact: any, index: number) => {
    const currentFunctionRole =
      content.related_contacts?.items[index]?.function;
    return { ...contact, functionRole: currentFunctionRole ?? 'contact' };
  });

  relatedContacts = groupByFunctionRole(relatedContacts);

  const totalFunctionRole = relatedContacts ? Object.keys(relatedContacts) : [];

  return isContactTypes || totalFunctionRole?.length > 0 ? (
    <div className="contact-content-sidebar-wrapper">
      {isContactTypes && (
        <ContactItem
          content={content}
          phoneSuffixVocab={phoneSuffixVocab}
          isContactTypes={isContactTypes}
        />
      )}
      {totalFunctionRole.map((role) => {
        return (
          <ContactItem
            key={role}
            content={relatedContacts[role]}
            phoneSuffixVocab={phoneSuffixVocab}
            functionSuffixVocab={functionSuffixVocab}
            functionRole={role}
            relatedContacts={true}
          />
        );
      })}
    </div>
  ) : null;
};

export default ContactSidebar;
