import React from 'react';
import { RenderBlocks } from '@plone/volto/components';
import { Tab, Container } from 'semantic-ui-react';
import { MenuItem } from './TabComponents';

/**
 * Generate panes configuration for tabs
 * @param {Object} props - Component props
 * @param {Array} tabsList - List of tab IDs
 * @param {Object} tabs - Object containing tab content
 * @param {string} blockId - Block ID
 * @param {boolean} isContainer - Whether to use Container component
 * @param {Object} metadata - Metadata object
 * @returns {Array} - Array of pane configurations
 */
export const getPanes = (
  props,
  tabsList,
  tabs,
  blockId,
  isContainer,
  metadata,
) => {
  return tabsList.map((tab, index) => {
    return {
      id: tab,
      menuItem: (
        <MenuItem
          {...props}
          key={tab}
          tab={tab}
          index={index}
          blockId={blockId}
        />
      ),
      pane: (
        <Tab.Pane key={tab} as={isContainer ? Container : undefined}>
          <div
            id={tabs[tab]?.title || `Tab ${tabsList.indexOf(tab) + 1}`}
            className="tab-name"
          >
            <div
              tabIndex={0}
              role="tabpanel"
              aria-labelledby={
                tabs[tab]?.title || `Tab ${tabsList.indexOf(tab) + 1}`
              }
              id={'tab-pane-' + tab}
            >
              <RenderBlocks
                {...props}
                metadata={metadata}
                content={tabs[tab]}
              />
            </div>
          </div>
        </Tab.Pane>
      ),
    };
  });
};
