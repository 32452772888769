import React from 'react';
import { withBlockExtensions } from '@plone/volto/helpers';
import { SidebarPortal, BlockDataForm } from '@plone/volto/components';
import { ApplicationAccordion } from './index';
import { ApplicationBlockSchema } from './index';
import { ApplicationAccordionBlockData } from '../../types/applicationAccordion';

interface ApplicationAccordionEditProps {
  data: ApplicationAccordionBlockData;
  block: string;
  onChangeBlock: (blockId: string, value: any) => void;
  isEditMode?: boolean;
  blocksConfig: any;
}

const Edit: React.FC<ApplicationAccordionEditProps> = (props: any) => {
  const { data, block, onChangeBlock, blocksConfig, selected } = props;

  return (
    <>
      <ApplicationAccordion {...props} isEditMode />
      <SidebarPortal selected={selected}>
        <BlockDataForm
          schema={ApplicationBlockSchema(props.intl)}
          title="Application Block"
          onChangeField={(id: any, value: any) =>
            onChangeBlock(block, { ...data, [id]: value })
          }
          onChangeBlock={onChangeBlock}
          formData={data}
          block={block}
          blocksConfig={blocksConfig}
        />
      </SidebarPortal>
    </>
  );
};

export default withBlockExtensions(Edit);
