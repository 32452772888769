import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'HTML block',
    defaultMessage: 'HTML block',
  },
  target: {
    id: 'Target',
    defaultMessage: 'Target',
  },
});

export { messages };
