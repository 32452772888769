import React, { useState, useRef, useEffect, MouseEvent } from 'react';
import { BodyClass } from '@plone/volto/helpers';
import { defineMessages, useIntl } from 'react-intl';
import { Link } from '@fhnw/components/Link/Link';
import Logo from '@fhnw/components/Logo/Logo';
import cx from 'classnames';
import BackSVG from '@plone/volto/icons/back.svg';
import { Icon, UniversalLink } from '@plone/volto/components';
import GlobalSearchPanel from '@fhnw/components/GlobalSearch/GlobalSearchPanel';
import { NavigationFooter } from './Header';
import { NavItem } from '@fhnw/types/navItem';

interface MobileNavigationProps {
  navItems: NavItem[];
  token: string;
  metaNav: NavItem[];
}

const messages = defineMessages({
  closeMobileMenu: {
    id: 'Close menu',
    defaultMessage: 'Close menu',
  },
  openMobileMenu: {
    id: 'Open menu',
    defaultMessage: 'Open menu',
  },
});

const MobileNavigation: React.FC<MobileNavigationProps> = ({
  navItems,
  token,
  metaNav,
}) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [currentNav, setCurrentNav] = useState(1);
  const [firstLevel, setFirstLevel] = useState(-1);
  const [secondLevel, setSecondLevel] = useState(-1);

  const firstLevelDivRef = useRef<HTMLDivElement>(null);
  const secondLevelDivRef = useRef<HTMLDivElement>(null);

  const [openGlobalSearch, setOpenGlobalSearch] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    if (currentNav === 2) {
      focusElement(firstLevelDivRef);
    }
  }, [currentNav]);

  useEffect(() => {
    if (currentNav === 3) {
      focusElement(secondLevelDivRef);
    }
  }, [currentNav]);

  const focusElement = (elementRef: React.RefObject<HTMLDivElement>) => {
    requestAnimationFrame(() => {
      setTimeout(() => elementRef.current?.focus(), 400);
    });
  };

  const onOpenGlobalSearch = (e: any) => {
    e.stopPropagation();
    setOpenGlobalSearch(!openGlobalSearch);
  };

  const closeGlobalSearch = (): void => {
    setOpenGlobalSearch(false);
  };

  const toggleMobileMenu = () => {
    const body = document.body;
    if (isMobileMenuOpen) {
      resetBodyStyles(body);
      resetNavigation();
    } else {
      setBodyStyles(body);
    }
    setIsMobileMenuOpen((prev) => !prev);
  };

  const setBodyStyles = (body: HTMLElement) => {
    body.style.position = 'fixed';
    body.style.width = '100%';
    body.style.top = '0px';
    body.style.left = '0px';
  };

  const resetBodyStyles = (body: HTMLElement) => {
    body.style.position = '';
    body.style.width = '';
    body.style.top = '';
    body.style.left = '';
  };

  const resetNavigation = () => {
    setFirstLevel(-1);
    setSecondLevel(-1);
    setCurrentNav(1);
  };

  const handleNavClick = (
    event: MouseEvent<HTMLAnchorElement>,
    index: number,
    remoteUrl: string,
    navLevel: number,
  ) => {
    if (!remoteUrl) {
      event.preventDefault();
      setNavigationLevels(index, navLevel);
    } else {
      toggleMobileMenu();
      resetNavigation();
    }
  };

  const setNavigationLevels = (index: number, navLevel: number) => {
    if (navLevel === 1) setFirstLevel(index);
    if (navLevel === 2) setSecondLevel(index);
    setCurrentNav(navLevel + 1);
  };

  const renderNavItems = (items: NavItem[], level: number) =>
    items?.map((item, index) => (
      <li key={index}>
        <Link
          href={item.remoteUrl ?? '#'}
          className={cx('btn btn-nav', { 'btn-nav--sublevel': level > 1 })}
          data-is-expandable={!item.remoteUrl}
          data-is-active={
            level === 1 ? index === firstLevel : index === secondLevel
          }
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
            handleNavClick(event, index, item.remoteUrl, level)
          }
          linkComponent={UniversalLink}
        >
          <span>{item.title}</span>
        </Link>
      </li>
    ));

  return (
    <div className="mobile-navigation">
      <Logo />
      <div className="mobile-nav">
        <button
          className={cx('hamburger', {
            'is-active': isMobileMenuOpen,
          })}
          aria-label={
            isMobileMenuOpen
              ? intl.formatMessage(messages.closeMobileMenu)
              : intl.formatMessage(messages.openMobileMenu)
          }
          title={
            isMobileMenuOpen
              ? intl.formatMessage(messages.closeMobileMenu)
              : intl.formatMessage(messages.openMobileMenu)
          }
          type="button"
          onClick={toggleMobileMenu}
        />

        <button
          className="mobile-navigation-search"
          onClick={onOpenGlobalSearch}
          aria-label="Search"
        >
          <span className="visually-hidden">Search</span>
        </button>

        <div
          className={cx('menu-drawer-mobile-navigation', {
            'slide-in-down': isMobileMenuOpen,
            'slide-out-up': !isMobileMenuOpen,
          })}
        >
          <div
            className="puller-pusher-container"
            style={{
              transform: `translateX(-${(currentNav - 1) * 100}vw)`,
            }}
          >
            <div className="nav level1">
              <nav className="mobile-navigation-level1" role="navigation">
                <ul>{renderNavItems(navItems, 1)}</ul>
              </nav>
              <NavigationFooter token={token} metaNav={metaNav} />
            </div>

            <div ref={firstLevelDivRef} tabIndex={-1} className="nav level2">
              <button className="back-button" onClick={() => setCurrentNav(1)}>
                <Icon name={BackSVG} size="30px" />
              </button>
              <ul>
                {renderNavItems(navItems?.[firstLevel]?.items || [], 2)}
                <li></li>
              </ul>
            </div>

            <div ref={secondLevelDivRef} tabIndex={-1} className="nav level3">
              <button className="back-button" onClick={() => setCurrentNav(2)}>
                <Icon name={BackSVG} size="30px" />
              </button>
              <ul>
                {renderNavItems(
                  navItems?.[firstLevel]?.items?.[secondLevel]?.items || [],
                  3,
                )}
                <li></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {openGlobalSearch && <BodyClass className="prevent-scroll" />}

      <div className="mobile global-search-container ">
        <div
          className={cx('mobile global-search-content-container', {
            'is-open': openGlobalSearch,
          })}
        >
          {openGlobalSearch && (
            <GlobalSearchPanel
              closeGlobalSearch={closeGlobalSearch}
              isMobile={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileNavigation;
