import { defineMessages } from 'react-intl';

export const FooterMessages = defineMessages({
  what_we_offer: {
    id: 'What we offer',
    defaultMessage: 'What we offer',
  },
  degree_programmes: {
    id: 'Degree Programmes',
    defaultMessage: 'Degree Programmes',
  },
  continuing_education: {
    id: 'Continuing Education',
    defaultMessage: 'Continuing Education',
  },
  research_and_services: {
    id: 'Research and Services',
    defaultMessage: 'Research and Services',
  },
  about_fhnw: {
    id: 'About FHNW',
    defaultMessage: 'About FHNW',
  },
  schools: {
    id: 'Schools',
    defaultMessage: 'Schools',
  },
  organisation: {
    id: 'Organisation',
    defaultMessage: 'Organisation',
  },
  management: {
    id: 'Management',
    defaultMessage: 'Management',
  },
  facts_and_figures: {
    id: 'Facts and Figures',
    defaultMessage: 'Facts and Figures',
  },
  information: {
    id: 'Information',
    defaultMessage: 'Information',
  },
  data_protection: {
    id: 'Data Protection',
    defaultMessage: 'Data Protection',
  },
  accessibility: {
    id: 'Accessibility',
    defaultMessage: 'Accessibility',
  },
  imprint: {
    id: 'Imprint',
    defaultMessage: 'Imprint',
  },
  support_intranet: {
    id: 'Support & Intranet',
    defaultMessage: 'Support & Intranet',
  },
  it_support: {
    id: 'IT Support',
    defaultMessage: 'IT Support',
  },
  login_inside_fhnw: {
    id: 'Login Inside-FHNW',
    defaultMessage: 'Login Inside-FHNW',
  },
  member_of: {
    id: 'Member of',
    defaultMessage: 'Member of',
  },
});
