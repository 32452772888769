import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getQueryStringResults } from '@plone/volto/actions';
import { transformContentLocations } from '../data/transformContentLocations';
import { fallbackLocationsData } from '../data/fallbackLocationsData';
import type { Location } from '@plone/types';

const useFetchLocations = (
  documentUrl: string,
  queryObject: Record<string, any>,
): Location[] => {
  const [locationsData, setLocationsData] = useState<Location[]>(
    fallbackLocationsData,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await dispatch(
          getQueryStringResults(documentUrl, queryObject, '', 1) as any,
        );

        if (response?.items) {
          setLocationsData(transformContentLocations(response.items));
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch locations', error);
        setLocationsData(fallbackLocationsData);
      }
    };

    fetchLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return locationsData;
};

export default useFetchLocations;
