import React from 'react';
import type { Location } from '@plone/types';
import { LocationMapMessages as messages } from './LocationMapMessages';
import { useIntl } from 'react-intl';
import addLineBreaks from '@fhnw/helpers/text/addLineBreaks';

interface LocationInfoBoxProps {
  location: Location;
}

const LocationInfoBox: React.FC<LocationInfoBoxProps> = ({ location }) => {
  const { title, address, contactData, routeURL, buttonLink } = location;
  const intl = useIntl();

  return (
    <div className="location-info-box">
      <div className="location-info-upper">
        <h3>{title}</h3>
        <div className="location-address">
          <p>{addLineBreaks(address.department)}</p>
          <p>{address.street}</p>
          <p>{address.zipCity}</p>
        </div>
        <div className="location-contact">
          {contactData.telephone && (
            <p>
              {intl.formatMessage(messages.phone)}
              <a href={`tel:${contactData.telephone}`}>
                {location.contactData.telephone}
              </a>
            </p>
          )}
          {contactData.email && (
            <p>
              {intl.formatMessage(messages.email)}
              <a href={`mailto:${contactData.email}`}>
                {location.contactData.email}
              </a>
            </p>
          )}
        </div>
        {buttonLink && (
          <div className="location-info-button">
            <a
              href={buttonLink}
              className="btn--md btn-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage(messages.moreInfosAboutLocation)}
            </a>
          </div>
        )}
      </div>
      {routeURL && (
        <div className="location-info-lower">
          <a
            href={routeURL}
            className="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            {intl.formatMessage(messages.calculateRoute)}
          </a>
        </div>
      )}
    </div>
  );
};

export default LocationInfoBox;
