import { UniversalLink } from '@plone/volto/components';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import type { Content } from '@plone/types';
import { setSidebarTab, setMetadataFocus } from '@plone/volto/actions';
import { isEmpty } from 'lodash';
import { Button } from '@plone/components';
import { Hero } from '../Hero/Hero';
import { CTAExpander } from '@fhnw/types/cta';
import { FormState } from '@fhnw/types/formState';
import { AnyAction } from 'redux';
import addLineBreaks from '@fhnw/helpers/text/addLineBreaks';
import { getReference } from '@fhnw/helpers/Url/UrlHelper';

const messages = defineMessages({
  ctaInheritanceSource: {
    id: 'CTA inheritance source',
    defaultMessage: 'CTA inheritance source',
  },
  addCtaButton: {
    id: 'Add CTA button',
    defaultMessage: 'Add CTA button',
  },
});

const emptyCtaData = {};

export const CTAButton = ({ content }: { content: Content }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const formData = useSelector<FormState, Content>(
    (state) => state.form.global,
  );

  const token = useSelector<FormState, string>(
    (state) => state?.userSession?.token,
  );

  const inheritedCtaData = useSelector<FormState, CTAExpander>(
    (state) => state.content.data?.['@components']?.cta || emptyCtaData,
  );

  const notDefault = (data: CTAExpander) => {
    return (
      !isEmpty(data?.cta_text) ||
      !isEmpty(data?.cta_url) ||
      data?.enable_cta_inheritance === true ||
      data?.show_cta_on_mobile === false ||
      data?.show_cta_on_desktop === false
    );
  };

  const showCTAOptions = () => {
    dispatch(setSidebarTab(0) as AnyAction);
    dispatch(setMetadataFocus('cta', 'cta_text') as AnyAction);
  };

  const data = !isEmpty(formData)
    ? notDefault(formData as CTAExpander)
      ? { ...formData, cta_inheritance_path: undefined }
      : inheritedCtaData
    : notDefault(content as CTAExpander)
      ? content
      : inheritedCtaData;

  return (
    <div className="cta-slot-container">
      <Hero content={content} />
      {!isEmpty(formData) && data.cta_inheritance_path && (
        <div className="cta-button-inheritance text-size-xxs p-xxs centered">
          {intl.formatMessage(messages.ctaInheritanceSource)} <br />
          <UniversalLink
            href={data.cta_inheritance_path}
            openLinkInNewTab={true}
          >
            {data.cta_inheritance_path}
          </UniversalLink>
        </div>
      )}
      {!isEmpty(data?.cta_text) ? (
        <>
          {!isEmpty(formData) ? (
            // We are in edit mode
            <Button
              className={cx('cta-button', {
                'hide-mobile': !data.show_cta_on_mobile,
                'hide-desktop': !data.show_cta_on_desktop,
              })}
              onPress={showCTAOptions}
            >
              <h2>{addLineBreaks(data.cta_text)}</h2>
            </Button>
          ) : (
            <UniversalLink
              href={getReference(data.cta_url, token)}
              // @ts-ignore
              className={cx('cta-button', {
                'hide-mobile': !data.show_cta_on_mobile,
                'hide-desktop': !data.show_cta_on_desktop,
              })}
            >
              <h2>{addLineBreaks(data.cta_text)}</h2>
            </UniversalLink>
          )}
        </>
      ) : (
        !isEmpty(formData) && (
          <Button className="cta-button placeholder" onPress={showCTAOptions}>
            {intl.formatMessage(messages.addCtaButton)}
          </Button>
        )
      )}
    </div>
  );
};
