import { Icon } from '@fhnw/components/Icon/Icon';

interface XingProps {
  size: 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL';
  color: string;
  onClick?: () => void;
}

const Xing = (props: XingProps) => (
  <Icon color={props.color} onClick={props.onClick} size={props.size}>
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none">
        <g transform="translate(-758.000000, -3900.000000)" fill="#767573">
          <path
            d="M773.909091,3931.81818 C765.1225,3931.81818 758,3924.69568 758,3915.90909 C758,3907.1233 765.1225,3900 773.909091,3900 C782.695682,3900 789.818182,3907.1233 789.818182,3915.90909 C789.818182,3924.69568 782.695682,3931.81818 773.909091,3931.81818 Z M767.661073,3911 L769.64363,3914.63436 L767,3919 L770.355708,3919 L773,3914.63436 L771.016781,3911 L767.661073,3911 Z M777.781554,3908 L773,3917.728 L776.0813,3924 L779.299745,3924 L776.219045,3917.728 L781,3908 L777.781554,3908 Z"
            id="XingFollow"
            fill="currentColor"
          ></path>
        </g>
      </g>
    </svg>
  </Icon>
);

export default Xing;
