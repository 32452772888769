export const locationQueryObject = (documentUrl: string) => ({
  b_size: '0',
  depth: '1',
  query: [
    {
      i: 'path',
      o: 'plone.app.querystring.operation.string.absolutePath',
      v: documentUrl,
    },
    {
      i: 'portal_type',
      o: 'plone.app.querystring.operation.selection.any',
      v: ['Location'],
    },
  ],
  sort_on: 'Creator',
  sort_order: 'ascending',
  sort_order_boolean: false,
});
