import { useCallback, RefObject } from 'react';

interface Props {
  setIsScrollVisible: (value: boolean) => void;
  navRef: RefObject<HTMLElement>;
}

/**
 * Custom hook that checks if the last child element is fully visible within its parent container
 *
 * @param {Object} props - Hook properties
 * @param {Function} props.setIsScrollVisible - State setter function to update scroll visibility
 * @param {RefObject<HTMLElement>} props.navRef - Reference to the navigation container element
 * @returns {() => void} Callback function that updates scroll visibility state based on last element position
 */
export const useCheckLastElementVisibility = ({
  setIsScrollVisible,
  navRef,
}: Props) => {
  const checkLastElementVisibility = useCallback(() => {
    if (navRef.current) {
      const lastElement = navRef.current.lastElementChild;
      const parentElement = navRef.current.parentElement;
      if (lastElement && parentElement) {
        const rect = lastElement.getBoundingClientRect();
        const parentRect = parentElement.getBoundingClientRect();
        setIsScrollVisible(!(rect.right < parentRect.right)); // Corrected the logical condition
      }
    }
  }, [navRef, setIsScrollVisible]);

  return checkLastElementVisibility;
};
