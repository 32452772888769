import { defineMessages } from 'react-intl';

const messages = defineMessages({
  ImageSlider: {
    id: 'Image Slider',
    defaultMessage: 'Image Slider',
  },
  Source: {
    id: 'Source',
    defaultMessage: 'Source',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  item: {
    id: 'Item',
    defaultMessage: 'Item',
  },
  items: {
    id: 'Items',
    defaultMessage: 'Items',
  },
  addItem: {
    id: 'Add item',
    defaultMessage: 'Add item',
  },
  showImageCaption: {
    id: 'Show image caption',
    defaultMessage: 'Show image caption',
  },
  showThumbnails: {
    id: 'Show thumbnails',
    defaultMessage: 'Show thumbnails',
  },
});

export const itemSchema = (props) => ({
  title: props.intl.formatMessage(messages.item),
  addMessage: props.intl.formatMessage(messages.addItem),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['href', 'title', 'description'],
    },
  ],

  properties: {
    href: {
      title: props.intl.formatMessage(messages.Source),
      widget: 'object_browser',
      mode: 'link',
      view: 'icons',
      selectedItemAttrs: [
        '@type',
        'Title',
        'Description',
        'getRemoteUrl',
        'hasPreviewImage',
        'image_field',
        'image_scales',
      ],
      allowExternals: true,
      widgetOptions: {
        pattern_options: {
          selectableTypes: ['Image'],
        },
      },
    },
    title: {
      title: props.intl.formatMessage(messages.title),
    },
    description: {
      title: props.intl.formatMessage(messages.description),
      widget: 'textarea',
    },
  },
  required: [],
});

export const ImageSliderSchema = (props) => ({
  title: props.intl.formatMessage(messages.ImageSlider),
  block: 'imageslider',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['slides', 'show-image-caption', 'show-thumbnails'],
    },
  ],
  properties: {
    slides: {
      widget: 'object_list',
      title: props.intl.formatMessage(messages.items),
      schema: itemSchema(props),
      activeObject: props.activeObject,
      setActiveObject: props.setActiveObject,
      default: [],
    },
    'show-image-caption': {
      title: props.intl.formatMessage(messages.showImageCaption),
      type: 'boolean',
      default: true,
    },
    'show-thumbnails': {
      title: props.intl.formatMessage(messages.showThumbnails),
      type: 'boolean',
      default: true,
    },
  },
  required: [],
});
