import { isInternalURL } from '@plone/volto/helpers';

export function isValidUrl(list: any, url: any) {
  if (isInternalURL(url)) {
    return true;
  }
  let targetURL;
  try {
    targetURL = new URL(url);
  } catch (e) {
    if (e instanceof TypeError) {
      return false;
    }
    throw e;
  }
  return list.includes(targetURL.hostname) || list.includes(targetURL.origin);
}
