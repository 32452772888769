import { defineMessages } from 'react-intl';

export const LocationMapMessages = defineMessages({
  oneOfThesePagesTitle: {
    id: 'LocationMap.oneOfThesePagesTitle',
    defaultMessage: 'Standorte der FHNW',
  },
  oneOfThesePagesDescription: {
    id: 'LocationMap.oneOfThesePagesDescription',
    defaultMessage:
      'Die Fachhochschule Nordwestschweiz FHNW umfasst neun Hochschulen, die sich auf die Hauptstandorte Basel, Brugg-Windisch, Muttenz und Olten verteilen.',
  },
  phone: {
    id: 'LocationMap.phone',
    defaultMessage: 'Telefon',
  },
  email: {
    id: 'LocationMap.email',
    defaultMessage: 'E-Mail',
  },
  moreInfosAboutLocation: {
    id: 'LocationMap.moreInfosAboutLocation',
    defaultMessage: 'Mehr Infos zum Standort',
  },
  calculateRoute: {
    id: 'LocationMap.calculateRoute',
    defaultMessage: 'Route berechnen',
  },
});
