import { useIntl } from 'react-intl';
import { IrfMessages as messages } from './index';

interface IrfAlertMessagesProps {
  dataState?: string;
  messageType?: string;
  totalEntrys?: number;
}

export const IrfAlertMessages: React.FC<IrfAlertMessagesProps> = ({
  dataState,
  messageType,
  totalEntrys,
}) => {
  const intl = useIntl();

  const dotLoader = () => {
    return (
      <div className="dot-loader">
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  };

  return (
    <>
      {dataState && (
        <li className="data-state" role="alert">
          {dataState === 'utd' && (
            <div className="alert-success fade-in">
              <p>{intl.formatMessage(messages.dataSyncUTD)}</p>
              {totalEntrys && <p>Total {totalEntrys}</p>}
            </div>
          )}
          {dataState === 'ood' && (
            <div className="alert-warning" role="alert">
              <p>{intl.formatMessage(messages.dataSyncOOD)}</p>
              {dotLoader()}
            </div>
          )}
          {dataState === 'failed' && (
            <div className="alert-warning" role="alert">
              <p>{intl.formatMessage(messages.dataSyncOOD)}</p>
              {dotLoader()}
            </div>
          )}
        </li>
      )}
      {messageType && (
        <>
          {messageType === 'selectCollection' && (
            <li className="no-data-seleced">
              <div className="alert-warning" role="alert">
                <p>{intl.formatMessage(messages.no_data_selected)}</p>
              </div>
            </li>
          )}

          {messageType === 'saveDatas' && (
            <li className="no-data-seleced">
              <div className="alert-warning" role="alert">
                <p>{intl.formatMessage(messages.saveDatas)}</p>
              </div>
            </li>
          )}

          {messageType === 'serverError500' && (
            <div className="data-state" role="alert">
              <div className="alert-warning">
                <p>{intl.formatMessage(messages.serverError500)}</p>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
