import React, { RefObject } from 'react';
import { Link } from '@fhnw/components/Link/Link';
import cx from 'classnames';

interface NavItem {
  remoteUrl?: string;
  title: string;
}

interface NavigationProps {
  firstLevel: number;
  handleNavigation: (
    level: 'first',
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    index: number,
    remoteUrl?: string,
  ) => void;
  navItems: NavItem[];
  firstLevelItemsRef: RefObject<(HTMLAnchorElement | null)[]>;
}

const Navigation: React.FC<NavigationProps> = ({
  firstLevel,
  handleNavigation,
  navItems,
  firstLevelItemsRef,
}) => {
  return (
    <nav className="navigation" role="navigation">
      <div>
        {navItems?.map((item, index) => (
          <div key={'dropdown-wrapper-' + index}>
            <div key={'dropdown-' + index} tabIndex={-1}>
              <Link
                href={item.remoteUrl ?? '#'}
                className={cx('btn btn-nav')}
                data-is-expandable={!item.remoteUrl}
                data-is-active={index === firstLevel}
                ref={(el) => {
                  if (firstLevelItemsRef.current) {
                    firstLevelItemsRef.current[index] =
                      el as HTMLAnchorElement | null;
                  }
                }}
                onClick={(
                  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                ) => handleNavigation('first', event, index, item.remoteUrl)}
              >
                <span>{item.title}</span>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </nav>
  );
};

export default Navigation;
