import React from 'react';

interface ProgressBarProps {
  progress: { current: number };
  index: number;
  currentSlide: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  index,
  currentSlide,
}) => {
  return (
    <div
      className="progress-bar"
      style={{
        width: index === currentSlide ? `${progress.current}%` : '0%',
      }}
    ></div>
  );
};

export default ProgressBar;
