import baseMessages from '@fhnw/messages/base';

function TableSchema(props) {
  const { intl } = props;
  return {
    title: intl.formatMessage(baseMessages.table),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['hideHeaders', 'sortable', 'striped'],
      },
    ],
    properties: {
      hideHeaders: {
        title: intl.formatMessage(baseMessages.hideHeaders),
        type: 'boolean',
      },
      sortable: {
        title: intl.formatMessage(baseMessages.sortable),
        type: 'boolean',
      },

      striped: {
        title: intl.formatMessage(baseMessages.striped),
        type: 'boolean',
      },
    },
    required: [],
  };
}

export function TableBlockSchema(props) {
  const { intl } = props;
  return {
    title: intl.formatMessage(baseMessages.table),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['table_field'],
      },
    ],
    properties: {
      table_field: {
        title: intl.formatMessage(baseMessages.table),
        widget: 'object',
        schema: TableSchema(props),
      },
    },

    required: [],
  };
}

export default TableBlockSchema;
