import { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import cx from 'classnames';
import { Icon } from '@plone/volto/components';
import { useDetectClickOutside, flattenToAppURL } from '@plone/volto/helpers';

import { SearchTabs } from '@fhnw/components/GlobalSearch/SearchTabs';
import { ResultItem } from '@fhnw/components/GlobalSearch/ResultItems';
import { solrSearchContent } from '@kitconcept/volto-solr/actions';
import { getSuggestions } from '@kitconcept/volto-solr/components/theme/SolrSearch/getSuggestions';
import { getCollationMisspellings } from '@kitconcept/volto-solr/components/theme/SolrSearch/getCollationMisspellings';

import arrowSvg from '@fhnw/icons/small-arrow.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import zoomSVG from '@plone/volto/icons/zoom.svg';
import { NavItem } from '@fhnw/types/navItem';

const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  close: {
    id: 'Close Global Search',
    defaultMessage: 'Close Global Search',
  },
  searchResults: {
    id: 'Search results',
    defaultMessage: 'Search results',
  },
  resultsFor: {
    id: 'Results for',
    defaultMessage: 'Results for',
  },
  noResultsFoundFor: {
    id: 'No results found for',
    defaultMessage: 'No results found for',
  },
  introStrong: {
    id: 'Type a search term',
    defaultMessage: 'Type a search term',
  },
  intro: {
    id: 'and search continuing education,degree programmes, events, documents and other content.',
    defaultMessage:
      'and search continuing education,degree programmes, events, documents and other content.',
  },
  continueEducation: {
    id: 'Continueeducation',
    defaultMessage: 'Search continuing education',
  },
  news: {
    id: 'Search news',
    defaultMessage: 'Search news',
  },
  degreeProgram: {
    id: 'Search degree program',
    defaultMessage: 'Search degree program',
  },
  event: {
    id: 'Search events',
    defaultMessage: 'Search events',
  },
  people: {
    id: 'Search people',
    defaultMessage: 'Search people',
  },
  suggestions: {
    id: 'Search suggestions',
    defaultMessage: 'Search suggestions',
  },
  noResult: {
    id: 'Your search request has no results',
    defaultMessage: 'Your search request has no results',
  },
  newSearch: {
    id: 'Please enter a new search request',
    defaultMessage: 'Please enter a new search request',
  },
  placeholder: {
    id: 'Search: type here',
    defaultMessage: 'Search: type here',
  },
  moreResults: {
    id: 'More results',
    defaultMessage: 'More results',
  },
  of: {
    id: 'of',
    defaultMessage: 'of',
  },
});

const emptyArray: any[] = [];

type GlobalSearchPanelProps = {
  closeGlobalSearch: () => void;
  isMobile: boolean;
};

const GlobalSearchPanel = ({
  closeGlobalSearch,
  isMobile,
}: GlobalSearchPanelProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const searchbarIntroRef = useRef<HTMLDivElement | null>(null);
  const [searchWord, setSearchWord] = useState('');
  const [groupSelect, setGroupSelect] = useState(0);
  const searchNavItems = useSelector(
    (state: any) =>
      state.content?.data?.['@components']?.searchnav || emptyArray,
  );

  const result = useSelector((state: any) => state.solrsearch);
  const suggestions = getSuggestions(result?.spellcheck?.suggestions || []);

  const showSuggestions = suggestions?.length > 0;

  const totalResult = result?.total;
  const resultItems = result?.items || [];
  const showResultItems = resultItems.length > 0 && searchWord.length > 2;
  const totalResultItems = result?.items?.length;
  const b_size = 9;
  const collationMisspellings = getCollationMisspellings(
    result?.collationMisspellings,
  );
  const correctedSearchWord =
    showResultItems && collationMisspellings[searchWord] !== searchWord
      ? collationMisspellings[searchWord]
      : null;

  //Close the panel when the user resize the window.
  useEffect(() => {
    const resizeObserver = new ResizeObserver(([entry]) => {
      if (!entry) return;
      const currentWidth = entry.contentRect.width;

      if (
        (isMobile && currentWidth > 1023) ||
        (!isMobile && currentWidth < 1023)
      ) {
        closeGlobalSearch();
      }
    });

    resizeObserver.observe(document.body);

    return () => resizeObserver.disconnect();
  }, [closeGlobalSearch, isMobile]);

  const onSolrSearch = useCallback(
    (searchWord: string, groupSelect: number, b_size: number) => {
      if (searchWord.length > 1) {
        dispatch(
          solrSearchContent('/', {
            SearchableText: searchWord,
            doEmptySearch: true,
            group_select: groupSelect,
            b_size,
            lang: intl.locale,
            collate: false,
          }),
        );
      }
    },
    [dispatch, intl.locale],
  );

  useEffect(() => {
    onSolrSearch(searchWord, groupSelect, b_size);
  }, [dispatch, groupSelect, searchWord, b_size, onSolrSearch]);

  const onMoreResult = () => {
    onSolrSearch(searchWord, groupSelect, totalResultItems + b_size);
  };

  const globalSearchPanelContainer = useDetectClickOutside({
    onTriggered: closeGlobalSearch,
    triggerKeys: ['Escape'],
    disableClick: undefined,
    disableKeys: undefined,
    allowAnyKey: undefined,
  });

  const onSuggestionsClick = (
    e: React.KeyboardEvent<HTMLDivElement>,
    value: string,
  ) => {
    if (e.key === 'Enter') {
      setSearchWord(value);
    }
  };

  useEffect(() => {
    const element = searchbarIntroRef.current;
    if (!element) return;

    const handleAnimationEnd = (event: AnimationEvent) => {
      if (event.animationName === 'fade-out') {
        element.style.display = 'none';
      } else {
        // Fading in. Put the input field in focus.
        // Note, doing this from useEffect does not work.
        inputRef.current?.focus();
      }
    };

    element.addEventListener('animationend', handleAnimationEnd);

    return () => {
      element.removeEventListener('animationend', handleAnimationEnd);
    };
  }, []);

  return (
    <div
      className={cx('global-searchbar-panel', {
        mobile: isMobile,
      })}
      ref={globalSearchPanelContainer}
    >
      <div className="searchbar-container">
        <form
          autoComplete="off"
          id="searchbar-input"
          onSubmit={(e) => e.preventDefault()}
        >
          <label htmlFor="searchbar-input" className="visually-hidden">
            {intl.formatMessage(messages.search)}
          </label>{' '}
          <input
            ref={inputRef}
            id="searchbar-input"
            type="text"
            placeholder={intl.formatMessage(messages.placeholder)}
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
          />{' '}
          <button type="button" onClick={closeGlobalSearch}>
            <Icon
              className="global-search-icon"
              name={clearSVG}
              size="20px"
              fill="#000"
            />{' '}
            <span className="visually-hidden">
              {intl.formatMessage(messages.close)}
            </span>
          </button>
        </form>
      </div>{' '}
      <div
        className={cx('search-bar-intro', {
          'fade-in': !searchWord,
          'fade-out': searchWord,
        })}
        style={{ display: !searchWord ? 'block' : 'inherit' }}
        ref={searchbarIntroRef}
      >
        <p>
          <strong>{intl.formatMessage(messages.introStrong)}</strong>{' '}
          {intl.formatMessage(messages.intro)}
        </p>
        {searchNavItems &&
          searchNavItems.map((item: NavItem) => {
            return (
              <Link
                key={item.remoteUrl}
                to={flattenToAppURL(item.remoteUrl)}
                onClick={closeGlobalSearch}
              >
                {item.title}{' '}
                <Icon
                  className="small-arrow-icon"
                  name={arrowSvg}
                  size="10px"
                  fill="#000"
                />{' '}
              </Link>
            );
          })}
      </div>
      {searchWord && (
        <div id="results" className="global-search-result-container">
          {showResultItems && (
            <div className="search-tab-categories">
              <SearchTabs
                groupSelect={groupSelect}
                setGroupSelect={(groupSelect: number) =>
                  setGroupSelect(groupSelect)
                }
                facetGroups={result?.facetGroups}
              />
            </div>
          )}
          {correctedSearchWord && (
            <div className="searchbar-corrected-title">
              <div className="searchbar-corrected-title-line1">
                {intl.formatMessage(messages.resultsFor)}{' '}
                <strong>"{correctedSearchWord}"</strong>
              </div>
              <div className="searchbar-corrected-title-line2">
                {intl.formatMessage(messages.noResultsFoundFor)}{' '}
                <strong>"{searchWord}"</strong>
              </div>
            </div>
          )}
          {showSuggestions && (
            <div className="search-suggestions">
              <div className="searchbar-title">
                <p>{intl.formatMessage(messages.suggestions)}</p>
              </div>
              <ul className="suggestions-container">
                {suggestions.map((suggestion: any, index: number) => (
                  <li key={index}>
                    <div
                      role="button"
                      tabIndex={0}
                      onKeyDown={(event) =>
                        onSuggestionsClick(event, suggestion)
                      }
                      onClick={() => setSearchWord(suggestion)}
                    >
                      <Icon className="zoom-icon" name={zoomSVG} size="30px" />{' '}
                      {suggestion}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {showResultItems && (
            <>
              <div className="searchbar-title">
                <p>{intl.formatMessage(messages.searchResults)}</p>
              </div>
              <div>
                {resultItems.map((item: any, index: number) => (
                  <div key={`${index}-${item['@id']}`}>
                    <ResultItem
                      item={item}
                      closeGlobalSearch={closeGlobalSearch}
                    />
                  </div>
                ))}
              </div>
              <div className="more-result-container">
                <div className="more-container">
                  {totalResultItems >= totalResult ? null : (
                    <button onClick={onMoreResult}>
                      {totalResultItems >= totalResult
                        ? ''
                        : intl.formatMessage(messages.moreResults)}
                    </button>
                  )}
                  <span>{`${totalResultItems} ${intl.formatMessage(messages.of)} ${totalResult}`}</span>
                </div>
              </div>
            </>
          )}

          {!showResultItems && searchWord.length > 2 && (
            <div className="noresult-container">
              <p>{intl.formatMessage(messages.noResult)}</p>
              <span>{intl.formatMessage(messages.newSearch)}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GlobalSearchPanel;
