import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import AlignMenu from '@fhnw/components/SlateDropDown/DropDownMenu.jsx';
import alignCenterSVG from '@plone/volto/icons/align-center.svg';
import alignLeftSVG from '@plone/volto/icons/align-left.svg';
import alignRightSVG from '@plone/volto/icons/align-right.svg';
import alignJustifySVG from '@plone/volto/icons/align-justify.svg';

const messages = defineMessages({
  align: {
    id: 'Align',
    defaultMessage: 'Align',
  },
  align_left: {
    id: 'Left',
    defaultMessage: 'Left',
  },
  align_center: {
    id: 'Center',
    defaultMessage: 'Center',
  },
  align_right: {
    id: 'Right',
    defaultMessage: 'Right',
  },
  align_justify: {
    id: 'Justify',
    defaultMessage: 'Justify',
  },
});

const ALIGN_OPTIONS = [
  {
    cssClass: 'text-left',
    title: messages.align_left,
    icon: alignLeftSVG,
    isBlock: true,
  },
  {
    cssClass: 'text-center',
    title: messages.align_center,
    icon: alignCenterSVG,
    isBlock: true,
  },
  {
    cssClass: 'text-end',
    title: messages.align_right,
    icon: alignRightSVG,
    isBlock: true,
  },
  {
    cssClass: 'text-justify',
    title: messages.align_justify,
    icon: alignJustifySVG,
    isBlock: true,
  },
];

export const AlignElement = ({ attributes, children, element }) => {
  return (
    <p {...attributes} align="test">
      {children}
    </p>
  );
};

const AlignButton = (props) => {
  const intl = useIntl();
  return (
    <AlignMenu
      {...props}
      OPTIONS={ALIGN_OPTIONS}
      title={intl.formatMessage(messages.align)}
    />
  );
};

export default function install(config) {
  const { slate } = config.settings;

  slate.buttons.align = AlignButton;

  slate.elements['align'] = AlignElement;

  //lo metto come primo elemento della toolbar
  slate.toolbarButtons = [...slate.toolbarButtons, 'separator', 'align'];
  slate.expandedToolbarButtons = [
    ...slate.expandedToolbarButtons,
    'separator',
    'align',
  ];

  //il deserializer html per aggiungere le classi è definito in deserializers.js
  return config;
}
