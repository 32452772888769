import { useMemo, useState } from 'react';
import { irfConfig } from '../IrfConfig';
import { InternalIrfPublicationProps } from './fetchData';
import { extractTypeCodes, filterByTypeCode } from '../index';

export interface TabState {
  publicationTab: InternalIrfPublicationProps[];
  projectTab: InternalIrfPublicationProps[];
  presentationTab: InternalIrfPublicationProps[];
  supervisedThesesTab: InternalIrfPublicationProps[];
  othersTab: InternalIrfPublicationProps[];
}

export const useTabs = (
  jsonData: InternalIrfPublicationProps[],
  selectedTypes: string[] | null,
  person: string | null,
) => {
  const selectedPublicationTypes = extractTypeCodes(selectedTypes || []);

  const tabs = useMemo(() => {
    const supervisedTheses = filterByTypeCode(
      jsonData,
      selectedPublicationTypes,
      irfConfig.supervisedThesesTab,
    );
    return {
      publicationTab: [
        ...filterByTypeCode(
          jsonData,
          selectedPublicationTypes,
          irfConfig.publicationTab,
        ),
        ...(person && jsonData.some((entry) => entry.authors?.includes(person))
          ? supervisedTheses
          : []),
      ],
      projectTab: filterByTypeCode(
        jsonData,
        selectedPublicationTypes,
        irfConfig.projectTab,
      ),
      presentationTab: filterByTypeCode(
        jsonData,
        selectedPublicationTypes,
        irfConfig.presentationTab,
      ),
      supervisedThesesTab:
        person && jsonData.some((entry) => entry.authors?.includes(person))
          ? []
          : supervisedTheses,
      othersTab: filterByTypeCode(
        jsonData,
        selectedPublicationTypes,
        irfConfig.othersTab,
      ),
    };
  }, [person, jsonData, selectedPublicationTypes]);

  const [activeTab, setActiveTab] = useState<keyof TabState>(() => {
    // If person exists in any publication's authors, default to publicationTab
    if (person && jsonData.some((entry) => entry.authors?.includes(person))) {
      return 'publicationTab';
    }

    // Otherwise use first tab with data
    const firstTabWithData = Object.entries(tabs).find(
      ([_, entries]) => entries.length > 0,
    );
    return (firstTabWithData?.[0] as keyof TabState) || 'publicationTab';
  });

  return { tabs, activeTab, setActiveTab };
};

export const useActiveTabs = (
  tabs: TabState,
  showOnlyPeerReviewed: boolean,
  excludedPublications: string[] = [],
) => {
  return useMemo(
    () =>
      Object.entries(tabs).filter(([tabKey, entries]) => {
        const filteredEntries = entries.filter(
          (entry: InternalIrfPublicationProps) =>
            !excludedPublications.includes(entry.url_handle?.toString() || ''),
        );
        return (
          filteredEntries.length > 0 &&
          (tabKey === 'projectTab' ||
            !showOnlyPeerReviewed ||
            filteredEntries.some(
              (entry: InternalIrfPublicationProps) => entry.peer_reviewed,
            ))
        );
      }),
    [tabs, showOnlyPeerReviewed, excludedPublications],
  );
};
