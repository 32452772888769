import {
  FieldError,
  TextArea,
  Label,
  Text,
  TextField as RACTextField,
  TextFieldProps as RACTextFieldProps,
  ValidationResult,
} from 'react-aria-components';

export type TextAreaFieldProps = {
  /**
   * A label for the input field.
   */
  label?: string;
  /**
   * A label, indicating that the field is required.
   */
  labelRequired?: string;
  /**
   * Description or additional information about the input field.
   */
  description?: string;
  /**
   * An error message to display when validation fails.
   * It can be either a string or a function that takes validation result as argument and returns a string.
   */
  errorMessage?: string | ((validation: ValidationResult) => string);
  /**
   * A placeholder text displayed when the input field is empty.
   */
  placeholder?: string;
  /**
   * Indicates whether the input field is required or not.
   */
  isRequired?: boolean;
} & RACTextFieldProps;

const TextAreaField = ({
  label,
  labelRequired,
  description,
  errorMessage,
  isRequired = false,
  ...props
}: TextAreaFieldProps) => {
  return (
    <RACTextField {...props}>
      <Label>
        {label}
        {isRequired && <Text slot="description">{labelRequired}</Text>}
      </Label>
      <FieldError>{errorMessage}</FieldError>
      <TextArea />
      {description && <Text slot="description">{description}</Text>}
    </RACTextField>
  );
};

export default TextAreaField;
