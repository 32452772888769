import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Block: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  Title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  Image: {
    id: 'Show image',
    defaultMessage: 'Show image',
  },
  Text: {
    id: 'Show text',
    defaultMessage: 'Show text',
  },
  Information: {
    id: 'Show extended information',
    defaultMessage: 'Show extended information',
  },
  Faculty: {
    id: 'Faculty Filter',
    defaultMessage: 'Faculty Filter',
  },
  Type: {
    id: 'Type Filter',
    defaultMessage: 'Type Filter',
  },
  Location: {
    id: 'Show Location Filter',
    defaultMessage: 'Show Location Filter',
  },
  School: {
    id: 'School Filter',
    defaultMessage: 'School Filter',
  },
  DefaultTemplate: {
    id: 'Template',
    defaultMessage: 'Template',
  },
  Table: {
    id: 'Table',
    defaultMessage: 'Table',
  },
  Grid: {
    id: 'Grid Solr Schema',
    defaultMessage: 'Grid',
  },
  List: {
    id: 'List',
    defaultMessage: 'List',
  },
  BatchSize: {
    id: 'Batch Size',
    defaultMessage: 'Batch Size',
  },
  TemplateOptions: {
    id: 'Template Options',
    defaultMessage: 'Template Options',
  },
  FilterOptions: {
    id: 'Filter Options',
    defaultMessage: 'Filter Options',
  },
  FieldOfStudy: {
    id: 'Preselected Faculties',
    defaultMessage: 'Preselected Faculties',
  },
  DegreeProgramType: {
    id: 'Preselected Types',
    defaultMessage: 'Preselected Types',
  },
  City: {
    id: 'Preselected Locations',
    defaultMessage: 'Preselected Locations',
  },
  Univesity: {
    id: 'Preselected Schools',
    defaultMessage: 'Preselected Schools',
  },
  From: {
    id: 'FromNewsSearch',
    defaultMessage: 'From',
  },
  To: {
    id: 'To',
    defaultMessage: 'To',
  },
  Subjects: {
    id: 'Keyword Filter',
    defaultMessage: 'Keyword Filter',
  },
  Tags: {
    id: 'Tags',
    defaultMessage: 'Tags',
  },
});
const filterOptions = {
  'degree-programmes': [
    'faculty-filter',
    'fieldsofstudy',
    'type-filter',
    'degreeprogramtype',
    'location-filter',
    'citydegree',
    'batch_size',
  ],
  course: [
    'faculty-filter',
    'fieldsofstudycourse',
    'type-filter',
    'continuingeducationtype',
    'location-filter',
    'citycourse',
    'batch_size',
  ],
  news: ['school-fliter', 'university', 'batch_size'],
  events: [
    'school-fliter',
    'universityevents',
    'type-filter',
    'eventtype',
    'location-filter',
    'city',
    'batch_size',
  ],
  persons: ['faculty-filter', 'fieldsofstudyperson', 'batch_size'],
};

const templateOptions = {
  'degree-programmes': ['template'],
  course: ['template'],
  news: ['default-template', 'from', 'to'],
  events: ['default-template'],
};

export const SolarSearchSchema = (props) => {
  const { intl } = props;
  const selectedTemplateOptions = props.data.variation ?? 'degree-programmes';

  return {
    title: intl.formatMessage(messages.Block),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [],
      },
      {
        id: 'template-options',
        title: intl.formatMessage(messages.TemplateOptions),
        fields: [
          ...(props.data.variation === 'persons'
            ? ['show-image']
            : [
                ...templateOptions[selectedTemplateOptions],
                'show-image',
                'show-text',
                'show-information',
              ].filter(Boolean)),
        ],
      },
      {
        id: 'filter-options',
        title: intl.formatMessage(messages.FilterOptions),
        fields: [
          ...(props.data.variation
            ? filterOptions[props.data.variation]
            : filterOptions['degree-programmes']),
        ],
      },
      {
        id: 'keywords-option',
        title: intl.formatMessage(messages.Tags),
        fields: ['keywords'],
      },
    ],

    properties: {
      template: {
        title: intl.formatMessage(messages.DefaultTemplate),
        widget: 'select',
        choices: [
          ['grid', intl.formatMessage(messages.Grid)],
          ['list', intl.formatMessage(messages.List)],
          ['table', intl.formatMessage(messages.Table)],
        ],
        default: 'table',
      },
      'default-template': {
        title: intl.formatMessage(messages.DefaultTemplate),
        widget: 'select',
        choices: [
          ['grid', intl.formatMessage(messages.Grid)],
          ['list', intl.formatMessage(messages.List)],
        ],
        default: 'list',
      },
      'faculty-filter': {
        title: intl.formatMessage(messages.Faculty),
        type: 'boolean',
        default: true,
      },
      'type-filter': {
        title: intl.formatMessage(messages.Type),
        type: 'boolean',
        default: true,
      },
      'location-filter': {
        title: intl.formatMessage(messages.Location),
        type: 'boolean',
        default: true,
      },
      'school-fliter': {
        title: intl.formatMessage(messages.School),
        type: 'boolean',
        default: true,
      },
      batch_size: {
        title: intl.formatMessage(messages.BatchSize),
        type: 'number',
        minimum: 1,
        default: 20,
      },
      'show-image': {
        title: intl.formatMessage(messages.Image),
        type: 'boolean',
        default: true,
      },
      'show-text': {
        title: intl.formatMessage(messages.Text),
        type: 'boolean',
        default: true,
      },
      'show-information': {
        title: intl.formatMessage(messages.Information),
        type: 'boolean',
        default: false,
      },
      from: {
        title: intl.formatMessage(messages.From),
        widget: 'date',
      },
      to: {
        title: intl.formatMessage(messages.To),
        widget: 'date',
      },
      university: {
        title: intl.formatMessage(messages.Univesity),
        widget: 'select',
        isMulti: true,
        vocabulary: { '@id': 'collective.taxonomy.university' },
      },
      universityevents: {
        title: intl.formatMessage(messages.Univesity),
        widget: 'select',
        isMulti: true,
        vocabulary: { '@id': 'collective.taxonomy.university' },
      },
      eventtype: {
        title: intl.formatMessage(messages.DegreeProgramType),
        widget: 'select',
        isMulti: true,
        vocabulary: { '@id': 'collective.taxonomy.eventtype' },
      },
      city: {
        title: intl.formatMessage(messages.City),
        widget: 'select',
        isMulti: true,
        vocabulary: { '@id': 'collective.taxonomy.city' },
      },
      citydegree: {
        title: intl.formatMessage(messages.City),
        widget: 'select',
        isMulti: true,
        vocabulary: { '@id': 'collective.taxonomy.city' },
      },
      citycourse: {
        title: intl.formatMessage(messages.City),
        widget: 'select',
        isMulti: true,
        vocabulary: { '@id': 'collective.taxonomy.city' },
      },
      fieldsofstudy: {
        title: intl.formatMessage(messages.FieldOfStudy),
        widget: 'select',
        isMulti: true,
        vocabulary: { '@id': 'collective.taxonomy.fieldsofstudy' },
      },
      fieldsofstudyperson: {
        title: intl.formatMessage(messages.FieldOfStudy),
        widget: 'select',
        isMulti: true,
        vocabulary: { '@id': 'collective.taxonomy.fieldsofstudy' },
      },
      fieldsofstudycourse: {
        title: intl.formatMessage(messages.FieldOfStudy),
        widget: 'select',
        isMulti: true,
        vocabulary: { '@id': 'collective.taxonomy.fieldsofstudy' },
      },
      degreeprogramtype: {
        title: intl.formatMessage(messages.DegreeProgramType),
        widget: 'select',
        isMulti: true,
        vocabulary: { '@id': 'collective.taxonomy.degreeprogramtype' },
      },
      continuingeducationtype: {
        title: intl.formatMessage(messages.DegreeProgramType),
        widget: 'select',
        isMulti: true,
        vocabulary: { '@id': 'collective.taxonomy.continuingeducationtype' },
      },
      keywords: {
        title: intl.formatMessage(messages.Subjects),
        widget: 'token',
        items: {
          vocabulary: { '@id': 'plone.app.vocabularies.Keywords' },
        },
      },
    },
    required: [],
  };
};
