import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import FormFieldWrapper from '@plone/volto/components/manage/Widgets/FormFieldWrapper';
import Button from '@fhnw/components/Button/Button';

const messages = defineMessages({
  large: {
    id: 'Gross',
    defaultMessage: 'Gross',
  },
  half: {
    id: 'Halb',
    defaultMessage: 'Halb',
  },
  third: {
    id: 'Drittel',
    defaultMessage: 'Drittel',
  },
  portrait: {
    id: 'Portrait',
    defaultMessage: 'Portrait',
  },
});
//props definition as needed in typescript
export interface FormatWidgetProps {
  actions?: any;
  actionsInfoMap?: any;
  defaultAction: string;
  id: string;
  onChange: Function;
  value: string;
  disabled: boolean;
  default: string;
}

const FormatWidget: React.FC<FormatWidgetProps> = (props) => {
  const { id, onChange, defaultAction, value, disabled } = props;

  React.useEffect(() => {
    if (!props.value && props.default) {
      props.onChange(props.id, props.default);
    }
  });
  const intl = useIntl();
  const actions = ['large', 'half', 'third', 'portrait'];
  const defaultActionsInfo = () => ({
    large: intl.formatMessage(messages.large),
    half: intl.formatMessage(messages.half),
    third: intl.formatMessage(messages.third),
    portrait: intl.formatMessage(messages.portrait),
  });
  const actionsInfo: any = {
    ...defaultActionsInfo(),
  };

  return (
    <FormFieldWrapper {...props} className="format-widget">
      <div className="format-buttons">
        {actions.map((action) => (
          <div key={action}>
            <Button
              className={
                (action === defaultAction && !value) || value === action
                  ? 'active-format-button'
                  : 'format-button'
              }
              aria-label={actionsInfo[action]}
              onPress={() => onChange(id, action)}
              isActive={
                (action === defaultAction && !value) || value === action
              }
              isDisabled={disabled}
              styles={''}
            >
              <div className="format-text">{actionsInfo[action]}</div>
            </Button>
          </div>
        ))}
      </div>
    </FormFieldWrapper>
  );
};

export default FormatWidget;
