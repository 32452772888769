import { TeaserProps } from '@fhnw/components/Teaser/Teaser';
import { Image } from '@plone/volto/components';
import dateFormat, {
  getShortDates,
} from '@fhnw/components/DateFormat/dateFormat';
import parseISOString from '@fhnw/components/DateFormat/parseISOString';
import { getItemReference } from '@fhnw/helpers/Url/UrlHelper';

export interface VocabularyItem {
  label: string;
  value: string;
}
export interface Item {
  '@id': string;
  title?: string;
  description?: string;
  image_field?: string;
  image_caption?: string;
  category?: string;
  start?: string;
  end?: string;
  whole_day?: boolean;
  open_end?: boolean;
  location?: string;
  teaser_title?: string;
  teaser_text?: string;
  review_state?: string;
  allowedRolesAndUsers?: string[];
  name_affix1?: string;
  name_affix2?: string;
  [key: string]: any;
}

export interface TemplateProps {
  items?: Item[];
  isEditMode?: boolean;
  headlineTag?: string;
  showTeaserImage?: boolean;
  showTeaserText?: boolean;
  showTeaserHeading?: boolean;
  showExtendedInfo?: boolean;
  buttonText?: string;
  buttonLink?: string;
  [key: string]: any;
  showWeekDay?: boolean;
  timeOnly?: boolean;
  locale?: string;
  showPrivatePages?: boolean;
}

/**
 * Maps an item to teaser properties based on the provided template and data.
 *
 * @param item - The item to be mapped.
 * @param data - The template properties containing configuration options.
 * @param template - The template string to be used if the item does not have a template.
 * @param withExtras - A Solr result item contains extras, which should be merged with the item. By default, this is true.
 * @param token - Depending on if the user is logged in or not, a different link can be generated
 * @returns The mapped teaser properties.
 *
 * @remarks
 * This function processes the item and data to generate teaser properties,
 * including options for displaying teaser images, text, and additional information.
 * It also formats date ranges and handles localization.
 *
 * @example
 * ```typescript
 * const teaserProps = mapItemToTeaserProps(item, data, 'default');
 * ```
 *
 * @see {@link Item}
 * @see {@link TemplateProps}
 * @see {@link TeaserProps}
 */
export const mapItemToTeaserProps = (
  item: Item,
  data: TemplateProps,
  template: string,
  withExtras: boolean = true,
  token?: string,
): TeaserProps => {
  if (withExtras) {
    item = { ...item.extras, ...item };
  }
  const showTeaserImage = data.showTeaserImage !== false;
  const showTeaserText = data.showTeaserText !== false;
  const showTeaserHeading = data.showTeaserHeading !== false;
  const showExtendedInfo = data.showExtendedInfo !== false;

  const isTeaserTitleNullOrEmpty =
    !item?.teaser_title || item?.teaser_title?.trim() === '';
  const isTeaserTextNullOrEmpty =
    !item?.teaser_text || item?.teaser_text?.trim() === '';

  const { showWeekDay, locale, timeOnly } = data;
  const { open_end: openEnd, whole_day: wholeDay } = item;
  const dateFormatOptions = {
    openEnd,
    wholeDay,
    showWeekDay,
    // If locale is missing, set it to 'en'
    locale: locale || 'en',
    timeOnly,
  };
  // Start date is either item.start or item.effective
  const startAsIso = item.start || item.effective || '';
  const endAsIso = item.end || '';
  const start = dateFormat({
    ...dateFormatOptions,
    start: parseISOString(startAsIso),
  });
  const end = dateFormat({
    ...dateFormatOptions,
    start: parseISOString(endAsIso),
  });
  const date = dateFormat({
    ...dateFormatOptions,
    start: parseISOString(startAsIso),
    end: parseISOString(endAsIso),
  });

  return {
    isPrivate:
      item.allowedRolesAndUsers && Array.isArray(item.allowedRolesAndUsers)
        ? item.allowedRolesAndUsers?.includes('Anonymous') === false
          ? true
          : false
        : undefined,
    reference: data.isEditMode ? undefined : getItemReference(item, token),
    name_affix1: item.name_affix1,
    name_affix2: item.name_affix2,
    title: isTeaserTitleNullOrEmpty ? item.title : item.teaser_title,
    text: showTeaserText
      ? isTeaserTextNullOrEmpty
        ? item.description
        : item.teaser_text
      : undefined,
    imageComponent: showTeaserImage ? Image : undefined,
    imageComponentProps: showTeaserImage
      ? {
          item: item,
          src: null,
          imageField: item.image_field,
          alt: '',
          loading: 'lazy',
          responsive: true,
        }
      : undefined,
    category: showTeaserHeading ? item?.category : undefined,
    dateRange:
      start && end
        ? getShortDates({
            ...dateFormatOptions,
            start: parseISOString(startAsIso),
            end: parseISOString(endAsIso),
          })
        : undefined,
    dateAndLocation:
      start || end || item.location
        ? {
            date,
            location: item.location,
          }
        : undefined,
    address: showExtendedInfo
      ? {
          tel1: item?.phone1,
          tel2: item?.phone2,
          tel1_suffix: item?.phone1_suffix,
          tel2_suffix: item?.phone2_suffix,
          email: item?.email,
        }
      : undefined,
    template: item?.template ?? template ?? 'default',
  };
};

export const mapItemAttrsByContenttype = (
  item: Item,
  organisation_entities?: VocabularyItem[],
  phonesuffix_tax?: VocabularyItem[],
  dateline_tax?: VocabularyItem[],
  props?: TemplateProps,
): Item => {
  const new_item = { ...item };
  new_item.category = getVocabLabel(
    dateline_tax,
    item?.taxonomy_dateline && item.taxonomy_dateline[0],
    item?.category,
  );

  switch (item['@type']) {
    case 'FHNWEvent':
    case 'InfoEvent':
    case 'ConEduEvent': {
      new_item.open_end = item.open_end;
      new_item.whole_day = item.whole_day;
      new_item.location = item.event_location_short;
      break;
    }
    case 'FHNWNews':
      new_item.template = 'news';

      new_item.start = item.effective;
      // we don't want to show the time in the teaser
      new_item.whole_day = true;
      new_item.location =
        props?.showExtendedInfo === true
          ? getVocabLabel(
              organisation_entities,
              item?.organisation_entities && item.organisation_entities[0],
              undefined,
            )
          : undefined;
      break;
    case 'Contact':
      new_item.template = 'profile';
      new_item.description = item?.role;
      new_item.phone1_suffix = getVocabLabel(
        phonesuffix_tax,
        item?.phone1_suffix,
        undefined,
      );
      new_item.phone2_suffix = getVocabLabel(
        phonesuffix_tax,
        item?.phone2_suffix,
        undefined,
      );
      break;
    case 'TeamContact':
      new_item.template = 'profile';
      new_item.phone1_suffix = getVocabLabel(
        phonesuffix_tax,
        item?.phone1_suffix,
        undefined,
      );
      new_item.phone2_suffix = getVocabLabel(
        phonesuffix_tax,
        item?.phone2_suffix,
        undefined,
      );
      break;
    default:
      new_item.effective = undefined;
      break;
  }
  return new_item;
};

const getVocabLabel = (
  vocab?: VocabularyItem[],
  value_to_find?: string,
  default_value?: string,
) => {
  return vocab && value_to_find
    ? vocab.find((vocab_item) => vocab_item.value === value_to_find)?.label
    : default_value;
};
