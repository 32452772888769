import { IntlShape, defineMessages, useIntl } from 'react-intl';
import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const messages = defineMessages({
  item: {
    id: 'Contact item',
    defaultMessage: 'Contact item',
  },
  addItem: {
    id: 'Add contact item',
    defaultMessage: 'Add contact item',
  },
  labelFunction: {
    id: 'Function',
    defaultMessage: 'Function',
  },
  labelSelectInstitution: {
    id: 'Select contact',
    defaultMessage: 'Select contact',
  },
});

const peopleFolders: { [key: string]: string } = {
  de: '/de/personen',
  en: '/en/people',
  fr: '/fr/personnes',
};

const ItemSchema = (intl: IntlShape) => {
  return {
    title: intl.formatMessage(messages.item),
    addMessage: intl.formatMessage(messages.addItem),
    properties: {
      href: {
        title: intl.formatMessage(messages.labelSelectInstitution),
        widget: 'object_browser',
        mode: 'link',
        widgetOptions: {
          pattern_options: { selectableTypes: ['Contact', 'TeamContact'] },
        },
        selectedItemAttrs: ['title'],
        allowExternals: false,
        initialPath: peopleFolders[intl.locale] || '/',
      },
      function: {
        title: intl.formatMessage(messages.labelFunction),
        widget: 'select',
        widgetOptions: {
          vocabulary: {
            '@id': '/@vocabularies/collective.taxonomy.function',
          },
        },
      },
    },
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.item),
        fields: ['href', 'function'],
      },
    ],
    required: ['text'],
  };
};

export interface ContactsWidgetProps {
  value?: { items?: Array<any> };
  default?: { items?: Array<any> };
  onChange: (id: string, value: { items: Array<any> }) => void;
}

const ContactsWidget: React.FC<ContactsWidgetProps> = (props) => {
  const intl = useIntl();
  const itemSchema = ItemSchema(intl);

  const { value, default: defaultValue, onChange, ...rest } = props;

  return (
    <ObjectListWidget
      schema={itemSchema}
      {...rest}
      value={value?.items || defaultValue?.items || []}
      onChange={(id: string, value: Array<any>) =>
        onChange(id, { items: value })
      }
    />
  );
};

export default ContactsWidget;
