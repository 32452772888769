import { IntlShape } from 'react-intl';
import { FollowUsBlockMessages } from './FollowUsMessages';

const FollowUsBlockSchema = (intl: IntlShape) => ({
  title: intl.formatMessage(FollowUsBlockMessages.blockTitle),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'description', 'folder', 'query'],
    },
  ],
  properties: {
    title: {
      title: intl.formatMessage(FollowUsBlockMessages.title),
      widget: 'string',
    },
    description: {
      title: intl.formatMessage(FollowUsBlockMessages.description),
      widget: 'textarea',
    },
    folder: {
      title: intl.formatMessage(FollowUsBlockMessages.selectFolder),
      widget: 'object_browser',
      mode: 'link',
      widgetOptions: {
        pattern_options: { selectableTypes: ['Document', 'Page'] },
      },
      allowExternals: false,
      allowPaths: true,
      initialPath: '/',
    },
    query: {
      title: intl.formatMessage(FollowUsBlockMessages.query),
      widget: 'query',
      description: intl.formatMessage(FollowUsBlockMessages.queryDescription),
      widgetOptions: {
        pattern_options: {
          selectableTypes: ['Link'],
        },
      },
    },
  },
  required: ['folder'],
});

export default FollowUsBlockSchema;
