// SemanticUI-free pre-@plone/components
import { defineMessages, useIntl } from 'react-intl';
import config from '@plone/volto/registry';
import { UniversalLink } from '@plone/volto/components';
import { toBackendLang } from '@plone/volto/helpers';
import variables from '@fhnw/theme/_export.module.scss';
import baseMessages from '@fhnw/messages/base';
import { useSelector } from 'react-redux';

const messages = defineMessages({
  logoLinkTitle: {
    id: 'Back to home page',
    defaultMessage: 'Back to home page',
  },
  logoAltText: {
    id: 'Logo of the University of Applied Sciences and Arts Northwestern Switzerland',
    defaultMessage:
      'Logo of the University of Applied Sciences and Arts Northwestern Switzerland',
  },
});

const Logo: React.FC = () => {
  const { settings } = config;
  const intl = useIntl();
  const currentLocation = useSelector(
    (state: any) => state.router?.location?.pathname,
  );
  const homePath = settings?.isMultilingual
    ? `/${toBackendLang(intl.locale)}`
    : '/';

  const handleClick = (e: React.MouseEvent) => {
    if (currentLocation === homePath) {
      e.preventDefault();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if ((e.key === 'Enter' || e.key === ' ') && currentLocation === homePath) {
      e.preventDefault();
    }
  };

  return (
    <UniversalLink
      href={homePath}
      // @ts-ignore
      title={intl.formatMessage(messages.logoLinkTitle)}
      openLinkInNewTab={false}
      // @ts-ignore
      className="logo-container"
      onClick={handleClick}
      onKeyDown={handleKeyDown}
    >
      <picture title={intl.formatMessage(baseMessages.fhnwTitle)}>
        <source
          srcSet="/logo/fhnw-logo-small.svg"
          media={`(max-width: ${variables.largestMobileScreen})`}
        />
        <source
          srcSet={`/logo/fhnw-logo-${intl.locale === 'de' ? 'de' : 'en'}.svg`}
          media={`(min-width: ${variables.largestMobileScreenPlusOne})`}
        />
        <img
          src="/logo/fhnw-logo-small.svg"
          alt={intl.formatMessage(messages.logoAltText)}
          title={intl.formatMessage(baseMessages.fhnwTitle)}
        />
      </picture>
    </UniversalLink>
  );
};

export default Logo;
