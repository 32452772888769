import { Icon } from '@fhnw/components/Icon/Icon';

interface FacebookProps {
  size: 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL';
  color: string;
  onClick?: () => void;
}

const Facebook = (props: FacebookProps) => (
  <Icon color={props.color} onClick={props.onClick} size={props.size}>
    <svg
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0C7.164 0 0 7.164 0 16c0 7.937 5.805 14.504 13.375 15.75V20.75h-4.063v-4.75H13.375v-3.25c0-4.063 2.438-6.313 6.125-6.313 1.75 0 3.5.313 3.5.313v3.875h-2.063c-1.938 0-2.563 1.188-2.563 2.438v3h4.125l-.656 4.75h-3.469V31.75C26.195 30.504 32 23.937 32 16 32 7.164 24.836 0 16 0"
        fill="currentColor"
        id="FacebookFollow"
      />
    </svg>
  </Icon>
);

export default Facebook;
