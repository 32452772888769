import { useEffect, useMemo } from 'react';

interface Content {
  analytics_type: { title: string };
  analytics_university: { title: string };
  analytics_degree: { title: string };
  '@type': string;
  analytics_product: string;
  blocks: any;
}

interface GoogleAnalyticsDataFormProps {
  content: Content;
}

export const GoogleAnalyticsDataForm = ({
  content,
}: GoogleAnalyticsDataFormProps) => {
  const {
    analytics_type = { title: '' },
    analytics_university = { title: '' },
    analytics_degree = { title: '' },
    analytics_product = '',
  } = content || {};

  const hasFormBlock = useMemo(() => {
    return Object.keys(content?.blocks || {}).some(
      (key) => content.blocks[key]['@type'] === 'schemaForm',
    );
  }, [content?.blocks]);

  useEffect(() => {
    const form = document.createElement('form');
    form.id = 'googleAnalyticsData';

    const fields = [
      { id: 'analyticsAnlass', value: analytics_type?.title },
      { id: 'analyticsHochschule', value: analytics_university?.title },
      { id: 'analyticsStufe', value: analytics_degree?.title },
      { id: 'analyticsType', value: content?.['@type'] },
      { id: 'analyticsProdukt', value: analytics_product },
      { id: 'analyticsSchritt', value: '' },
      { id: 'analyticsAnmeldeart', value: '' },
      { id: 'analyticsHasForm', value: hasFormBlock ? 'ja' : 'nein' },
    ];

    fields.forEach(({ id, value }) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.id = id;
      input.value = value || '';
      form.appendChild(input);
    });

    document.body.appendChild(form);

    return () => {
      form.remove();
    };
  }, [
    content,
    analytics_type,
    analytics_university,
    analytics_degree,
    analytics_product,
    hasFormBlock,
  ]);

  return null;
};
