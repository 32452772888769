import React, { useState } from 'react';
import TextField from '@fhnw/components/Form/TextField/TextField';
import Button from '@fhnw/components/Button/Button';
import Checkbox from '@fhnw/components/Form/Checkbox/Checkbox';
import RadioGroup from '@fhnw/components/Form/RadioGroup/RadioGroup';
import { Radio } from 'react-aria-components';
import config from '@plone/volto/registry';

interface NewsletterProps {
  data: {
    formSchema: any;
    title: string;
    description: string;
    cleverreachUrl: string;
  };
}

const Newsletter: React.FC<NewsletterProps> = ({ data }) => {
  const { formSchema, title, description, cleverreachUrl } = data;

  const [formValues, setFormValues] = useState<Record<string, any>>({});
  const [message, setMessage] = useState<string | null>(null);
  const [formKey, setFormKey] = useState<number>(0);

  const handleChange = (fieldName: string, value: any) => {
    setFormValues((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleArrayChange = (fieldName: string, value: string) => {
    setFormValues((prev) => ({
      ...prev,
      [fieldName]: prev[fieldName]?.includes(value)
        ? prev[fieldName].filter((v: string) => v !== value)
        : [...(prev[fieldName] || []), value],
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const missingFields = formSchema.required.filter(
      (field: string) => !formValues[field],
    );
    if (missingFields.length > 0) {
      setMessage(
        `Bitte füllen Sie die Pflichtfelder aus: ${missingFields.join(', ')}`,
      );
      return;
    }

    try {
      const cleverreachPostUrl = cleverreachUrl.endsWith('/')
        ? `${cleverreachUrl}wcs/`
        : `${cleverreachUrl}/wcs/`;

      const baseUrl = `${config._data.settings.apiPath}/++api++/@newsletter-proxy`;

      const payload = new URLSearchParams({
        url: cleverreachPostUrl,
        ...formValues,
      });

      const response = await fetch(`${baseUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: payload.toString(),
      });

      if (response.ok) {
        const { message_html, status_code, error } = await response.json();

        setMessage(status_code === 200 && !error ? message_html : error);
        setFormValues({});
        setFormKey((k) => k + 1);
      } else {
        setMessage('Anmeldung fehlgeschlagen. Bitte versuchen Sie es erneut.');
      }
    } catch (error) {
      //eslint-disable-next-line no-console
      console.error('Error submitting form:', error);
      setMessage(
        'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
      );
    }
  };

  return (
    <div className="newsletter-form">
      {title && <h2>{title}</h2>}
      {description && <p>{description}</p>}
      <form key={formKey} onSubmit={handleSubmit}>
        <fieldset>
          {formSchema &&
            formSchema.order.map((fieldName: string) => {
              const fieldConfig = formSchema.properties[fieldName];
              const { type, title, options } = fieldConfig;
              const isRequired = formSchema.required.includes(fieldName);

              switch (type) {
                case 'string':
                  return (
                    <TextField
                      key={fieldName}
                      label={title}
                      placeholder={title}
                      isRequired={isRequired}
                      errorMessage={`Bitte geben Sie ${title} ein.`}
                      value={formValues[fieldName] || ''}
                      onChange={(value) => handleChange(fieldName, value)}
                    />
                  );

                case 'checkbox':
                  return (
                    <div key={fieldName} className="checkbox-group">
                      <label className="title">{title}</label>
                      {options?.map((option: any, index: number) => (
                        <Checkbox
                          key={`${fieldName}-${index}`}
                          label={option.label}
                          isRequired={isRequired}
                          isInvalid={
                            !formValues[fieldName]?.length && isRequired
                          }
                          defaultSelected={formValues[fieldName]?.includes(
                            option.value,
                          )}
                          onChange={() =>
                            handleArrayChange(fieldName, option.value)
                          }
                        />
                      ))}
                    </div>
                  );

                case 'radio':
                  return (
                    <RadioGroup
                      key={fieldName}
                      label={'Bitte auswählen'}
                      isInvalid={!formValues[fieldName] && isRequired}
                      errorMessage={`Bitte wählen Sie ${title || 'eine Option'}.`}
                      value={formValues[fieldName] || ''}
                      onChange={(value) => handleChange(fieldName, value)}
                    >
                      {options?.map((option: any, index: number) => (
                        <Radio key={index} value={option.value}>
                          {option.label}
                        </Radio>
                      ))}
                    </RadioGroup>
                  );

                default:
                  return <p key={fieldName}>Unsupported field type: {type}</p>;
              }
            })}

          <Button
            type="submit"
            label="Anmelden"
            styles="btn btn-primary"
            onPress={() => {}}
          />
        </fieldset>

        {message && <p className="message">{message}</p>}
      </form>
    </div>
  );
};

export default Newsletter;
