import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import ProgressBar from '../ProgressBar';
import { Story } from '../StartSlider';
import { useDispatch, useSelector } from 'react-redux';
import { BehaviorSubject, delay, filter, tap } from 'rxjs';
import { setSiteInit } from '@fhnw/actions/siteInit';
import { useIntl } from 'react-intl';

const sliderReady$ = new BehaviorSubject(false);

export const useHandleSlick = (
  storys: Story[],
  sliderRef: React.MutableRefObject<any>,
  currentSlide: number,
  playSpeed: number,
) => {
  const progressRef = useRef<NodeJS.Timeout | null>(null);
  const progress = useRef(0);
  const dotsRef = useRef<NodeListOf<HTMLElement> | null>(null);

  const dispatch = useDispatch();

  const currentLocation = useSelector(
    (state: any) => state.router.location?.key,
  );

  const isInit = useSelector((state: any) => state.siteInit.siteInit);

  const updateProgressBar = useCallback(() => {
    if (!dotsRef.current) return;

    dotsRef.current.forEach((dot, index) => {
      const progressBar = dot.querySelector('.progress-bar') as HTMLElement;
      if (index === currentSlide && progressBar) {
        progressBar.style.width = `${progress.current}%`;
      } else if (progressBar) {
        progressBar.style.width = `0%`;
      }
    });
  }, [currentSlide]);

  const resetProgressBar = useCallback(() => {
    progress.current = 0;
    if (progressRef.current) {
      clearInterval(progressRef.current);
      progressRef.current = null;
    }
  }, []);

  const startProgressBar = useCallback(() => {
    if (progressRef.current) return;

    resetProgressBar();
    progressRef.current = setInterval(() => {
      progress.current += 1;
      updateProgressBar();

      if (progress.current >= 100) {
        clearInterval(progressRef.current!);
        progressRef.current = null;
        progress.current = 0;

        sliderRef.current.slickNext();
      }
    }, playSpeed / 100);
  }, [playSpeed, sliderRef, updateProgressBar, resetProgressBar]);

  const handleDotClick = useCallback(
    (index: number) => {
      if (index !== currentSlide) {
        sliderRef.current?.slickGoTo(index);
        startProgressBar();
      }
    },
    [currentSlide, sliderRef, startProgressBar],
  );

  const settings = useMemo(
    () => ({
      autoplay: false,
      autoplaySpeed: playSpeed,
      dots: true,
      fade: true,
      infinite: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      waitForAnimate: false,
      cssEase: 'ease',
      pauseOnFocus: false,
      pauseOnDotsHover: false,
      pauseOnHover: false,
      arrows: false,
      dotsClass: 'story_navigation',
      appendDots: () => (
        <div className="story_navigation" role="toolbar">
          <ul className="slick-dots">
            {storys?.map((story, index) => (
              <li
                key={index}
                role="tab"
                tabIndex={0}
                className={index === currentSlide ? 'slick-active' : ''}
                onClick={() => handleDotClick(index)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleDotClick(index);
                  }
                }}
              >
                <ProgressBar
                  progress={progress}
                  index={index}
                  currentSlide={currentSlide}
                />
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="story_navigation_slide">
                  {story.short_description || `Slide ${index + 1}`}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ),
    }),
    [storys, currentSlide, playSpeed, handleDotClick],
  );

  const intl = useIntl();

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const dots = document.querySelectorAll('.slick-dots li');
      if (dots.length > 0) {
        dotsRef.current = dots as NodeListOf<HTMLElement>;
        observer.disconnect();
        startProgressBar();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    if (document.querySelector('.slick-dots li')) {
      startProgressBar();
    }

    return () => {
      observer.disconnect();
      resetProgressBar();
    };
  }, [startProgressBar, intl.locale, currentLocation, resetProgressBar]);

  useEffect(() => {
    if (!sliderRef.current) return;

    sliderReady$.next(true);

    const subscription = sliderReady$
      .pipe(
        filter((ready) => ready),
        delay(200),
        tap(() => {
          if (!isInit) {
            sliderRef.current?.slickGoTo(0);
          }
        }),
        delay(100),
      )
      .subscribe(() => {
        if (!isInit) {
          startProgressBar();
        }
      });

    return () => {
      subscription.unsubscribe();
      resetProgressBar();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation, intl.locale]);

  useEffect(() => {
    if (isInit) {
      dispatch(setSiteInit(false) as any);
    }
  }, [dispatch, isInit]);

  return { resetProgressBar, settings };
};
