import {
  EduBoxButtons,
  EduBoxInfoText,
  EduBoxKeyDatas,
  EduBoxMobileNav,
  EduBoxFieldProps,
} from './index';

const EduBox = ({ content }: { content: EduBoxFieldProps }) => {
  return (
    <>
      <div className="web25__edubox">
        {/* Key datas */}

        <EduBoxKeyDatas content={content} />

        {/* Infotext */}
        <EduBoxInfoText content={content} />

        {/* Info and Application-Buttons */}
        <EduBoxButtons content={content} />
        <div className="fullbleed-edubox-wrapper"></div>
      </div>
      {/* Edubox mobile nav */}
      <EduBoxMobileNav />
    </>
  );
};

export default EduBox;
