import React, { useState, useMemo, useEffect } from 'react';
import { solrSearchContent } from '@kitconcept/volto-solr/actions';
import ResultItemPreviewImage from '@kitconcept/volto-solr/components/theme/SolrSearch/resultItems/helpers/ResultItemPreviewImage';
import { encodeConditionTree } from '@kitconcept/volto-solr/components/theme/SolrSearch/SearchConditions';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, FormattedMessage, defineMessages } from 'react-intl';
import { getVocabulary } from '@plone/volto/actions';
import Select from '@fhnw/components/Form/Select/Select';
import DatePicker from '@fhnw/components/Form/DatePicker/DatePicker';
// import config from '@plone/volto/registry';
import { Icon } from '@plone/volto/components';
import SearchInput from './SearchInput';
import Teaser from '@fhnw/components/Teaser/Teaser';
import {
  mapToEnabledSelectOptions,
  getEnabledTokens,
  FacetToken,
  EnabledSelectOptions,
  mapTokenToLabel,
} from '@fhnw/components/Blocks/SolarSearch/lib';
import Button from '@fhnw/components/Button/Button';
import contentlistingActive from '@fhnw/icons/list_active.svg';
import gridListing from '@fhnw/icons/grid.svg';
import downSVG from '@plone/volto/icons/down.svg';

import resetSVG from '@plone/volto/icons/reset.svg';
import { encodeExtraConditions } from './ExtraConditions';
import { parseDate } from '@internationalized/date';
import {
  mapItemToTeaserProps,
  mapItemAttrsByContenttype,
} from '../Listing/variations/mapItemToTeaserProps';

type ComponentViews = 'list' | 'grid';

export interface DateRangeCondition {
  ge?: string;
  le?: string;
}

const messages = defineMessages({
  school: {
    id: 'School',
    defaultMessage: 'School',
  },
  clearFilters: {
    id: 'Clear all filters',
    defaultMessage: 'Clear all filters',
  },
  moreResults: {
    id: 'More results',
    defaultMessage: 'More results',
  },
  of: {
    id: 'of',
    defaultMessage: 'of',
  },
  from: {
    id: 'From news solr',
    defaultMessage: 'From',
  },
  to: {
    id: 'To news solr',
    defaultMessage: 'To',
  },
});

export const formatDateInUTC = (from: any) => {
  const date = new Date(Date.UTC(from.year, from.month - 1, from.day));
  const formattedDateUTC = date.toISOString();
  const formattedDateWithoutMs = formattedDateUTC.replace('.000', '');

  return formattedDateWithoutMs;
};

// NOTE: an immutable empty array MUST be used as the default value for the select components
const emptySelection: EnabledSelectOptions[] = [];

const NewsView = (props: any) => {
  const { locale } = useIntl();
  const intl = useIntl();
  const { data } = props;
  let initialFromDate = data.from ? parseDate(data.from) : null;
  let initialToDate = data.to ? parseDate(data.to) : null;
  const batch_size = parseInt(data?.batch_size);
  const defaultTemplate = data['default-template'];
  const [searchInput, setSearchInput] = useState('');
  const [sortOrder, setSortOrder] = useState('reverse');
  const [currentView, setCurrentView] = useState(defaultTemplate);
  const [from, setFrom] = useState(initialFromDate);
  const [to, setTo] = useState(initialToDate);
  const [selectValue, setSelectValue] = useState<EnabledSelectOptions[] | []>(
    [],
  );

  const showImage = data?.['show-image'];

  const university = useSelector(
    (state: any) =>
      state.vocabularies['collective.taxonomy.university']?.items ||
      emptySelection,
  );

  const organisationEntities = useSelector(
    (state: any) =>
      state.vocabularies['fhnw.vocabularies.organisation_entities']?.items ||
      emptySelection,
  );

  const initialUniversitySelected = useMemo(() => {
    return data.university?.map((token: string) =>
      mapTokenToLabel(token, university),
    );
  }, [data.university, university]);

  useEffect(() => {
    if (data.university?.length > 0) {
      setSelectValue(initialUniversitySelected);
    } else {
      setSelectValue([]);
    }
  }, [data.university, initialUniversitySelected]);

  // const [, setCurrentView] = useState<ComponentViews>('Default');
  // const RenderView = config.getComponent({
  //   name: 'searchBlockResultItem',
  //   dependencies: ['News Item', currentView],
  // }).component;

  const dispatch = useDispatch();
  const blockuuid = props.block;
  const result = useSelector(
    (state: any) => state.solrsearch.subrequests?.[blockuuid],
  );

  // Syncing the value of the current view with the sidebar
  React.useEffect(() => {
    setCurrentView(defaultTemplate);
  }, [defaultTemplate]);
  React.useEffect(() => {
    if (data.from) {
      setFrom(parseDate(data.from));
    } else {
      setFrom(null);
    }
  }, [data.from]);

  React.useEffect(() => {
    if (data.to) {
      setTo(parseDate(data.to));
    } else {
      setTo(null);
    }
  }, [data.to]);

  const enabledTokens = getEnabledTokens(result);
  const universityWithEnable: EnabledSelectOptions[] =
    mapToEnabledSelectOptions(university, 0, enabledTokens);

  const onSolrSearch = (b_size: number) => {
    let facetToken: FacetToken = {};
    if (selectValue.length > 0) {
      for (let item of selectValue) {
        facetToken[item.value] = true;
      }
    }

    const dateRangeCondition: DateRangeCondition = {};
    if (from) {
      dateRangeCondition.ge = formatDateInUTC(from);
    }
    if (to) {
      dateRangeCondition.le = formatDateInUTC(to);
    }
    const extra_conditions = [
      ['effective', 'date-range', dateRangeCondition],
      ['Subject', 'string', { in: data.keywords || emptySelection }],
    ];

    dispatch(
      solrSearchContent(
        '/',
        {
          SearchableText: searchInput.length < 3 ? '' : searchInput,
          group_select: 4,
          doEmptySearch: true,
          facet_conditions: encodeConditionTree({
            taxonomy_university: {
              c: facetToken,
              m: true,
            },
          }),
          extra_conditions: encodeExtraConditions(extra_conditions as any),
          b_size,
          sort_on: searchInput.length < 3 ? 'effective' : null,
          sort_order: sortOrder,
          lang: locale,
        },
        blockuuid,
      ),
    );
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    //if batch_size is null or undefined , equal to 0, > total result items
    if (!batch_size || batch_size > result?.total) {
      onSolrSearch(result?.total);
    } else onSolrSearch(batch_size);
  }, [
    dispatch,
    searchInput,
    blockuuid,
    selectValue,
    from,
    to,
    sortOrder,
    batch_size,
    result?.total,
    data.keywords,
  ]);

  React.useEffect(() => {
    if (university.length === 0) {
      dispatch(
        /* @ts-ignore */
        getVocabulary({
          vocabNameOrURL: 'collective.taxonomy.university',
          size: -1,
        }),
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  React.useEffect(() => {
    if (organisationEntities.length === 0) {
      dispatch(
        /* @ts-ignore */
        getVocabulary({
          vocabNameOrURL: 'fhnw.vocabularies.organisation_entities',
          size: -1,
        }),
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const onChangeSelect = (value: any) => {
    setSelectValue(value);
  };

  const onChangeSearchInput = (value: string) => {
    setSearchInput(value);
  };

  const onChangeView = (view: ComponentViews) => {
    setCurrentView(view);
  };

  const onDateChangeFrom = (value: any) => {
    setFrom(value);
  };

  const onDateChangeTo = (value: any) => {
    setTo(value);
  };

  const onResetClick = () => {
    setSearchInput('');
    setSelectValue([]);
    setFrom(null);
    setTo(null);
  };

  const onToggleSort = () => {
    if (!sortOrder) {
      setSortOrder('reverse');
      return;
    }
    setSortOrder('');
  };

  const onMoreResult = () => {
    onSolrSearch(result?.items?.length + batch_size);
  };

  return (
    <div className="solr-search-news-view-template">
      <SearchInput value={searchInput} onChange={onChangeSearchInput} />
      <div className="select-container">
        {data['school-fliter'] && (
          <Select
            options={universityWithEnable}
            name={`select-1`}
            onChange={onChangeSelect}
            isMulti={true}
            value={selectValue}
            placeholder={intl.formatMessage(messages.school)}
          />
        )}

        <DatePicker
          label={intl.formatMessage(messages.from)}
          value={from as any}
          onChange={onDateChangeFrom}
        />
        <DatePicker
          label={intl.formatMessage(messages.to)}
          value={to as any}
          onChange={onDateChangeTo}
        />
      </div>

      <div className="reset-toggle-container">
        <div>
          <Button type="button" styles="clear-all" onPress={onResetClick}>
            <Icon name={resetSVG} size="20px" />
            <span>{intl.formatMessage(messages.clearFilters)}</span>
          </Button>
        </div>
        <div className="sort-container">
          <button onClick={onToggleSort}>
            {!sortOrder ? (
              <FormattedMessage
                id="Sorting ascending"
                defaultMessage="Sorting ascending"
              />
            ) : (
              <FormattedMessage
                id="Sorting descending"
                defaultMessage="Sorting descending"
              />
            )}
            <Icon className={sortOrder} name={downSVG} size="20px" />
          </button>
        </div>
      </div>
      <div className="toggle-button news-view">
        <Button
          type="button"
          styles="view-icon"
          onPress={() => onChangeView('list')}
        >
          <Icon
            name={contentlistingActive}
            size="30px"
            color={currentView === 'list' ? '#000' : '#8D8D8D'}
          />
        </Button>
        <Button
          type="button"
          styles="view-icon"
          onPress={() => onChangeView('grid')}
        >
          <Icon
            name={gridListing}
            size="30px"
            color={currentView === 'grid' ? '#000' : '#8D8D8D'}
          />
        </Button>
      </div>
      <div className="resultview-container">
        <ul
          className={currentView === 'grid' ? 'teaser-col-3' : 'teaser-listing'}
        >
          {result?.items.map((item: any, index: number) => (
            <Teaser
              key={index}
              {...mapItemToTeaserProps(
                mapItemAttrsByContenttype(
                  {
                    ...item,
                    organisation_entities: [
                      item.extras?.organisation_entities?.[0],
                    ],
                  },
                  organisationEntities,
                  undefined,
                  undefined,
                  {
                    showExtendedInfo: data?.['show-information'],
                  },
                ),
                {
                  locale,
                  ...data,
                  showTeaserImage: data?.['show-image'],
                  showTeaserText: data?.['show-text'],
                  showExtendedInfo: data?.['show-information'],
                },
                'news',
              )}
              imageComponentProps={
                showImage ? { item, Wrapper: null } : undefined
              }
              imageComponent={showImage ? ResultItemPreviewImage : undefined}
            />
          ))}
        </ul>
      </div>
      <div className="more-result-container">
        <div className="more-container">
          <button onClick={onMoreResult}>
            {result?.items?.length >= result?.total
              ? ''
              : ` ${intl.formatMessage(messages.moreResults)}`}
          </button>
          <span>{`${result?.items?.length} ${intl.formatMessage(messages.of)} ${result?.total}`}</span>
        </div>
      </div>
    </div>
  );
};

export default NewsView;
