import React from 'react';
import { FullBleedTeaserProps } from '@fhnw/types/fullBleedTeaser';
import { useIntl } from 'react-intl';
import { Link } from '../Link/Link';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import TeaserContent from '../Teaser/TeaserContent';
import Image from '@plone/volto/components/theme/Image/Image';
import isEmpty from 'lodash/isEmpty';
import { UniversalLink } from '@plone/volto/components';
import { teaserBlockDefaultMessages } from '../Blocks/Teaser/variations/TeaserBlockDefaultMessages';
import { getItemReference } from '@fhnw/helpers/Url/UrlHelper';
import { FormState } from '@fhnw/types/formState';
import { useSelector } from 'react-redux';

/**
 * FullBleedTeaser component
 *
 * A full-width teaser component with image and content section.
 * Displays content differently based on the edit mode and content availability.
 *
 * @param props - Component props
 * @returns Rendered component
 */
const FullBleedTeaser: React.FC<
  FullBleedTeaserProps<Record<string, unknown>>
> = (props) => {
  const { data, isEditMode } = props;
  const token = useSelector((state: FormState) => state?.userSession?.token);
  const intl = useIntl();

  const overwrite = data?.overwrite === true;
  const href = data?.href?.[0];

  /**
   * Helper functions for content retrieval
   */
  const getTitle = () => {
    if (overwrite) return data?.title;
    if (isEmpty(href?.teaser_title)) return href?.title ?? data?.title;
    return href?.teaser_title;
  };

  const getText = () => {
    if (overwrite) {
      return isEmpty(data?.description) ? undefined : data?.description;
    }
    return isEmpty(href?.teaser_text) ? data?.description : href?.teaser_text;
  };

  const getImage = () => (overwrite ? data?.preview_image?.[0] : href);
  const getHeading = () => (overwrite ? data?.head_title : '');

  // Get content values
  const title = getTitle();
  const text = getText();
  const image = getImage();
  const heading = getHeading();

  return (
    <>
      {!href && isEditMode && (
        <div className="teaser-item placeholder">
          <img src={imageBlockSVG} alt="" />
          <p>
            {intl.formatMessage(teaserBlockDefaultMessages.PleaseChooseContent)}
          </p>
        </div>
      )}
      {href && (
        <div className="web25__fullbleed-teaser">
          <div className="fullbleed-teaser-image">
            <div className="infobox-image">
              <Image
                item={image ?? href}
                src={null}
                imageField={image?.image_field || href?.image_field || 'image'}
                alt={image?.alt_text || href?.alt_text || title || ''}
                loading="lazy"
                responsive={true}
              />
            </div>
          </div>
          <Link
            className="teaser-link"
            title={title}
            linkComponent={UniversalLink}
            {...(isEditMode
              ? {}
              : {
                  href: getItemReference(href, token),
                })}
          >
            <div className="fullbleed-teaser-wrapper">
              <TeaserContent category={heading} title={title} text={text} />
            </div>
          </Link>
        </div>
      )}
    </>
  );
};

// Memoize the component to prevent unnecessary re-renders
export default FullBleedTeaser;
