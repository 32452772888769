import { defineMessages, useIntl } from 'react-intl';
import { getTitleByToken } from '@fhnw/components/Blocks/SolarSearch/lib';
import { Icon } from '@plone/volto/components';
import {
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Table,
} from 'semantic-ui-react';
import galleryRightSVG from '@fhnw/icons/arrow-black.svg';
import { vocabType } from '@fhnw/components/Blocks/SolarSearch/lib';

const messages = defineMessages({
  faculty: {
    id: 'Faculty',
    defaultMessage: 'Faculty',
  },
  type: {
    id: 'Type',
    defaultMessage: 'Type',
  },
  degreeProgrammes: {
    id: 'Degree Programmes',
    defaultMessage: 'Degree Programmes',
  },
  location: {
    id: 'Location Placeholder',
    defaultMessage: 'Location',
  },
});

type Props = {
  result: any;
  degreeProgramType: vocabType[];
  fieldsofstudy: vocabType[];
  location: vocabType[];
  onClickRow: (item: any) => void;
};

const TableTemplateProgrammeView = ({
  result,
  degreeProgramType,
  fieldsofstudy,
  location,
  onClickRow,
}: Props) => {
  const intl = useIntl();
  return (
    <div className="search__table">
      <Table className="table-result-view-container">
        <TableHeader>
          <TableRow>
            <TableHeaderCell>
              {intl.formatMessage(messages.degreeProgrammes)}
            </TableHeaderCell>
            <TableHeaderCell>
              {intl.formatMessage(messages.type)}
            </TableHeaderCell>
            <TableHeaderCell>
              {intl.formatMessage(messages.faculty)}
            </TableHeaderCell>
            <TableHeaderCell>
              {intl.formatMessage(messages.location)}
            </TableHeaderCell>
            <TableHeaderCell></TableHeaderCell>
          </TableRow>
        </TableHeader>

        <TableBody>
          {result?.items.map((item: any, index: number) => (
            <TableRow
              className="clickable-table-row"
              key={index}
              onClick={() => onClickRow(item)}
            >
              <TableCell>{item.title}</TableCell>
              <TableCell>
                {item?.extras?.degree_program_types?.map(
                  (item: string, index: number, array: any) => {
                    return (
                      <span key={index}>
                        {getTitleByToken(item, degreeProgramType)}
                        {index < array.length - 1 && ', '}
                      </span>
                    );
                  },
                )}
              </TableCell>

              <TableCell>
                {item?.extras?.taxonomy_fieldsofstudy?.map(
                  (item: string, index: number, array: any) => {
                    return (
                      <span key={index}>
                        {getTitleByToken(item, fieldsofstudy)}
                        {index < array.length - 1 && ', '}
                      </span>
                    );
                  },
                )}
              </TableCell>

              <TableCell>
                {item?.extras?.location_category?.map(
                  (item: string, index: number, array: any) => {
                    return (
                      <span key={index}>
                        {getTitleByToken(item, location)}
                        {index < array.length - 1 && ', '}
                      </span>
                    );
                  },
                )}
              </TableCell>
              <TableCell>
                <Icon
                  className="arrow-right"
                  name={galleryRightSVG}
                  size="10px"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TableTemplateProgrammeView;
