import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { BlockDataForm } from '@plone/volto/components';
import { AnchorBlockSchema } from './schema';

const AnchorBlockData = (props: any) => {
  const { block, data, onChangeBlock, contentType, navRoot } = props;
  const intl = useIntl();
  const schema = AnchorBlockSchema({ ...props, intl });

  return (
    <>
      <BlockDataForm
        schema={schema}
        title={schema.title}
        onChangeField={(id: string, value: any) => {
          onChangeBlock(block, {
            ...data,
            [id]: value,
          });
        }}
        onChangeBlock={onChangeBlock}
        formData={data}
        block={block}
        navRoot={navRoot}
        contentType={contentType}
      />
      <div className="note">
        <FormattedMessage
          id="Note: Before using anchor block it's necessary to save the page, so that the complete URL to anchor is known and can be copied."
          defaultMessage="Note: Before using anchor block it's necessary to save the page, so that the complete URL to anchor is known and can be copied."
        />
      </div>
    </>
  );
};

AnchorBlockData.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default AnchorBlockData;
