import { useIntl } from 'react-intl';
import ICalendar from '@fhnw/components/ICalendar/ICalendar.tsx';
import FHNWDateFormat from '../DateFormat/FHNWDateFormat';
import { EventDetailMessages as messages, EventDetailProps } from './index';
import addLineBreaks, {
  addLineBreaksAsHTML,
} from '@fhnw/helpers/text/addLineBreaks';
import containsHTML from '@fhnw/helpers/text/containsHTML';

const EventDetail = ({ content }: { content: EventDetailProps }) => {
  const intl = useIntl();
  const start_date = new Date(content.start);
  const end_date = new Date(content.end);
  const whole_day = content.whole_day;
  const open_end = content.open_end;
  const organiser = content.event_organiser?.trim();
  const html = organiser
    ? containsHTML(organiser)
      ? organiser
      : addLineBreaksAsHTML(organiser)
    : null;

  return (
    <>
      <div className="web25__eventdetail">
        <ol>
          {/* DateTime */}
          {(start_date?.valueOf() || end_date?.valueOf()) && (
            <li className="field_names datetime">
              <h3>{intl.formatMessage(messages.datetime)}</h3>
              <p className="field_data datetime">
                <FHNWDateFormat
                  start={start_date}
                  end={end_date}
                  openEnd={open_end}
                  wholeDay={whole_day}
                />
                <ICalendar link={content['@id']} />
              </p>
            </li>
          )}

          {/*Location*/}
          {content.event_location_long && (
            <li className="field_names location">
              <h3>{intl.formatMessage(messages.location)}</h3>
              <p className="field_data location_long">
                {addLineBreaks(content.event_location_long)}
              </p>
            </li>
          )}

          {/*Organiser*/}
          {(content.organisation_entities.length > 0 ||
            content.event_organiser) && (
            <li className="field_names organiser">
              <h3>{intl.formatMessage(messages.organiser)}</h3>
              {content.organisation_entities.length > 0 && (
                <p className="field_data organiser">
                  {content.organisation_entities.map((entity) => (
                    <span className="entity" key={entity.token}>
                      {entity.title}
                    </span>
                  ))}
                </p>
              )}
              {html && (
                <p
                  className="field_data organiser"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              )}
            </li>
          )}

          {/*Cost*/}
          {content.event_costs && (
            <li className="field_names costs">
              <h3>{intl.formatMessage(messages.costs)}</h3>
              <p className="field_data costs">{content.event_costs}</p>
            </li>
          )}

          {/*EventLanguage*/}
          {content.event_language && (
            <li className="field_names eventlanguage">
              <h3>{intl.formatMessage(messages.eventlanguage)}</h3>
              <p className="field_data language">{content.event_language}</p>
            </li>
          )}
        </ol>
      </div>
    </>
  );
};

export default EventDetail;
