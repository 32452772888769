import { GET_ALLOWLIST } from '../constants/ActionTypes';

export function getAllowlist() {
  return {
    type: GET_ALLOWLIST,
    request: {
      op: 'get',
      path: '/@embed',
    },
  };
}
