import React from 'react';
import config from '@plone/volto/registry';
import { Link } from '@fhnw/components/Link/Link.tsx';
import { UniversalLink } from '@plone/volto/components';
import cx from 'classnames';
import { isInternalURL } from '@plone/volto/helpers';

interface ImageData {
  '@id': string;
  image: {
    download: string;
    width: number;
    height: number;
    scales: {
      preview: {
        download: string;
        width: number;
        height: number;
      };
    };
  };
}

interface TestimonialData {
  testimonial?: string;
  name?: string;
  image?: ImageData[];
  button_link?: string;
  button_text?: string;
}

interface TestimonialProps {
  data?: TestimonialData;
}

interface ReplaceWithBrProps {
  txt?: string;
}

const DefaultImage = (props: any) => <img {...props} alt={props.alt || ''} />;

const ReplaceWithBr = ({ txt = '' }: ReplaceWithBrProps) => {
  const testimonialText = txt.replace(/^\n+|\n+$/g, '');
  const parts = testimonialText.split('\n');

  return (
    <blockquote>
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {index < parts.length - 1 && <br />}
        </React.Fragment>
      ))}
    </blockquote>
  );
};

const TestimonialsView = ({ data = {} }: TestimonialProps) => {
  const image = data?.image?.[0];
  const name = data?.name || 'Anonymous';
  const buttonText = data?.button_text || 'View profile';

  const Image = config.getComponent('Image').component || DefaultImage;
  return (
    <div className="testimonial blocks-group-wrapper">
      <figure
        className={cx('testimonial', {
          has_bg: !image,
          no_button: !data?.button_link,
        })}
        role="region"
        aria-label={`Testimonial from ${name}`}
      >
        <div className="testimonial_content">
          {image && (
            <div className="author_image">
              {isInternalURL(image['@id']) ? (
                <Image
                  src={image['@id']}
                  item={isInternalURL(image['@id']) && image}
                  alt={`Portrait of ${name}`}
                  loading="lazy"
                  responsive={true}
                />
              ) : (
                <Image
                  src={image['@id']}
                  alt={`Portrait of ${name}`}
                  loading="lazy"
                  responsive={true}
                />
              )}
            </div>
          )}
          <div className="testimonial_text_block">
            <ReplaceWithBr txt={data?.testimonial} />
            <figcaption>
              <cite className="author_name">{name}</cite>
              {data?.button_link && (
                <div className="profile_button">
                  <Link
                    className="btn btn-primary"
                    href={data.button_link}
                    target="_blank"
                    title={`${buttonText}`}
                    rel="noopener noreferrer"
                    aria-label={`${buttonText} - Opens in new tab`}
                    linkComponent={UniversalLink}
                  >
                    {buttonText}
                  </Link>
                </div>
              )}
            </figcaption>
          </div>
        </div>
      </figure>
    </div>
  );
};

export default TestimonialsView;
