import React, { useMemo, memo } from 'react';
import { useInfiniteScroll } from './hooks/useInfiniteScroll';
import { Link } from '../Link/Link';
import { IrfAlertMessages } from './index';
import { IrfMessages as messages } from './IrfMessages';
import { InternalIrfPublicationProps } from './utils/fetchData';
import { useIntl } from 'react-intl';

interface RenderEntriesProps {
  entries: InternalIrfPublicationProps[];
  peerReviewedCache: Record<string, boolean>;
  activeTab: string;
  showOnlyPeerReviewed: boolean;
  getExcludedPublications: string[];
  isEditMode: boolean;
  dataState: string;
  jsonData: InternalIrfPublicationProps[];
  displayContentGrouped: string;
}

// Separate component for non-grouped content with infinite scroll
const NonGroupedContent: React.FC<{
  entries: InternalIrfPublicationProps[];
  displayContentGrouped: string;
}> = memo(({ entries, displayContentGrouped }) => {
  const { displayedItems, loadMoreRef, hasMore } = useInfiniteScroll(
    entries,
    10,
  );

  return (
    <>
      {displayedItems.map((entry, index) => (
        <EntryItem
          key={`${entry.url_handle}-${index}`}
          entry={entry}
          showType={displayContentGrouped === 'group_by_type'}
        />
      ))}
      {hasMore && (
        <div
          ref={loadMoreRef}
          style={{ height: '20px', margin: '20px 0' }}
          className="load-more-trigger"
          aria-hidden="true"
        />
      )}
    </>
  );
});

NonGroupedContent.displayName = 'NonGroupedContent';

interface EntryItemProps {
  entry: InternalIrfPublicationProps;
  showType: boolean;
}

const EntryItem: React.FC<EntryItemProps> = memo(({ entry, showType }) => {
  const entryType = entry.type?.split('-').slice(1).join('').trim() || '';

  return (
    <li className="teaser">
      <Link
        className="teaser-link"
        href={entry.url_handle}
        title={entryType}
        newTab
        aria-label={`${entry.type} - ${entry.citation_entry?.replace(/<[^>]*>/g, '')}`}
      >
        <div className="teaser-wrapper">
          {showType && (
            <h3 className="text-size-md pt-lg pb-sm mb-none">{entryType}</h3>
          )}
          {entry.peer_reviewed && (
            <span
              className="peer-reviewed"
              aria-label="Peer-Reviewed Publication"
              role="status"
            >
              Peer-Reviewed
            </span>
          )}
          <div
            className="irf-content"
            dangerouslySetInnerHTML={{
              __html: entry.citation_entry || '',
            }}
          />
        </div>
      </Link>
    </li>
  );
});

export const RenderEntries: React.FC<RenderEntriesProps> = memo(
  ({
    entries,
    peerReviewedCache,
    activeTab,
    showOnlyPeerReviewed,
    getExcludedPublications,
    isEditMode,
    dataState,
    jsonData,
    displayContentGrouped,
  }) => {
    const intl = useIntl();

    const filteredAndGroupedEntries = useMemo(() => {
      const filtered = entries.filter(
        (entry) =>
          (!peerReviewedCache[activeTab] || entry.peer_reviewed === true) &&
          (activeTab === 'projectTab' ||
            !showOnlyPeerReviewed ||
            entry.peer_reviewed) &&
          !getExcludedPublications.includes(entry.url_handle?.toString() || ''),
      );

      if (displayContentGrouped === 'group_by_type') {
        const groupedByType = filtered.reduce(
          (acc, entry) => {
            const type = entry.type?.split('-').slice(1).join('').trim() || '';
            if (!acc[type]) acc[type] = [];
            acc[type].push(entry);
            return acc;
          },
          {} as Record<string, InternalIrfPublicationProps[]>,
        );

        return { filtered, grouped: groupedByType };
      }

      return { filtered, grouped: null };
    }, [
      entries,
      peerReviewedCache,
      activeTab,
      showOnlyPeerReviewed,
      getExcludedPublications,
      displayContentGrouped,
    ]);

    if (filteredAndGroupedEntries.filtered.length === 0) {
      return (
        <li className="no-peer-reviewed-message" role="alert">
          <p>{intl.formatMessage(messages.no_peer_reviewed_message)}</p>
        </li>
      );
    }

    return (
      <>
        {isEditMode === true && dataState === 'utd' ? (
          <IrfAlertMessages
            dataState={dataState}
            totalEntrys={jsonData.length}
          />
        ) : (
          <>
            {isEditMode === true && <IrfAlertMessages dataState={dataState} />}
          </>
        )}

        {displayContentGrouped === 'group_by_type' &&
          filteredAndGroupedEntries.grouped &&
          Object.entries(filteredAndGroupedEntries.grouped).map(
            ([type, groupEntries]) => (
              <React.Fragment key={type}>
                <h3 className="text-size-md pt-lg pb-sm mb-none">{type}</h3>
                {groupEntries.map((entry, index) => (
                  <EntryItem
                    key={`${entry.url_handle}-${index}`}
                    entry={entry}
                    showType={false}
                  />
                ))}
              </React.Fragment>
            ),
          )}

        {displayContentGrouped !== 'group_by_type' && (
          <NonGroupedContent
            entries={filteredAndGroupedEntries.filtered}
            displayContentGrouped={displayContentGrouped}
          />
        )}
      </>
    );
  },
);

RenderEntries.displayName = 'RenderEntries';
EntryItem.displayName = 'EntryItem';
