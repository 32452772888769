import type { BlockViewProps } from '@plone/types';
import { useSelector } from 'react-redux';
import { FormState } from '@fhnw/types/formState';

const HTMLBlockView = (props: BlockViewProps) => {
  const { data } = props;
  const token = useSelector<FormState, string>(
    (state) => state.userSession.token,
  );

  if (data?.href?.[0]?.snippet) {
    return <div dangerouslySetInnerHTML={{ __html: data.href[0].snippet }} />;
  }
  return token ? (
    <>
      Select an HTML snippet on the sidebar. <br />
      The content will not be rendered until you save the content.
    </>
  ) : null;
};

export default HTMLBlockView;
