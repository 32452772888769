import { useState, useEffect } from 'react';
import { GroupBase, Props, default as ReactSelect } from 'react-select';
import { Text } from 'react-aria-components';

export type SelectProps = {
  /**
   * A label, indicating that the field is required.
   */
  labelRequired?: string;
  /**
   * Indicates whether the input field is required or not.
   */
  isRequired?: boolean;
};

const Select = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: Props<Option, IsMulti, Group> & SelectProps,
) => {
  const [isClient, setIsClient] = useState(false);

  // Must be deleted once
  // https://github.com/JedWatson/react-select/issues/5459 is fixed.
  useEffect(
    () => setIsClient(true),
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [],
  );

  return (
    <div className="react-select-container">
      <div className="react-select-label-wrapper">
        <label>
          {props.label}
          {props.isRequired && (
            <Text slot="description">{props.labelRequired}</Text>
          )}
        </label>
        {props.errorMessage && (
          <div className="react-select-error">{props.errorMessage}</div>
        )}
      </div>
      {isClient && (
        <ReactSelect
          className={
            props.errorMessage
              ? 'react-select-component has-error'
              : 'react-select-component'
          }
          classNamePrefix="react-select"
          {...props}
        />
      )}
      {props.description && <Text slot="description">{props.description}</Text>}
    </div>
  );
};

export default Select;
