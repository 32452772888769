import { withBlockExtensions } from '@plone/volto/helpers';
import View from './View';

const Edit = (props: any) => {
  return (
    <>
      <View {...props} />
    </>
  );
};

export default withBlockExtensions(Edit);
