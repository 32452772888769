import {
  FileTrigger,
  Button,
  FileTriggerProps,
  Text,
  Label,
  ValidationResult,
} from 'react-aria-components';

export type FileSelectorProps = {
  /**
   * Label for the button that triggers the file selector.
   */
  labelButton?: string;
  /**
   * Label for the selected file and also if not file is selected.
   */
  labelFile?: string;
  /**
   * Optional flag to determine whether a close button should be shown on the file selector.
   * If true, a close button will be displayed; if false or undefined, it will not.
   */
  showCloseButton?: boolean;
  /**
   * Flag to indicate if there is an error in the file selection process.
   * If true, an error state will be displayed; if false or undefined, it will not.
   */
  hasError?: boolean;
  /**
   * Aria label for the delete button.
   */
  ariaLabelDeleteButton?: string;
  /**
   * Callback function to be called when the delete button is clicked.
   */
  /**
   * A label for the input field.
   */
  label?: string;
  /**
   * A label, indicating that the field is required.
   */
  labelRequired?: string;
  /**
   * Description or additional information about the input field.
   */
  description?: string;
  /**
   * An error message to display when validation fails.
   * It can be either a string or a function that takes validation result as argument and returns a string.
   */
  errorMessage?: string | ((validation: ValidationResult) => string);
  /**
   * Indicates whether the input field is required or not.
   */
  isRequired?: boolean;
  /**
   * Which values to accept.
   */
  accept?: string;
  deleteFilesCallback?: () => void;
} & FileTriggerProps;

const FileSelector = ({
  labelButton = 'Choose File',
  labelFile = 'No file chosen',
  showCloseButton = true,
  hasError = false,
  deleteFilesCallback = () => {},
  ariaLabelDeleteButton = 'Remove selected files',
  label,
  labelRequired,
  description,
  errorMessage,
  isRequired = false,
  accept = undefined,
  ...props
}: FileSelectorProps) => {
  return (
    <>
      <div className="react-aria-file-label-wrapper">
        {label && (
          <Label>
            {label}
            {isRequired && <Text slot="description">{labelRequired}</Text>}
          </Label>
        )}
        <span className="react-aria-FieldError">{errorMessage as any}</span>
      </div>
      <div
        className={
          hasError ? 'file-selector-box has-error' : 'file-selector-box'
        }
      >
        <div className="file-selector">
          <FileTrigger
            {...props}
            {...(accept ? { acceptedFileTypes: [accept] } : {})}
          >
            <Button>{labelButton}</Button>
          </FileTrigger>
          <div className="file-selector-filename" aria-live="polite">
            {labelFile}
          </div>
        </div>

        {showCloseButton && (
          <Button
            className="file-selector-delete"
            aria-label={ariaLabelDeleteButton}
            onPress={deleteFilesCallback}
          >
            &times;
          </Button>
        )}
      </div>
      {description && <Text slot="description">{description}</Text>}
    </>
  );
};

export default FileSelector;
