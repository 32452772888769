import {
  Button,
  Calendar,
  CalendarCell,
  CalendarGrid,
  DateInput,
  DatePicker as RACDatePicker,
  DatePickerProps as RACDatePickerProps,
  DateSegment,
  DateValue,
  Dialog,
  FieldError,
  Group,
  Heading,
  Label,
  Popover,
  Text,
  ValidationResult,
} from 'react-aria-components';
import { I18nProvider } from 'react-aria';
import DatePickerIcon from './DatePickerIcon';
import DatePickerClearButton from './DatePickerClearButton';

export type DatePickerProps<T extends DateValue> = {
  label?: string;
  description?: string;
  labelRequired?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  isDateOnly?: boolean;
  isRequired?: boolean;
  locale?: string;
} & RACDatePickerProps<T>;

const DatePicker = <T extends DateValue>({
  label,
  description,
  errorMessage,
  isRequired = false,
  labelRequired,
  isDateOnly = true,
  locale = 'de',
  ...props
}: DatePickerProps<T>) => {
  return (
    <I18nProvider locale={`${locale}-CH`}>
      <RACDatePicker
        {...props}
        {...(isDateOnly ? {} : { granularity: 'minute', hourCycle: 24 })}
      >
        <Label>
          {label}
          {isRequired && <Text slot="description">{labelRequired}</Text>}
        </Label>
        <FieldError>{errorMessage}</FieldError>
        <Group>
          <DateInput>
            {(segment) => <DateSegment segment={segment} />}
          </DateInput>
          <div className="datepicker-button-Wrapper">
            <DatePickerClearButton />
            <Button>
              <DatePickerIcon size="S" />
            </Button>
          </div>
        </Group>
        {description && <Text slot="description">{description}</Text>}
        <Popover>
          <Dialog>
            <Calendar>
              <header>
                <Button slot="previous">◀</Button>
                <Heading />
                <Button slot="next">▶</Button>
              </header>
              <CalendarGrid>
                {(date) => <CalendarCell date={date} />}
              </CalendarGrid>
            </Calendar>
          </Dialog>
        </Popover>
      </RACDatePicker>
    </I18nProvider>
  );
};

export default DatePicker;
