import {
  forwardRef,
  ReactNode,
  ElementType,
  ComponentPropsWithoutRef,
  Ref,
} from 'react';

type ElementRef<T extends ElementType> = T extends keyof JSX.IntrinsicElements
  ? JSX.IntrinsicElements[T] extends { ref?: infer R }
    ? R
    : never
  : never;

export type LinkProps<T extends ElementType = 'a'> = {
  children?: ReactNode;
  newTab?: boolean;
  linkComponent?: T;
} & Omit<ComponentPropsWithoutRef<T>, 'target' | 'rel'>;

export const Link = forwardRef(
  <T extends ElementType = 'a'>(
    { children, linkComponent, newTab = false, ...props }: LinkProps<T>,
    ref: Ref<ElementRef<T>>,
  ) => {
    const LinkComponent = linkComponent || ('a' as ElementType);

    return (
      <LinkComponent
        {...(props as ComponentPropsWithoutRef<T>)}
        {...(newTab ? { target: '_blank', rel: 'noreferrer' } : {})}
        ref={ref}
      >
        {children}
      </LinkComponent>
    );
  },
);
