import { defineMessages } from 'react-intl';

export const FollowUsBlockMessages = defineMessages({
  blockTitle: {
    id: 'FollowUsBlock.blockTitle',
    defaultMessage: 'FollowUs Block',
  },
  title: {
    id: 'FollowUsBlock.title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'FollowUsBlock.description',
    defaultMessage: 'Description',
  },
  selectFolder: {
    id: 'FollowUsBlock.selectFolder',
    defaultMessage: 'Select Folder',
  },
  query: {
    id: 'FollowUsBlock.query',
    defaultMessage: 'Query',
  },
  queryDescription: {
    id: 'FollowUsBlock.queryDescription',
    defaultMessage: 'Define a query to select content based on folder and tags',
  },
});
