export const irfConfig = {
  publicationTab: [
    '01A',
    '01B',
    '01',
    '02',
    '03',
    '04',
    '04A',
    '04B',
    '05',
    '11',
  ],
  projectTab: ['00'],
  presentationTab: ['06'],
  supervisedThesesTab: ['11'],
  othersTab: ['07', '09', '10', '12', '13', '14', '99'],
};
