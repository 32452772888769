import { SidebarPortal } from '@plone/volto/components';
import SolarSearchView from './View';

import Sidebar from './Sidebar';

const SolarSerachEdit = (props: any) => {
  const { data, block, onChangeBlock, selected } = props;

  return (
    <div>
      <SolarSearchView {...props} />
      <SidebarPortal selected={selected}>
        <Sidebar
          {...props}
          data={data}
          block={block}
          onChangeBlock={onChangeBlock}
        />
      </SidebarPortal>
    </div>
  );
};

export default SolarSerachEdit;
