import { IntlShape } from 'react-intl';
import { ApplicationAccordionMessages } from './index';

const ApplicationBlockSchema = (intl: IntlShape) => ({
  title: 'ApplicationAccordion Block',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['listing_order'],
    },
  ],
  properties: {
    listing_order: {
      title: intl.formatMessage(ApplicationAccordionMessages.listing_order),
      description: intl.formatMessage(
        ApplicationAccordionMessages.listing_order_description,
      ),
      type: 'boolean',
      default: false,
    },
  },
  required: [],
});

export default ApplicationBlockSchema;
