/**
 * Custom hook that checks if navigation content exceeds container width
 * @param {Object} props - Hook properties
 * @param {React.RefObject<HTMLElement>} props.navRef - Reference to navigation container element
 * @returns {() => boolean | undefined} Callback function that returns true if content width exceeds container width
 */

import { useCallback, RefObject } from 'react';

interface handleResizeProps {
  navRef: RefObject<HTMLElement>;
}

export const useHandleResize = ({ navRef }: handleResizeProps) => {
  const handleResize = useCallback(() => {
    if (navRef.current) {
      const navWidth = navRef.current.scrollWidth;
      const containerWidth = navRef.current.clientWidth;
      return navWidth > containerWidth; // Return the boolean value instead of setting a state
    }
  }, [navRef]);

  return handleResize;
};
