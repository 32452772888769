/**
 * AccordionItem component that represents a single expandable/collapsible section
 *
 * @component
 * @param {Object} props - Component props
 * @param {ApplicationAccordionFieldProps} props.data - The data object containing accordion item content and configuration
 * @param {boolean} props.isExpanded - Flag indicating if the accordion item is currently expanded
 * @param {Function} props.onToggle - Callback function to handle expanding/collapsing the accordion item
 *
 * @returns {JSX.Element} Rendered accordion item component with header and body
 *
 * @example
 * <AccordionItem
 *   data={itemData}
 *   isExpanded={true}
 *   onToggle={() => handleToggle()}
 * />
 */

import React from 'react';
import {
  AccordionHeader,
  AccordionBody,
  ApplicationAccordionFieldProps,
} from './index';

interface AccordionItemProps {
  data: ApplicationAccordionFieldProps;
  isExpanded: boolean;
  onToggle: () => void;
  reviewState?: string;
}

const AccordionItem = React.memo(
  ({ data, isExpanded, onToggle, reviewState }: AccordionItemProps) => {
    const handleKeyPress = (event: React.KeyboardEvent) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        onToggle();
      }
    };

    return (
      <div
        className={`accordion ${isExpanded ? 'open' : ''} ${reviewState === 'private' ? 'publish_state_private' : ''}`}
        onClick={onToggle}
        onKeyDown={handleKeyPress}
        role="button"
        tabIndex={isExpanded ? 0 : -1}
        aria-expanded={isExpanded}
        aria-controls={`accordion-content-${data.id}`}
        id={`accordion-header-${data.id}`}
      >
        <AccordionHeader data={data} />
        <AccordionBody data={data} isOpen={isExpanded} />
      </div>
    );
  },
);

export default AccordionItem;
