/**
 * AccordionBody Component
 *
 * This component renders the body of an accordion item, displaying additional information
 * related to an application. It conditionally renders various fields based on the provided data.
 *
 * @param {Object} props - The component props.
 * @param {ApplicationAccordionFieldProps} props.data - The data object containing all the fields
 * to be displayed in the accordion body. This includes fields like start date, end date, duration,
 * place, free places, total places, occurrence number, course management, final application date,
 * and additional information.
 * @param {boolean} props.isOpen - A boolean flag indicating whether the accordion is currently open.
 * This is used to conditionally render the application button and other elements.
 *
 * @returns {JSX.Element} - The rendered accordion body.
 */

import React from 'react';
import { useIntl } from 'react-intl';
import ICalendar from '../ICalendar/ICalendar';
import {
  ApplicationAccordionMessages as messages,
  ApplicationAccordionFieldProps,
  ApplicationButton,
  DateDisplay,
} from './index';

interface AccordionItemProps {
  data: ApplicationAccordionFieldProps;
  isOpen: boolean;
}

const AccordionBody = React.memo(({ data, isOpen }: AccordionItemProps) => {
  const intl = useIntl();
  const icsViewUrl = new URL(data?.['@id']);

  const hasAdditionalInfo = !!(
    data?.alternative_start ||
    data?.duration ||
    data?.place ||
    data?.free_places ||
    data?.total_places ||
    data?.occ_number ||
    data?.additional_information
  );

  const renderDateInfo = () => {
    if (!data?.start) return null;

    return (
      <>
        {!data.alternative_start && (
          <div>
            <dt>{intl.formatMessage(messages.start_end_date)}</dt>
            <dd>
              <DateDisplay
                start={data.start}
                end={data.end}
                wholeDay={data.whole_day}
                openEnd={data.open_end}
                alternativeStart={data.alternative_start}
              />
              {data.application_button_link && (
                <ICalendar link={icsViewUrl?.pathname} />
              )}
            </dd>
          </div>
        )}
      </>
    );
  };

  if (!hasAdditionalInfo) {
    return (
      <div className="accordion-body">
        <div>
          <p className="no_infos">
            {intl.formatMessage(messages.no_additional_information)}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="accordion-body">
      <div>
        <dl>
          {renderDateInfo()}

          {data.alternative_start && (
            <div>
              <dt>{intl.formatMessage(messages.alternative_start)}</dt>
              <dd>{data.alternative_start}</dd>
            </div>
          )}

          {data.duration && (
            <div>
              <dt>{intl.formatMessage(messages.duration)}</dt>
              <dd dangerouslySetInnerHTML={{ __html: data.duration }}></dd>
            </div>
          )}

          {data.place && (
            <div>
              <dt>{intl.formatMessage(messages.place)}</dt>
              <dd>{data.place}</dd>
            </div>
          )}

          {data.free_places && (
            <div>
              <dt>{intl.formatMessage(messages.free_places)}</dt>
              <dd>{data.free_places}</dd>
            </div>
          )}

          {data.total_places && (
            <div>
              <dt>{intl.formatMessage(messages.total_places)}</dt>
              <dd>{data.total_places}</dd>
            </div>
          )}

          {data.occ_number && (
            <div>
              <dt>{intl.formatMessage(messages.occ_number)}</dt>
              <dd>{data.occ_number}</dd>
            </div>
          )}

          {data.course_management && (
            <div>
              <dt>{intl.formatMessage(messages.course_management)}</dt>
              <dd>{data.course_management}</dd>
            </div>
          )}
          {data.final_application_date && (
            <div>
              <dt>{intl.formatMessage(messages.final_application_date)}</dt>
              <dd>
                <DateDisplay
                  start={data.final_application_date}
                  dateOnly={true}
                />
              </dd>
            </div>
          )}
          {isOpen && (
            <div className="accordion-body-signup">
              <ApplicationButton
                enabled={data.application_button_enabled === true}
                link={data.application_button_link}
                label={data.application_button_label}
              />
            </div>
          )}

          {data.additional_information && (
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: data.additional_information
                    .split('\\n')
                    .join('<br />')
                    .split('\n')
                    .join('<br />'),
                }}
              ></p>
            </div>
          )}
        </dl>
      </div>
    </div>
  );
});

export default AccordionBody;
