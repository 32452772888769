import { Icon } from '@fhnw/components/Icon/Icon';

interface SnapchatProps {
  size: 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL';
  color: string;
  onClick?: () => void;
}

const Snapchat = (props: SnapchatProps) => (
  <Icon color={props.color} onClick={props.onClick} size={props.size}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="32px"
      height="32px"
    >
      <path
        d="M16,0c8.837,0,16,7.163,16,16s-7.163,16-16,16S0,24.837,0,16S7.163,0,16,0z"
        fill="currentColor"
      />
      <path
        d="M21.894 21.321c.42-.41.482-.857.346-1.283-.143-.449-.617-.605-.897-.764-1.525-.874-1.787-1.705-1.847-1.814-.06-.13-.1-.22.06-.358.15-.1.953-.414 1.295-.991.175-.27.221-.683.065-.975-.26-.465-.694-.736-1.292-.736-.22 0-.445.047-.474.056-.011-.304-.012-.657-.03-.978-.09-1.334-1.191-4.455-4.053-4.455s-3.963 3.121-4.053 4.455c-.019.321-.02.674-.03.978-.03-.01-.254-.057-.474-.057-.598 0-1.032.27-1.292.736-.156.292-.11.705.065.975.342.577 1.146.891 1.295.99.16.14.12.23.06.358-.06.11-.318.94-1.847 1.814-.28.16-.753.315-.897.764-.136.426-.074.872.346 1.283.487.47 1.344.586 1.458.684.113.098.163.477.523.695.36.22.804.213 1.03.232.917.07 1.156.11 1.728.466.184.116 1.09.735 2.413.735s2.229-.62 2.413-.735c.572-.355.81-.395 1.728-.466.226-.02.67-.014 1.03-.232.36-.218.41-.597.523-.695.114-.098.97-.214 1.458-.684z"
        fill="#FFFFFF"
        transform="translate(1, 0)"
      />
    </svg>
  </Icon>
);

export default Snapchat;
