import React from 'react';
import type { Location } from '@plone/types';
export interface LocationNavigationProps {
  locations: Location[];
  activeLocation: Location;
  setActiveLocation: (location: Location) => void;
}

const LocationNavigation: React.FC<LocationNavigationProps> = ({
  locations,
  activeLocation,
  setActiveLocation,
}) => {
  return (
    <nav className="location-navigation">
      <ul>
        {locations.map((location) => (
          <li key={location.title}>
            <button
              className={`tab-button ${location.title === activeLocation.title ? 'active' : ''}`}
              onClick={() => setActiveLocation(location)}
              role="tab"
              aria-selected={location.title === activeLocation.title}
            >
              {location.tabName}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default LocationNavigation;
