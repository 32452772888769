export const teaserSchemaEnhancer = ({
  schema,
  formData,
  intl,
}: {
  schema: any;
  formData: any;
  intl: any;
}) => {
  schema.properties.href.selectedItemAttrs.push('getRemoteUrl');

  return schema;
};

export const gridTeaserDisableStylingSchema = ({
  schema,
  formData,
  intl,
}: {
  schema: any;
  formData: any;
  intl: any;
}) => {
  schema.fieldsets = schema.fieldsets.filter(
    (item: { id?: string }) => item.id !== 'styling',
  );
  return schema;
};
