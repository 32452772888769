import FHNWDateFormat from '../DateFormat/FHNWDateFormat';
import { InfoEventFieldProps } from './index';
import { Link } from '../Link/Link';

const InfoEvent = ({ item }: InfoEventFieldProps) => {
  const infoEventLink = item['@id'] ? new URL(item['@id']).pathname : undefined;

  // DATE FORMATTING
  const date = item.start ? new Date(item.start) : null;
  const startDate = item.start ? new Date(item.start) : null;
  const endDate = item.end ? new Date(item.end) : null;

  const isEndDateAfterNow = (date: Date | null) => {
    if (!date) return false;
    const now = new Date();
    const specificDate = new Date(date);
    return specificDate < now;
  };

  return (
    <>
      {isEndDateAfterNow(endDate) === false && (
        <li className="teaser">
          <Link
            className="teaser-link"
            href={infoEventLink}
            aria-label={item.title}
          >
            <div className="teaser-wrapper">
              {date && (
                <span className="date" aria-label="Event date">
                  <FHNWDateFormat
                    start={date}
                    showWeekday={true}
                    wholeDay={true}
                  />
                </span>
              )}
              {startDate && endDate && (
                <span className="time" aria-label="Event time">
                  <FHNWDateFormat
                    start={startDate}
                    end={endDate}
                    timeOnly={true}
                  />
                </span>
              )}
              {item.event_location_short && (
                <span className="place" aria-label="Event location">
                  {item.event_location_short}
                </span>
              )}{' '}
            </div>
          </Link>
        </li>
      )}
    </>
  );
};

export default InfoEvent;
