import { useEffect, RefObject } from 'react';
import mapboxgl from 'mapbox-gl';
import { Location as HistoryLocation } from 'history';
import { MapAction, MapState } from '../LocationMapContent';
import { isCurrentPage } from '../utils/pageUtils';

interface AppLocation extends Omit<HistoryLocation, 'key'> {
  key?: string;
}

type InitializeMapProps = {
  mapContainerRef: RefObject<HTMLDivElement>;
  state: MapState;
  dispatch: React.Dispatch<MapAction>;
  location: AppLocation;
  oneOfThesePages: string[];
};

function useInitializeMap({
  mapContainerRef,
  state,
  dispatch,
  location,
  oneOfThesePages,
}: InitializeMapProps) {
  useEffect(() => {
    if (!mapContainerRef.current) return;

    const isMatchingPage = isCurrentPage(location.pathname, oneOfThesePages);

    dispatch({
      type: 'SET_ONE_OF_THESE_PAGES',
      payload: isMatchingPage,
    });

    const mapInstance = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'https://maps.fhnw.ch/res/style-cdn.json',
      center: [7.9, 47.46],
      zoom: state.activeLocation.zoomLevel,
      interactive: true,
      scrollZoom: false,
    });

    mapInstance.on('load', () => {
      mapInstance.addSource('universities', {
        type: 'geojson',
        data: 'https://maps.fhnw.ch/universities.json',
      });
      mapInstance.addLayer({
        id: 'university-buildings',
        type: 'fill',
        source: 'universities',
        paint: { 'fill-color': '#FDE70D', 'fill-opacity': 0.5 },
        filter: ['==', '$type', 'Polygon'],
      });
      mapInstance.addLayer({
        id: 'housenumber-labels',
        type: 'symbol',
        source: 'universities',
        minzoom: 15,
        layout: {
          'text-field': '{Housenumber}',
          'text-justify': 'center',
          'text-font': ['Noto Sans Bold'],
        },
        paint: {
          'text-color': '#000',
          'text-halo-color': '#fff',
          'text-halo-width': 2,
        },
      });
    });

    mapInstance.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    mapInstance.addControl(new mapboxgl.FullscreenControl(), 'bottom-right');

    dispatch({
      type: 'SET_MAP_AND_IS_CLIENT',
      payload: { map: mapInstance, isClient: true },
    });

    const handleFullscreenChange = () => {
      const isFullscreen =
        document.fullscreenElement === mapInstance.getContainer();
      dispatch({ type: 'SET_FULLSCREEN', payload: isFullscreen });
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      mapInstance.remove();
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
}

export default useInitializeMap;
