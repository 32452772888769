import config from '@plone/registry';
import { DefaultGlobalSearchResultItem } from '@fhnw/components/GlobalSearch/ResultItems';

type ResultItemProps = {
  closeGlobalSearch: () => void;
  item: any;
};

const ResultItem = ({ item, closeGlobalSearch }: ResultItemProps) => {
  const ResultItemTemplate =
    config.views.globalContentTypeSearchResultViews[item['@type']] ||
    DefaultGlobalSearchResultItem;

  return (
    <ResultItemTemplate item={item} closeGlobalSearch={closeGlobalSearch} />
  );
};

export default ResultItem;
