export type SelectOption = {
  value: string;
};

export type FacetToken = {
  [key: string]: boolean;
};

export interface vocabType {
  label: string;
  value: string;
}

export interface OptionType {
  value: string;
  label: string;
}

export interface EnabledSelectOptions {
  label: string;
  value: string;
  isDisabled: boolean;
}

export interface StringKeysObject {
  [key: string]: number;
}

export const createFacetToken = (items: SelectOption[]): FacetToken =>
  items.reduce<FacetToken>((acc, item) => {
    acc[item.value] = true;
    return acc;
  }, {});

export function getTitleByToken(token: string, Vocabulary: vocabType[]) {
  const value = Vocabulary.find((item) => item.value === token);
  return value ? value.label : null;
}

export function mapTokenToLabel(token: string, Vocabulary: vocabType[]): any {
  return {
    label: getTitleByToken(token, Vocabulary),
    value: token,
    isDisabled: false,
  };
}

export const mapToEnabledSelectOptions = (
  items: vocabType[],
  enabledTokensIndex: number,
  enabledTokens: StringKeysObject[],
): EnabledSelectOptions[] => {
  return enabledTokens[enabledTokensIndex]
    ? items.map((item) => ({
        label: item.label,
        value: item.value,
        isDisabled: !!!enabledTokens[enabledTokensIndex][item.value],
      }))
    : [];
};

export function getEnabledTokens(result: any): StringKeysObject[] {
  const enabledTokens: StringKeysObject[] = [];

  if (result && result.facetFields) {
    for (const facetField of result.facetFields) {
      const enabled: StringKeysObject = {};
      for (const [token, count] of facetField[1]) {
        if (count) {
          enabled[token] = count;
        }
      }
      enabledTokens.push(enabled);
    }
  }

  return enabledTokens;
}
