// @ts-nocheck
import { formBlockSchemaEnhancer } from '../components/Blocks/Form/schema';

import Button from '../components/Button/Button';

import Checkbox from '../components/Form/Checkbox/Checkbox';
import CheckboxField from '../components/Form/CheckboxField/CheckboxField';
import CheckboxGroup from '../components/Form/CheckboxGroup/CheckboxGroup';
import DatePicker from '../components/Form/DatePicker/DatePicker';
import FileSelector from '../components/Form/FileSelector/FileSelector';
import RadioGroup from '../components/Form/RadioGroup/RadioGroup';
import Select from '../components/Form/Select/Select';
import TextAreaField from '../components/Form/TextAreaField/TextAreaField';
import TextField from '../components/Form/TextField/TextField';
import TimeField from '../components/Form/TimeField/TimeField';

import CheckboxWrapper from 'volto-form-block/components/Wrappers/CheckboxWrapper';
import CheckboxGroupWrapper from 'volto-form-block/components/Wrappers/CheckboxGroupWrapper';
import DatetimeWrapper from 'volto-form-block/components/Wrappers/DatetimeWrapper';
import EmailWrapper from 'volto-form-block/components/Wrappers/EmailWrapper';
import FileWrapper from 'volto-form-block/components/Wrappers/FileWrapper';
import HiddenWrapper from 'volto-form-block/components/Wrappers/HiddenWrapper';
import NumberWrapper from 'volto-form-block/components/Wrappers/NumberWrapper';
import RadioGroupWrapper from 'volto-form-block/components/Wrappers/RadioGroupWrapper';
import SelectWrapper from 'volto-form-block/components/Wrappers/SelectWrapper';
import TextareaWrapper from 'volto-form-block/components/Wrappers/TextareaWrapper';
import TextWrapper from 'volto-form-block/components/Wrappers/TextWrapper';
import TimeWrapper from 'volto-form-block/components/Wrappers/TimeWrapper';
import FormComponent from 'volto-form-block/components/Wrappers/FormComponent';

import { Radio } from 'react-aria-components';

import { defineMessages } from 'react-intl';

import countries from './countries.json';

const messages = defineMessages({
  termsAccepted: {
    id: 'termsAccepted',
    defaultMessage: 'Accept general terms',
  },
  country: {
    id: 'country',
    defaultMessage: 'Country',
  },
  gender: {
    id: 'gender',
    defaultMessage: 'Gender',
  },
  contactAllowed: {
    id: 'contactAllowed',
    defaultMessage: 'Contact allowed',
  },
  contactAllowedTitle: {
    id: 'We would like to tell you about what is happening at FHNW in the future but require your consent to do so.',
    defaultMessage:
      'We would like to tell you about what is happening at FHNW in the future but require your consent to do so.',
  },
  contactAllowedYes: {
    id: 'I would like to receive more information',
    defaultMessage: 'I would like to receive more information',
  },
  contactAllowedNo: {
    id: 'I would not like to receive any more information',
    defaultMessage: 'I would not like to receive any more information',
  },
  dataprotectionInfo: {
    id: 'dataprotectionInfo',
    defaultMessage: 'Data protection info',
  },
  dataprotectionInfoValue: {
    id: '<p><strong>Data privacy</strong></p><p>Without your further consent, the data sent will only be used for the purposes of processing your request. You can find more information on the <a href="https://www.fhnw.ch/en/imprint/data-protection" target="_blank" rel="noopener noreferrer" class="external">Legal page</a>.</p>',
    defaultMessage:
      '<p><strong>Data privacy</strong></p><p>Without your further consent, the data sent will only be used for the purposes of processing your request. You can find more information on the <a href="https://www.fhnw.ch/en/imprint/data-protection" target="_blank" rel="noopener noreferrer" class="external">Legal page</a>.</p>',
  },
  phonenumber: {
    id: 'phonenumber',
    defaultMessage: 'Phone number',
  },
  Female: {
    id: 'Female',
    defaultMessage: 'Female',
  },
  Male: {
    id: 'Male',
    defaultMessage: 'Male',
  },
  Other: {
    id: 'Other',
    defaultMessage: 'Other',
  },
  termsAcceptedValue: {
    id: 'I agree with the general terms and conditions',
    defaultMessage: 'I agree with the general terms and conditions',
  },
});

const ButtonWrapper = ({ primary, secondary, type, title, onClick }) => {
  return (
    <Button
      type={type === 'submit' ? 'submit' : 'button'}
      styles={secondary ? 'btn btn-secondary' : 'btn btn-primary'}
      onPress={onClick || (() => {})}
      label={title}
    />
  );
};

const schemaFormBlock = (config) => {
  config.registerUtility({
    name: 'termsAccepted',
    type: 'fieldFactoryProperties',
    method: (intl) => ({}),
  });

  config.registerUtility({
    name: 'termsAccepted',
    type: 'fieldFactoryInitialData',
    method: (intl) => ({
      type: 'boolean',
      required: true,
      factory: 'termsAccepted',
      title: intl.formatMessage(messages.termsAcceptedValue),
      id: 'terms-accepted',
    }),
  });

  config.registerUtility({
    name: 'country',
    type: 'fieldFactoryProperties',
    method: (intl) => ({}),
  });

  config.registerUtility({
    name: 'country',
    type: 'fieldFactoryInitialData',
    method: (intl) => ({
      type: 'string',
      factory: 'country',
      choices: countries[intl.locale].map((country) => [country, country]),
      id: 'country',
    }),
  });

  config.registerUtility({
    name: 'gender',
    type: 'fieldFactoryProperties',
    method: (intl) => ({}),
  });

  config.registerUtility({
    name: 'gender',
    type: 'fieldFactoryInitialData',
    method: (intl) => ({
      type: 'string',
      factory: 'gender',
      widget: 'radio_group',
      choices: [
        [
          intl.formatMessage(messages.Female),
          intl.formatMessage(messages.Female),
        ],
        [intl.formatMessage(messages.Male), intl.formatMessage(messages.Male)],
        [
          intl.formatMessage(messages.Other),
          intl.formatMessage(messages.Other),
        ],
      ],
      id: 'gender',
    }),
  });

  config.registerUtility({
    name: 'contactAllowed',
    type: 'fieldFactoryProperties',
    method: (intl) => ({}),
  });

  config.registerUtility({
    name: 'contactAllowed',
    type: 'fieldFactoryInitialData',
    method: (intl) => ({
      type: 'string',
      factory: 'contactAllowed',
      title: intl.formatMessage(messages.contactAllowedTitle),
      widget: 'radio_group',
      choices: [
        [
          intl.formatMessage(messages.contactAllowedYes),
          intl.formatMessage(messages.contactAllowedYes),
        ],
        [
          intl.formatMessage(messages.contactAllowedNo),
          intl.formatMessage(messages.contactAllowedNo),
        ],
      ],
      id: 'contact-allowed',
    }),
  });

  config.registerUtility({
    name: 'dataprotectionInfo',
    type: 'fieldFactoryProperties',
    method: (intl) => ({}),
  });

  config.registerUtility({
    name: 'dataprotectionInfo',
    type: 'fieldFactoryInitialData',
    method: (intl) => ({
      type: 'object',
      factory: 'dataprotectionInfo',
      widget: 'static_text',
      default: {
        data: intl.formatMessage(messages.dataprotectionInfoValue),
      },
      id: 'dataprotectioninfo',
    }),
  });

  config.registerUtility({
    name: 'phonenumber',
    type: 'fieldFactoryProperties',
    method: (intl) => ({}),
  });

  config.registerUtility({
    name: 'phonenumber',
    type: 'fieldFactoryInitialData',
    method: (intl) => ({
      type: 'string',
      factory: 'phonenumber',
      id: 'phonenumber',
    }),
  });

  config.blocks.blocksConfig.schemaForm = {
    ...config.blocks.blocksConfig.schemaForm,
    defaultSender: 'noreply@fhnw.ch',
    defaultSenderName: 'FHNW',
    group: 'content',
    widgets: {
      ...config.widgets,
      widget: {
        ...config.widgets.widget,
        textarea: TextareaWrapper,
        file: FileWrapper,
        date: DatetimeWrapper,
        time: TimeWrapper,
        datetime: DatetimeWrapper,
        email: EmailWrapper,
        radio_group: RadioGroupWrapper,
        checkbox_group: CheckboxGroupWrapper,
        hidden: HiddenWrapper,
      },
      choices: SelectWrapper,
      type: {
        ...config.widgets.type,
        object: FileWrapper,
        date: DatetimeWrapper,
        datetime: DatetimeWrapper,
        number: NumberWrapper,
        integer: NumberWrapper,
        boolean: CheckboxWrapper,
      },
      default: TextWrapper,
    },
    component: FormComponent,
    buttonComponent: ButtonWrapper,
    innerWidgets: {
      text: TextField,
      textarea: TextAreaField,
      file: FileSelector,
      date: DatePicker,
      datetime: DatePicker,
      time: TimeField,
      email: TextField,
      radioGroup: RadioGroup,
      radioGroupOption: Radio,
      number: TextField,
      checkbox: CheckboxField,
      checkboxGroup: CheckboxGroup,
      checkboxGroupOption: Checkbox,
      select: Select,
      hidden: TextField,
    },
    additionalFactory: [
      ...config.blocks.blocksConfig.schemaForm.additionalFactory,
      { value: 'termsAccepted', label: 'Accept general terms' },
      { value: 'country', label: 'Country' },
      { value: 'gender', label: 'Gender' },
      { value: 'contactAllowed', label: 'Contact allowed' },
      { value: 'dataprotectionInfo', label: 'Data protection info' },
      { value: 'phonenumber', label: 'Phone number' },
    ],
    filterFactory: [
      ...config.blocks.blocksConfig.schemaForm.filterFactory,
      'termsAccepted',
      'country',
      'gender',
      'contactAllowed',
      'dataprotectionInfo',
      'phonenumber',
    ],
    filterFactorySend: [
      ...config.blocks.blocksConfig.schemaForm.filterFactorySend,
      'dataprotectionInfo',
    ],
    schemaEnhancer: formBlockSchemaEnhancer,
  };

  return config;
};

export default schemaFormBlock;
