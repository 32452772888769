import { defineMessages } from 'react-intl';

export const InfoEventMessages = defineMessages({
  title: {
    id: 'info_event_title',
    defaultMessage: 'Next information events',
  },
  show_all: {
    id: 'info_event_show_all',
    defaultMessage: 'Show all',
  },
  no_results: {
    id: 'info_event_no_results',
    defaultMessage: 'No results available. Please check your information.',
  },
  info_event_subjects: {
    id: 'info_event_subjects',
    defaultMessage: 'Keyword Filter',
  },
  info_event_roominfo: {
    id: 'info_event_roominfo',
    defaultMessage: 'Room Information',
  },
  info_event_emptytext: {
    id: 'info_event_emptytext',
    defaultMessage: 'Infotext if no Info-Events are available',
  },
});
