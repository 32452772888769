import React from 'react';
import { SidebarPortal, BlockDataForm } from '@plone/volto/components';
import FollowUsBlockSchema from './FollowUsBlockSchema';
import { withBlockExtensions } from '@plone/volto/helpers';
import {
  useFolderEffect,
  useQueryEffect,
  useSyncBlockDataEffect,
} from './hooks';
import { IntlShape } from 'react-intl';
import { useSelector } from 'react-redux';
import FollowUs from './FollowUs';
import type { FollowUsData } from '@fhnw/types/followUs';

interface FollowUsBlockEditProps {
  data: FollowUsData;
  block: string;
  onChangeBlock: (blockId: string, newData: FollowUsData) => void;
  selected: boolean;
  intl: IntlShape;
}

const FollowUsBlockEdit: React.FC<FollowUsBlockEditProps> = (props) => {
  const { data, block, onChangeBlock } = props;

  const [title, setTitle] = React.useState(data.title);
  const [description, setDescription] = React.useState(data.description);

  const links = useSelector((state: any) => {
    return state.querystringsearch.subrequests[block]?.items;
  });

  useFolderEffect(data, block);
  useQueryEffect(data, block);
  useSyncBlockDataEffect(data, title, setTitle, description, setDescription);

  return (
    <div>
      <FollowUs title={title} description={description} links={links} />

      <SidebarPortal selected={props.selected}>
        <BlockDataForm
          schema={FollowUsBlockSchema(props.intl)}
          title="FollowUs Block"
          onChangeField={(id: any, value: any) =>
            onChangeBlock(block, { ...data, [id]: value })
          }
          onChangeBlock={onChangeBlock}
          formData={data}
          block={block}
        />
      </SidebarPortal>
    </div>
  );
};

export default withBlockExtensions(FollowUsBlockEdit);
