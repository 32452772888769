const typesToId = {
  Contact: 'Contact Global',
  DegreeProgram: 'Degree Program Global',
  FHNWNews: 'FHNW News Global',
  FHNWEvent: 'FHNW Event Global',
  ContinuingEducation: 'Continuing Education Global',
  File: 'File Global',
  Page: 'Page Global',
};

export const mapContentTypesToLocaleId = (type) => {
  return typesToId[type] || type;
};
