/**
 * Extended breadcrumbs reducer.
 * @module reducers/extended_breadcrumbs/extended_breadcrumbs
 *
 *  A replacement for the original breadcrumbs action, which
 * does not support subrequest. Adding the subrequest is the only
 * modification done.
 */

import { GET_BREADCRUMBS } from '@fhnw/constants/ActionTypes';

/**
 * Get breadcrumbs.
 * @function getBreadcrumbs
 * @param {string} url Content url.
 * @param {string} subrequest Optional subrequest identifier.
 * @returns {Object} Get breadcrumbs action.
 */
export function getBreadcrumbs(url: string, subrequest?: string) {
  return {
    type: GET_BREADCRUMBS,
    request: {
      op: 'get',
      path: `${url}/@breadcrumbs`,
    },
    subrequest,
  };
}
