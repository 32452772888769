import React, { useEffect, useMemo, useReducer, useRef } from 'react';
import ReactDOM from 'react-dom';
import useInitializeMap from './hooks/useInitializeMap';
import useMapManager from './hooks/useMapManager';
import { useLocation } from 'react-router';
import LocationNavigation from './LocationNavigation';
import LocationInfoBox from './LocationInfoBox';
import type { Content, Location } from '@plone/types';
import useDynamicLocations from './hooks/useDynamicLocations';
import { useIntl } from 'react-intl';
import { LocationMapMessages as messages } from './LocationMapMessages';

export type MapState = {
  map: mapboxgl.Map | null;
  activeLocation: Location;
  isSetFitBounds: boolean;
  isOneOfThesePages: boolean;
  isClient: boolean;
  isFullscreen: boolean;
};

export type MapAction =
  | {
      type: 'SET_MAP_AND_IS_CLIENT';
      payload: { map: mapboxgl.Map; isClient: boolean };
    }
  | { type: 'SET_FIT_BOUNDS'; payload: boolean }
  | {
      type: 'SET_LOCATION_AND_BOUNDS';
      payload: { location: Location; fitBounds: boolean };
    }
  | { type: 'SET_ONE_OF_THESE_PAGES'; payload: boolean }
  | { type: 'SET_FULLSCREEN'; payload: boolean };

function mapReducer(state: MapState, action: MapAction): MapState {
  switch (action.type) {
    case 'SET_MAP_AND_IS_CLIENT':
      return {
        ...state,
        map: action.payload.map,
        isClient: action.payload.isClient,
      };
    case 'SET_FIT_BOUNDS':
      return { ...state, isSetFitBounds: action.payload };
    case 'SET_LOCATION_AND_BOUNDS':
      return {
        ...state,
        activeLocation: action.payload.location,
        isSetFitBounds: action.payload.fitBounds,
      };
    case 'SET_ONE_OF_THESE_PAGES':
      return { ...state, isOneOfThesePages: action.payload };
    case 'SET_FULLSCREEN':
      return { ...state, isFullscreen: action.payload };
    default:
      return state;
  }
}

interface LocationMapProps {
  content: Content;
  oneOfThesePages: string[];
  locationsData: Location[];
}

const offset: [number, number] = [-29, -35];

const LocationMapContent: React.FC<LocationMapProps> = ({
  content,
  oneOfThesePages,
  locationsData,
}) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const intl = useIntl();

  const { locations } = useDynamicLocations({
    content,
    location,
    oneOfThesePages,
    locationsData,
  });

  const initialState = useMemo(
    () => ({
      map: null as mapboxgl.Map | null,
      activeLocation: locations[0],
      isSetFitBounds: false,
      isOneOfThesePages: true,
      isClient: false,
      isFullscreen: false,
    }),
    [locations],
  );

  const [state, dispatch] = useReducer(mapReducer, initialState);

  useInitializeMap({
    mapContainerRef,
    state,
    dispatch,
    location,
    oneOfThesePages,
  });

  const { handleLocationClick } = useMapManager({
    locationsData: locations,
    state,
    dispatch,
    location,
    oneOfThesePages,
    offset,
    mapInstance: state.map as mapboxgl.Map,
  });

  const renderLocationInfoBox = () => (
    <LocationInfoBox location={state.activeLocation} />
  );

  const renderInfoBoxInFullscreen = () =>
    state.isFullscreen &&
    !state.isSetFitBounds &&
    ReactDOM.createPortal(
      renderLocationInfoBox(),
      state.map?.getContainer() || document.body,
    );

  useEffect(() => {
    const id = 'mapbox-gl-css';

    if (!document.getElementById(id)) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = '/mapbox-gl/css/mapbox-gl.css';
      link.id = id;
      document.head.appendChild(link);
    }

    return () => {
      const existing = document.getElementById(id);
      if (existing) {
        existing.remove();
      }
    };
  }, []);

  return (
    locations &&
    locations.length > 0 && (
      <div className="location-map">
        {state.isOneOfThesePages && state.isClient && (
          <>
            <h1 className="text-size-lg text-weight-semibold centered">
              {intl.formatMessage(messages.oneOfThesePagesTitle)}
            </h1>
            <p className="centered pb-lg">
              {intl.formatMessage(messages.oneOfThesePagesDescription)}
            </p>
          </>
        )}
        {content?.locations_title && (
          <h2 className="text-size-lg text-weight-semibold has-b-border locations-title">
            {content.locations_title}
          </h2>
        )}
        {content?.locations_description && (
          <p className="locations-description">
            {content.locations_description}
          </p>
        )}
        {!state.isOneOfThesePages && (
          <LocationNavigation
            locations={locations}
            activeLocation={state.activeLocation}
            setActiveLocation={handleLocationClick}
          />
        )}
        <div className="location-map-container">
          {renderInfoBoxInFullscreen()}
          {!state.isFullscreen &&
            !state.isSetFitBounds &&
            renderLocationInfoBox()}
          <div
            key={location.pathname}
            ref={mapContainerRef}
            style={{ width: '100%', height: '670px' }}
          />
        </div>
      </div>
    )
  );
};

export default LocationMapContent;
