import baseMessages from '@fhnw/messages/base';

export const TableOfContentsSchema = ({ intl }) => {
  return {
    title: intl.formatMessage(baseMessages.toc),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'ordered', 'levels'],
      },
      {
        id: 'variant',
        title: intl.formatMessage(baseMessages.Variant),
        fields: ['enumeration'],
      },
    ],
    properties: {
      title: {
        title: intl.formatMessage(baseMessages.Title),
      },
      levels: {
        title: intl.formatMessage(baseMessages.Entries),
        isMulti: true,
        choices: [
          ['h2', 'h2'],
          ['h3', 'h3'],
          ['h4', 'h4'],
          ['h5', 'h5'],
          ['h6', 'h6'],
        ],
        default: ['h2', 'h3', 'h4', 'h5', 'h6'],
      },
      ordered: {
        title: intl.formatMessage(baseMessages.Ordered),
        type: 'boolean',
        default: false,
      },
      enumeration: {
        title: intl.formatMessage(baseMessages.Enumeration),
        type: 'boolean',
        default: true,
      },
    },
    required: [],
  };
};

export default TableOfContentsSchema;
