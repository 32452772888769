/**
 * Checks whether a given string contains HTML tags.
 *
 * This is a simple detection using a regex to identify basic HTML structures
 * like <p>, <br />, <div>, etc. It helps to distinguish between plain text and
 * actual HTML content — for example, to convert line breaks into <br /> only
 * when no HTML is present.
 *
 * @param str - The input string to check
 * @returns true if HTML tags are detected, otherwise false
 * @example
 * const text = "<p>Hello World</p>";
 * const result = containsHTML(text);
 * // result: true
 */
const containsHTML = (str: string): boolean => {
  const htmlPattern = /<\/?[a-z][\s\S]*?>/i;
  return htmlPattern.test(str);
};

export default containsHTML;
