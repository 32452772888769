import type { Location } from '@plone/types';

export const fallbackLocationsData: Location[] = [
  {
    '@id': 'http://localhost:8080/Plone/de/start-maps/olten',
    title: 'Campus Olten',
    navTitle: 'Standort Olten',
    address: {
      name: 'Fachhochschule Nordwestschweiz FHNW',
      department: 'Campus-Bibliothek Olten 6.1C',
      street: 'Bahnhofstrasse 6',
      zipCity: '5200 Olten',
    },
    contactData: {
      telephone: '+41 56 202 77 70',
      email: 'bibliothek.windisch@fhnw.ch',
    },
    coordinates: {
      x: 7.907868513413526,
      y: 47.348195040590305,
    },
    zoomLevel: 16,
    locationPageURL: '/pages/location_brugg/location_brugg.html',
    routeURL: 'https://maps.google.ch',
    tabName: 'Olten',
    buttonLink: 'https://www.fhnw.ch',
    showInSlider: true,
  },
  {
    '@id': 'http://localhost:8080/Plone/de/start-maps/brugg-windisch',
    title: 'Campus Brugg-Windisch',
    navTitle: 'Standort Brugg',
    address: {
      name: 'Fachhochschule Nordwestschweiz FHNW',
      department: 'Campus-Bibliothek Brugg-Windisch 6.1C',
      street: 'Bahnhofstrasse 6',
      zipCity: '5210 Windisch',
    },
    contactData: {
      telephone: '+41 56 202 77 70',
      email: 'bibliothek.windisch@fhnw.ch',
    },
    coordinates: {
      x: 8.211382640054387,
      y: 47.48176398027476,
    },
    zoomLevel: 16,
    locationPageURL: '/pages/location_brugg/location_brugg.html',
    routeURL: 'https://maps.google.ch',
    tabName: 'Brugg-Windisch',
    buttonLink: 'https://www.fhnw.ch',
    showInSlider: true,
  },
];
