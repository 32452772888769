import { useLocation } from 'react-router-dom';
import CRMFormBlockView from './CRMFormBlockView';
import SidebarPortal from '@plone/volto/components/manage/Sidebar/SidebarPortal';
import BlockDataForm from '@plone/volto/components/manage/Form/BlockDataForm';
import { CRMFormBlockData } from './types';
import CRMFormBlockSchema from './CRMFormBlockSchema';
import { CSSProperties } from 'react';

interface CRMFormBlockEditProps {
  data: CRMFormBlockData;
  onChangeBlock: (blockId: string, newData: CRMFormBlockData) => void;
  block: string;
  selected: boolean;
  className: string;
  style: CSSProperties;
}

const CRMFormBlockEdit = (props: CRMFormBlockEditProps) => {
  const { data, onChangeBlock, selected, block, className, style } = props;
  const location = useLocation();
  const schema = CRMFormBlockSchema(props);

  return (
    <>
      {data.formBlockId && data.websiteId && data.hostname && data.version ? (
        <CRMFormBlockView
          data={data}
          key={location.key}
          className={className}
          style={style}
          isEditMode={true}
        />
      ) : (
        <div className="teaser-item placeholder">
          <p>
            Setze die Website ID und die Form Block ID um das CRM-Formular
            hinzuzufügen.
          </p>
        </div>
      )}
      <SidebarPortal selected={selected}>
        <BlockDataForm
          schema={schema}
          title={schema.title}
          onChangeField={(id: any, value: any) =>
            onChangeBlock(block, { ...data, [id]: value })
          }
          onChangeBlock={onChangeBlock}
          formData={data}
          block={block}
        />
      </SidebarPortal>
    </>
  );
};

export default CRMFormBlockEdit;
