/**
 * OVERRIDE EditSchema.jsx
 * REASON: https://gitlab.fhnw.ch/webteam/fhnw-webauftritt-2025/-/issues/98 .
 *         Add schema properties as per the requirements.
 * FILE: https://github.com/eea/volto-group-block/blob/30a935a7155da37344418b24212c60586e0f9405/src/components/manage/Blocks/Group/EditSchema.jsx
 * FILE VERSION: eea/volto-group-block 7.1.0
 * PULL REQUEST: https://gitlab.fhnw.ch/webteam/fhnw-webauftritt-2025/-/merge_requests/273
 * TICKET: https://gitlab.fhnw.ch/webteam/fhnw-webauftritt-2025/-/issues/98
 * DATE: 2024-10-17
 * DEVELOPER: @tisha.soumya
 * CHANGELOG:
 *  - Implement group block (#273) @tisha.soumya
 *
 * Depending on the nature of the change, if possible or suitable, mark the change with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */

import { defineMessages } from 'react-intl';
import baseMessages from '@fhnw/messages/base';

// START CUSTOMIZATION
const messages = defineMessages({
  Name: {
    id: 'name',
    defaultMessage: 'Name',
  },
  Default: {
    id: 'default',
    defaultMessage: 'Default',
  },
});

export function Schema(props) {
  const { intl } = props;
  return {
    title: 'Gruppe block',
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.Default),
        fields: [
          'title',
          'showOnDesktop',
          'showOnMobile',
          'visibleFrom',
          'visibleUntil',
        ],
      },
    ],
    properties: {
      title: {
        title: intl.formatMessage(messages.Name),
        type: 'string',
      },
      showOnMobile: {
        title: intl.formatMessage(baseMessages.showOnMobile),
        type: 'boolean',
        default: true,
      },
      showOnDesktop: {
        title: intl.formatMessage(baseMessages.showOnDesktop),
        type: 'boolean',
        default: true,
      },
      visibleFrom: {
        title: intl.formatMessage(baseMessages.visibleFrom),
        type: 'string',
        widget: 'datetime',
      },
      visibleUntil: {
        title: intl.formatMessage(baseMessages.visibleUntil),
        type: 'string',
        widget: 'datetime',
      },
    },
    required: [],
  };
}
// END CUSTOMIZATION

export default Schema;
