import { defineMessages } from 'react-intl';

export const IrfMessages = defineMessages({
  person: {
    id: 'block.irf.person',
    defaultMessage: 'Person Selection',
  },
  person_description: {
    id: 'block.irf.person_description',
    defaultMessage: 'Select a person whose IRF content should be displayed.',
  },
  collection: {
    id: 'block.irf.collection',
    defaultMessage: 'Collection Selection',
  },
  collection_description: {
    id: 'block.irf.collection_description',
    defaultMessage:
      'Select a collection whose IRF content should be displayed.',
  },
  selected_types: {
    id: 'block.irf.selected_types',
    defaultMessage: 'Displayed Publication Types',
  },
  selected_types_description: {
    id: 'block.irf.selected_types_description',
    defaultMessage: 'Choose which publication types to display.',
  },
  citation_style: {
    id: 'block.irf.citation_style',
    defaultMessage: 'Citation Style Selection',
  },
  citation_style_description: {
    id: 'block.irf.citation_style_description',
    defaultMessage: 'Select the citation style for displaying publications.',
  },
  excluded_publications: {
    id: 'block.irf.excluded_publications',
    defaultMessage: 'Excluded Publications',
  },
  excluded_publications_description: {
    id: 'block.irf.excluded_publications_description',
    defaultMessage:
      'Enter URLs of publications to exclude from the display, separated by commas.',
  },
  newer_than: {
    id: 'block.irf.newer_than',
    defaultMessage: 'Newer Than',
  },
  newer_than_description: {
    id: 'block.irf.newer_than_description',
    defaultMessage: 'Filter publications newer than the selected year.',
  },
  only_peer_reviewed: {
    id: 'block.irf.only_peer_reviewed',
    defaultMessage: 'Display Only Peer-Reviewed',
  },
  only_peer_reviewed_description: {
    id: 'block.irf.only_peer_reviewed_description',
    defaultMessage:
      'If selected, only peer-reviewed publications will be displayed.',
  },
  only_peer_reviewed_switch: {
    id: 'block.irf.only_peer_reviewed_switch',
    defaultMessage: 'Only Peer-Reviewed',
  },
  no_peer_reviewed_message: {
    id: 'block.irf.no_peer_reviewed_message',
    defaultMessage: 'No peer-reviewed content available',
  },
  no_data_selected: {
    id: 'block.irf.no_data_selected',
    defaultMessage: 'Please choose a person or a collection.',
  },
  processing_message: {
    id: 'block.irf.processing_message',
    defaultMessage: 'Data is processed.',
  },
  processing_message_failed: {
    id: 'block.irf.processing_message_failed',
    defaultMessage: 'Data processing failed!',
  },
  display_content_grouped: {
    id: 'block.irf.display_content_grouped',
    defaultMessage: 'Display Content Grouped',
  },
  content_no_grouping: {
    id: 'block.irf.content_no_grouping',
    defaultMessage: 'No Grouping',
  },
  content_group_by_type: {
    id: 'block.irf.content_group_by_type',
    defaultMessage: 'Display content grouped by publication type',
  },
  display_content_grouped_description: {
    id: 'block.irf.display_content_grouped_description',
    defaultMessage: 'Choose how to display content grouping.',
  },
  tabPublications: {
    id: 'block.irf.tabPublications',
    defaultMessage: 'Publications',
  },
  tabProjects: {
    id: 'block.irf.tabProject',
    defaultMessage: 'Projects',
  },
  tabPresentations: {
    id: 'block.irf.tabPresentations',
    defaultMessage: 'Presentations',
  },
  tabSupervisedTheses: {
    id: 'block.irf.tabSupervisedTheses',
    defaultMessage: 'Supervised Theses',
  },
  tabOthers: {
    id: 'block.irf.tabOthers',
    defaultMessage: 'Others',
  },
  dataSyncUTD: {
    id: 'block.irf.dataSyncUTD',
    defaultMessage: 'The data is up to date.',
  },
  dataSyncOOD: {
    id: 'block.irf.dataSyncOOD',
    defaultMessage: 'Synchronization of data is in progress.',
  },
  serverError500: {
    id: 'block.irf.serverError500',
    defaultMessage:
      'Server Error 500: The server is not responding or connected.',
  },
  saveDatas: {
    id: 'block.irf.saveDatas',
    defaultMessage: 'You can now save your selection.',
  },
});
