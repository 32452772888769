const CRMFormBlockSchema = (props: any) => ({
  title: 'Dynamics CRM Formular',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['formBlockId', 'websiteId', 'hostname', 'version'],
    },
  ],
  properties: {
    formBlockId: {
      title: 'Form Block ID',
      type: 'string',
    },
    websiteId: {
      title: 'Website ID',
      type: 'string',
      default: '',
      widget: 'text',
    },
    hostname: {
      title: 'Hostname',
      type: 'string',
      default: '016be72792894e29a96f0c8561dbd488.svc.dynamics.com',
      widget: 'text',
    },
    version: {
      title: 'Version',
      type: 'string',
      default: '1.84.2007',
      widget: 'text',
    },
  },
  required: ['formBlockId', 'websiteId', 'hostname', 'version'],
});

export default CRMFormBlockSchema;
