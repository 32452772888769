import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { EduBoxMessages as messages, EduBoxFieldProps } from './index';
import { Link } from '../Link/Link';
import Button from '../Button/Button';
import { UniversalLink } from '@plone/volto/components';
import { getReference } from '@fhnw/helpers/Url/UrlHelper';
const EduBoxButtons = ({ content }: { content: EduBoxFieldProps }) => {
  const intl = useIntl();
  const [hasAccordion, setAppAccordion] = useState<boolean>(false);

  useEffect(() => {
    const jumpMark = document.querySelector('#event-application-accordion'); // see reference ApplicationAccordion.tsx
    if (jumpMark) {
      setAppAccordion(true);
    } else {
      setAppAccordion(false);
    }
  }, []);

  return (
    <>
      <div className="web25__edubox-buttons">
        {/* INFO EVENT BUTTON */}
        {content.info_event_path && (
          <Link
            className={`btn--md btn--link btn-primary w-100`}
            href={content.info_event_path}
            linkComponent={UniversalLink}
          >
            {intl.formatMessage(messages.info_event_button_text)}
          </Link>
        )}

        {/* APPLICATION BUTTON */}
        {content.application_enabled ? (
          <Link
            className={`btn--md btn--link btn-primary ${content.info_event_path ? 'w-100' : 'w-50'}`}
            href={
              hasAccordion === true
                ? `#event-application-accordion`
                : getReference(content.application_button_link)
            }
            title={
              content.application_button_text
                ? content.application_button_text
                : intl.formatMessage(messages.application_button_text)
            }
            linkComponent={UniversalLink}
          >
            {content.application_button_text_enabled === true &&
            content.application_button_text
              ? content.application_button_text
              : intl.formatMessage(messages.application_button_text)}
          </Link>
        ) : (
          <Button
            label={
              content.application_enabled === false &&
              content.application_button_text_enabled === false
                ? intl.formatMessage(messages.application_button_text)
                : content.application_button_text
                  ? content.application_button_text
                  : intl.formatMessage(messages.application_button_text)
            }
            onPress={() => {}}
            styles={`btn--md btn--link btn-primary ${content.info_event_path ? 'w-100' : 'w-50'}`}
            type="button"
            isDisabled={true}
          />
        )}
      </div>
    </>
  );
};

export default EduBoxButtons;
