/**
 * OVERRIDE View.jsx
 * REASON: https://gitlab.fhnw.ch/webteam/fhnw-webauftritt-2025/-/issues/98 .
 *         Add conditions for the visiblity of the block.
 * FILE: https://github.com/eea/volto-group-block/blob/30a935a7155da37344418b24212c60586e0f9405/src/components/manage/Blocks/Group/View.jsx
 * FILE VERSION: eea/volto-group-block 7.1.0
 * PULL REQUEST: https://gitlab.fhnw.ch/webteam/fhnw-webauftritt-2025/-/merge_requests/273
 * TICKET: https://gitlab.fhnw.ch/webteam/fhnw-webauftritt-2025/-/issues/98
 * DATE: 2024-10-17
 * DEVELOPER: @tisha.soumya
 * CHANGELOG:
 *  - Implement group block (#273) @tisha.soumya
 *
 * Depending on the nature of the change, if possible or suitable, mark the change with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */

import React from 'react';
import { withBlockExtensions } from '@plone/volto/helpers';
import BodyComponent from '@eeacms/volto-group-block/components/manage/Blocks/Group/Body.jsx';
import cx from 'classnames';

const View = (props) => {
  const { data } = props;
  // START CUSTOMIZATION
  const startDate = new Date(data.visibleFrom);
  const endDate = new Date(data.visibleUntil);
  const currentDate = new Date();
  const startVisible = data.visibleFrom ? currentDate >= startDate : true;
  const endVisible = data.visibleUntil ? currentDate < endDate : true;
  const visible = startVisible && endVisible;
  const CustomTag = `${data.as || 'div'}`;
  const customId = data?.title
    ?.toLowerCase()
    ?.replace(/[^a-zA-Z-\s]/gi, '')
    ?.trim()
    ?.replace(/\s+/gi, '-');

  return (
    <CustomTag id={customId}>
      {visible ? (
        <div
          className={cx('group-block-view', {
            show_on_desktop: data.showOnDesktop,
            show_on_mobile: data.showOnMobile,
            hide_on_all_viewports:
              data.showOnMobile === false && data.showOnDesktop === false,
          })}
        >
          <BodyComponent {...props} />
        </div>
      ) : null}
    </CustomTag>
  );
  // END CUSTOMIZATION
};

export default withBlockExtensions(View);
