/**
 * Extracts type codes from an array of type strings by splitting on hyphen and taking first part.
 * @param types - Array of type strings in format "CODE-Description" or null
 * @returns Array of uppercase type codes, or empty array if input is null
 */
export const extractTypeCodes = (types: string[] | null) => {
  if (!types) return [];
  return types.map((type) => {
    const [code] = type.split('-');
    return code.trim().toUpperCase();
  });
};
