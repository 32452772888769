import addOnSVG from '@plone/volto/icons/add-on.svg';
import attachmentSVG from '@plone/volto/icons/attachment.svg';
import degreeProgramSVG from '../icons/degree-program.svg';
import contentListingSVG from '@plone/volto/icons/content-listing.svg';
import continuingEducationSVG from '../icons/continuing-education.svg';
import conEduEventSVG from '../icons/con-edu-event.svg';
import enterpriseSVG from '@plone/volto/icons/enterprise.svg';
import eventSVG from '@plone/volto/icons/event.svg';
import infoEventSVG from '../icons/info-event.svg';
import megaphoneSVG from '@plone/volto/icons/megaphone.svg';
import showCaseSVG from '@plone/volto/icons/showcase.svg';
import userSVG from '@plone/volto/icons/user.svg';

export const contentIcons = {
  DegreeProgram: degreeProgramSVG,
  // Image is already covered by the default icon
  File: attachmentSVG,
  Occasion: contentListingSVG,
  InfoEvent: infoEventSVG,
  Event: eventSVG,
  ConEduEvent: conEduEventSVG,
  Contact: userSVG,
  // Link is already covered by the default icon
  ModuleEvent: addOnSVG,
  News: megaphoneSVG,
  Location: enterpriseSVG,
  Project: showCaseSVG,
  // Document (Page) is already covered by the default icon
  ContinuingEducation: continuingEducationSVG,
};
