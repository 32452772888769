/**
 * Checks if the current page path matches any of the specified paths.
 *
 * This function normalizes the given `path` by removing trailing slashes
 * and the optional `/edit` suffix. It then checks if the normalized path
 * is included in the list of `pages` provided.
 *
 * @param path - The current page path to be checked (e.g., from `location.pathname`).
 * @param pages - An array of page paths to match against (e.g., ['/de', '/en']).
 * @returns `true` if the normalized path matches one of the specified pages; otherwise, `false`.
 *
 * @example
 * ```typescript
 * const isOnHomePage = isCurrentPage('/en/edit', ['/de', '/en']);
 * // isOnHomePage will be true because '/en/edit' normalizes to '/en'
 * ```
 */
export const isCurrentPage = (path: string, pages: string[]): boolean => {
  const normalizedPath = path.replace(/\/(edit|)\/?$/, '');
  return pages.includes(normalizedPath);
};
