import { useCallback, useState, useEffect } from 'react';

export const scrollTabIntoView = (tabIndex: number, blockId: string) => {
  // Find the specific tab block by its ID
  const blockElement = document.getElementById(blockId);
  if (!blockElement) return;

  // Find the menu element within this specific block
  const menuElement = blockElement.querySelector(
    '.tabs-secondary-variant.menu',
  );
  if (!menuElement) return;

  // Get all tab buttons within this specific menu
  const allTabs = Array.from(menuElement.querySelectorAll('.tab_button'));
  if (allTabs.length <= tabIndex) return;

  // Get the target tab
  const targetTab = allTabs[tabIndex];
  if (!targetTab) return;

  // Get dimensions for calculations
  const menuRect = menuElement.getBoundingClientRect();
  const tabRect = targetTab.getBoundingClientRect();

  // Check if tab is not fully visible
  const isTabLeftOutside = tabRect.left < menuRect.left;
  const isTabRightOutside = tabRect.right > menuRect.right;

  // Only scroll if tab is not fully visible
  if (isTabLeftOutside || isTabRightOutside) {
    // Calculate the exact amount to scroll
    let scrollAmount;

    if (isTabLeftOutside) {
      // Tab is off to the left - scroll left to make it visible
      scrollAmount = tabRect.left - menuRect.left;
    } else {
      // Tab is off to the right - scroll right to make it visible
      scrollAmount = tabRect.right - menuRect.right;
    }

    // Apply the scroll with smooth behavior
    menuElement.scrollBy({
      left: scrollAmount,
      behavior: 'smooth',
    });
  }
};

// Function to check if tabs overflow
export const checkTabsOverflow = (blockId: string): boolean => {
  const blockElement = document.getElementById(blockId);
  if (!blockElement) return false;

  const menuElement = blockElement.querySelector(
    '.tabs-secondary-variant.menu',
  ) as HTMLElement;
  if (!menuElement) return false;

  // Check if the scroll width is greater than the client width
  return menuElement.scrollWidth > menuElement.clientWidth;
};

export const useHandleResize = (blockId: string) => {
  const handleResize = useCallback(() => {
    const blockElement = document.getElementById(blockId);
    if (!blockElement) return;

    const menuElement = blockElement.querySelector(
      '.tabs-secondary-variant.menu',
    );
    if (!menuElement) return;
  }, [blockId]);

  return handleResize;
};

// Hook to track tabs overflow state
export const useTabsOverflow = (blockId: string): boolean => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (!blockId) return;

    // Check overflow initially
    const checkOverflow = () => {
      setIsOverflowing(checkTabsOverflow(blockId));
    };

    // Check after a short delay to ensure DOM is fully rendered
    const timeoutId = setTimeout(checkOverflow, 100);

    // Add resize listener to update overflow state
    window.addEventListener('resize', checkOverflow);

    // Cleanup
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', checkOverflow);
    };
  }, [blockId]);

  return isOverflowing;
};
