import useDataFetchEffect from './useDataFetchEffect';
import { getQueryStringResults } from '@plone/volto/actions';
import config from '@plone/volto/registry';
import type { FollowUsData } from '@fhnw/types/followUs';

interface QueryObject {
  b_size: number;
  depth: string;
  query: {
    i: string; // Field name
    o: string; // Operation
    v: string | string[] | null; // Value(s)
  }[];
  sort_on: string;
  sort_order: string;
  sort_order_boolean: boolean;
}

/**
 * Extracts the unique query key from a folder ID.
 * Handles both absolute and relative URLs.
 *
 * @param folderId - The folder ID to extract the query key from.
 * @returns The extracted path or null if invalid.
 */
const getUniqueQueryKey = (folderId: string): string | null => {
  const baseUrl = config.settings.apiPath || 'http://localhost:3000/++api++';
  let uniqueQueryKey: string | null = null;
  try {
    uniqueQueryKey = folderId?.startsWith('http') // Absolute URL
      ? new URL(folderId).pathname // Extract the path
      : new URL(folderId, baseUrl).pathname; // Relative URL
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error constructing URL for folderId:', folderId, error);
    uniqueQueryKey = null;
  }
  return uniqueQueryKey;
};

const staticQuery = {
  b_size: 0,
  depth: '1',
  sort_on: 'sortable_title',
  sort_order: 'ascending',
  sort_order_boolean: false,
};

const generateQuery = (folderId: string): QueryObject | [] => {
  if (!folderId) {
    // eslint-disable-next-line no-console
    console.warn('generateQuery: folderId is missing or invalid');
    return [];
  }
  const uniqueQueryKey = getUniqueQueryKey(folderId);
  return {
    ...staticQuery,
    query: [
      {
        i: 'path',
        o: 'plone.app.querystring.operation.string.absolutePath',
        v: uniqueQueryKey,
      },
      {
        i: 'portal_type',
        o: 'plone.app.querystring.operation.selection.any',
        v: ['Link'],
      },
    ],
  };
};

const extractUniqueKey = (folderId: string): string | null =>
  getUniqueQueryKey(folderId);

/**
 * A custom hook for fetching folder-related data using useDataFetchEffect.
 *
 * @param data - The input data containing folder information.
 * @param block - The block identifier for the query.
 */
const useFolderEffect = (data: FollowUsData, blockId: string): void => {
  const folderId = data.folder?.[0]?.['@id'];
  useDataFetchEffect({
    data,
    block: blockId,
    generateQuery: () => generateQuery(folderId ?? ''),
    extractUniqueKey: () => extractUniqueKey(folderId ?? ''),
    createAction: (path, query) =>
      getQueryStringResults(path, query, blockId, 1),
    validateData: () => !!folderId,
    dependencies: [folderId],
  });
};

export default useFolderEffect;
