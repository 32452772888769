import { useRef, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { getQueryStringResults } from '@plone/volto/actions';
import { useDispatch } from 'react-redux';
import { Config, InfoEvent, InfoEventMessages as messages } from './index';
import { EduBoxFieldProps } from '../EduBox';
import { Link } from '../Link/Link';
import { AnyAction } from 'redux';
import { InfoEventFieldProps } from '@fhnw/types/infoEvent';
import {
  getSubjectSearchQuery,
  getNonSubjectSearchQuery,
} from './InfoEventQuerys';

const View = ({ content }: { content: EduBoxFieldProps }) => {
  const intl = useIntl();
  const ulRef = useRef<HTMLUListElement>(null);
  const [liCount, setLiCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  // DATE FORMATTING
  const currentDate = new Date();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, '0');
  const year = currentDate.getFullYear();
  const formattedDate = `${year}-${month}-${day}`;

  const dispatch = useDispatch();
  const [infoEventTeaserList, setinfoEventTeaserList] = useState<
    InfoEventFieldProps[]
  >([]);

  const infoEventsPath = content.info_event_path
    ? new URL(content.info_event_path).pathname
    : undefined;

  const dataArray = Object.values(content.blocks);
  const infoEvent: any = dataArray.find(
    (item: any) => item['@type'] === 'infoevent',
  );

  useEffect(() => {
    let queryString = [];
    setIsLoading(true);
    setHasError(false);

    if (infoEvent.info_event_subjects?.length >= 1) {
      queryString = getSubjectSearchQuery(
        formattedDate,
        infoEvent.info_event_subjects,
        infoEventsPath,
      );
    } else {
      queryString = getNonSubjectSearchQuery(formattedDate, infoEventsPath);
    }

    const timer = setTimeout(() => {
      if (ulRef.current) {
        const liChildren = ulRef.current.querySelectorAll('li');
        setLiCount(liChildren.length);
      }
    }, 500);

    const fetchQueryResults = async () => {
      try {
        const result = await dispatch(
          getQueryStringResults(
            `${infoEventsPath}`,
            {
              limit: Config.maxItems,
              fullobjects: true,
              depth: Config.depth,
              sort_on: Config.sortOn,
              query: queryString,
            },
            null,
            null,
          ) as AnyAction,
        );

        if (result?.items) {
          setinfoEventTeaserList(result.items);
        } else {
          // eslint-disable-next-line no-console
          console.error('No items found in the result');
          setHasError(true);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching query results');
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchQueryResults();

    return () => clearTimeout(timer);
  }, [dispatch, infoEventsPath, formattedDate, infoEvent.info_event_subjects]);

  return (
    <div
      className="info_event"
      role="region"
      aria-labelledby="info-event-title"
    >
      <h2 id="info-event-title">{intl.formatMessage(messages.title)}</h2>
      <>
        {infoEventTeaserList.length > 0 && (
          <ul
            ref={ulRef}
            className={`teaser-listing linklist has-anim fade-in`}
            aria-live="polite"
            aria-busy={isLoading}
            aria-label={intl.formatMessage(messages.title)}
          >
            {infoEventTeaserList.map((item: any, index: number) => (
              <InfoEvent key={index} item={item} />
            ))}
          </ul>
        )}
      </>

      {/* MORE LINK AFTER MORE 3 LIST ITEMS */}
      {liCount > Config.maxItems - 1 && (
        <Link
          className={'btn-primary btn--link mt-lg'}
          href={infoEventsPath}
          newTab={false}
          aria-label={intl.formatMessage(messages.show_all)}
        >
          {intl.formatMessage(messages.show_all)}
        </Link>
      )}

      {/* EMPTY LIST TEXT */}
      {infoEventTeaserList.length === 0 && !isLoading && (
        <p aria-live="polite">{infoEvent.info_event_emptytext}</p>
      )}

      {/* LOADING STATE */}
      {isLoading && (
        <p aria-live="polite" aria-busy="true">
          Loading...
        </p>
      )}

      {/* ERROR STATE */}
      {hasError && (
        <p aria-live="assertive" role="alert">
          {intl.formatMessage(messages.no_results)}
        </p>
      )}

      {/* ROOM INFORMATION */}
      {infoEvent.info_event_roominfo && (
        <span className="info_event-roominfo mt-md">
          {infoEvent.info_event_roominfo}
        </span>
      )}
    </div>
  );
};

export default View;
