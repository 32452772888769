/**
 * Breadcrumbs reducer.
 * @module reducers/breadcrumbs/breadcrumbs
 *
 * A replacement for the original breadcrumbs reducer, which
 * does not support subrequest. Adding the subrequest is the only
 * modification done.
 */

import map from 'lodash/map';
import { flattenToAppURL, getBaseUrl } from '@plone/volto/helpers/Url/Url';
import { hasApiExpander } from '@plone/volto/helpers/Utils/Utils';

import { GET_CONTENT } from '@plone/volto/constants/ActionTypes';
import { GET_BREADCRUMBS } from '@fhnw/constants/ActionTypes';

const initialState = {
  error: null,
  items: [],
  root: null,
  loaded: false,
  loading: false,
};

/**
 * Breadcrumbs reducer.
 * @function breadcrumbs
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function breadcrumbs(state = initialState, action = {}) {
  let hasExpander;
  let result;
  switch (action.type) {
    case `${GET_BREADCRUMBS}_PENDING`:
      result = {
        error: null,
        loaded: false,
        loading: true,
      };
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: result,
            },
          }
        : {
            ...state,
            ...result,
          };
    case `${GET_CONTENT}_SUCCESS`:
      hasExpander = hasApiExpander(
        'breadcrumbs',
        getBaseUrl(flattenToAppURL(action.result['@id'])),
      );
      if (hasExpander && !action.subrequest) {
        return {
          ...state,
          error: null,
          items: map(
            action.result['@components'].breadcrumbs.items,
            (item) => ({
              title: item.title,
              url: flattenToAppURL(item['@id']),
            }),
          ),
          root: flattenToAppURL(action.result['@components'].breadcrumbs.root),
          loaded: true,
          loading: false,
        };
      }
      return state;
    case `${GET_BREADCRUMBS}_SUCCESS`:
      hasExpander = hasApiExpander(
        'breadcrumbs',
        getBaseUrl(flattenToAppURL(action.result['@id'])),
      );
      if (hasExpander && !action.subrequest) {
        return state;
      }
      result = {
        error: null,
        items: map(action.result.items, (item) => ({
          title: item.title,
          url: flattenToAppURL(item['@id']),
        })),
        root: flattenToAppURL(action.result.root),
        loaded: true,
        loading: false,
      };
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: result,
            },
          }
        : {
            ...state,
            ...result,
          };
    case `${GET_BREADCRUMBS}_FAIL`:
      result = {
        error: action.error,
        items: [],
        loaded: false,
        loading: false,
      };
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: result,
            },
          }
        : {
            ...state,
            ...result,
          };
    default:
      return state;
  }
}
