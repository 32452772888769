import config from '@plone/volto/registry';

export interface BlockVariation {
  id: string;
  title: string;
  template: React.ComponentType<any>;
  isDefault?: boolean;
}

const SolarSearchView = (props: any) => {
  const { data } = props;

  const variations =
    config.blocks?.blocksConfig['solarSearch']?.variations || [];
  const defaultVariation = variations.filter(
    (item: any) => item.isDefault,
  )?.[0];
  const variation = variations.find((item: any) => item.id === data.variation);

  let SearchView = null;
  if (variation) {
    SearchView = variation.template;
  } else {
    SearchView = defaultVariation.template;
  }

  return <>{SearchView ? <SearchView {...props} /> : null}</>;
};

export default SolarSearchView;
