import { InternalIrfPublicationProps } from './fetchData';

/**
 * Filters publications by type code.
 * @param jsonData - The array of publication data.
 * @param selectedPublicationTypes - The selected publication types to filter by.
 * @param typeCodes - The type codes to filter by.
 * @returns The filtered array of publications.
 */
export const filterByTypeCode = (
  jsonData: InternalIrfPublicationProps[],
  selectedPublicationTypes: string[],
  typeCodes: string[],
): InternalIrfPublicationProps[] => {
  return jsonData.filter((item) => {
    // Only uppercase the code part for comparison
    const [itemTypeCode] = (item.type?.split(' ')[0] || '').split('-');
    const normalizedItemCode = itemTypeCode
      .trim()
      .toUpperCase()
      .padStart(2, '0');

    return (
      typeCodes.includes(normalizedItemCode) &&
      (selectedPublicationTypes.length === 0 ||
        selectedPublicationTypes.includes(normalizedItemCode))
    );
  });
};
