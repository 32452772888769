import React from 'react';
import { Link } from '../Link/Link';
import TeaserContent from './TeaserContent';
import TeaserContactSearch from './TeaserContactSearch';
import TeaserContactProfile from './TeaserContactProfile';
import { UniversalLink } from '@plone/volto/components';
import clsx from 'clsx';

export type TeaserDefaultProps<T> = {
  reference?: React.LinkHTMLAttributes<HTMLAnchorElement>['href'];
  isPrivate?: boolean;
  newTab?: boolean;
  imageComponent?: React.ComponentType<any>;
  imageComponentProps?: T;
  fullBleedImage?: string;
  fullBleedImageHeight?: number;
  category?: string;
  dateRange?: {
    start?: string;
    end?: string;
  };
  title?: string;
  name_affix1?: string;
  name_affix2?: string;
  text?: string;
  review_state?: string;
};

export type DateAndLocationProps = {
  dateAndLocation?: {
    day?: string;
    date?: string;
    time?: string;
    location?: string;
  };
};

export type ProfileProps = {
  address?: {
    location?: string;
    street?: string;
    tel1?: string;
    tel2?: string;
    tel1_suffix?: string;
    tel2_suffix?: string;
    email?: string | null;
  };
};

export type TeaserTypeProps = {
  type?: string;
  template?: string;
};

export type TeaserProps = TeaserDefaultProps<any> &
  DateAndLocationProps &
  ProfileProps &
  TeaserTypeProps;

export const processDateAndLocation = (
  dateAndLocation?: DateAndLocationProps['dateAndLocation'],
) => {
  return [
    dateAndLocation?.day && `${dateAndLocation.day},`,
    dateAndLocation?.date && `${dateAndLocation.date}`,
    dateAndLocation?.time && `${dateAndLocation.time}`,
    dateAndLocation?.location && `${dateAndLocation.location}`,
  ]
    .filter(Boolean)
    .join(' | ');
};

const Teaser = ({
  reference,
  newTab,
  imageComponent,
  imageComponentProps,
  category,
  dateRange,
  dateAndLocation,
  title,
  name_affix1,
  name_affix2,
  text = '',
  address,
  template,
  isPrivate,
}: TeaserProps) => {
  const processedDateAndLocation = processDateAndLocation(dateAndLocation);
  const Image = imageComponent || 'img';

  return (
    <>
      {(template === 'news' ||
        template === 'event' ||
        template === 'linklist' ||
        template === 'default') && (
        <li
          className={clsx(
            'teaser',
            { 'new-tab': newTab },
            { 'is-private': isPrivate },
          )}
        >
          <Link
            className="teaser-link"
            href={reference}
            title={title}
            newTab={newTab}
            linkComponent={UniversalLink}
          >
            <div className="teaser-wrapper">
              {imageComponentProps && (
                <div className="teaser-image">
                  <Image {...imageComponentProps} />
                  {(dateRange?.start || dateRange?.end) && (
                    <div className="teaser-dates">
                      {dateRange?.start && (
                        <span>
                          {dateRange.start}
                          {dateRange?.end && '-'}
                        </span>
                      )}
                      {dateRange?.end && <span>{dateRange.end}</span>}
                    </div>
                  )}
                </div>
              )}
              {!imageComponentProps && (dateRange?.start || dateRange?.end) && (
                <div className="teaser-image--no-img">
                  <div className="teaser-dates--only">
                    {dateRange?.start && (
                      <span>
                        {dateRange.start}
                        {dateRange?.end && '-'}
                      </span>
                    )}
                    {dateRange?.end && <span>{dateRange.end}</span>}
                  </div>
                </div>
              )}
              <TeaserContent
                template={template}
                category={category}
                title={title}
                processedDateAndLocation={processedDateAndLocation}
                text={text}
                name_affix1={name_affix1}
              />
            </div>
          </Link>
        </li>
      )}

      {template === 'profile' && title && (
        <TeaserContactProfile
          reference={reference}
          title={title}
          text={text}
          address={address}
          imageComponent={imageComponent}
          imageComponentProps={imageComponentProps}
          name_affix1={name_affix1}
          name_affix2={name_affix2}
        />
      )}

      {template === 'profile-search' && title && (
        <TeaserContactSearch
          reference={reference}
          title={title}
          text={text}
          address={address}
          imageComponent={imageComponent}
          imageComponentProps={imageComponentProps}
        />
      )}
    </>
  );
};

export default Teaser;
