import React, { useRef } from 'react';
import { withBlockExtensions } from '@plone/volto/helpers';
import cx from 'classnames';
import config from '@plone/volto/registry';

export interface HeadingViewProps {
  className?: string;
  data: {
    heading: string;
    anchor?: string;
    tag?: string;
    alignment?: React.CSSProperties['textAlign'];
  };
}

const HeadingView: React.FC<HeadingViewProps> = (props) => {
  const { className, data } = props;
  const Element = (data.tag || 'h2') as keyof JSX.IntrinsicElements;
  const show_alignment = config.blocks?.blocksConfig?.heading?.show_alignment;

  const generateSlug = (title: string) =>
    title
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');

  const uniqueId = useRef(generateSlug(data?.heading ?? '')).current;

  return (
    <>
      {data && (
        <div className={cx('block heading', className)}>
          <div className="heading-wrapper">
            <Element
              id={data?.anchor || uniqueId}
              style={show_alignment ? { textAlign: data.alignment } : undefined}
              className="heading"
            >
              {data?.heading || ''}
            </Element>
          </div>
        </div>
      )}
    </>
  );
};

export default withBlockExtensions(HeadingView);
