import { listingSchemaEnhancer } from '@fhnw/components/Blocks/Listing/schema';
import ListTeaser from '@fhnw/components/Blocks/Listing/variations/ListTeaser';
import OneQuarterTeaser from '@fhnw/components/Blocks/Listing/variations/OneQuarterTeaser';
import OneThirdTeaser from '@fhnw/components/Blocks/Listing/variations/OneThirdTeaser';

export const listingBlockVariations = [
  {
    id: 'list',
    title: 'Liste',
    isDefault: true,
    template: ListTeaser,
    schemaEnhancer: listingSchemaEnhancer,
  },
  {
    id: 'oneThird',
    title: '1/3 Teaser',
    isDefault: false,
    template: OneThirdTeaser,
    schemaEnhancer: listingSchemaEnhancer,
  },
  {
    id: 'oneQuarter',
    title: '1/4 Teaser',
    isDefault: false,
    template: OneQuarterTeaser,
    schemaEnhancer: listingSchemaEnhancer,
  },
];
