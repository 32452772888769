import { IntlShape } from 'react-intl';

const NewsletterBlockSchema = (intl: IntlShape) => ({
  title: intl.formatMessage({ id: 'Newsletter' }),
  required: [],
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'description', 'cleverreachUrl'],
    },
  ],
  properties: {
    title: {
      title: intl.formatMessage({ id: 'Newsletter Form Title' }),
      description: intl.formatMessage({ id: 'Newsletter Signup' }),
      widget: 'text',
    },
    description: {
      title: intl.formatMessage({ id: 'Newsletter Form Description' }),
      description: intl.formatMessage({ id: 'Newsletter Signup Description' }),
      widget: 'text',
    },
    cleverreachUrl: {
      title: intl.formatMessage({ id: 'Link' }),
      description: intl.formatMessage({
        id: 'Link to CleverReach form',
      }),
      widget: 'text',
    },
  },
});

export default NewsletterBlockSchema;
