import type { ConfigType } from '@plone/registry';
import { CTAButton } from './components/CTAButton/CTAButton';
import ContactsWidget from './components/Widgets/Contact/ContactsWidget';
import FormatWidget from './components/Widgets/FormatWidget/FormatWidget';
import type { FormatWidgetProps } from './components/Widgets/FormatWidget/FormatWidget';
import type { ContactsWidgetProps } from './components/Widgets/Contact/ContactsWidget';
import { CTAExpander } from './types/cta';
import SolarSerachEdit from './components/Blocks/SolarSearch/Edit';
import SolarSearchView, {
  BlockVariation,
} from './components/Blocks/SolarSearch/View';
import descriptionSVG from '@plone/volto/icons/description.svg';
import DegreeProgramme from './components/Blocks/SolarSearch/DegreeProgramme';
import CourseView from './components/Blocks/SolarSearch/CourseView';
import NewsView from './components/Blocks/SolarSearch/NewsView';
import EventsView from './components/Blocks/SolarSearch/Eventsview';
import PersonView from './components/Blocks/SolarSearch/Personview';
import ListView from './components/Blocks/SolarSearch/ListView';
import Teaser from './components/Teaser/Teaser';
import cloneDeep from 'lodash/cloneDeep';
import { HeroExpander, ImageCroppingOptions } from './types/hero';
import { defineMessages, IntlShape } from 'react-intl';

import {
  EduBoxBlockView,
  EduBoxBlockEdit,
  applicationSVG,
} from './components/EduBox/index';
import { ImageSliderDataAdapter } from './components/ImageSlider/adapter';
import ImageSliderEdit from './components/ImageSlider/Edit';
import ImageSliderView from './components/ImageSlider/View';
import imagesSVG from '@plone/volto/icons/images.svg';
import PDFViewerIcon from '@plone/volto/icons/presentation.svg';
import MegaphoneIcon from '@plone/volto/icons/megaphone.svg';
import addUserSVG from '@plone/volto/icons/add-user.svg';
import tabsSVG from '@plone/volto/icons/interface.svg';
import columnSVG from '@plone/volto/icons/column.svg';
import TestimonialIcon from '@plone/volto/icons/quote.svg';
import AuflistungIcon from '@plone/volto/icons/content-listing.svg';
import ShowBlocksIcon from '@plone/volto/icons/show-blocks.svg';
import accordionSVG from '@plone/volto/icons/circle-bottom.svg';
import rowSVG from '@plone/volto/icons/row.svg';
import {
  ApplicationAccordionBlockView,
  ApplicationAccordionBlockEdit,
  applicationAccordionSVG,
} from './components/ApplicationAccordion/index';
import {
  InfoEventBlockView,
  InfoEventBlockEdit,
  infoSVG,
} from './components/InfoEvent/index';
import linkSVG from '@plone/volto/icons/link.svg';

import {
  EventDetailEdit,
  EventDetailView,
} from './components/EventDetail/index';
import { EventApplicationBox } from './components/EventApplicationBox/index';
import { contentIcons } from './config/contentIcons';
import { ComponentType } from 'react';
import { FollowUsEdit, FollowUsView } from './components/Blocks/FollowUs';

import {
  DefaultEdit,
  DefaultView,
} from './customizations/@eeacms/volto-tabs-block/components/templates/default';
import { teaserBlockVariations } from './config/teasers';
import { listingBlockVariations } from './config/listings';
import { composeSchema } from '@plone/volto/helpers';
import {
  gridTeaserDisableStylingSchema,
  teaserSchemaEnhancer,
} from './components/Blocks/Teaser/schema';
import { initialBlocks } from './config/initialBlocks';
import { ContentTypeCondition } from '@plone/volto/helpers/Slots';
import SidebarRightNavigation from './components/SidebarNavigation/SidebarRightNavigation';
import { ContactSidebar } from './components/ContactSidebar/index';
import { TableOfContentsSchema } from './customizations/volto/components/manage/Blocks/ToC/Schema';
import { ButtonSchemaEnhancer } from './components/Blocks/Button/schemaEnhancer';
import { NewsDetailEdit, NewsDetailView } from './components/Blocks/NewsDetail';
import installLink from '@fhnw/components/SlateLink/index';
import {
  TestimonialsView,
  TestimonialsEdit,
} from './components/Blocks/Testimonial';
import {
  NewsletterView,
  NewsletterEdit,
  newsletterSVG,
} from './components/Blocks/Newsletter';
import {
  CRMBlockView,
  CRMBlockEdit,
  CRMBlockSchema,
} from './components/Blocks/CRMFormBlock';

import * as ResultItems from '@fhnw/components/GlobalSearch/ResultItems';

import installAlignment from '@fhnw/components/Alignment';
import installEnlargeReduce from '@fhnw/components/EnlargeReduce';

import { IrfEditView, IrfView } from './components/Irf';
import { config as HTMLBlockConfig } from './customizations/volto/components/manage/Blocks/HTML';
import schemaFormBlock from './config/schemaFormBlock';

import BlockSettingsSchema from '@plone/volto/components/manage/Blocks/Block/Schema';
import IframeView from './components/Blocks/Iframe/View';
import IframeEdit from './components/Blocks/Iframe/Edit';

import AnchorBlockView from './components/Blocks/Anchor/View';
import AnchorBlockEdit from './components/Blocks/Anchor/Edit';
import AnchorWidget, {
  AnchorWidgetProps,
} from './components/Widgets/Anchor/AnchorWidget';

import CookieConsent from './components/CookieConsent/CookieConsent';

import reducers from './reducers';
import StartSlider from './components/StartSlider/StartSlider';
import type { Story } from './components/StartSlider/StartSlider';
// eslint-disable-next-line
import { BlockConfigBase, SettingsConfig } from '@plone/types';
import { customUrlValidator } from './helpers/FromValidation/customUrlValidator';
import ModuleView from './components/Views/ModuleView';
import Error from './customizations/volto/components/theme/Error/Error';

// Define some translation used by searchTabs of global Search

defineMessages({
  All: {
    id: 'All',
    defaultMessage: 'All',
  },
  DegreeProgram: {
    id: 'Degree Program Global',
    defaultMessage: 'Degree programmes',
  },
  FHNWNews: {
    id: 'FHNW News Global',
    defaultMessage: 'News',
  },
  FHNWEvent: {
    id: 'FHNW Event Global',
    defaultMessage: 'Events',
  },
  ContinuingEducation: {
    id: 'Continuing Education Global',
    defaultMessage: 'Continuing education programmes',
  },
  Files: {
    id: 'File Global',
    defaultMessage: 'Documents',
  },
  Contact: {
    id: 'Contact Global',
    defaultMessage: 'Persons',
  },
  Page: {
    id: 'Page Global',
    defaultMessage: 'General',
  },
  persons: {
    id: 'Persons',
    defaultMessage: 'Persons',
  },
});

// We extend the Content type to include the new fields from the ICTA behavior
declare module '@plone/types' {
  export interface Address {
    name: string;
    department: string;
    street: string;
    zipCity: string;
  }

  export interface ContactData {
    telephone: string;
    email: string;
  }

  export interface Coordinates {
    x: number;
    y: number;
  }

  export interface Location {
    ['@id']: string;
    title: string;
    navTitle: string;
    address: Address;
    contactData: ContactData;
    coordinates: Coordinates;
    zoomLevel: number;
    locationPageURL?: string;
    routeURL?: string;
    tabName?: string;
    buttonLink?: string;
    showInSlider: boolean | null;
  }

  export interface Content {
    cta_text?: string;
    cta_url?: string;
    enable_cta_inheritance?: boolean;
    show_cta_on_mobile?: boolean;
    show_cta_on_desktop?: boolean;
    cta_inheritance_path?: string;
    hero_image?: RelatedItem;
    hero_image_cropping?: ImageCroppingOptions;
    hero_inheritance_path?: string;
    hero_inheritance?: boolean;
    locations_title?: string;
    locations_description?: string;
    related_locations: Location[];
  }
  export interface Expanders {
    cta: CTAExpander;
    hero: HeroExpander;
    story: Story;
  }

  export interface WidgetsConfigByWidget {
    contacts_widget: React.FC<ContactsWidgetProps>;
    format_widget: React.FC<FormatWidgetProps>;
    anchor: React.ComponentType<AnchorWidgetProps>;
  }

  export interface RelatedItem {
    image: {
      download: string;
      width: number;
      height: number;
      scales: {
        preview: {
          download: string;
          width: number;
          height: number;
        };
      };
    };
  }

  export interface BlocksFormData {
    href: Array<{
      snippet: string;
    }>;
  }

  export interface ViewsConfig {
    globalContentTypeSearchResultViews: {
      [key: string]: React.ComponentType<any>;
    };
  }

  export interface BlocksConfigData {
    // TEXT
    heading: {
      allowed_headings: string[];
      schemaEnhancer: ((props: SchemaProps) => Schema) | null;
      show_alignment: boolean;
    };
    //TABS
    tabs_block: {};
    // LISTINGSEARCH
    solarSearch: {} & { variations: BlockVariation[] };
    // MEDIA
    imageslider: {};
    // LAYOUT
    group: {};
    // CONTENT
    accordion: {};
    __button: {
      schemaEnhancer: ((props: SchemaProps) => Schema) | null;
    };
    slateTable: {};
    newsletter: {};
    pdf_viewer: {};
    crmformblock: {};
    // EDUPRODUCT
    edubox: {};
    applicationAccordion: {};

    followus: {};
    infoevent: {};
    // COMMON
    eventdetail: {};
    highlight: {};
    introduction: {};
    separator: {};
    eventinfo: {};
    newsdetail: {};
    // ADDONS
    schemaForm: {
      defaultSender: string;
      defaultSenderName: string;
      group: string;
      widgets: {};
      innerWidgets: {};
      additionalFactory: any[];
      filterFactory: any[];
      filterFactorySend: any[];
    };
    form: {
      restricted: boolean;
    };
    // TEMPORARY
    eventapplication: {};
    testimonial: {};
    irf: {};
    iframe: {};
    anchor: {};
  }
}

export interface SchemaProps {
  schema: Schema;
  intl: IntlShape;
  formData: {
    tag: string;
    alignment: string;
    styles: Record<string, any>;
  };
}

export interface Schema {
  title: string;
  fieldsets: {
    id: string;
    title: string;
    fields: string[];
  }[];
  properties: {
    tag?: {
      title: string;
      choices: [string, string][];
      default: string;
      noValueOption: boolean;
    };
    alignment?: {
      title: string;
      choices: [string, string][];
      default: string;
    };
  };
  required: string[];
}

const applyConfig = (
  config: ConfigType & {
    experimental: { addBlockButton: { enabled: boolean } };
    settings: {
      slate: {
        toolbarButtons: string[];
      };
      contentIcons: Record<string, ComponentType>;
      apiExpanders: any[];
      cookiebotDomainGroupId?: string;
      gaMeasurementId?: string;
    };
  },
) => {
  config.settings['FHNW_ENV'] =
    (typeof window !== 'undefined' ? (window as any) : (process as any))?.env
      ?.RAZZLE_FHNW_ENV || 'NOENV';

  installAlignment(config);
  installEnlargeReduce(config);
  installLink(config);

  config.addonReducers = { ...config.addonReducers, ...reducers };

  config.settings.slate.toolbarButtons = [
    ...config.settings.slate.toolbarButtons.slice(0, 2),
    'underline',
    ...config.settings.slate.toolbarButtons.slice(2),
  ];

  config.experimental.addBlockButton.enabled = true;

  config.blocks.initialBlocks = initialBlocks;

  config.blocks.requiredBlocks = [
    ...config.blocks.requiredBlocks,
    'eventdetail',
    'edubox',
  ];

  config.views.globalContentTypeSearchResultViews = {
    FHNWEvent: ResultItems.EventGlobalSearchResultItem,
    FHNWNews: ResultItems.NewsGlobalSearchResultItem,
    Contact: ResultItems.PersonGlobalSearchResultItem,
    File: ResultItems.FileGlobalSearchResultItem,
    Default: ResultItems.DefaultGlobalSearchResultItem,
  };

  config.settings = {
    ...config.settings,
    querystringSearchGet: true,
    isMultilingual: true,
    supportedLanguages: ['de', 'en', 'fr'],
    defaultLanguage: 'de',
    cookiebotDomainGroupId: '1346d074-2668-4a7b-8f47-624c2786b205',
    gaMeasurementId: 'GTM-KDPH6Q5',
    appExtras: [
      ...config.settings.appExtras,
      ...(config.settings['FHNW_ENV'] === 'PROD' ||
      config.settings['FHNW_ENV'] === 'TEST'
        ? [
            {
              match: '',
              component: CookieConsent,
              props: {},
            },
          ]
        : []),
    ],
  } as (SettingsConfig | Record<string, never>) & {
    slate: {
      toolbarButtons: string[];
    };
    contentIcons: Record<string, ComponentType>;
    apiExpanders: any[];
    cookiebotDomainGroupId?: string;
    gaMeasurementId?: string;
  };

  config.settings.contentIcons = {
    ...config.settings.contentIcons,
    ...(contentIcons as unknown as Record<string, ComponentType>),
  };

  config.settings.hasWorkingCopySupport = true;

  config.settings.apiExpanders = [
    ...config.settings.apiExpanders,
    {
      match: '',
      GET_CONTENT: [
        'cta',
        'hero',
        'related_contacts',
        'searchnav',
        'metanav',
        'story',
      ],
    },
    {
      match: '',
      GET_CONTENT: ['navtree'],
      querystring: {
        'expand.navtree.depth': '3',
      },
    },
    {
      match: '',
      GET_CONTENT: ['navigation_with_excluded'],
      querystring: {
        'expand.navigation_with_excluded.depth': '3',
      },
    },
  ];

  config.widgets.widget.contacts_widget = ContactsWidget;
  config.widgets.widget.format_widget = FormatWidget;
  config.widgets.widget.anchor =
    AnchorWidget as React.ComponentType<AnchorWidgetProps>;

  config.registerSlotComponent({
    slot: 'heroSlot',
    name: 'CTAButton',
    component: CTAButton,
    predicates: [
      ({ location }) =>
        !['/de', '/de/edit', '/en', '/en/edit', '/fr', '/fr/edit'].includes(
          location.pathname,
        ),
    ],
  });

  config.registerSlotComponent({
    slot: 'startSliderSlot',
    name: 'StartSlider',
    component: StartSlider,
    predicates: [
      ({ location }) =>
        ['/de', '/de/edit', '/en', '/en/edit', '/fr', '/fr/edit'].includes(
          location.pathname,
        ),
    ],
  });

  config.registerSlotComponent({
    slot: 'sidebar-nav-right',
    name: 'SidebarRightNavigation',
    component: SidebarRightNavigation,
    // predicates: [ContentTypeCondition(['Document']), RouteCondition('/hello')],
  });

  config.registerSlotComponent({
    slot: 'sidebar-nav-right',
    name: 'SidebarEventRegistrationBox',
    component: EventApplicationBox,
    predicates: [
      ContentTypeCondition(['Event', 'FHNWEvent', 'InfoEvent', 'ConEduEvent']),
    ],
  });

  config.registerSlotComponent({
    slot: 'sidebar-nav-right',
    name: 'SidebarContact',
    component: ContactSidebar,
    // predicates: [ContentTypeCondition(['Contact', 'TeamContact'])],
  });

  config.registerComponent({
    name: 'searchBlockResultItem',
    component: Teaser,
    dependencies: ['News Item', 'Default'],
  });

  config.registerComponent({
    name: 'searchBlockResultItem',
    component: ListView,
    dependencies: ['News Item', 'List'],
  });
  config.registerComponent({
    name: 'searchBlockResultItem',
    component: Teaser,
    dependencies: ['Event', 'Default'],
  });

  config.registerComponent({
    name: 'searchBlockResultItem',
    component: ListView,
    dependencies: ['Event', 'List'],
  });
  config.registerComponent({
    name: 'searchBlockResultItem',
    component: Teaser,
    dependencies: ['Course', 'Default'],
  });

  config.registerComponent({
    name: 'searchBlockResultItem',
    component: ListView,
    dependencies: ['Course', 'List'],
  });

  // ==============================
  // GROUP BLOCKS ORDER
  // ==============================
  config.blocks.groupBlocksOrder = [
    // { id: 'mostUsed', title: 'Häufig verwendet' },
    { id: 'text', title: 'Text' },
    { id: 'listingsearch', title: 'Auflistungen / Suche' },
    { id: 'media', title: 'Bilder / Videos' },
    { id: 'layout', title: 'Layout' },
    { id: 'content', title: 'Inhalt' },
    { id: 'eduproduct', title: 'Produktinformationen' },
    { id: 'common', title: 'Allgemein' },
  ] as any; // fix for wrong type in Blocks.d.ts

  // ==============================
  // TEXT BLOCKS
  // ==============================
  config.blocks.blocksConfig.heading = {
    ...config.blocks.blocksConfig.heading,
    allowed_headings: ['h2', 'h3', 'h4', 'h5', 'h6'],
    schemaEnhancer: null,
  };

  // ==============================
  // LISTINGSEARCH BLOCKS
  // ==============================

  config.blocks.blocksConfig.teaser = {
    ...config.blocks.blocksConfig.teaser,
    group: 'listingsearch',
    variations: [...teaserBlockVariations],
    imageScale: 'huge',
    schemaEnhancer: composeSchema(
      gridTeaserDisableStylingSchema,
      teaserSchemaEnhancer,
    ),
  } as BlockConfigBase & { imageScale: string };

  config.blocks.blocksConfig.listing = {
    ...config.blocks.blocksConfig.listing,
    variations: [...listingBlockVariations],
    icon: AuflistungIcon,
    group: 'listingsearch',
  };

  config.blocks.blocksConfig.search = {
    ...config.blocks.blocksConfig.search,
    restricted: true,
  };

  config.blocks.blocksConfig.solarSearch = {
    id: 'solarSearch',
    title: 'Search',
    icon: descriptionSVG,
    group: 'listingsearch',
    view: SolarSearchView,
    edit: SolarSerachEdit,
    mostUsed: false,
    sidebarTab: 1,
    variations: [
      {
        id: 'degree-programmes',
        isDefault: true,
        title: 'Degree Programmes',
        template: DegreeProgramme,
      },
      {
        id: 'course',
        title: 'Continuing Education',
        template: CourseView,
      },
      {
        id: 'news',
        title: 'News',
        template: NewsView,
      },
      {
        id: 'events',
        title: 'Events',
        template: EventsView,
      },
      {
        id: 'persons',
        title: 'Persons',
        template: PersonView,
      },
    ],
  } as BlockConfigBase & { variations: BlockVariation[] };

  config.blocks.blocksConfig.toc = {
    ...config.blocks.blocksConfig.toc,
    schemaEnhancer: composeSchema(TableOfContentsSchema),
    group: 'listingsearch',
  };

  // ==============================
  // MEDIA BLOCKS
  // ==============================

  config.blocks.blocksConfig.imageslider = {
    id: 'imageslider',
    title: 'Bildergalerie',
    icon: imagesSVG,
    group: 'media',
    view: ImageSliderView,
    edit: ImageSliderEdit,
    dataAdapter: ImageSliderDataAdapter,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    enableStyling: false,
  };
  config.blocks.blocksConfig.testimonial = {
    id: 'testimonial',
    title: 'Testimonial',
    icon: TestimonialIcon,
    group: 'content',
    view: TestimonialsView,
    edit: TestimonialsEdit,
    restricted: false,
    sidebarTab: 1,
    enableStyling: false,
  };

  // ==============================
  // EDUPRODUCT BLOCKS
  // ==============================

  config.blocks.blocksConfig.applicationAccordion = {
    id: 'applicationAccordion',
    title: 'Durchführungen',
    icon: applicationAccordionSVG,
    group: 'eduproduct',
    view: ApplicationAccordionBlockView,
    edit: ApplicationAccordionBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };

  config.blocks.blocksConfig.infoevent = {
    id: 'infoevent',
    title: 'Info-Anlass Teaser',
    icon: infoSVG,
    group: 'eduproduct',
    view: InfoEventBlockView,
    edit: InfoEventBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };

  // ==============================
  // LAYOUT BLOCKS
  // ==============================
  config.blocks.blocksConfig.group = {
    ...config.blocks.blocksConfig.group,
    title: 'Gruppe',
    group: 'layout',
    icon: ShowBlocksIcon,
  };

  // ==============================
  // CONTENT BLOCKS
  // ==============================

  config.blocks.blocksConfig.__button = {
    ...config.blocks.blocksConfig.__button,
    group: 'content',
    schemaEnhancer: ButtonSchemaEnhancer,
  } as any;

  config.blocks.blocksConfig.slateTable = {
    ...config.blocks.blocksConfig.slateTable,
    group: 'content',
  };

  config.blocks.blocksConfig.html = {
    ...config.blocks.blocksConfig.html,
  };

  config.blocks.blocksConfig.pdf_viewer = {
    ...config.blocks.blocksConfig.pdf_viewer,
    icon: PDFViewerIcon,
    group: 'content',
  };

  config.blocks.blocksConfig.tabs_block = {
    ...config.blocks.blocksConfig.tabs_block,
    icon: tabsSVG,
    group: 'content',
    variations: [
      {
        id: 'default',
        title: 'Default',
        isDefault: true,
        edit: DefaultEdit,
        view: DefaultView,
        // schemaEnhancer: DefaultEdit.schemaEnhancer,
      },
    ],
  };

  config.blocks.blocksConfig.newsletter = {
    id: 'newsletter',
    title: 'Newsletter (CleverReach)',
    icon: newsletterSVG,
    group: 'content',
    view: NewsletterView,
    edit: NewsletterEdit,
    restricted: false,
    mostUsed: false,
  };

  config.blocks.blocksConfig.iframe = {
    id: 'iframe',
    title: 'Embed',
    icon: applicationSVG,
    group: 'content',
    view: IframeView,
    edit: IframeEdit,
    schema: BlockSettingsSchema,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };

  // ==============================
  // FORM BLOCK
  // ==============================

  config = schemaFormBlock(config);

  config.blocks.blocksConfig.form = {
    ...config.blocks.blocksConfig.form,
    restricted: true,
  };

  config.blocks.blocksConfig.crmformblock = {
    id: 'crmformblock',
    title: 'CRM Formular',
    icon: rowSVG,
    group: 'content',
    view: CRMBlockView,
    edit: CRMBlockEdit,
    schema: CRMBlockSchema,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };

  // ==============================
  // OTHER BLOCKS
  // ==============================

  // this block will be in the sidebar in the future, only for testing as a block
  config.blocks.blocksConfig.eventapplication = {
    id: 'eventapplication',
    title: 'Event Application',
    icon: applicationSVG,
    group: 'common',
    view: EventApplicationBox,
    edit: EventApplicationBox,
  };

  // this block is placed automatically on event pages.
  config.blocks.blocksConfig.eventdetail = {
    id: 'eventdetail',
    title: 'Event-Detail Block',
    icon: applicationSVG,
    group: 'common',
    view: EventDetailView,
    edit: EventDetailEdit,
    restricted: true,
    mostUsed: false,
  };

  // this block is placed automatically on eduproduct pages.
  config.blocks.blocksConfig.edubox = {
    id: 'edubox',
    title: 'Edubox',
    icon: applicationSVG,
    group: 'common',
    view: EduBoxBlockView,
    edit: EduBoxBlockEdit,
    restricted: false,
    mostUsed: false,
  };

  // this block is placed automatically on news pages.
  config.blocks.blocksConfig.newsdetail = {
    id: 'newsdetail',
    title: 'News-Detail',
    icon: applicationSVG,
    group: 'common',
    view: NewsDetailView,
    edit: NewsDetailEdit,
    restricted: false,
    mostUsed: false,
  };

  config.blocks.blocksConfig.followus = {
    id: 'followus',
    title: 'Follow Us',
    icon: MegaphoneIcon,
    group: 'content',
    view: FollowUsView,
    edit: FollowUsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };

  // console.log('config', config.blocks.blocksConfig.heading);
  // these blocks are not neccesary, so put into "Other" for now.
  config.blocks.blocksConfig.separator = {
    ...config.blocks.blocksConfig.separator,
    group: 'common',
    restricted: true,
  };

  config.blocks.blocksConfig.highlight = {
    ...config.blocks.blocksConfig.highlight,
    group: 'common',
    restricted: true,
  };

  config.blocks.blocksConfig.introduction = {
    ...config.blocks.blocksConfig.introduction,
    group: 'common',
    restricted: true,
  };

  config.blocks.blocksConfig.gridBlock = {
    ...config.blocks.blocksConfig.gridBlock,
    title: 'Spalten',
    group: 'layout',
    icon: columnSVG,
    blocksConfig: cloneDeep(config.blocks.blocksConfig),
    allowedBlocks: ['image', 'listing', 'slate', 'teaser'],
  } as BlockConfigBase & { allowedBlocks: string[] };

  config.blocks.blocksConfig.irf = {
    id: 'irf',
    title: 'IRF Inhalte',
    icon: addUserSVG,
    group: 'content',
    view: IrfView,
    edit: IrfEditView,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };

  config.blocks.blocksConfig.html = HTMLBlockConfig;

  config.blocks.blocksConfig.accordion = {
    ...config.blocks.blocksConfig.accordion,
    icon: accordionSVG,
    group: 'content',
    blocksConfig: cloneDeep(config.blocks.blocksConfig),
    allowedBlocks: [
      'image',
      'listing',
      'video',
      'html',
      'teaser',
      'iframe',
      'slate',
      'slateTable',
      '__button',
      'heading',
      'separator',
      'pdf_viewer',
      'schemaForm',
      'form',
      'solarSearch',
      'imageslider',
      'testimonial',
      'newsletter',
      'edubox',
    ],
  };

  // Anchor block
  config.blocks.blocksConfig.anchor = {
    id: 'anchor',
    title: 'Anchor',
    icon: linkSVG,
    group: 'content',
    view: AnchorBlockView,
    edit: AnchorBlockEdit,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
  };

  config.registerUtility({
    name: 'url',
    type: 'validator',
    dependencies: { widget: 'url' },
    method: customUrlValidator,
  });

  config.views.contentTypesViews = {
    ...config.views.contentTypesViews,
    Module: ModuleView as ComponentType<any>,
  };

  // External routes
  config.settings.externalRoutes = [{ match: '/plattformen' }];

  config.views.errorViews = {
    ...config.views.errorViews,
    '404': Error as React.ComponentType<any>,
  };

  return config;
};

export default applyConfig;
