import Blogs from './Blogs';
import Facebook from './Facebook';
import Flickr from './Flickr';
import Google from './Google';
import Instagram from './Instagram';
import Itunes from './Itunes';
import Linkedin from './Linkedin';
import Snapchat from './Snapchat';
import Tiktok from './Tiktok';
import X from './X';
import Xing from './Xing';
import Youtube from './Youtube';

export const iconMappings: { [key: string]: React.FC<any> } = {
  blogs: Blogs,
  facebook: Facebook,
  flickr: Flickr,
  google: Google,
  instagram: Instagram,
  itunes: Itunes,
  linkedin: Linkedin,
  snapchat: Snapchat,
  tiktok: Tiktok,
  x: X,
  xing: Xing,
  youtube: Youtube,
};
