import { defineMessages } from 'react-intl';

export const EduBoxMessages = defineMessages({
  keydata: {
    id: 'block.edubox.keydata',
    defaultMessage: 'Key data',
  },
  degree: {
    id: 'block.edubox.degree',
    defaultMessage: 'Degree',
  },
  ects: {
    id: 'block.edubox.ects',
    defaultMessage: 'ECTS points',
  },
  next_start: {
    id: 'block.edubox.next_start',
    defaultMessage: 'Next start',
  },
  duration: {
    id: 'block.edubox.duration',
    defaultMessage: 'Duration',
  },
  final_application_date: {
    id: 'block.edubox.final_application_date',
    defaultMessage: 'Final application date',
  },
  school_days: {
    id: 'block.edubox.school_days',
    defaultMessage: 'School days',
  },
  teaching_language: {
    id: 'block.edubox.teaching_language',
    defaultMessage: 'Teaching language',
  },
  place: {
    id: 'block.edubox.place',
    defaultMessage: 'Place',
  },
  fee: {
    id: 'block.edubox.fee',
    defaultMessage: 'Price',
  },
  target_group: {
    id: 'block.edubox.target_group',
    defaultMessage: 'Target group',
  },
  admission_requirements: {
    id: 'block.edubox.admission_requirements',
    defaultMessage: 'Admission requirements',
  },
  financing_terms: {
    id: 'block.edubox.financing_terms',
    defaultMessage: 'Financing terms',
  },
  documents: {
    id: 'block.edubox.documents',
    defaultMessage: 'Documents',
  },
  terms_and_conditions: {
    id: 'block.edubox.terms_and_conditions',
    defaultMessage: 'Terms and conditions',
  },
  to_the_general_terms_and_conditions: {
    id: 'block.edubox.to_the_general_terms_and_conditions',
    defaultMessage: 'To the general terms and conditions',
  },
  studying_type: {
    id: 'block.edubox.studying_type',
    defaultMessage: 'Studying type',
  },
  study_start: {
    id: 'block.edubox.study_start',
    defaultMessage: 'Study start',
  },
  studying_model: {
    id: 'block.edubox.studying_model',
    defaultMessage: 'Studying mode',
  },
  stay_abroad: {
    id: 'block.edubox.stay_abroad',
    defaultMessage: 'Stay abroad',
  },
  application_fee: {
    id: 'block.edubox.application_fee',
    defaultMessage: 'Application fee',
  },
  semester_fee: {
    id: 'block.edubox.semester_fee',
    defaultMessage: 'Semester fee',
  },
  semester_start: {
    id: 'block.edubox.semester_start',
    defaultMessage: 'Semester start',
  },
  application_note: {
    id: 'block.edubox.application_note',
    defaultMessage: 'Application note',
  },
  application_button_text: {
    id: 'block.edubox.application_button_text',
    defaultMessage: 'Apply now',
  },
  info_event_button_text: {
    id: 'block.edubox.info_event_button_text',
    defaultMessage: 'Go to information events',
  },
});
