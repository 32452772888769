import {
  FieldError,
  DateInput,
  DateSegment,
  Label,
  Text,
  TimeField as RACTimeField,
  TimeFieldProps as RACTimeFieldProps,
  TimeValue,
  ValidationResult,
} from 'react-aria-components';

export type TimeFieldProps<T extends TimeValue> = {
  label?: string;
  labelRequired?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  isRequired?: boolean;
} & RACTimeFieldProps<T>;

const TimeField = <T extends TimeValue>({
  label,
  description,
  errorMessage,
  isRequired = false,
  labelRequired,
  ...props
}: TimeFieldProps<T>) => {
  return (
    <RACTimeField {...props} hourCycle={24}>
      <Label>
        {label}
        {isRequired && <Text slot="description">{labelRequired}</Text>}
      </Label>
      <FieldError>{errorMessage}</FieldError>
      <DateInput>{(segment) => <DateSegment segment={segment} />}</DateInput>
      {description && <Text slot="description">{description}</Text>}
    </RACTimeField>
  );
};

export default TimeField;
