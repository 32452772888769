import { InfoboxProps } from '@fhnw/types/infobox';
import { Link } from '../Link/Link';
import { useIntl } from 'react-intl';
import TeaserContent from '../Teaser/TeaserContent';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import Image from '@plone/volto/components/theme/Image/Image';
import isEmpty from 'lodash/isEmpty';
import { UniversalLink } from '@plone/volto/components';
import { teaserBlockDefaultMessages } from '../Blocks/Teaser/variations/TeaserBlockDefaultMessages';
import { getItemReference } from '@fhnw/helpers/Url/UrlHelper';
import { useSelector } from 'react-redux';
import { FormState } from '@fhnw/types/formState';

const Infobox: React.FC<InfoboxProps<any>> = (props: any) => {
  const { data, isEditMode } = props;
  const token = useSelector((state: FormState) => state?.userSession?.token);
  const intl = useIntl();

  const showTeaserImage = data.showTeaserImage ?? false;

  const overwrite = data?.overwrite === true;
  const href = data?.href?.[0];

  const title = overwrite
    ? data?.title
    : isEmpty(href?.teaser_title)
      ? href?.title ?? data?.title
      : href?.teaser_title;
  const text = overwrite
    ? isEmpty(data.description)
      ? undefined
      : data?.description
    : isEmpty(href?.teaser_text)
      ? data?.description
      : href?.teaser_text;
  const image = overwrite ? data?.preview_image?.[0] : href;
  const heading = overwrite ? data?.head_title : '';

  const showButtonLink = data.buttonText ? data.buttonText : false;

  return (
    <>
      {!href && isEditMode && (
        <div className="teaser-item placeholder">
          <img src={imageBlockSVG} alt="" />
          <p>
            {intl.formatMessage(teaserBlockDefaultMessages.PleaseChooseContent)}
          </p>
        </div>
      )}
      {href && (
        <div className="blocks-group-wrapper transparent">
          <div className="web25__infobox">
            <div className="fullbleed-infobox-wrapper"></div>
            <div className="infobox-wrapper">
              {showTeaserImage && (image || href) && (
                <div className="infobox-image">
                  <Image
                    {...{
                      item: image ?? href,
                      src: null,
                      imageField: image ? image.image_field : href?.image_field,
                      alt: '',
                      loading: 'lazy',
                      responsive: true,
                    }}
                  />
                </div>
              )}
              <div className="fullbleed-teaser-box">
                <TeaserContent category={heading} title={title} text={text} />
                {showButtonLink && (
                  <Link
                    className="btn btn-primary btn--md text-center"
                    {...(isEditMode
                      ? {}
                      : {
                          href: getItemReference(href, token),
                        })}
                    title={title}
                    linkComponent={UniversalLink}
                  >
                    {data?.buttonText}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Infobox;
