import {
  insertInArray,
  removeFromArray,
} from '@plone/volto/helpers/Utils/Utils';
import baseMessages from '@fhnw/messages/base';

export const imageBlockSchemaEnhancer = ({ formData, schema, intl }) => {
  if (formData.url) {
    // schema.fieldsets[0].fields = insertInArray(
    //   schema.fieldsets[0].fields,
    //   'block_description',
    //   1,
    // );
    schema.fieldsets[0].fields = insertInArray(
      schema.fieldsets[0].fields,
      'description',
      1,
    );
    schema.fieldsets[0].fields = insertInArray(
      schema.fieldsets[0].fields,
      'format',
      4,
    );

    schema.fieldsets[0].fields = removeFromArray(schema.fieldsets[0].fields, 5);
    schema.fieldsets[0].fields = removeFromArray(schema.fieldsets[0].fields, 3);
    // schema.properties.block_description = {
    //   title: intl.formatMessage(baseMessages.Description),
    //   widget: 'textarea',
    // };
    schema.properties.alt = {
      title: intl.formatMessage(baseMessages.AlternativeText),
    };
    schema.properties.align = {
      ...schema.properties.align,
      disable: 'false',
    };
    schema.properties.description = {
      title: intl.formatMessage(baseMessages.Caption),
    };
    schema.properties.format = {
      title: intl.formatMessage(baseMessages.Format),
      widget: 'format_widget',
      default: 'large',
    };
  }
  schema.properties.align.default = 'center';
  schema.properties.align.actions = ['left', 'right', 'center'];
  schema.properties.size.default = 'l';
  schema.properties.align.disable = formData.format === 'large';
  return schema;
};
