import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Testimonial: {
    id: 'Testimonial',
    defaultMessage: 'Testimonial Text',
  },
  Testimonials: {
    id: 'Testimonials',
    defaultMessage: 'Testimonials',
  },
  Image: {
    id: 'Image',
    defaultMessage: 'Image',
  },
  Person: {
    id: 'Person',
    defaultMessage: 'Person',
  },
  Button_text: {
    id: 'Button Text',
    defaultMessage: 'Button Text',
  },
  Button_link: {
    id: 'Button Link',
    defaultMessage: 'Button Link',
  },
});

export const itemSchema = (props) => {
  return {
    title: props.intl.formatMessage(messages.Testimonials),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['testimonial', 'image', 'name', 'button_text', 'button_link'],
      },
    ],

    properties: {
      testimonial: {
        title: props.intl.formatMessage(messages.Testimonial),
        widget: 'textarea',
      },
      image: {
        title: props.intl.formatMessage(messages.Image),
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
      },
      name: {
        title: props.intl.formatMessage(messages.Person),
      },
      button_text: {
        title: props.intl.formatMessage(messages.Button_text),
        widget: 'text',
      },
      button_link: {
        title: props.intl.formatMessage(messages.Button_link),
        widget: 'url',
      },
    },
    required: [],
  };
};
